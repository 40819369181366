export default function ShipIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.4198 13.1698C21.2898 13.5198 21.8297 14.5498 21.6297 15.4598L21.2197 17.3198C20.5097 20.5198 17.9997 22.7998 14.3797 22.7998H9.61974C5.99974 22.7998 3.48974 20.5198 2.77974 17.3198L2.36974 15.4598C2.16974 14.5498 2.70973 13.5198 3.57973 13.1698L4.99974 12.5998L10.5098 10.3898C11.4698 10.0098 12.5297 10.0098 13.4897 10.3898L18.9997 12.5998L20.4198 13.1698Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 22.7998V10.7998"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 8.7998V12.5998L13.49 10.3898C12.53 10.0098 11.47 10.0098 10.51 10.3898L5 12.5998V8.7998C5 7.1498 6.35 5.7998 8 5.7998H16C17.65 5.7998 19 7.1498 19 8.7998Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 5.7998H9.5V3.7998C9.5 3.2498 9.95 2.7998 10.5 2.7998H13.5C14.05 2.7998 14.5 3.2498 14.5 3.7998V5.7998Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
