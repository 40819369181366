import { TFunction } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import Grid from '@mui/material/Grid';
import { CheckboxField, SelectField, TextField } from '../../componentsv2/Field';
import MultiSelectField from '../../componentsv2/Field/MultiSelectField';
import Button from '../../componentsv2/Button';
import Form from '../../componentsv2/Form';
import DateRangeField from '../../componentsv2/Field/DateRangeField';
import { useTranslation } from '../../hooks/i18n';
import { FilterType, Nullable } from '../../types';

type TableFiltersProps = {
  filterOptions: FilterType[];
  applyFilters: Nullable<Function>;
  clearFilters: Nullable<Function>;
  initialValues: any;
  trackingEvent?: string;
  showFilteringButtons?: boolean;
  showLabel?: boolean;
};

const renderFilterFormikField = (
  {
    name,
    type,
    title,
    placeholder = 'admin:clientsPage.gridFilters.genericFilterPlaceholder',
    options = [],
    tKey,
    Icon,
    showLabel = true,
    disableFuture = true,
  }: FilterType,
  values: any,
  t: TFunction,
  setFieldValue: (field: string, value: any) => void
) => {
  if (type === 'text') {
    return (
      <TextField
        startAdornment={Icon}
        name={name}
        placeholder={t(placeholder)}
        label={showLabel ? (title ? t(title) : '') : undefined}
        clearable
      />
    );
  } else if (type === 'number') {
    return (
      <TextField
        type="number"
        startAdornment={Icon}
        name={name}
        placeholder={t(placeholder)}
        label={showLabel ? (title ? t(title) : '') : undefined}
        clearable
      />
    );
  } else if (type === 'select') {
    return (
      <SelectField
        startAdornment={Icon}
        name={name}
        placeholder={t(placeholder)}
        label={showLabel ? (title ? t(title) : '') : undefined}
        clearable
        options={options.map((value: string) => {
          // if tKey is a function, it means that we need to use it to get the label, this is useful for translation keys that are more complex than just a string
          const label = tKey && typeof tKey === 'function' ? tKey(value) : `${tKey}.${value}`;
          return {
            value,
            label: t(label),
          };
        })}
      />
    );
  } else if (type === 'multipleSelect') {
    return (
      <MultiSelectField
        name={name}
        value={values[name] ?? []}
        placeholder={t(placeholder)}
        label={showLabel ? (title ? t(title) : '') : undefined}
        options={options.map((value: string) => ({
          value,
          label: t(`${tKey}.${value}`),
        }))}
      />
    );
  } else if (type === 'checkbox') {
    return <CheckboxField name={name} label={showLabel ? (title ? t(title) : '') : undefined} />;
  } else if (type === 'dateRange') {
    return (
      <DateRangeField
        name={name}
        label={showLabel ? (title ? t(title) : '') : undefined}
        disableFuture={disableFuture}
        clearable
        value={values[name]}
      />
    );
  }
};

function FiltersForm({
  filterOptions,
  trackingEvent,
  values,
  t,
  setFieldValue,
  resetForm,
  clearFilters,
  showFilteringButtons = true,
  submitForm,
  dirty,
}: {
  filterOptions: FilterType[];
  values: any;
  t: TFunction;
  setFieldValue: (field: string, value: any) => void;
  resetForm: () => void;
  clearFilters: Nullable<Function>;
  trackingEvent?: string;
  showFilteringButtons?: boolean;
  submitForm: () => void;
  dirty: boolean;
}) {
  return (
    <Form
      name="tableFilters"
      id="tableFilters"
      noValidate
      style={{ width: '100%' }}
      trackingEvent={trackingEvent}>
      <Grid container rowSpacing={2} columnSpacing={2} sx={{ alignItems: 'end' }}>
        {filterOptions.map((filter: FilterType) => (
          <Grid item xs={filter.size} key={filter.name} style={{ display: 'flex' }}>
            {renderFilterFormikField(filter, values, t, setFieldValue)}
          </Grid>
        ))}
        {showFilteringButtons ? (
          <>
            <Grid item xs={1}>
              <Button
                fullWidth
                onClick={() => {
                  resetForm();
                  clearFilters && clearFilters();
                }}
                type="outline">
                {t(`admin:clientsPage.gridFilters.reset`)}
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button isSubmit fullWidth>
                {t(`admin:clientsPage.gridFilters.apply`)}
              </Button>
            </Grid>
          </>
        ) : (
          <Grid item xs={1}>
            <Button isSubmit fullWidth disabled={!dirty} color="secondary">
              {t(`admin:clientsPage.gridFilters.apply`)}
            </Button>
          </Grid>
        )}
      </Grid>
    </Form>
  );
}

const TableFilters = ({
  filterOptions = [],
  applyFilters,
  clearFilters,
  initialValues,
  trackingEvent,
  showFilteringButtons = true,
}: TableFiltersProps) => {
  const { t } = useTranslation('admin');

  return (
    <Formik
      initialValues={{ ...initialValues }}
      enableReinitialize
      onSubmit={(values: any) => {
        applyFilters && applyFilters(values);
      }}>
      {({ values, resetForm, setFieldValue, submitForm, dirty }: FormikProps<any>) => {
        return (
          <FiltersForm
            filterOptions={filterOptions}
            values={values}
            t={t}
            setFieldValue={setFieldValue}
            resetForm={resetForm}
            clearFilters={clearFilters}
            trackingEvent={trackingEvent}
            showFilteringButtons={showFilteringButtons}
            submitForm={submitForm}
            dirty={dirty}
          />
        );
      }}
    </Formik>
  );
};

export default TableFilters;
