export interface Step {
  index: number;
  percentage: {
    start: number;
    end: number;
  };
}

export interface Field {
  values?: any;
  setFieldValue?: any;
}

export const STEPS: Step[] = [
  { index: 1, percentage: { start: 0, end: 8.3 } },
  { index: 2, percentage: { start: 8.3, end: 16.6 } },
  { index: 3, percentage: { start: 16.6, end: 24.9 } },
  { index: 4, percentage: { start: 24.9, end: 33.2 } },
  { index: 5, percentage: { start: 33.2, end: 41.5 } },
  { index: 6, percentage: { start: 41.5, end: 49.8 } },
  { index: 7, percentage: { start: 49.8, end: 58.1 } },
  { index: 8, percentage: { start: 58.1, end: 66.4 } },
  { index: 9, percentage: { start: 66.4, end: 74.7 } },
  { index: 10, percentage: { start: 74.7, end: 83 } },
  { index: 11, percentage: { start: 83, end: 91.9 } },
  { index: 12, percentage: { start: 91.9, end: 100 } },
];

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const emailRegExp =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
