export default function SearchIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4328 14.7363L12.2453 10.5488C13.1516 9.43945 13.6516 8.04258 13.6516 6.51758C13.6516 2.92695 10.7413 0.0175781 7.15157 0.0175781C3.56188 0.0175781 0.679688 2.92789 0.679688 6.51758C0.679688 10.1073 3.58969 13.0176 7.15157 13.0176C8.67626 13.0176 10.075 12.4891 11.1828 11.6101L15.3703 15.7976C15.5453 15.9457 15.7391 16.0176 15.9297 16.0176C16.1203 16.0176 16.3134 15.9443 16.46 15.7979C16.7516 15.5051 16.7516 15.0301 16.4328 14.7363ZM2.17969 6.51758C2.17969 3.7607 4.42282 1.51758 7.17969 1.51758C9.93657 1.51758 12.1797 3.7607 12.1797 6.51758C12.1797 9.27445 9.93657 11.5176 7.17969 11.5176C4.42282 11.5176 2.17969 9.27383 2.17969 6.51758Z"
        fill="currentColor"
      />
    </svg>
  );
}
