import { useState } from 'react';
import Grid from '@mui/material/Grid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CloseOutlined } from '@mui/icons-material';
import { Holiday } from "../../types/holidays";
import { useUserLocation } from '../../hooks/useUserLocation';
import { COLORS } from '../../themev2';
import { formatDate } from '../../utils/common';
import Typography from '../../componentsv2/Typography';
import { useLazyTranslation } from '../../hooks/i18n';
import { Nullable } from '../../types';

export type HolidayNoticeProps = {
    holiday: Nullable<Holiday>;
}

const HolidayNotice = ({ holiday }: HolidayNoticeProps) => {
    const { language } = useUserLocation();
    const [isVisible, setIsVisible] = useState(true);

    const { t, isLoading: isLoadingTranslations } = useLazyTranslation(['common', 'application', 'servicing']);


    if (!isVisible || !holiday || isLoadingTranslations) {
        return null;
    }

    return (
        <Grid item xs={12}>
            <Grid sx={{ display: 'flex', alignItems: 'start', gap: '4px', backgroundColor: '#E0E4F2', padding: "16px", borderRadius: "12px" }}>
                <InfoOutlinedIcon sx={{ color: '#4F55FD', height: '20px', marginTop: '2px' }} />
                <Typography
                    sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: 400,
                        color: COLORS.gray[1100],
                    }}>
                    {t('holidays.noticeMessageOne')}
                    <span style={{ fontWeight: 600 }}> {formatDate(
                        new Date(holiday.date),
                        language, {
                        year: undefined,
                        month: 'long',
                        day: 'numeric'
                    },
                        true,
                        true

                    )}</span>
                    {t('holidays.noticeMessageTwo')}<span style={{ fontWeight: 600 }}> {t(`holidays.${holiday.name}`)}.</span>
                </Typography>
                <CloseOutlined
                    sx={{ cursor: 'pointer', color: '#4F55FD', height: '20px', marginTop: '2px', marginLeft: 'auto' }}
                    onClick={() => setIsVisible(false)} />

            </Grid>
        </Grid>
    )
}

export default HolidayNotice;