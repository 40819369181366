import { useAppDispatch, useAppSelector, setAnonymousUserLocation } from '../store';
import { CountryKeys } from '../types';

export function useAnonymousUser() {
  const userLocation = useAppSelector((state) => state.anonymous.userLocation);
  const dispatch = useAppDispatch();

  function handleChangeLocation(location: CountryKeys) {
    dispatch(setAnonymousUserLocation(location));
  }

  return {
    location: userLocation,
    handleChangeLocation,
  };
}
