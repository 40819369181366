import { useEffect, useState } from 'react';
import { logEvent } from '@amplitude/analytics-browser';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Grid, { GridProps } from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '../../componentsv2/Button';
import TopBar from '../../componentsv2/TopBar';
import Typography from '../../componentsv2/Typography';
import { ArrowLeftIcon, ArrowRightIcon } from '../../components/Icons';
import { newApplication } from '../../services/application';
import { useLazyTranslation } from '../../hooks/i18n';
import { useAuth } from '../../hooks';
import { useUserLocation } from '../../hooks/useUserLocation';
import { transformFactoringRequest, useAppSelector } from '../../store';
import { APIApplication } from '../../types/application';
import { Nullable } from '../../types';
import { APP_FLOW } from '../../constants/auth';
import { invoiceEvents } from '../../constants/events';
import { COLORS } from '../../themev2';
import classes from './Application.module.scss';

export const CustomGridItem = styled((props: GridProps) => <Grid {...props} />)(({ theme }) => ({
  paddingLeft: '0 !important',
}));

export const BulletedTypography = styled((props: TypographyProps) => <Typography {...props} />)(
  ({ theme }) => ({
    '&::before': {
      content: '"•"',
      marginRight: '8px',
    },
  })
);

const IntroAppScreen = ({
  setApplication,
  application,
}: {
  setApplication: (application: APIApplication) => void;
  application: Nullable<APIApplication>;
}) => {
  const { t, isLoading: isLoadingTranslations } = useLazyTranslation(['application']);
  const [isLoadingApplication, setIsLoadingApplication] = useState(false);
  const client = useAppSelector((state) => state.client.client);
  const newCompany = useAppSelector((state) => state.client.newCompany);
  const company = client!.companies[0];
  const navigate = useNavigate();
  const { user } = useAuth();
  const { location } = useUserLocation();

  const isLoading = isLoadingApplication || isLoadingTranslations;
  const isReapply = application?.status === 'application_rejected' && !application.isReapply;

  const handleStartApplication = () => {
    logEvent(invoiceEvents.START);
    setIsLoadingApplication(true);
    newApplication(company.id, isReapply)
      .then((application) => {
        setApplication(transformFactoringRequest(application, newCompany!));
        localStorage.setItem(APP_FLOW, application.flowType);
      })
      .finally(() => {
        setIsLoadingApplication(false);
      });
  };

  useEffect(() => {
    logEvent(invoiceEvents.INTRO);
  }, []);

  return (
    <>
      <TopBar>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '1em', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '24px', height: '24px' }}>
            <IconButton
              onClick={() => {
                navigate(-1);
              }}>
              <ArrowLeftIcon />
            </IconButton>
          </Box>
          <Box sx={{ marginX: 'auto' }}>
            <Typography>{t('application:intro.topBar')}</Typography>
          </Box>
        </Box>
      </TopBar>
      <div className={classes.icon}>
        <svg width="355" height="477" viewBox="0 0 355 477" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            opacity="0.4"
            d="M302.295 39.7007H104.428C46.7946 39.7007 0.12793 87.5219 0.12793 146.049V369.929C0.12793 428.457 47.0279 476.278 104.428 476.278H302.061C359.695 476.278 406.361 428.457 406.361 369.929V146.049C406.595 87.284 359.695 39.7007 302.295 39.7007Z"
            fill="url(#paint0_linear_527_23698)"
          />
          <path
            d="M258.194 0.491943H148.528C124.261 0.491943 104.428 20.4769 104.428 45.2203V67.5844C104.428 92.3278 124.028 112.313 148.294 112.313H258.194C282.461 112.313 302.061 92.3278 302.061 67.5844V45.2203C302.294 20.4769 282.461 0.491943 258.194 0.491943ZM175.594 356.177C171.161 356.177 166.728 354.512 163.228 350.943L128.228 315.256C124.973 311.898 123.148 307.367 123.148 302.646C123.148 297.925 124.973 293.394 128.228 290.037C134.994 283.137 146.194 283.137 152.961 290.037L175.594 313.114L256.561 230.557C263.328 223.658 274.528 223.658 281.294 230.557C288.061 237.457 288.061 248.877 281.294 255.777L187.961 350.943C184.694 354.512 180.028 356.177 175.594 356.177Z"
            fill="url(#paint1_linear_527_23698)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_527_23698"
              x1="203.245"
              y1="145.33"
              x2="201.336"
              y2="420.343"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#EAF5FF" />
              <stop offset="1" stopColor="#CDDFF0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_527_23698"
              x1="203.245"
              y1="86.5498"
              x2="200.642"
              y2="310.587"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#EAF5FF" />
              <stop offset="1" stopColor="#CDDFF0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <Container
        maxWidth={false}
        sx={{ maxWidth: { xs: '100%', sm: 400, md: 724, lg: 1080 }, paddingBottom: '1rem' }}>
        <Grid container sx={{ marginTop: { xs: '8px', md: '40px' } }} rowSpacing={4}>
          <Grid item xs={12} md={8}>
            <Grid container>
              <Grid item xs={12} sx={{ paddingLeft: 0 }}>
                <Typography
                  variant="h5"
                  sx={{
                    background: 'linear-gradient(255.79deg, #4572B8 0%, #2FC0B8 95.35%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textAlign: { xs: 'left', md: 'initial' },
                  }}>
                  {t('application:intro.title', { name: user?.firstName })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ color: '#0A1334', textAlign: { xs: 'left', md: 'initial' } }}>
                  {t('application:intro.subtitle')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#66758F',
                    whiteSpace: 'pre-line',
                    textAlign: { xs: 'left', md: 'initial' },
                  }}>
                  {t('application:intro.description')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{ color: COLORS.secondary.indigo, textAlign: { xs: 'left', md: 'initial' } }}>
                  {t('application:intro.requirements.title')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <BulletedTypography
                  variant="body1"
                  sx={{ color: '#4F5A84', textAlign: { xs: 'left', md: 'initial' } }}>
                  {t('application:intro.requirements.generalInfo')}
                </BulletedTypography>
                {/* not the most correct approach ever but it's way easier than using the flow type that's not set yet */}
                {location === 'mx' && (
                  <BulletedTypography
                    variant="body1"
                    sx={{ color: '#4F5A84', textAlign: { xs: 'left', md: 'initial' } }}>
                    {t('application:intro.requirements.SAT')}
                  </BulletedTypography>
                )}
                <BulletedTypography
                  variant="body1"
                  sx={{ color: '#4F5A84', textAlign: { xs: 'left', md: 'initial' } }}>
                  {t('application:intro.requirements.customersData')}
                </BulletedTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Button
                  // Once the application has the flag isReapply as true, we don't want the clients to reapply again.
                  disabled={application?.isReapply}
                  fullWidth
                  onClick={handleStartApplication}
                  isLoading={isLoading}
                  endIcon={isLoading ? null : <ArrowRightIcon />}>
                  {t('application:startApplicationButton')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default IntroAppScreen;
