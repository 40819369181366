export default function TrashIcon({ size = 'md' }: { size?: string }) {
  const width = size === 'sm' ? '14' : '24';
  const height = size === 'sm' ? '14' : '24';
  return (
    <svg width={width} height={height} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.39844 5.47866H20.5984M8.59844 17.4787V10.2787M13.3984 17.4787V10.2787M15.7984 22.2787H6.19844C4.87295 22.2787 3.79844 21.2041 3.79844 19.8787V6.67866C3.79844 6.01592 4.3357 5.47866 4.99844 5.47866H16.9984C17.6612 5.47866 18.1984 6.01592 18.1984 6.67866V19.8787C18.1984 21.2041 17.1239 22.2787 15.7984 22.2787ZM8.59844 5.47866H13.3984C14.0612 5.47866 14.5984 4.9414 14.5984 4.27866V3.07866C14.5984 2.41592 14.0612 1.87866 13.3984 1.87866H8.59844C7.9357 1.87866 7.39844 2.41592 7.39844 3.07866V4.27866C7.39844 4.9414 7.9357 5.47866 8.59844 5.47866Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
