import { Box, Divider, Grid } from '@mui/material';
import { useLazyTranslation } from '../../hooks/i18n';
import { IDocumentCategory } from '../../types/documents';
import Typography from '../../componentsv2/Typography';
import DocumentCategory from './DocumentCategory';

const DocumentCategories = ({
  groupedCategories,
  companyId,
  language,
  loadClientCategories,
  isAdmin = false,
  onApprove = () => {},
  onReject = () => {},
  uploadAssets,
  deleteAsset,
  onToggle = () => {},
}: {
  groupedCategories: Record<string, IDocumentCategory[]>;
  companyId: number;
  language: string;
  loadClientCategories: (companyId: number) => void;
  isAdmin?: boolean;
  onApprove?: (categoryId: number) => void;
  onReject?: (categoryId: number, rejectionComment: string) => void;
  uploadAssets: (
    categoryId: number,
    documentId: number,
    files: File[],
    categoryName: string,
    groupName: string
  ) => void;
  deleteAsset: (categoryId: number, documentId: number, assetId: number) => void;
  onToggle?: (categoryId: number) => void;
}) => {
  const { t } = useLazyTranslation(['client']);

  return (
    <>
      {Object.entries(groupedCategories).map(([group, documents]) => {
        return (
          <Grid item xs={12} key={group}>
            <Box
              sx={{
                boxShadow: '0px 10px 24px rgba(201, 210, 224, 0.3)',
                background: '#fbfcfe',
                borderRadius: '12px',
                padding: '2em',
                marginBottom: '1em',
              }}>
              <Typography sx={{ fontSize: '1.2em', fontWeight: 600 }}>
                {t(`client:documents.groups.${group}`)}
              </Typography>
              {documents.map((document, index) => {
                return (
                  <>
                    <DocumentCategory
                      {...document}
                      language={language}
                      loadClientCategories={loadClientCategories}
                      isAdmin={isAdmin}
                      onApprove={onApprove}
                      onReject={onReject}
                      uploadAssets={uploadAssets}
                      deleteAsset={deleteAsset}
                      onToggle={onToggle}
                      groupName={group}
                    />
                    {index < documents.length - 1 && <Divider />}
                  </>
                );
              })}
            </Box>
          </Grid>
        );
      })}
    </>
  );
};

export default DocumentCategories;
