import { Trans } from 'react-i18next';
import { useTranslation } from '../../hooks/i18n';
import MarcoLogo from '../../componentsv2/MarcoLogo';
import Grid from '@mui/material/Grid';

export default function ErrorBoundary() {
  const { t } = useTranslation();

  return (
    <Grid container sx={{ height: '100vh', display: 'grid', placeItems: 'center' }}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          fontSize: '1.5em',
        }}>
        <MarcoLogo size="large" />
        <div>
          <h1>{t('errorBoundary.title')}</h1>
          <Trans t={t} i18nKey="errorBoundary.subtitle">
            <p>
              Let's get you back <a href="/">Home</a>
            </p>
          </Trans>
        </div>
      </Grid>
    </Grid>
  );
}
