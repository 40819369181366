import * as React from 'react';
import { useNavigate as _useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MarcoDrawer from '../Drawer';
import MarcoAppBar from '../AppBar';
import { useUserLocation as _useUserLocation } from '../../hooks/useUserLocation';
import { useAuth } from '../../hooks';
import {
  useAppDispatch,
  logout,
  resetAlertsSlice,
  resetApplicationSlice,
  resetAssetsSlice,
  resetClientState,
  resetClientsState,
  useAppSelector,
  setAdminTakeoverClient,
  setShowSidebar,
} from '../../store';
import { ADMIN_TAKEOVER_TOKEN_STORAGE_KEY, CURRENT_USER } from '../../constants/auth';

type LayoutProps = {
  useNavigate?: Function;
  useUserLocation?: Function;
  useLocation?: Function;
};

const Layout: React.FC<LayoutProps> = ({
  children,
  useUserLocation = _useUserLocation,
  useNavigate = _useNavigate,
}) => {
  const dispatch = useAppDispatch();
  const { adminTakeoverClient, showSidebar } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const { isAdmin, user } = useAuth();

  const setLogout = () => {
    if (adminTakeoverClient && !!localStorage.getItem(ADMIN_TAKEOVER_TOKEN_STORAGE_KEY)) {
      localStorage.removeItem(ADMIN_TAKEOVER_TOKEN_STORAGE_KEY);
      localStorage.removeItem(CURRENT_USER);
      const client = adminTakeoverClient;
      dispatch(setAdminTakeoverClient(null));
      window.location.replace(`/companies/${client}`);
    } else {
      dispatch(logout())
        .unwrap()
        .then(() => {
          navigate('/login');
          dispatch(resetClientState());
          dispatch(resetClientsState());
          dispatch(resetAlertsSlice());
          dispatch(resetApplicationSlice());
          dispatch(resetAssetsSlice());
        });
    }
  };

  const handleShowSidebar = (show: boolean) => {
    dispatch(setShowSidebar(show));
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <MarcoAppBar
        open={showSidebar}
        useUserLocation={useUserLocation}
        logoutUser={setLogout}
        user={user}
        setOpen={handleShowSidebar}
      />
      <MarcoDrawer open={showSidebar} setOpen={handleShowSidebar} isAdmin={isAdmin} />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: { xs: 2, md: 4 }, marginTop: '64px', backgroundColor: '#F1F6FD' }}>
        <Container
          sx={{
            maxWidth: { xs: '100%', lg: 1272, xl: 1500 },
            paddingBottom: '1rem',
          }}>
          {children}
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;
