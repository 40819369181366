import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import BusinessStep from './BusinessStep';
import CompanyStep from './CompanyStep';
import TaxStep from './TaxStep';
import FinancingStepCustomersList from './FinancingStepCustomersList';
import FinancingStepAging from './FinancingStepAging';
import { useTranslation } from '../../hooks/i18n';
import { useAppDispatch, showSideBar, useAppSelector } from '../../store';
import * as service from '../../services/application';
import { APIApplication, APIAsset, ApplicationFlowType, ApplicationStepProps } from '../../types/application';
import { CURRENT_APP_STEP_STORAGE_KEY } from '../../constants/auth';
import { ApplicationFlow } from '../../constants/application';
import CompanyAndBusinessStepLite from './CompanyAndBusinessStepLite';
import TopBar from '../../componentsv2/TopBar';
import { Box, IconButton, Step, StepLabel, Stepper } from '@mui/material';
import { COLORS } from '../../themev2';
import { ArrowLeftIcon } from '../../components/Icons';
import { useNavigate } from 'react-router-dom';
import { Nullable } from '../../types';

type ApplicationStep = {
  stepLabel: string;
  title: string;
  subtitle: string;
  StepComponent: (props: ApplicationStepProps) => JSX.Element;
  icon: JSX.Element;
};

const getStepsByFlowType = (appFlow: ApplicationFlowType): ApplicationStep[] => {
  if (appFlow === ApplicationFlow.Satws) {
    return [
      {
        stepLabel: 'steps.company.label',
        title: 'steps.company.title',
        subtitle: 'steps.company.subtitle',
        StepComponent: CompanyAndBusinessStepLite,
        icon: <CorporateFareIcon />,
      },
      {
        stepLabel: 'steps.tax.label',
        title: 'steps.tax.title',
        subtitle: 'steps.tax.subtitle',
        StepComponent: TaxStep,
        icon: <TextSnippetIcon />,
      },
      {
        stepLabel: 'steps.financing.label',
        title: 'steps.financing.title',
        subtitle: 'steps.financing.subtitle',
        StepComponent: FinancingStepCustomersList,
        icon: <AttachMoneyIcon />,
      },
    ];
  }
  // traditional flow
  return [
    {
      stepLabel: 'steps.company.label',
      title: 'steps.company.title',
      subtitle: 'steps.company.subtitle',
      StepComponent: CompanyStep,
      icon: <CorporateFareIcon />,
    },
    {
      stepLabel: 'steps.business.label',
      title: 'steps.business.title',
      subtitle: 'steps.business.subtitle',
      StepComponent: BusinessStep,
      icon: <TextSnippetIcon />,
    },
    {
      stepLabel: 'steps.financing.label',
      title: 'steps.financing.title',
      subtitle: 'steps.financing.subtitle',
      StepComponent: FinancingStepAging,
      icon: <AttachMoneyIcon />,
    },
  ];
};

type ApplicationStepperProps = {
  setDisplayCompletedModal: (displayModal: boolean) => void;
  application: APIApplication;
  onApplicationSubmit: (application: APIApplication) => void;
  errorMessage: string;
  isLoading: boolean;
  isCustomerListEnabled: boolean;
  storedCurrentStep: Nullable<number>;
};

const ApplicationStepper = ({
  application,
  onApplicationSubmit,
  errorMessage,
  isLoading,
  storedCurrentStep,
}: ApplicationStepperProps) => {
  const dispatch = useAppDispatch();
  const newCompany = useAppSelector((state) => state.client.newCompany);
  const [currentStep, setCurrentStep] = useState(storedCurrentStep || 0);
  const [applicationDraft, setApplicationDraft] = useState<APIApplication>(application);
  const steps = getStepsByFlowType(application?.flowType);
  const navigate = useNavigate();
  const [isLoadingStep, setIsLoadingStep] = useState(false);

  const { StepComponent, title, subtitle } = steps[currentStep];
  const { t } = useTranslation('application');

  const onFilesUpload = (files: APIAsset[]) => {
    // this is not correct, all the dropzone magic should happen here
    setApplicationDraft((prev) => ({ ...prev, assets: [...(prev.assets || []), ...files] }));
  };

  const onFileDelete = (deletedFile: APIAsset) => {
    setApplicationDraft((prev) => ({
      ...prev,
      assets: [...prev.assets.filter((file) => file.id !== deletedFile.id)],
    }));
  };

  useEffect(() => {
    dispatch(showSideBar(false));
    return () => {
      dispatch(showSideBar(true));
    };
  }, [dispatch]);

  const onStepReturn = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
      window.scrollTo(0, 0);
    }
  };
  const isLastStep = currentStep === steps.length - 1;

  const onNextStep = (stepValues: any) => {
    setIsLoadingStep(true);
    service
      .updateApplication(stepValues, application.id, newCompany!)
      .then((submittedValues) => {
        setApplicationDraft((prev) => ({ ...prev, ...submittedValues }));
        if (isLastStep) {
          onApplicationSubmit(submittedValues);
        } else {
          const nextStep = currentStep + 1;
          setCurrentStep(nextStep);
          localStorage.setItem(CURRENT_APP_STEP_STORAGE_KEY, `${nextStep}`);
          window.scrollTo(0, 0);
        }
      })
      .finally(() => {
        setIsLoadingStep(false);
      });
  };

  return (
    <>
      <TopBar>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '1em' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '24px', height: '24px' }}>
            <IconButton
              onClick={() => {
                navigate(-1);
              }}>
              <ArrowLeftIcon />
            </IconButton>
          </Box>
          <Box sx={{ width: '60%', marginX: 'auto' }}>
            <Stepper
              activeStep={currentStep}
              sx={{
                '& .MuiStepIcon-root.Mui-active': { color: COLORS.gray[1300] },
                '& .Mui-disabled .MuiStepIcon-root': {
                  color: '#91A2BB',
                  display: { xs: 'none', md: 'initial' },
                },
                '& .Mui-completed .MuiStepIcon-root': {
                  display: { xs: 'none', md: 'initial' },
                },
                '& .MuiStepIcon-text': { fill: 'white' },
              }}>
              {steps.map(({ title }) => (
                <Step key={title}>
                  <StepLabel
                    sx={{
                      '&.Mui-disabled .MuiStepLabel-label': {
                        color: '#91A2BB',
                        display: { xs: 'none', md: 'initial' },
                      },
                      '& .MuiStepLabel-labelContainer .Mui-completed': {
                        display: { xs: 'none', md: 'initial' },
                      },
                    }}>
                    <Typography fontWeight={600} sx={{ minWidth: { xs: '150px', md: 'initial' } }}>
                      {t(title)}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '24px', height: '24px' }}>
            {/* <CrossIcon /> */}
          </Box>
        </Box>
      </TopBar>
      <Container sx={{ paddingBottom: '5em' }}>
        <Box sx={{ marginY: '2em' }}>
          <Typography sx={{ fontSize: '1.5em', color: COLORS.green[600], fontWeight: 600 }}>
            {t(title)}
          </Typography>
          <Typography sx={{ fontWeight: 500 }}> {t(subtitle)}</Typography>
        </Box>
        <StepComponent
          onStepSubmit={onNextStep}
          onStepReturn={onStepReturn}
          title={title}
          subtitle={subtitle}
          errorMessage={errorMessage}
          applicationDraft={applicationDraft}
          onFilesUpload={onFilesUpload}
          onFileDelete={onFileDelete}
          isLoading={isLoading}
          isLoadingStep={isLoadingStep}
        />
      </Container>
    </>
  );
};

export default ApplicationStepper;
