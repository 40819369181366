import MUISnackbar, { SnackbarProps as MUISnackbarProps } from '@mui/material/Snackbar';

interface SnackbarProps extends Omit<MUISnackbarProps, 'onClose'> {
  handleOnClose: () => void;
}

const Snackbar = ({
  open,
  handleOnClose,
  autoHideDuration = 5000,
  anchorOrigin = { vertical: 'top', horizontal: 'right' },
  children,
  ...rest
}: SnackbarProps) => {
  return (
    <MUISnackbar
      open={open}
      onClose={handleOnClose}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      {...rest}>
      {children}
    </MUISnackbar>
  );
};

export default Snackbar;
