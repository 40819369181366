export default function EditIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4482 6.95241L17.0482 10.5524M4.44824 19.5524L8.81423 18.6727C9.046 18.626 9.25882 18.5119 9.42596 18.3446L19.1996 8.56559C19.6682 8.09674 19.6679 7.33675 19.1989 6.86829L17.1285 4.80021C16.6597 4.33194 15.9001 4.33226 15.4317 4.80092L5.657 14.581C5.49019 14.7479 5.37629 14.9603 5.32954 15.1916L4.44824 19.5524Z"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
