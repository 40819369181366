import Switch, { SwitchProps } from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/system';

interface SwitchToggleProps extends Omit<SwitchProps, 'checked' | 'onChange'> {
  label: string | number | React.ReactElement;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  name: string;
  labelPlacement?: 'top' | 'end' | 'start' | 'bottom';
}

const SwitchToggle = ({
  label = '',
  checked,
  onChange,
  name,
  size = 'medium',
  color = 'secondary',
  labelPlacement = 'end',
  ...props
}: SwitchToggleProps) => {
  return (
    <FormControlLabel
      labelPlacement={labelPlacement}
      control={
        <Switch checked={checked} size={size} onChange={onChange} color={color} name={name} {...props} />
      }
      label={label}
    />
  );
};

export default styled((props: SwitchToggleProps) => <SwitchToggle {...props} />)(
  ({ theme, labelPlacement }) => ({
    width: 42,
    height: 26,
    padding: 0,
    marginLeft: labelPlacement === 'start' ? 8 : 0,
    marginRight: labelPlacement === 'end' ? 8 : 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: 'secondary',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
    },
  })
);
