import React from 'react';
import { Box, Divider } from '@mui/material';
import Typography from '../Typography';
import { COLORS } from '../../themev2';

type FormSectionProps = {
  title?: string;
};

const FormSection: React.FC<FormSectionProps> = ({ title, children }) => {
  return (
    <Box sx={{ color: COLORS.gray[1200], marginTop: '3em' }}>
      <Typography sx={{ fontSize: '16px' }}>{title}</Typography>
      <Divider sx={{ margin: '0.5em 0' }} />
      {children}
    </Box>
  );
};

export default FormSection;
