import { useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FormikContext } from 'formik';
import { useTranslation } from '../../hooks/i18n';
import Typography from '../Typography';
import { FormTrackingContext } from '../Form';
import { logFieldChange } from '../../utils/tracking';
import { CommonProps } from './types';
import { COLORS } from '../../themev2';
import InfoButton from '../InfoButton';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '../../components/Icons/ClearIcon';
import { Box, SvgIcon } from '@mui/material';

type Option = { value: any; label: string };

function hasValue(value: any) {
  return Array.isArray(value) ? !!value.length : !!value;
}

type SelectFieldProps = CommonProps & {
  value?: string;
  options: { value: any; label: string }[];
  renderOption?: (option: Option) => React.ReactNode;
  renderValue?: (value: any) => React.ReactNode;
  startAdornment?: React.ReactNode;
};

export default function SelectField({
  fullWidth = true,
  error,
  name,
  label,
  onChange,
  value,
  options,
  placeholder,
  helperText,
  disabled = false,
  eventLogger = logFieldChange,
  optional = false,
  renderOption,
  renderValue,
  moreInfoTooltip,
  sx = {},
  clearable,
  startAdornment,
}: SelectFieldProps) {
  const { t } = useTranslation();
  const formik = useContext(FormikContext);
  const _error = error || (formik?.touched[name] && formik?.errors[name]);
  const { trackingEvent } = useContext(FormTrackingContext);

  const handleClear = () => {
    formik && formik.setFieldValue(name, '');
    onChange && onChange('');
  };

  return (
    <FormControl fullWidth={fullWidth} error={!!_error}>
      <FormLabel sx={{ padding: '8px 0', fontWeight: 600, display: 'flex' }}>
        <Typography>{label}</Typography>
        {moreInfoTooltip && <InfoButton title={moreInfoTooltip} />}
        {optional && (
          <Typography sx={{ color: COLORS.gray[1000], marginLeft: 'auto' }}>{t('optional')}</Typography>
        )}
      </FormLabel>
      <Select
        color="secondary"
        startAdornment={
          startAdornment ? (
            <InputAdornment
              position="start"
              style={{
                width: 'auto',
                color: '#3B4567',
              }}>
              {startAdornment}
            </InputAdornment>
          ) : null
        }
        disabled={disabled}
        value={value || formik?.values[name]}
        onChange={(e) => {
          const value = e.target.value;
          formik && formik.setFieldValue(name, value);
          onChange && onChange(value);
          trackingEvent && eventLogger(trackingEvent, name, value);
        }}
        variant="outlined"
        displayEmpty
        renderValue={(value: any) =>
          hasValue(value) ? (
            renderValue ? (
              renderValue(value)
            ) : (
              options.find((option) => option.value.toString() === value.toString())?.label
            )
          ) : (
            <Typography style={{ color: COLORS.gray[1000] }}>{placeholder}</Typography>
          )
        }
        sx={{
          background: disabled ? COLORS.gray[300] : COLORS.brand.white,
          paddingRight: '8px',
          '& .MuiSelect-select': {
            paddingY: '12px',
          },
          '& .MuiOutlinedInput-input': {},
          '& fieldset': {
            borderColor: COLORS.gray[900],
            borderRadius: '8px',
          },
          ...sx,
        }}
        endAdornment={
          clearable && formik?.values[name] ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear selection"
                onClick={handleClear}
                edge="end"
                size="small"
                sx={{ marginRight: '1em', color: '#4F5A84' }}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : null
        }>
        {options.map((option) =>
          renderOption ? (
            renderOption(option)
          ) : (
            <MenuItem key={option.value} value={option.value} sx={{ padding: '12px' }}>
              {option.label}
            </MenuItem>
          )
        )}
      </Select>
      {(_error || helperText) && (
        <FormHelperText>
          <Typography sx={{ color: 'inherit' }}>{_error || helperText}</Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
}
