import { COLORS } from '../../themev2';

export default function PasswordCheckIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.6453 22.7451C16.2353 22.7451 15.8953 22.4051 15.8953 21.9951V3.49512C15.8953 3.08512 16.2353 2.74512 16.6453 2.74512C17.0553 2.74512 17.3953 3.08512 17.3953 3.49512V21.9951C17.3953 22.4051 17.0553 22.7451 16.6453 22.7451Z" />
      <path
        opacity="0.4"
        d="M6.04531 20.7451H14.0432V4.74512H6.04531C3.72847 4.74512 1.85193 6.53512 1.85193 8.74512V16.7451C1.85193 18.9551 3.72847 20.7451 6.04531 20.7451ZM18.1013 20.7451H17.0529V4.74512H18.1013C20.4181 4.74512 22.2947 6.53512 22.2947 8.74512V16.7451C22.2947 18.9551 20.4181 20.7451 18.1013 20.7451Z"
      />
      <path d="M6.63226 15.4128C6.46387 15.4128 6.29548 15.3507 6.16254 15.2178L4.83313 13.8884C4.70951 13.7633 4.64018 13.5945 4.64018 13.4187C4.64018 13.2428 4.70951 13.074 4.83313 12.9489C5.09015 12.6919 5.51556 12.6919 5.77258 12.9489L6.63226 13.8086L10.1376 10.2703C10.3946 10.0133 10.82 10.0133 11.0771 10.2703C11.3341 10.5273 11.3341 10.9527 11.0771 11.2097L7.10199 15.2178C6.96905 15.3507 6.80066 15.4128 6.63226 15.4128Z" />
    </svg>
  );
}
