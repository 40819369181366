import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import * as service from '../services/application';
import { DropzoneFile, Nullable } from '../types';
import { APIApplication, APIAsset, ContractMpsa } from '../types/application';
import { FactoringRequest, MPSA } from '../types/factoring';
import { Company } from '../types/company';

type Status = 'loading' | 'idle' | 'failed';

export type ApplicationState = {
  assets: APIAsset[];
  status: Status;
  error: Nullable<string>;
  applicationDraft: APIApplication;
  applications: APIApplication[];
};

const applicationInitialState: ApplicationState = {
  assets: [],
  error: null,
  status: 'loading',
  applicationDraft: {} as APIApplication,
  applications: [],
};

const factoringRequestStatusMap: Record<FactoringRequest['status'], APIApplication['status']> = {
  created: 'application_started',
  submitted: 'application_submitted',
  pending_review: 'application_submitted', // TODO: check
  termsheet: 'application_submitted',
  document_validation: 'application_submitted',
  due_dilligence: 'application_submitted',
  credit_committee: 'application_submitted',
  legal: 'application_submitted',
  onboarding: 'application_submitted',
  completed: 'application_approved',
  rejected: 'application_rejected',
};

const mpsaStatusMap: Record<MPSA['status'], ContractMpsa['status']> = {
  completed: 'document.completed',
  viewed: 'document.viewed',
  sent: 'document.sent',
};

function transformMPSA({ recipients, status, userLink, ...rest }: MPSA): ContractMpsa {
  return {
    clientLink: userLink,
    pandadocLinkId: 'dummy', // useless
    salesforceId: 'dummy', // useless
    salesforceLastModifiedDate: 'dummy', // useless
    recipients,
    status: mpsaStatusMap[status], // TODO: check
    ...rest,
  };
}

export function transformFactoringRequest(
  factoringRequest: FactoringRequest,
  company: Company
): APIApplication {
  const {
    foundationYear,
    creditInsuranceEnabled,
    creditInsuranceProvider,
    financialInstitutionEnabled,
    financialInstitutionName,
    financialInstitutionInstrument,
    salesLast12Month,
    addressStreetTwo,
    status,
    mpsa,
    proposal,
    stateOfIncorporation,
    ...rest
  } = factoringRequest;

  return {
    ...rest,
    status: factoringRequestStatusMap[status], // dummy
    companyName: company.name,
    author: 0, // dummy, useless now
    opportunityId: 'dummy', // dummy, useless now
    accountId: 'dummy', // dummy, useless now
    yearCompanyWasFounded: foundationYear,
    operatesWithCreditInsurance: creditInsuranceEnabled,
    insuranceProvider: creditInsuranceProvider,
    usingFinancialInstitutions: financialInstitutionEnabled,
    financialInstitution: financialInstitutionName,
    financialInstitutionType: financialInstitutionInstrument,
    saleLast12Month: salesLast12Month,
    applicationLanguage: null, // TODO: dummy, useless
    addressTwo: addressStreetTwo,
    addressCountry: company.country,
    companyContactName: factoringRequest.companyContactName,
    companyContactPhone: factoringRequest.companyContactPhone,
    companyContactEmail: factoringRequest.companyContactEmail,
    proposalStatus: null, // TODO: dummy, seems useless
    proposalLink: proposal?.link || null,
    contractMpsa: mpsa ? transformMPSA(mpsa) : null,
    flowMetadata: company.productStatus.factoringFlowMetadata,
    stateOfIncorporation: factoringRequest.stateOfIncorporation,
  };
}

export const applicationSlice = createSlice({
  name: 'application',
  initialState: applicationInitialState,
  reducers: {
    resetApplicationSlice: () => applicationInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplications.fulfilled, (state, action) => {
        const applications = action.payload;
        if (applications.length > 0) {
          state.applicationDraft = { ...state.applicationDraft, ...applications[0] };
        }
        state.applications = applications;
      })
      .addCase(uploadApplicationAssets.fulfilled, (state, action) => {
        state.applicationDraft = {
          ...state.applicationDraft,
          assets: state.applicationDraft?.assets
            ? [...state.applicationDraft.assets, ...action.payload]
            : [...action.payload],
        };
      })
      .addCase(updateApplication.fulfilled, (state, action) => {
        state.applicationDraft = { ...state.applicationDraft, ...action.payload };
      })
      .addCase(deleteApplicationAsset.fulfilled, (state, action) => {
        const appAssets = state?.applicationDraft?.assets || [];
        const filteredAssets = appAssets.filter((asset: APIAsset) => asset.id !== action.payload.id);
        state.applicationDraft.assets = filteredAssets;
      })
      .addMatcher(
        isFulfilled(getApplications, updateApplication, uploadApplicationAssets, deleteApplicationAsset),
        (state) => {
          state.status = 'idle';
        }
      )
      .addMatcher(
        isPending(getApplications, updateApplication, uploadApplicationAssets, deleteApplicationAsset),
        (state) => {
          state.status = 'loading';
          state.error = null;
        }
      )
      .addMatcher(
        isRejected(getApplications, updateApplication, uploadApplicationAssets, deleteApplicationAsset),
        (state, action) => {
          state.status = 'failed';
          state.error = action.error.message || null;
        }
      );
  },
});

export const getApplications = createAsyncThunk('application/getApplications', async (companyId: number) => {
  const apps = await service.getApplications(companyId);
  return apps;
});

export const updateApplication = createAsyncThunk(
  'application/updateApplication',
  async ({ application, id, company }: { application: APIApplication; id: number; company: Company }) => {
    const res = await service.updateApplication(application, id, company);
    return res;
  }
);

export const uploadApplicationAssets = createAsyncThunk(
  'application/uploadApplicationAssets',
  async ({ files, id, companyId }: { files: DropzoneFile[]; id: number; companyId: number }) => {
    const data = new FormData();
    files.forEach((file: DropzoneFile) => {
      data.append('asset', file, file.name);
      data.append('file_name', file.name);
      data.append('asset_type', 'AR');
      data.append('asset_source', 'source_1');
    });
    const assets = await service.uploadApplicationAssets(data, id, companyId);
    return assets;
  }
);

export const deleteApplicationAsset = createAsyncThunk(
  'application/deleteApplicationAsset',
  async ({
    applicationId,
    assetId,
    companyId,
  }: {
    applicationId: number;
    assetId: number;
    companyId: number;
  }) => {
    const deletedAsset = await service.deleteApplicationAsset(companyId, applicationId, assetId);
    return deletedAsset;
  }
);

export const { resetApplicationSlice } = applicationSlice.actions;
