//Application status
export const APPLICATION_STARTED = 'application_started';
export const APPLICATION_SUBMITTED = 'application_submitted';
export const APPLICATION_PENDING_SUBMIT = 'application_pending_submit';
export const APPLICATION_REJECTED = 'application_rejected';
export const APPLICATION_APPROVED = 'application_approved';

//Debtor status
export const DEBTOR_PENDING = 'debtor_pending';
export const DEBTOR_REJECTED = 'debtor_rejected';
export const DEBTOR_APPROVED = 'debtor_approved';

//File Extensions
export const FileExtensions = {
  PDF: 'pdf',
  XLS: 'xls',
  XLSX: 'xlsx',
  JPG: 'jpg',
  PNG: 'png',
  JPEG: 'jpeg',
};

export const APP_STEP_ONE = 'stepOne';
export const APP_STEP_TWO = 'stepTwo';
export const APP_STEP_THREE = 'stepThree';
