//@ts-nocheck
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyTranslation } from '../../hooks/i18n';
import { Box, Grid, IconButton } from '@mui/material';
import TopBar from '../../componentsv2/TopBar';
import MarcoLogo from '../../componentsv2/MarcoLogo';
import Typography from '../../componentsv2/Typography';
import { Formik } from 'formik';
import Form from '../../componentsv2/Form';
import { NewPasswordSchema } from '../../schemas';
import { TextField } from '../../componentsv2/Field';
import Button from '../../componentsv2/Button';
import {
  CheckCircleOutlineOutlined as CheckCircleOutlineIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material';
import { hasDigit, hasLowerCase, hasUpperCase, mapLandingPages } from '../../utils/helpers';
import { loadClient, loadUser as loadUserThunk, setUserPassword, useAppDispatch } from '../../store';
import { logEvent, setUserId } from '@amplitude/analytics-browser';
import { signupEvents } from '../../constants/events';
import { CURRENT_APP_STEP_STORAGE_KEY, DASHBOARD, EMAIL_VERIFICATION, filesURL } from '../../constants/auth';
import { identifyUser } from '../../utils/tracking';
import { useUserLocation } from '../../hooks/useUserLocation';
import { currentUserDetail } from '../../services/clients';

const CreatePassword = () => {
  const { t } = useLazyTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [sentRecoveryEmailToUser, setSentRecoveryEmailToUser] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();
  const [error, setError] = useState<Error>();
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const email = params.get('email');
  const landingPage = params.get('landing_page');
  const reset = params.get('reset');
  const product = mapLandingPages[landingPage as keyof typeof mapLandingPages] || DASHBOARD;
  const { language } = useUserLocation();

  const submit = ({ password }: { password: string }) => {
    if (!token) return;
    setIsLoading(true);
    dispatch(setUserPassword({ token, password }))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        !reset && localStorage.removeItem(CURRENT_APP_STEP_STORAGE_KEY);
        dispatch(loadUserThunk())
          .unwrap()
          .then(() => {
            currentUserDetail().then(({ country, companies, name, email }) => {
              identifyUser({ country, companyName: companies[0].name, name, email, language }).promise.then(
                () => {
                  const event = !!reset ? signupEvents.PASSWORD_RESET : signupEvents.PASSWORD_SET;
                  logEvent(event, { Email: email });
                  navigate(product);
                }
              );
            });
          });
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
        navigate(EMAIL_VERIFICATION);
      });
  };

  useEffect(() => {
    // queryparams are encoded and the plus sign becomes a whitespace (this cleaning is only useful for dev purposes)
    email && setUserId(email.replace(' ', '+'));
    logEvent(signupEvents.VIEW_SET_PASSWORD, { Email: email });
  }, []);

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <TopBar>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <IconButton href="/">
              <MarcoLogo />
            </IconButton>
          </Box>
        </TopBar>
      </Box>
      <Grid xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1em',
            paddingTop: { xs: '4em', md: '0' },
            width: { xs: '80%', md: '30%' },
            textAlign: 'center',
          }}>
          <Typography variant="h4">{t('createPassword.title')}</Typography>
          <Typography>{t('createPassword.subtitle')}</Typography>
          <Formik
            onSubmit={submit}
            initialValues={{ password: '', confirmPassword: '' }}
            validationSchema={NewPasswordSchema(t)}>
            {({ values, isValid, dirty }) => {
              return (
                <Form>
                  <Box sx={{ width: '100%' }}>
                    <TextField
                      type="password"
                      fullWidth
                      name="password"
                      label={t('createPassword.passwordLabel')}
                      placeholder={t('createPassword.passwordPlaceholder')}
                    />
                    <TextField
                      type="password"
                      fullWidth
                      name="confirmPassword"
                      label={t('createPassword.confirmPasswordLabel')}
                      placeholder={t('createPassword.confirmPasswordPlaceholder')}
                    />
                    <Grid container sx={{ marginTop: '1em', textAlign: 'left' }} rowSpacing={1}>
                      <Grid item xs={12} sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                        {values?.password && values.password.length >= 8 && values.password.length <= 20 ? (
                          <CheckCircleOutlineIcon />
                        ) : (
                          <RadioButtonUncheckedIcon />
                        )}
                        <Typography>{t('createPassword.passwordLengthCriteria')}</Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                        {values?.password &&
                        hasUpperCase(values.password) &&
                        hasLowerCase(values.password) ? (
                          <CheckCircleOutlineIcon />
                        ) : (
                          <RadioButtonUncheckedIcon />
                        )}
                        <Typography>{t('createPassword.passwordUpperAndLowercaseCriteria')}</Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                        {values?.password && hasDigit(values.password) ? (
                          <CheckCircleOutlineIcon />
                        ) : (
                          <RadioButtonUncheckedIcon />
                        )}
                        <Typography>{t('createPassword.passwordDigitCriteria')}</Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ marginTop: '2em' }}>
                        <Typography textAlign="center">
                          {t('signup.termsAndConditionsFirstPart')}{' '}
                          <a
                            href={`${filesURL}/${t('signup.termsAndConditionsDocument')}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}>
                            {`${t('signup.termsAndConditions')}`}
                          </a>
                          {`${t('signup.termsAndConditionsSecondPart')} `}
                          <a
                            href={`${filesURL}/${t('signup.privacyPolicyDocument')}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}>
                            {t('signup.privacyPolicy')}
                          </a>
                          {` ${t('signup.termsAndConditionsThirdPart')} `}
                          <a
                            href={`${filesURL}/${t('signup.platformAgreementDocument')}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}>
                            {t('signup.platformAgreement')}
                          </a>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box sx={{ marginTop: '4em' }}>
                      <Button
                        fullWidth
                        color="secondary"
                        isSubmit
                        disabled={!dirty || !isValid}
                        isLoading={isLoading}>
                        {t('createPassword.createPassword')}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Grid>
    </>
  );
};

export default CreatePassword;
