import { updateUserPreferences } from '../services/clients';
import { useAppSelector } from '../store';
import { CountryKeys } from '../types';
import { useTranslation } from './i18n';

export function useUserLocation() {
  const client = useAppSelector((store) => store.client.client);
  const { i18n } = useTranslation();

  function handleChangeLocation(location: CountryKeys) {
    i18n.changeLanguage(location);
    updateUserPreferences({ language: location.slice(0, 2) });
  }

  return {
    location: client?.country,
    locale: i18n.language,
    language: i18n.language.substring(0, 2) === 'es' ? 'es' : 'en',
    handleChangeLocation,
  };
}
