import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactElement } from 'react';
import Typography from '../Typography';

export const currencyIconMap: Record<string, ReactElement> = {
  USD: <img alt={'USD'} src={`/images/countryFlags/us.svg`} />,
  MXN: <img alt={'MXN'} src={`/images/countryFlags/mx.svg`} />,
  DKK: <img alt={'DKK'} src={`/images/countryFlags/dk.svg`} />,
  EUR: <img alt={'EUR'} src={`/images/countryFlags/eu.svg`} />,
  PEN: <img alt={'PEN'} src={`/images/countryFlags/pe.svg`} />,
  CNY: <img alt={'CNY'} src={`/images/countryFlags/cn.svg`} />,
  HKD: <img alt={'HKD'} src={`/images/countryFlags/hk.svg`} />,
  CAD: <img alt={'CAD'} src={`/images/countryFlags/ca.svg`} />,
  AED: <img alt={'AED'} src={`/images/countryFlags/ae.svg`} />,
  COP: <img alt={'COP'} src={`/images/countryFlags/co.svg`} />,
  CLP: <img alt={'CLP'} src={`/images/countryFlags/cl.svg`} />,
  GTQ: <img alt={'GTQ'} src={`/images/countryFlags/gt.svg`} />,
};

export default function CurrencyAdornment({ currency }: { currency: string }) {
  return (
    <InputAdornment position="start" sx={{}}>
      <Box
        sx={{ display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'center', width: '64px' }}>
        {currencyIconMap[currency]}
        <Typography>{currency}</Typography>
      </Box>
      <Divider
        orientation="vertical"
        sx={{
          height: '42px',
          marginLeft: '1.5em',
        }}
      />
    </InputAdornment>
  );
}
