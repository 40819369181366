import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { ReactNode } from 'react';
import { TooltipProps } from '@mui/material';
import Tooltip from '../Tooltip';

type CustomTooltipProps = {
  title: NonNullable<ReactNode>;
  placement?: TooltipProps['placement'];
  customSX?: TooltipProps['sx'];
  color?: 'primary' | 'secondary';
};

const InfoButton = ({
  title,
  placement = 'bottom',
  customSX = null,
  color = 'primary',
}: CustomTooltipProps) => {
  const iconColor = color === 'primary' ? '#4E57D3' : '#91A2BB';
  return (
    <Tooltip title={title} placement={placement} enterTouchDelay={0} tabIndex={-1}>
      <IconButton sx={{ maxHeight: '20px', padding: '0px 4px', ...customSX }} disableRipple>
        <InfoIcon sx={{ color: iconColor, height: '20px' }} />
      </IconButton>
    </Tooltip>
  );
};

export default InfoButton;
