import { useContext, useState } from 'react';
import { FormikContext } from 'formik';
import { Dayjs } from 'dayjs';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ClearIcon from '../../components/Icons/ClearIcon';
import { useUserLocation } from '../../hooks/useUserLocation';
import { FormTrackingContext } from '../Form';
import { logFieldChange } from '../../utils/tracking';
import { CommonProps } from './types';
import { TDateField } from './DateField';
import { COLORS } from '../../themev2';

export type TDateRange = {
  fromDate: TDateField;
  toDate: TDateField;
};

type DateRangeFieldProps = CommonProps & {
  label?: string;
  value?: TDateRange;
  onChange?: (value: TDateRange) => void;
  disableFuture?: boolean;
  minDate?: TDateField;
  maxDate?: TDateField;
};

export default function DateRangeField({
  fullWidth = true,
  name,
  label,
  value,
  onChange,
  error,
  eventLogger = logFieldChange,
  disableFuture = false,
  clearable,
}: DateRangeFieldProps) {
  const { language } = useUserLocation();
  const formik = useContext(FormikContext);
  const { trackingEvent } = useContext(FormTrackingContext);
  const _error = error || (formik?.touched[name] && formik?.errors[name]);

  const [isFromDatePickerOpen, setFromDatePickerOpen] = useState(false);
  const [isToDatePickerOpen, setToDatePickerOpen] = useState(false);

  const handleClear = (dateType: 'fromDate' | 'toDate') => {
    const updatedValue = { ...value, [dateType]: null };
    formik && formik.setFieldValue(name, updatedValue);
    onChange && onChange(updatedValue);
  };

  const handleDateChange = (dateType: 'fromDate' | 'toDate', newValue: Dayjs | null) => {
    const updatedValue = { ...value, [dateType]: newValue };
    formik && formik.setFieldValue(name, updatedValue);
    onChange && onChange(updatedValue);
    trackingEvent && eventLogger(trackingEvent, name, JSON.stringify(updatedValue));

    if (dateType === 'fromDate') {
      setFromDatePickerOpen(false);
      !value?.toDate && setToDatePickerOpen(true); // Open the 'toDate' picker after selecting 'fromDate'
    } else if (dateType === 'toDate') {
      setToDatePickerOpen(false);
      !value?.fromDate && setFromDatePickerOpen(true); // Open the 'fromDate' picker after selecting 'toDate'
    }
  };

  return (
    <FormControl error={!!_error} fullWidth={fullWidth}>
      {label && <FormLabel id={`${name}-label`}>{label}</FormLabel>}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'end',
            width: fullWidth ? '100%' : 'initial',
          }}>
          <DatePicker
            value={value?.fromDate || null}
            onChange={(newValue) => handleDateChange('fromDate', newValue)}
            disableFuture={disableFuture}
            maxDate={value?.toDate}
            format={language === 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'}
            sx={{
              '& .MuiInputBase-input': {
                padding: '13px 10px',
              },
              '& .MuiInputBase-input::placeholder': {
                color: COLORS.gray[1000],
                opacity: 1,
              },
              width: '100%',
              '& fieldset': {
                borderColor: COLORS.gray[900],
                borderRadius: '8px',
              },
              '& svg': {
                color: '#3B4567',
              },
            }}
            slots={{
              clearButton: () => (
                <IconButton aria-label="clear selection" size="small" onClick={() => handleClear('fromDate')}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
            slotProps={{
              day: {
                sx: {
                  '&.MuiPickersDay-root.Mui-selected': {
                    backgroundColor: COLORS.secondary.indigo,
                    color: COLORS.brand.white,
                  },
                },
              },
              field: { clearable: clearable },
            }}
            open={isFromDatePickerOpen}
            onOpen={() => setFromDatePickerOpen(true)}
            onClose={() => setFromDatePickerOpen(false)}
          />
          <div
            style={{
              padding: '0 1em',
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              width: '5px',
            }}>
            –
          </div>
          <DatePicker
            value={value?.toDate || null}
            onChange={(newValue) => handleDateChange('toDate', newValue)}
            minDate={value?.fromDate}
            disableFuture={disableFuture}
            format={language === 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'}
            sx={{
              '& .MuiInputBase-input': {
                padding: '13px 10px',
              },
              '& .MuiInputBase-input::placeholder': {
                color: COLORS.gray[1000],
                opacity: 1,
              },
              width: '100%',
              '& fieldset': {
                borderColor: COLORS.gray[900],
                borderRadius: '8px',
              },
              '& svg': {
                color: '#3B4567',
              },
            }}
            slots={{
              clearButton: () => (
                <IconButton aria-label="clear selection" size="small" onClick={() => handleClear('toDate')}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
            slotProps={{
              day: {
                sx: {
                  '&.MuiPickersDay-root.Mui-selected': {
                    backgroundColor: COLORS.secondary.indigo,
                    color: COLORS.brand.white,
                  },
                },
              },
              field: { clearable: clearable },
            }}
            open={isToDatePickerOpen}
            onOpen={() => setToDatePickerOpen(true)}
            onClose={() => setToDatePickerOpen(false)}
          />
        </div>
      </LocalizationProvider>
    </FormControl>
  );
}
