import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';

type SectionProps = {
  sx?: SxProps;
  isFullHeight?: boolean;
  isOpaque?: boolean;
};

const Section: React.FC<SectionProps> = ({ children, sx, isFullHeight, isOpaque = true }) => {
  return (
    <Box
      sx={{
        ...(isOpaque
          ? { boxShadow: '0px 10px 24px rgba(201, 210, 224, 0.3)', background: '#ffffff', padding: '2em' }
          : {}),
        borderRadius: '12px',
        ...(isFullHeight ? { height: '100%' } : {}),
        ...sx,
      }}>
      {children}
    </Box>
  );
};

export default Section;
