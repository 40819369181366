import { ReactNode, ElementType } from 'react';
import { bool, func, node, object, oneOf, oneOfType, string } from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import classes from './Button.module.scss';

export enum ButtonSizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ButtonColors {
  Inherit = 'inherit',
  Primary = 'primary',
  Secondary = 'secondary',
  Action = 'action',
}

export enum ButtonTypes {
  Default = 'defaultBtn',
  Primary = 'primaryBtn',
  Secondary = 'secondaryBtn',
  Error = 'errorBtn',
  Action = 'actionBtn',
}

interface Links {
  component?: ElementType;
  to?: string;
  href?: string;
}

export interface ButtonAdditionalProps {
  className?: string;
  size?: ButtonSizes | string;
  color?: ButtonColors;
  btnType?: ButtonTypes;
  text?: string;
  to?: string;
  marginReset?: boolean;
  disabled?: boolean;
  startIcon?: ReactNode; // Element placed before the children.
  endIcon?: ReactNode; // Element placed after the children.
  children?: any;
  iconStyles?: string;
  location?: Location;
  component?: ElementType;
  isLoading?: boolean;
}

/**
 * @param text - Text for button
 * @param disabled - If true, the button will be disabled.
 * @param children - list of options
 * @param to - list of options
 * @param location - Avoid redirects on the same page and saving to history
 * @param component - The component used for the root node. Either a string to use a DOM element or a component or link.
 * @param color - The color of the component. It supports those theme colors that make sense for this component.
 * @param size - Size
 * @param btnType - Type of button
 * @param className - classes
 * @param marginReset
 * @param startIcon - Start icon
 * @param endIcon - End icon
 * @param rest - other default props
 * */

const Btn = ({
  text,
  disabled = false,
  children,
  to,
  location,
  component = Link,
  btnType = ButtonTypes.Default,
  className,
  color,
  marginReset,
  size,
  startIcon,
  endIcon,
  iconStyles,
  isLoading = false,
  ...rest
}: ButtonProps & ButtonAdditionalProps): JSX.Element => {
  const {
    palette: { mode },
  } = useTheme();
  let links: Links = {};
  if (to) {
    links = { component, to };
    if (component === 'button') {
      delete links.to;
      links.href = to;
    }
  }
  // Avoid redirects on the same page and saving to history
  if (location && location.pathname === to) {
    links = {};
  }
  return (
    <Button
      {...rest}
      {...links}
      disabled={disabled}
      color={color}
      className={clsx(
        classes.btn,
        classes[btnType] || '',
        (size === ButtonSizes.Small && classes.smallBtn) ||
          (size === ButtonSizes.Medium && classes.mediumBtn) ||
          (size === ButtonSizes.Large && classes.largeBtn) ||
          '',
        marginReset && classes.marginReset,
        classes[mode],
        className
      )}>
      {isLoading && <CircularProgress />}
      {startIcon && <span className={classes.startIcon}>{startIcon}</span>}
      {children && <span className={classes.children}>{children}</span>}
      {text && <span className={classes.textBtn}>{text}</span>}
      {endIcon && <span className={classes.endIcon}>{endIcon}</span>}
    </Button>
  );
};

Btn.propTypes = {
  className: oneOfType([object, string]),
  size: oneOf(Object.values(ButtonSizes)),
  color: oneOf(Object.values(ButtonColors)),
  btnType: oneOf(Object.values(ButtonTypes)),
  text: string,
  to: string,
  onClick: func,
  onlyIcon: node,
  marginReset: bool,
  startIcon: node, // Element placed before the children.
  endIcon: node, // Element placed after the children.
  type: string,
};

export default Btn;
