import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export const BuyerCreditCheckSchema = (t: TFunction) =>
  Yup.object().shape(
    {
      companyName: Yup.string().required(t('common:requiredField')),
      addressCountry: Yup.string().required(t('common:requiredField')),
      addressStreet: Yup.string().required(t('common:requiredField')),
      addressCity: Yup.string().required(t('common:requiredField')),
      addressState: Yup.string().required(t('common:requiredField')),
      addressZipCode: Yup.string().required(t('common:requiredField')),
      secondaryAddressStreet: Yup.string()
        .nullable()
        .when('hasAdditionalAddress', {
          is: true,
          then: Yup.string().required(t('common:requiredField')).nullable(),
        }),
      secondaryAddressCity: Yup.string()
        .nullable()
        .when('hasAdditionalAddress', {
          is: true,
          then: Yup.string().required(t('common:requiredField')).nullable(),
        }),
      secondaryAddressState: Yup.string()
        .nullable()
        .when('hasAdditionalAddress', {
          is: true,
          then: Yup.string().required(t('common:requiredField')).nullable(),
        }),
      secondaryAddressZipCode: Yup.string()
        .nullable()
        .when('hasAdditionalAddress', {
          is: true,
          then: Yup.string().required(t('common:requiredField')).nullable(),
        }),
      ein: Yup.string()
        .nullable()
        .when(['knowsBuyersEIN', 'duns'], {
          is: (knowsBuyersEIN: boolean, duns: string) => knowsBuyersEIN && !duns,
          then: Yup.string().required(t('common:requiredField')).nullable(),
        }),
      duns: Yup.string()
        .nullable()
        .when(['knowsBuyersEIN', 'ein'], {
          is: (knowsBuyersEIN: boolean, ein: string) => knowsBuyersEIN && !ein,
          then: Yup.string().required(t('common:requiredField')).nullable(),
        }),
      knowsBuyersEIN: Yup.boolean().notRequired(),
      hasAdditionalAddress: Yup.boolean().notRequired(),
    },
    [['ein', 'duns']]
  );
