export default function MoneyIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3999 17.4181H10.8899C9.24991 17.4181 7.91991 16.0381 7.91991 14.3381C7.91991 13.9281 8.25991 13.5881 8.66991 13.5881C9.07991 13.5881 9.41991 13.9281 9.41991 14.3381C9.41991 15.2081 10.0799 15.9181 10.8899 15.9181H13.3999C14.0499 15.9181 14.5899 15.3381 14.5899 14.6381C14.5899 13.7681 14.2799 13.5981 13.7699 13.4181L9.73991 11.9981C8.95991 11.7281 7.90991 11.1481 7.90991 9.35812C7.90991 7.81812 9.11991 6.57812 10.5999 6.57812H13.1099C14.7499 6.57812 16.0799 7.95812 16.0799 9.65812C16.0799 10.0681 15.7399 10.4081 15.3299 10.4081C14.9199 10.4081 14.5799 10.0681 14.5799 9.65812C14.5799 8.78813 13.9199 8.07812 13.1099 8.07812H10.5999C9.94991 8.07812 9.40991 8.65812 9.40991 9.35812C9.40991 10.2281 9.71991 10.3981 10.2299 10.5781L14.2599 11.9981C15.0399 12.2681 16.0899 12.8481 16.0899 14.6381C16.0799 16.1681 14.8799 17.4181 13.3999 17.4181Z"
        fill="#91A2BB"
      />
      <path
        d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V18C12.75 18.41 12.41 18.75 12 18.75Z"
        fill="#91A2BB"
      />
      <path
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill="#91A2BB"
      />
    </svg>
  );
}
