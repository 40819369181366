import { useContext } from 'react';
import { FormikContext } from 'formik';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import { FormTrackingContext } from '../Form';
import { useTranslation } from '../../hooks/i18n';
import { useUserLocation } from '../../hooks/useUserLocation';
import { logFieldChange } from '../../utils/tracking';
import { CommonProps } from './types';
import Typography from '../Typography';
import InfoButton from '../InfoButton';
import { COLORS } from '../../themev2';

export type TDateField = Dayjs | null;

type DateFieldProps = CommonProps & {
  label: string;
  value?: TDateField;
  onChange?: (value: TDateField) => void;
  disableFuture?: boolean;
  minDate?: TDateField;
  maxDate?: TDateField;
};

export default function DateField({
  fullWidth = true,
  name,
  label,
  value,
  onChange,
  error,
  eventLogger = logFieldChange,
  disableFuture = false,
  minDate,
  maxDate,
  moreInfoTooltip = null,
  optional = false,
  disabled = false,
}: DateFieldProps) {
  const { t } = useTranslation();
  const { language } = useUserLocation();
  const formik = useContext(FormikContext);
  const { trackingEvent } = useContext(FormTrackingContext);
  const _error = error || (formik?.touched[name] && formik?.errors[name]);

  return (
    <FormControl error={!!_error} fullWidth={fullWidth}>
      <FormLabel
        sx={{
          padding: '8px 0',
          fontWeight: 600,
          display: 'flex',
          alignItems: 'center',
        }}>
        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <Typography>{label}</Typography>
          {moreInfoTooltip && <InfoButton title={moreInfoTooltip} />}
        </div>
        {optional && <Typography sx={{ color: COLORS.gray[1000] }}>{t('optional')}</Typography>}
      </FormLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value || formik?.values[name]}
          onChange={(newValue) => {
            formik && formik.setFieldValue(name, newValue);
            onChange && onChange(newValue);
            trackingEvent && eventLogger(trackingEvent, name, newValue.$d.toString());
          }}
          disableFuture={disableFuture}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          format={language === 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'}
          sx={{
            '& .MuiInputBase-input': {
              padding: '12px',
            },
            '& .MuiInputBase-input::placeholder': {
              color: COLORS.gray[1000],
              opacity: 1,
            },
            width: '100%',
            '& fieldset': {
              borderColor: COLORS.gray[900],
              borderRadius: '8px',
            },
            '& svg': {
              color: '#3B4567',
            },
          }}
          slotProps={{
            day: {
              sx: {
                '&.MuiPickersDay-root.Mui-selected': {
                  backgroundColor: COLORS.secondary.indigo,
                  color: COLORS.brand.white,
                },
              },
            },
          }}
        />
      </LocalizationProvider>
      {!!_error && <FormHelperText error={!!_error}>{_error}</FormHelperText>}
    </FormControl>
  );
}
