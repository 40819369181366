import React from 'react';

export default function LinkIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.27195 12C2.48195 11.05 2.00195 9.83 2.00195 8.5C2.00195 5.48 4.47195 3 7.50195 3H12.502C15.522 3 18.002 5.48 18.002 8.5C18.002 11.52 15.532 14 12.502 14H10.002"
        stroke="currentColor"
        stroke-width="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.732 12C21.522 12.95 22.002 14.17 22.002 15.5C22.002 18.52 19.532 21 16.502 21H11.502C8.48195 21 6.00195 18.52 6.00195 15.5C6.00195 12.48 8.47195 10 11.502 10H14.002"
        stroke="currentColor"
        stroke-width="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
