export default function DocumentUploadIcon() {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M34.6667 16.9833H29.85C25.9 16.9833 22.6833 13.7666 22.6833 9.81665V4.99998C22.6833 4.08331 21.9333 3.33331 21.0167 3.33331H13.95C8.81666 3.33331 4.66666 6.66665 4.66666 12.6166V27.3833C4.66666 33.3333 8.81666 36.6666 13.95 36.6666H27.05C32.1833 36.6666 36.3333 33.3333 36.3333 27.3833V18.65C36.3333 17.7333 35.5833 16.9833 34.6667 16.9833Z"
        fill="url(#paint0_linear_428_28877)"
      />
      <path
        d="M26.8333 3.68334C26.15 3.00001 24.9667 3.46668 24.9667 4.41668V10.2333C24.9667 12.6667 27.0333 14.6833 29.55 14.6833C31.1333 14.7 33.3333 14.7 35.2167 14.7C36.1667 14.7 36.6667 13.5833 36 12.9167C33.6 10.5 29.3 6.15001 26.8333 3.68334ZM19.7167 20.7833L16.3833 17.45C16.3667 17.4333 16.35 17.4333 16.35 17.4167C16.2416 17.3147 16.1179 17.2303 15.9833 17.1667H15.95C15.8167 17.1167 15.6833 17.1 15.55 17.0833H15.4167C15.3167 17.0833 15.2 17.1167 15.1 17.15C15.05 17.1667 15.0167 17.1833 14.9833 17.2C14.85 17.2667 14.7167 17.3333 14.6167 17.45L11.2833 20.7833C10.8 21.2667 10.8 22.0667 11.2833 22.55C11.7667 23.0333 12.5667 23.0333 13.05 22.55L14.25 21.35V28.3333C14.25 29.0167 14.8167 29.5833 15.5 29.5833C16.1833 29.5833 16.75 29.0167 16.75 28.3333V21.35L17.95 22.55C18.2 22.8 18.5167 22.9167 18.8333 22.9167C19.15 22.9167 19.4667 22.8 19.7167 22.55C20.2 22.0667 20.2 21.2667 19.7167 20.7833Z"
        fill="url(#paint1_linear_428_28877)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_428_28877"
          x1="6.30251"
          y1="33.4166"
          x2="39.9092"
          y2="27.411"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3D67A6" />
          <stop offset="1" stopColor="#0BAAA1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_428_28877"
          x1="12.2321"
          y1="27.0264"
          x2="39.1398"
          y2="22.1271"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3D67A6" />
          <stop offset="1" stopColor="#0BAAA1" />
        </linearGradient>
      </defs>
    </svg>
  );
}
