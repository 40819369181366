import { ChipTypeMap } from '@mui/material';
import MUIChip from '@mui/material/Chip';
import Tooltip from '../Tooltip';

type ChipProps = ChipTypeMap['props'] & { tooltip?: string };

const Chip = ({ tooltip, sx, ...props }: ChipProps) => {
  const defaultStyles = {
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: '"Inter", sans-serif',
    ...sx,
  };

  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip} placement="top">
          <MUIChip {...props} sx={defaultStyles} />
        </Tooltip>
      ) : (
        <MUIChip {...props} sx={defaultStyles} />
      )}
    </>
  );
};

export default Chip;
