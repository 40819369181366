import { backendCountryValues } from '../constants/data';
import { Credentials, Nullable, User } from '../types';
import { SignupFields, Referral } from '../types/signup';
import { InvitationSignupValues } from '../viewsv2/InvitationSignup';
import axios from './axios';
import { SignUpResponse, Tokens } from './types';

export function refreshToken(refreshToken: Tokens['refresh']): Promise<Tokens> {
  return axios.post<Tokens>('/auth/token/refresh/', { refresh: refreshToken }).then(({ data }) => data);
}

export function tokenApi(credentials: Credentials, requireTwoFactor = false): Promise<Tokens> {
  return axios
    .post(`/auth/token/`, {
      email: credentials.email,
      password: credentials.password,
    })
    .then(({ data }) => data);
}

export function userDetailApi(): Promise<User> {
  return axios.get(`/users/me/`).then(({ data }) => data);
}

export function setUserPassword(token: string, password: string): Promise<Tokens> {
  return axios
    .post<Tokens>(`/password/set-password`, {
      token,
      password,
    })
    .then(({ data }) => data);
}

export function signUp(
  { fullName, email, country, phoneNumber, businessName, captcha }: SignupFields & { captcha: string },
  { id, source }: Referral,
  page: Nullable<string>,
  language: string,
  referralClient: Nullable<string>
) {
  return axios
    .post<SignUpResponse>('/signup/', {
      name: fullName,
      email: email,
      country: backendCountryValues[country],
      contact_phone: phoneNumber,
      company_name: businessName,
      utm_landing_page: page,
      ...(id ? { specified_referral: source, referral_id: id } : {}),
      language,
      captcha_token: captcha,
      ...(referralClient ? { company_user_referral_id: referralClient } : {}),
    })
    .then(({ data }) => data);
}

export function signupWithInvitation(
  values: Partial<InvitationSignupValues> & { name: string; contactPhone: string; token: string }
) {
  return axios
    .post<{ refresh: string; access: string }>(`/signup/with-invitation/`, values)
    .then(({ data }) => data);
}
