import styled from '@emotion/styled';
import { Slider } from '@mui/material';
import { COLORS } from './colors';
import { COLORS as COLORSV2 } from '../themev2';

export const styleDataGrid = {
  border: 'none',
  '& .MuiDataGrid-virtualScroller': {
    overflowX: 'auto',
    minHeight: '610px',
  },
  '& .columnHeaders': {
    borderBottom: 'none',
  },
  '& .columnHeader': {
    padding: 0,
    border: 'none',
  },
  '& .columnHeaderContainer, & .columnTitleContainer': {
    background: COLORSV2.gray[600],
    padding: 0.5,
    border: 'none',
  },
  '& .columnHeaderTitle': {
    fontSize: '14px !important',
    fontWeight: 600,
    color: COLORSV2.brand.night,
  },
  '& .row': {
    background: COLORSV2.brand.paper,
    cursor: 'pointer',
    marginTop: 1,
    marginBottom: 1,
    '&.Mui-selected, &.Mui-selected:hover, &:hover': {
      background: COLORSV2.gray[600],
    },
  },
  '& .cell': {
    padding: 1,
    fontSize: '14px',
    color: COLORSV2.brand.night,
    border: 'none',
  },
  '.pagination': {
    '.buttons': {
      color: COLORSV2.brand.night,
      '&.selected': {
        background: COLORSV2.brand.paper,
      },
    },
    '.previousNext': {
      background: COLORSV2.brand.paper,
    },
  },
};

export const AmountSlider = styled(Slider)({
  color: '#29D3B1',
  height: 12,
  marginTop: 15,
  '& .MuiSlider-track': {
    border: 'none',
    zIndex: '1',
  },
  '& .MuiSlider-rail': {
    color: COLORS.lightGrayLow,
  },
  '& .MuiSlider-markLabel, & .MuiSlider-valueLabelLabel': {
    color: COLORS.whiteOpacity,
    fontWeight: 600,
    fontSize: 14,
  },
  '& .MuiSlider-markLabel': {
    top: -12,
    letterSpacing: '0.15px',
    '&[data-index="0"]': {
      left: '10px !important',
    },
    '&[data-index="8"]': {
      right: '-50px !important',
      left: 'inherit !important;',
    },
  },
  '& .MuiSlider-valueLabelLabel': {
    color: 'white',
    letterSpacing: '0.15px',
  },
  '& .MuiSlider-mark': {
    width: 6,
    height: 6,
    backgroundColor: COLORS.mineShaft,
    borderRadius: '50%',
    '&[data-index="0"], &[data-index="8"]': {
      display: 'none',
    },
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    zIndex: '2',
    backgroundColor: 'currentColor',
    border: '6px solid white',
    '&[data-index="0"]': {
      '.MuiSlider-valueLabel': {
        right: -5,
      },
    },
    '&[data-index="1"]': {
      '.MuiSlider-valueLabel': {
        left: -5,
      },
    },
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    top: 49,
    padding: '2px 8px',
    background: COLORS.turquoiseMin,
    '&:before': { display: 'none' },
  },
});
