import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Nullable } from '../../types';
import Button from '../../componentsv2/Button';
import { COLORS } from '../../themev2';

interface ConfirmationModalProps {
  onConfirm: (id: number) => void;
  children: ({ openModal }: { openModal: (id: number) => void }) => React.ReactElement;
  title: string;
  body?: React.ReactElement;
  cancelLabel: string;
  confirmLabel: string;
}

const ConfirmationModal = ({
  onConfirm,
  children,
  title,
  body,
  cancelLabel,
  confirmLabel,
}: ConfirmationModalProps) => {
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState<Nullable<number>>(null);

  const openModal = (id: number) => {
    setId(id);
    setShowModal(true);
  };

  const confirmAction = () => {
    id && onConfirm(id);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
    setId(null);
  };

  return (
    <>
      {children({ openModal })}
      {showModal && (
        <Modal
          open={showModal}
          onClose={handleClose}
          componentsProps={{
            backdrop: {
              sx: {
                background: `${COLORS.gray[1300]}cc`,
              },
            },
          }}>
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Paper sx={{ maxWidth: { xs: '400px', sm: '600px' } }}>
              <Box p={4}>
                <Typography variant="h5">{title}</Typography>
                <Box my={2}>{body}</Box>
                <Box display="flex" justifyContent="flex-end">
                  <Button onClick={handleClose} type="outline">
                    {cancelLabel}
                  </Button>
                  <Box mx={1} />
                  <Button onClick={confirmAction}>{confirmLabel}</Button>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ConfirmationModal;
