export default function MiniNotepadIcon({ isCurrentColor = false }: any) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M21.6602 11.1214L20.6802 15.3014C19.8402 18.9114 18.1802 20.3714 15.0602 20.0714C14.5602 20.0314 14.0202 19.9414 13.4402 19.8014L11.7602 19.4014C7.59018 18.4114 6.30018 16.3514 7.28018 12.1714L8.26018 7.9814C8.46018 7.1314 8.70018 6.3914 9.00018 5.7814C10.1702 3.3614 12.1602 2.7114 15.5002 3.5014L17.1702 3.8914C21.3602 4.8714 22.6402 6.9414 21.6602 11.1214Z"
        fill={isCurrentColor ? 'currentColor' : '#CEEDF8'}
      />
      <path
        d="M15.0584 20.0754C14.4384 20.4954 13.6584 20.8454 12.7084 21.1554L11.1284 21.6754C7.15839 22.9554 5.06839 21.8854 3.77839 17.9154L2.49839 13.9654C1.21839 9.9954 2.27839 7.8954 6.24839 6.6154L7.82839 6.0954C8.23839 5.9654 8.62839 5.8554 8.99839 5.7854C8.69839 6.3954 8.45839 7.1354 8.25839 7.9854L7.27839 12.1754C6.29839 16.3554 7.58839 18.4154 11.7584 19.4054L13.4384 19.8054C14.0184 19.9454 14.5584 20.0354 15.0584 20.0754ZM17.4904 11.1954C17.4304 11.1954 17.3704 11.1854 17.3004 11.1754L12.4504 9.9454C12.2699 9.8859 12.1188 9.76013 12.0275 9.59349C11.9362 9.42686 11.9116 9.23179 11.9586 9.04769C12.0055 8.86359 12.1206 8.70418 12.2806 8.60166C12.4406 8.49915 12.6335 8.46117 12.8204 8.4954L17.6704 9.7254C17.8442 9.77233 17.9953 9.88039 18.0959 10.0298C18.1965 10.1791 18.2398 10.3597 18.2179 10.5385C18.196 10.7172 18.1104 10.882 17.9767 11.0027C17.8431 11.1234 17.6704 11.1918 17.4904 11.1954Z"
        fill={isCurrentColor ? 'currentColor' : '#CEEDF8'}
      />
      <path
        d="M14.5614 14.5724C14.5014 14.5724 14.4414 14.5624 14.3714 14.5524L11.4614 13.8124C11.281 13.7529 11.1298 13.6271 11.0385 13.4605C10.9472 13.2938 10.9226 13.0988 10.9696 12.9147C11.0166 12.7306 11.1317 12.5712 11.2916 12.4686C11.4516 12.3661 11.6445 12.3281 11.8314 12.3624L14.7414 13.1024C15.1414 13.2024 15.3814 13.6124 15.2814 14.0124C15.2014 14.3524 14.9014 14.5724 14.5614 14.5724Z"
        fill={isCurrentColor ? 'currentColor' : '#CEEDF8'}
      />
    </svg>
  );
}
