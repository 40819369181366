import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import classes from './ErrorMessage.module.scss';

interface ErrorMessageProps {
  errorMessage: string;
  onDismiss?: () => void;
}

const BANNER_DURATION = 5000;

const ErrorMessage = ({ errorMessage, onDismiss }: ErrorMessageProps) => {
  useEffect(() => {
    if (!!onDismiss) {
      const timer = setTimeout(() => {
        onDismiss();
      }, BANNER_DURATION);

      return () => clearTimeout(timer);
    }
  }, [onDismiss]);

  return (
    <div className={classes.errorWrapper}>
      <Box className={classes.boxWrapper}>
        <ErrorIcon className={classes.icon} />
        <Typography className={classes.errorText}>{errorMessage}</Typography>
        {onDismiss && (
          <CloseIcon
            className={classes.icon}
            style={{ marginLeft: 'auto', cursor: 'pointer' }}
            onClick={() => {
              onDismiss();
            }}
          />
        )}
      </Box>
    </div>
  );
};

export default ErrorMessage;
