import { AddCustomerState } from '../containers/ApplicationStepperv2/FinancingStepCustomersList';
import { transformFactoringRequest } from '../store';
import { APIApplication, APIApplicationBuyerInfo, APIAsset, APIDocumentAsset } from '../types/application';
import { APINotification } from '../types/client';
import { FactoringRequest } from '../types/factoring';
import axios from './axios';
import * as clientsService from '../services/clients';
import { Company } from '../types/company';
import { EmptyString } from '../types';

export async function getApplications(companyId: number): Promise<APIApplication[]> {
  const factoringRequest = await axios
    .get<EmptyString<FactoringRequest> | ''>(`companies/${companyId}/products/factoring/request/`)
    .then(({ data }) => data);
  if (!factoringRequest) return [];

  const client = await clientsService.currentUserDetail();
  const company = client.companies[0];
  const application = transformFactoringRequest(factoringRequest, company);
  return [application];
}

export function uploadAssets(assetsToUpload: FormData, applicationId: number): Promise<APIDocumentAsset> {
  return axios
    .post(`/applications/${applicationId}/documents/`, assetsToUpload, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=BoUnDaRyStRiNg`,
      },
    })
    .then(({ data }) => data || []);
}

export function uploadApplicationAssets(
  assetsToUpload: FormData,
  applicationId: number,
  companyId: number
): Promise<APIAsset[]> {
  return axios
    .post(
      `/companies/${companyId}/products/factoring/request/${applicationId}/upload_asset/`,
      assetsToUpload,
      {
        headers: {
          'Content-Type': `multipart/form-data; boundary=BoUnDaRyStRiNg`,
        },
      }
    )
    .then(({ data }) => data || []);
}

export function newApplication(companyId: number, isReapply = false): Promise<FactoringRequest> {
  return axios
    .post(`/companies/${companyId}/products/factoring/request/${isReapply ? 'reapply/' : ''}`)
    .then(({ data }) => data || {});
}

export function formatApplicationPayload(application: Partial<APIApplication>) {
  return {
    ...application,
    foundationYear: application.yearCompanyWasFounded,
    creditInsuranceEnabled: application.operatesWithCreditInsurance,
    creditInsuranceProvider: application.insuranceProvider,
    financialInstitutionEnabled: application.usingFinancialInstitutions,
    financialInstitutionName: application.financialInstitution,
    financialInstitutionInstrument: application.financialInstitutionType,
    // @ts-ignore
    sales_last_12_month: application.sale_last_12_month,
    addressStreetTwo: application.addressTwo,
  };
}

export function updateApplication(application: Partial<APIApplication>, id: number, company: Company) {
  return axios
    .patch<FactoringRequest>(
      `/companies/${company.id}/products/factoring/request/${id}/`,
      formatApplicationPayload(application)
    )
    .then(({ data }) => transformFactoringRequest(data, company));
}

export function submitApplication(application: Partial<APIApplication>, id: number, company: Company) {
  return axios
    .post<FactoringRequest>(
      `/companies/${company.id}/products/factoring/request/${id}/submit/`,
      formatApplicationPayload(application)
    )
    .then(({ data }) => transformFactoringRequest(data, company));
}

export function getAlerts(): Promise<APINotification[]> {
  return axios.get(`/notifications/`).then(({ data }) => data);
}

export function readAlert(alertId: number): Promise<APINotification> {
  return axios.post(`/notifications/${alertId}/read/`).then(({ data }) => data);
}

export function deleteApplicationAsset(
  companyId: number,
  factoringRequestId: number,
  assetId: number
): Promise<APIAsset> {
  return axios
    .delete(`/companies/${companyId}/products/factoring/request/${factoringRequestId}/assets/${assetId}/`)
    .then(({ data }) => data || {});
}

export function getApplicationBuyers(applicationId: number, companyId: number) {
  return axios
    .get<APIApplicationBuyerInfo[]>(
      `/companies/${companyId}/products/factoring/request/${applicationId}/buyer_info/`
    )
    .then(({ data }) => data);
}

export function addBuyer(applicationId: number, buyerInfo: AddCustomerState, companyId: number) {
  return axios
    .post<APIApplicationBuyerInfo>(
      `/companies/${companyId}/products/factoring/request/${applicationId}/buyer_info/`,
      buyerInfo
    )
    .then(({ data }) => data);
}

export function editBuyer(
  applicationId: number,
  buyerInfoId: number,
  buyerInfo: AddCustomerState,
  companyId: number
) {
  return axios
    .patch<APIApplicationBuyerInfo>(
      `/companies/${companyId}/products/factoring/request/${applicationId}/buyer_info/${buyerInfoId}/`,
      buyerInfo
    )
    .then(({ data }) => data);
}

export function deleteBuyer(applicationId: number, buyerInfoId: number, companyId: number) {
  return axios
    .delete(`/companies/${companyId}/products/factoring/request/${applicationId}/buyer_info/${buyerInfoId}/`)
    .then(({ data }) => data);
}

export function deleteApplication(id: number): Promise<any> {
  return axios.delete(`/applications/${id}/`).then(({ data }) => data || []);
}

export function submitCES(
  applicationId: number,
  values: { score?: number; comment?: string },
  companyId: number
): Promise<any> {
  return axios
    .post(`/companies/${companyId}/products/factoring/request/${applicationId}/ces/`, values)
    .then(({ data }) => data || {});
}

export function updateProductStatus(companyId: number, productStatusId: number, payload: any) {
  return axios
    .patch(`companies/${companyId}/products/product_status/${productStatusId}/`, {
      ...payload,
      factoringFlowMetadata: payload.flowMetadata,
    })
    .then(({ data }) => data || {});
}
