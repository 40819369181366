import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '../../componentsv2/Typography';
import { useLazyTranslation } from '../../hooks/i18n';

type SimpleBackdropProps = {
  isLoading?: boolean;
  hasOpaqueBackground?: boolean;
};

const SimpleBackdrop = (props: SimpleBackdropProps) => {
  const { t, isLoading: isLoadingTranslations } = useLazyTranslation();
  const { isLoading = false, hasOpaqueBackground = true } = props;

  return (
    <div>
      <Backdrop
        open={isLoading}
        sx={{
          color: '#fff',
          zIndex: 999999,
          ...(hasOpaqueBackground && { backgroundColor: '#F6FAFE' }),
        }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={50} />
          {!isLoadingTranslations && (
            <Box
              sx={{
                paddingTop: '2em',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Typography sx={{ fontWeight: 600, fontSize: '18px', lineHeight: '27px', color: '#4F5A84' }}>
                {t('simpleBackdrop.title')}
              </Typography>
              <Typography sx={{ fontWeight: 400, fontSize: '18px', lineHeight: '27px', color: '#4F5A84' }}>
                {t('simpleBackdrop.description')}
              </Typography>
            </Box>
          )}
        </Box>
      </Backdrop>
    </div>
  );
};

export default SimpleBackdrop;
