export default function NotificationsIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0277 3.51001C8.31385 3.51001 5.29562 6.20001 5.29562 9.51001V12.4C5.29562 13.01 5.00389 13.94 4.65607 14.46L3.36574 16.37C2.56911 17.55 3.1189 18.86 4.57752 19.3C9.41343 20.74 14.6308 20.74 19.4667 19.3C20.8244 18.9 21.4191 17.47 20.6785 16.37L19.3882 14.46C19.0516 13.94 18.7599 13.01 18.7599 12.4V9.51001C18.7599 6.21001 15.7304 3.51001 12.0277 3.51001Z"
        stroke="#3B4567"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.8699 3.68053C13.5599 3.59053 13.2399 3.52053 12.9099 3.48053C11.9499 3.36053 11.0299 3.43053 10.1699 3.68053C10.4599 2.94053 11.1799 2.42053 12.0199 2.42053C12.8599 2.42053 13.5799 2.94053 13.8699 3.68053Z"
        stroke="#3B4567"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0195 19.66C15.0195 21.31 13.6695 22.66 12.0195 22.66C11.1995 22.66 10.4395 22.32 9.89953 21.78C9.35953 21.24 9.01953 20.48 9.01953 19.66"
        stroke="#3B4567"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
