import Grid from '@mui/material/Grid';
import Layout from '../../componentsv2/Layout';
import Typography from '../../componentsv2/Typography';
import Clients from './Clients';
import { useLazyTranslation } from '../../hooks/i18n';
import { useAppSelector } from '../../store';
import { formatDate } from '../../utils/common';

const AdminDashboard = () => {
  const {
    t,
    i18n: { language },
  } = useLazyTranslation(['common', 'admin']);

  const user = useAppSelector((state) => state.auth.user);
  const now = new Date();

  return (
    <Layout>
      <Grid container rowSpacing={2}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5" fontWeight="700">
            {`${t('common:welcome')}, ${user?.firstName}`}
          </Typography>
          <Typography fontWeight={600}>{formatDate(now, language)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Clients />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default AdminDashboard;
