import { TFunction } from 'react-i18next';
import { GridColumns } from '@mui/x-data-grid';
import Chip from '../componentsv2/Chip';
import { CountriesForDebtorsKeys, CountryKeys, CountryOption, CountryOptionForDebtor } from '../types';
import { APICurrencyOptions, CurrencyKeys } from '../types/fx';
import { formatDateTime } from '../utils/common';

export function getCountries(t: TFunction): CountryOption[] {
  return [
    { countryKey: 'ae', country: t('common:countries.ae') },
    { countryKey: 'ca', country: t('common:countries.ca') },
    { countryKey: 'co', country: t('common:countries.co') },
    { countryKey: 'dk', country: t('common:countries.dk') },
    { countryKey: 'do', country: t('common:countries.do') },
    { countryKey: 'ec', country: t('common:countries.ec') },
    { countryKey: 'sv', country: t('common:countries.sv') },
    { countryKey: 'gt', country: t('common:countries.gt') },
    { countryKey: 'hn', country: t('common:countries.hn') },
    { countryKey: 'hk', country: t('common:countries.hk') },
    { countryKey: 'mx', country: t('common:countries.mx') },
    { countryKey: 'ni', country: t('common:countries.ni') },
    { countryKey: 'pa', country: t('common:countries.pa') },
    { countryKey: 'pe', country: t('common:countries.pe') },
    { countryKey: 'pl', country: t('common:countries.pl') },
    { countryKey: 'pr', country: t('common:countries.pr') },
    { countryKey: 'us', country: t('common:countries.us') },
    { countryKey: 'ar', country: t('common:countries.ar') },
    { countryKey: 'bo', country: t('common:countries.bo') },
    { countryKey: 'br', country: t('common:countries.br') },
    { countryKey: 'bz', country: t('common:countries.bz') },
    { countryKey: 'cl', country: t('common:countries.cl') },
    { countryKey: 'py', country: t('common:countries.py') },
    { countryKey: 'uy', country: t('common:countries.uy') },
    { countryKey: 'cr', country: t('common:countries.cr') },
    { countryKey: 'es', country: t('common:countries.es') },
    { countryKey: 'ch', country: t('common:countries.ch') },
    { countryKey: 'in', country: t('common:countries.in') },
    { countryKey: 'uk', country: t('common:countries.uk') },
  ];
}

export const getUSStates = [
  { stateKey: 'AL', state: 'Alabama' },
  { stateKey: 'AK', state: 'Alaska' },
  { stateKey: 'AZ', state: 'Arizona' },
  { stateKey: 'AR', state: 'Arkansas' },
  { stateKey: 'CA', state: 'California' },
  { stateKey: 'CO', state: 'Colorado' },
  { stateKey: 'CT', state: 'Connecticut' },
  { stateKey: 'DC', state: 'District of Columbia' },
  { stateKey: 'DE', state: 'Delaware' },
  { stateKey: 'FL', state: 'Florida' },
  { stateKey: 'GA', state: 'Georgia' },
  { stateKey: 'HI', state: 'Hawaii' },
  { stateKey: 'ID', state: 'Idaho' },
  { stateKey: 'IL', state: 'Illinois' },
  { stateKey: 'IN', state: 'Indiana' },
  { stateKey: 'IA', state: 'Iowa' },
  { stateKey: 'KS', state: 'Kansas' },
  { stateKey: 'KY', state: 'Kentucky' },
  { stateKey: 'LA', state: 'Louisiana' },
  { stateKey: 'ME', state: 'Maine' },
  { stateKey: 'MD', state: 'Maryland' },
  { stateKey: 'MA', state: 'Massachusetts' },
  { stateKey: 'MI', state: 'Michigan' },
  { stateKey: 'MN', state: 'Minnesota' },
  { stateKey: 'MS', state: 'Mississippi' },
  { stateKey: 'MO', state: 'Missouri' },
  { stateKey: 'MT', state: 'Montana' },
  { stateKey: 'NE', state: 'Nebraska' },
  { stateKey: 'NV', state: 'Nevada' },
  { stateKey: 'NH', state: 'New Hampshire' },
  { stateKey: 'NJ', state: 'New Jersey' },
  { stateKey: 'NM', state: 'New Mexico' },
  { stateKey: 'NY', state: 'New York' },
  { stateKey: 'NC', state: 'North Carolina' },
  { stateKey: 'ND', state: 'North Dakota' },
  { stateKey: 'OH', state: 'Ohio' },
  { stateKey: 'OK', state: 'Oklahoma' },
  { stateKey: 'OR', state: 'Oregon' },
  { stateKey: 'PA', state: 'Pennsylvania' },
  { stateKey: 'RI', state: 'Rhode Island' },
  { stateKey: 'SC', state: 'South Carolina' },
  { stateKey: 'SD', state: 'South Dakota' },
  { stateKey: 'TN', state: 'Tennessee' },
  { stateKey: 'TX', state: 'Texas' },
  { stateKey: 'UT', state: 'Utah' },
  { stateKey: 'VT', state: 'Vermont' },
  { stateKey: 'VA', state: 'Virginia' },
  { stateKey: 'WA', state: 'Washington' },
  { stateKey: 'WV', state: 'West Virginia' },
  { stateKey: 'WI', state: 'Wisconsin' },
  { stateKey: 'WY', state: 'Wyoming' },
];

export function getCountriesForDebtors(t: TFunction): CountryOptionForDebtor[] {
  return [
    { countryKey: 'ad', country: t('common:countries.ad') },
    { countryKey: 'at', country: t('common:countries.at') },
    { countryKey: 'be', country: t('common:countries.be') },
    { countryKey: 'hr', country: t('common:countries.hr') },
    { countryKey: 'cz', country: t('common:countries.cz') },
    { countryKey: 'dk', country: t('common:countries.dk') },
    { countryKey: 'ee', country: t('common:countries.ee') },
    { countryKey: 'fi', country: t('common:countries.fi') },
    { countryKey: 'fr', country: t('common:countries.fr') },
    { countryKey: 'de', country: t('common:countries.de') },
    { countryKey: 'hu', country: t('common:countries.hu') },
    { countryKey: 'is', country: t('common:countries.is') },
    { countryKey: 'ie', country: t('common:countries.ie') },
    { countryKey: 'it', country: t('common:countries.it') },
    { countryKey: 'lv', country: t('common:countries.lv') },
    { countryKey: 'li', country: t('common:countries.li') },
    { countryKey: 'lt', country: t('common:countries.lt') },
    { countryKey: 'lu', country: t('common:countries.lu') },
    { countryKey: 'nl', country: t('common:countries.nl') },
    { countryKey: 'no', country: t('common:countries.no') },
    { countryKey: 'pl', country: t('common:countries.pl') },
    { countryKey: 'pt', country: t('common:countries.pt') },
    { countryKey: 'sk', country: t('common:countries.sk') },
    { countryKey: 'si', country: t('common:countries.si') },
    { countryKey: 'es', country: t('common:countries.es') },
    { countryKey: 'se', country: t('common:countries.se') },
    { countryKey: 'ch', country: t('common:countries.ch') },
    { countryKey: 'gb', country: t('common:countries.gb') },
    { countryKey: 'bo', country: t('common:countries.bo') },
    { countryKey: 'br', country: t('common:countries.br') },
    { countryKey: 'cl', country: t('common:countries.cl') },
    { countryKey: 'co', country: t('common:countries.co') },
    { countryKey: 'cr', country: t('common:countries.cr') },
    { countryKey: 'cw', country: t('common:countries.cw') },
    { countryKey: 'do', country: t('common:countries.do') },
    { countryKey: 'ec', country: t('common:countries.ec') },
    { countryKey: 'sv', country: t('common:countries.sv') },
    { countryKey: 'gt', country: t('common:countries.gt') },
    { countryKey: 'hn', country: t('common:countries.hn') },
    { countryKey: 'mx', country: t('common:countries.mx') },
    { countryKey: 'pa', country: t('common:countries.pa') },
    { countryKey: 'pe', country: t('common:countries.pe') },
    { countryKey: 'maf', country: t('common:countries.maf') },
    { countryKey: 'uy', country: t('common:countries.uy') },
    { countryKey: 'gu', country: t('common:countries.gu') },
    { countryKey: 'au', country: t('common:countries.au') },
    { countryKey: 'nz', country: t('common:countries.nz') },
    { countryKey: 'us', country: t('common:countries.us') },
    { countryKey: 'ca', country: t('common:countries.ca') },
    { countryKey: 'hk', country: t('common:countries.hk') },
    { countryKey: 'il', country: t('common:countries.il') },
    { countryKey: 'jp', country: t('common:countries.jp') },
    { countryKey: 'ph', country: t('common:countries.ph') },
    { countryKey: 'qa', country: t('common:countries.qa') },
    { countryKey: 'sa', country: t('common:countries.sa') },
    { countryKey: 'sg', country: t('common:countries.sg') },
    { countryKey: 'kr', country: t('common:countries.kr') },
    { countryKey: 'tw', country: t('common:countries.tw') },
    { countryKey: 'th', country: t('common:countries.th') },
    { countryKey: 'ae', country: t('common:countries.ae') },
    { countryKey: 'za', country: t('common:countries.za') },
  ];
}

export function getCurrencies(t: TFunction) {
  return [
    { currencyKey: 'mx', currency: t('fx:form.currencyOptions.mx') },
    { currencyKey: 'us', currency: t('fx:form.currencyOptions.us') },
  ];
}

export const signupCountryCodes = [
  'ae',
  'ca',
  'co',
  'dk',
  'do',
  'ec',
  'sv',
  'gt',
  'hn',
  'hk',
  'mx',
  'ni',
  'pa',
  'pe',
  'pl',
  'pr',
  'us',
  'ar',
  'bo',
  'br',
  'bz',
  'cl',
  'py',
  'uy',
  'cr',
  'es',
  'ch',
  'in',
  'uk',
];

export const countryCodes = [...signupCountryCodes, 'cn', 'al', 'bb', 'bg', 'de', 'nl'] as const;

export const countryCodesForDebtors = [
  'ad',
  'at',
  'be',
  'hr',
  'cz',
  'dk',
  'ee',
  'fi',
  'fr',
  'de',
  'hu',
  'is',
  'ie',
  'it',
  'lv',
  'li',
  'lt',
  'lu',
  'nl',
  'no',
  'pl',
  'pt',
  'sk',
  'si',
  'es',
  'se',
  'ch',
  'gb',
  'bo',
  'br',
  'cl',
  'co',
  'cr',
  'cw',
  'do',
  'ec',
  'sv',
  'gt',
  'hn',
  'mx',
  'pa',
  'pe',
  'maf',
  'uy',
  'gu',
  'au',
  'nz',
  'us',
  'ca',
  'hk',
  'il',
  'jp',
  'ph',
  'qa',
  'sa',
  'sg',
  'kr',
  'tw',
  'th',
  'ae',
  'za',
];

export const currencyCodes = ['mx', 'us'] as const;

export const backendCurrencyValues: Record<CurrencyKeys, APICurrencyOptions> = {
  mx: 'MXN',
  us: 'USD',
};

export const countryLocales: Record<CountryKeys, string> = {
  co: 'es-CO',
  ec: 'es-EC',
  mx: 'es-MX',
  pe: 'es-PE',
  us: 'en-US',
  ca: 'en-CA',
  hk: 'en-HK',
  ae: 'en-AE',
  dk: 'en-DK',
  do: 'es-DO',
  sv: 'es-SV',
  gt: 'es-GT',
  hn: 'es-HN',
  ni: 'es-NI',
  pa: 'es-PA',
  pl: 'en-PL',
  pr: 'es-PR',
  ar: 'es-AR',
  bo: 'es-BO',
  br: 'en-BR',
  bz: 'en-BZ',
  cl: 'es-CL',
  py: 'es-PY',
  uy: 'es-UY',
  cr: 'es-CR',
  es: 'es-ES',
  ch: 'en-CH',
  in: 'en-IN',
  uk: 'en-GB',
  cn: 'en-US',
  al: 'en-US',
  bb: 'en-US',
  bg: 'en-US',
  de: 'en-US',
  nl: 'en-US',
};

export const backendCountryValues: Record<CountryKeys, string> = {
  co: 'colombia',
  ec: 'ecuador',
  mx: 'mexico',
  pe: 'peru',
  us: 'usa',
  ca: 'canada',
  hk: 'hongKong',
  ae: 'unitedArabEmirates',
  dk: 'denmark',
  do: 'dominicanRepublic',
  sv: 'elSalvador',
  gt: 'guatemala',
  hn: 'honduras',
  ni: 'nicaragua',
  pa: 'panama',
  pl: 'poland',
  pr: 'puertoRico',
  ar: 'argentina',
  bo: 'bolivia',
  br: 'brazil',
  bz: 'belize',
  cl: 'chile',
  py: 'paraguay',
  uy: 'uruguay',
  cr: 'costaRica',
  es: 'spain',
  ch: 'switzerland',
  in: 'india',
  uk: 'uk',
  cn: 'china',
  al: 'albania',
  bb: 'barbados',
  bg: 'bulgaria',
  de: 'germany',
  nl: 'netherlands',
};

export const backendCountryValuesForDebtors: Record<CountriesForDebtorsKeys, string> = {
  ad: 'andorra',
  at: 'austria',
  be: 'belgium',
  hr: 'croatia',
  cz: 'czechRepublic',
  dk: 'denmark',
  ee: 'estonia',
  fi: 'finland',
  fr: 'france',
  de: 'germany',
  hu: 'hungary',
  is: 'iceland',
  ie: 'ireland',
  it: 'italy',
  lv: 'latvia',
  li: 'liechtenstein',
  lt: 'lithuania',
  lu: 'luxembourg',
  nl: 'netherlands',
  no: 'norway',
  pl: 'poland',
  pt: 'portugal',
  sk: 'slovakia',
  si: 'slovenia',
  es: 'spain',
  se: 'sweden',
  ch: 'switzerland',
  gb: 'uk',
  bo: 'bolivia',
  br: 'brazil',
  cl: 'chile',
  co: 'colombia',
  cr: 'costaRica',
  cw: 'curacao',
  do: 'dominicanRepublic',
  ec: 'ecuador',
  sv: 'elSalvador',
  gt: 'guatemala',
  hn: 'honduras',
  mx: 'mexico',
  pa: 'panama',
  pe: 'peru',
  maf: 'saintMarteen',
  uy: 'uruguay',
  gu: 'guam',
  au: 'australia',
  nz: 'newZealand',
  us: 'usa',
  ca: 'canada',
  hk: 'hongKong',
  il: 'israel',
  jp: 'japan',
  ph: 'philippines',
  qa: 'qatar',
  sa: 'saudiArabia',
  sg: 'singapore',
  kr: 'southKorea',
  tw: 'taiwan',
  th: 'thailand',
  ae: 'unitedArabEmirates',
  za: 'southAfrica',
};

export const countries = [
  'albania',
  'angola',
  'argentina',
  'armenia',
  'aruba',
  'australia',
  'austria',
  'azerbaijan',
  'bahamas',
  'bahrain',
  'bangladesh',
  'barbados',
  'belarus',
  'belgium',
  'belize',
  'benin',
  'bermuda',
  'bolivia',
  'bosniaAndHerzegovina',
  'botswana',
  'brazil',
  'bulgaria',
  'burkinaFaso',
  'cambodia',
  'cameroon',
  'canada',
  'capeVerde',
  'caymanIslands',
  'chile',
  'china',
  'colombia',
  'congo',
  'costaRica',
  'croatia',
  'cuba',
  'cyprus',
  'czechRepublic',
  'denmark',
  'dominicanRepublic',
  'ecuador',
  'egypt',
  'elSalvador',
  'estonia',
  'ethiopia',
  'fiji',
  'finland',
  'france',
  'gabon',
  'georgia',
  'germany',
  'ghana',
  'greece',
  'grenada',
  'guatemala',
  'honduras',
  'hongKong',
  'hungary',
  'iceland',
  'india',
  'indonesia',
  'iraq',
  'ireland',
  'isleOfMan',
  'israel',
  'italy',
  'ivoryCoast',
  'jamaica',
  'japan',
  'jordan',
  'kazakhstan',
  'kenya',
  'kuwait',
  'kyrgyzstan',
  'laos',
  'latvia',
  'lebanon',
  'lesotho',
  'liechtenstein',
  'lithuania',
  'luxembourg',
  'macau',
  'macedonia',
  'malaysia',
  'maldives',
  'mali',
  'malta',
  'mauritius',
  'mexico',
  'moldova',
  'mongolia',
  'montenegro',
  'montserrat',
  'morocco',
  'mozambique',
  'namibia',
  'netherlands',
  'newZealand',
  'nicaragua',
  'niger',
  'nigeria',
  'norway',
  'oman',
  'pakistan',
  'panama',
  'papuaNewGuinea',
  'paraguay',
  'peru',
  'philippines',
  'poland',
  'portugal',
  'puertoRico',
  'qatar',
  'republicOfTheCongo',
  'romania',
  'russia',
  'rwanda',
  'sanMarino',
  'saudiArabia',
  'senegal',
  'serbia',
  'seychelles',
  'singapore',
  'slovakia',
  'slovenia',
  'solomonIslands',
  'southAfrica',
  'southKorea',
  'spain',
  'sriLanka',
  'stVincentAndTheGrenadines',
  'suriname',
  'swaziland',
  'sweden',
  'switzerland',
  'taiwan',
  'tajikistan',
  'tanzania',
  'thailand',
  'togo',
  'trinidadAndTobago',
  'tunisia',
  'turkey',
  'uganda',
  'ukraine',
  'unitedArabEmirates',
  'uk',
  'usa',
  'uruguay',
  'uzbekistan',
  'venezuela',
  'vietnam',
  'zambia',
] as const;

export const qualityChecks = [
  { value: 'No', label: 'No' },
  { value: 'Yes - Internal', label: 'Yes - Internal' },
  { value: 'Yes - performed by Buyer', label: 'Yes - performed by Buyer' },
  { value: 'Yes - internal and by the Buyer', label: 'Yes - internal and by the Buyer' },
  { value: '3rd party checks', label: '3rd party checks' },
  { value: 'Samples are exchanged before shipment', label: 'Samples are exchanged before shipment' },
];

export const currency = [
  { value: 'usd', label: 'USD' },
  { value: 'eur', label: 'EUR' },
  { value: 'mxn', label: 'MXN' },
  { value: 'gbp', label: 'GBP' },
  { value: 'aud', label: 'AUD' },
  { value: 'cad', label: 'CAD' },
  { value: 'other', label: 'Other' },
];

export const radioQuestion = [
  { value: 'yes', label: 'yes' },
  { value: 'no', label: 'no' },
];

export const threeOptionsRadioQuestions = [...radioQuestion, { value: 'not_sure', label: 'notSure' }];

export const regions = ['northAmerica', 'centralAndSouthAmerica', 'europe', 'africa', 'asia', 'oceania'];

export const sources = [
  { value: 'searchEngine', img: 'search' },
  { value: 'linkedIn', img: 'linkedIn' },
  { value: 'ads', img: 'advertisement' },
  { value: 'email', img: 'blog' },
  { value: 'referral', img: 'referral' },
  { value: 'other', img: 'other' },
];

export const sectors = [
  'accomodationAndFood',
  'wasteManagement',
  'agriculture',
  'arts',
  'construction',
  'education',
  'financeInsurance',
  'healthcare',
  'information',
  'management',
  'manufacturing',
  'mining',
  'scientific',
  'publicAdministration',
  'realEstate',
  'retail',
  'warehousing',
  'utilities',
  'wholesale',
  'other',
];

export const currencies = ['usd', 'eur', 'mxn', 'gbp', 'aud', 'cad', 'other'];

export const products = ['abl', 'factoring', 'factoringAndPo', 'po', 'supplyChain', 'notSure'];

type CellValueBoxProps = {
  children: React.ReactChildren;
};

const CellValueBox = (props: CellValueBoxProps) => {
  return (
    <div
      style={{
        borderRadius: '4px',
        minHeight: '32px',
        minWidth: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '0.875rem',
      }}>
      {props.children}
    </div>
  );
};

export const getClientsColumns = (language: string = 'en-US', t: TFunction): GridColumns => {
  return [
    {
      field: 'companyUuid',
      type: 'string',
      headerName: t(`admin:clientsPage.columnOptions.companyId`),
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      minWidth: 150,
      renderCell: (row: any) => <CellValueBox>{row.value}</CellValueBox>,
    },
    {
      field: 'name',
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      headerName: t(`admin:clientsPage.columnOptions.user`),
      sortable: false,
      minWidth: 200,
    },
    {
      field: 'companyName',
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      headerName: t(`admin:clientsPage.columnOptions.company`),
      sortable: false,
      minWidth: 200,
    },
    {
      field: 'country',
      type: 'string',
      headerClassName: 'gridheader',
      headerAlign: 'left',
      headerName: t(`admin:clientsPage.columnOptions.country`),
      sortable: false,
      align: 'left',
      minWidth: 150,
      valueFormatter: (row: any) => {
        return t(`user:preapproval.location.options.${row.value}`);
      },
    },
    {
      field: 'stage',
      headerClassName: 'gridheader',
      headerName: t(`admin:clientsPage.columnOptions.stage`),
      headerAlign: 'left',
      sortable: false,
      minWidth: 150,
      align: 'left',
      renderCell: (row: any) => {
        return (
          <Chip
            label={t(`user:dashboard.stage.${row.value}`)}
            size="small"
            sx={{ backgroundColor: '#E1FAED', color: '#11705F' }}
          />
        );
      },
    },
    {
      field: 'status',
      headerClassName: 'gridheader',
      headerName: t(`admin:clientsPage.columnOptions.status`),
      headerAlign: 'left',
      sortable: false,
      minWidth: 200,
      align: 'left',
      renderCell: (row: any) => {
        return (
          <Chip
            label={t(`user:dashboard.status.${row.value}`)}
            size="small"
            sx={{ backgroundColor: '#E1FAED', color: '#11705F' }}
          />
        );
      },
    },
    {
      field: 'updatedAt',
      headerClassName: 'gridheader',
      align: 'left',
      headerAlign: 'left',
      headerName: t(`admin:clientsPage.columnOptions.dateModified`),
      sortable: false,
      minWidth: 200,
      valueFormatter: (row: any) => {
        return formatDateTime(new Date(row.value), language, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
      },
      flex: 1,
    },
  ];
};

export const getBuyerCreditCheckColumns = (language: string = 'en-US', t: TFunction): GridColumns => {
  return [
    {
      field: 'id',
      type: 'string',
      headerName: t(`admin:clientsPage.buyerCreditCheck.columnOptions.id`),
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (row: any) => <CellValueBox>{row.value}</CellValueBox>,
    },
    {
      field: 'companyName',
      headerClassName: 'gridheader',
      type: 'string',
      headerName: t(`admin:clientsPage.buyerCreditCheck.columnOptions.companyName`),
      sortable: false,
      flex: 1,
      headerAlign: 'center',
      minWidth: 120,
    },
    {
      field: 'tradeName',
      headerClassName: 'gridheader',
      type: 'string',
      headerName: t(`admin:clientsPage.buyerCreditCheck.columnOptions.tradeName`),
      sortable: false,
      flex: 1,
      minWidth: 120,
      headerAlign: 'center',
    },
    {
      field: 'addressCountry',
      type: 'string',
      headerClassName: 'gridheader',
      headerAlign: 'center',
      headerName: t(`admin:clientsPage.buyerCreditCheck.columnOptions.country`),
      sortable: false,
      flex: 1,
      align: 'center',
      minWidth: 50,
      valueFormatter: (row: any) => {
        return t(`user:preapproval.location.options.${row.value}`);
      },
    },
    {
      field: 'addressCity',
      type: 'string',
      headerClassName: 'gridheader',
      headerAlign: 'center',
      headerName: t(`admin:clientsPage.buyerCreditCheck.columnOptions.city`),
      sortable: false,
      flex: 1,
      align: 'center',
      minWidth: 50,
    },
    {
      field: 'ein',
      type: 'string',
      headerClassName: 'gridheader',
      headerAlign: 'center',
      headerName: t(`admin:clientsPage.buyerCreditCheck.columnOptions.ein`),
      sortable: false,
      flex: 1,
      align: 'center',
      minWidth: 50,
    },
    {
      field: 'duns',
      type: 'string',
      headerClassName: 'gridheader',
      headerAlign: 'center',
      headerName: t(`admin:clientsPage.buyerCreditCheck.columnOptions.duns`),
      sortable: false,
      flex: 1,
      align: 'center',
      minWidth: 50,
    },
    {
      field: 'createdAt',
      headerClassName: 'gridheader',
      headerName: t(`admin:clientsPage.buyerCreditCheck.columnOptions.date`),
      sortable: false,
      flex: 1,
      minWidth: 147,
      headerAlign: 'center',
      valueFormatter: (row: any) => {
        return formatDateTime(new Date(row.value), language, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
      },
    },
  ];
};

export const getFxRequestColumns = (language: string = 'en-US', t: TFunction): GridColumns => {
  return [
    {
      field: 'id',
      type: 'string',
      headerName: t(`admin:clientsPage.fxRequest.columnOptions.id`),
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (row: any) => <CellValueBox>{row.value}</CellValueBox>,
    },
    {
      field: 'fromCurrency',
      headerClassName: 'gridheader',
      type: 'string',
      headerName: t(`admin:clientsPage.fxRequest.columnOptions.fromCurrency`),
      sortable: false,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 147,
    },
    {
      field: 'toCurrency',
      type: 'string',
      headerClassName: 'gridheader',
      headerAlign: 'center',
      headerName: t(`admin:clientsPage.fxRequest.columnOptions.toCurrency`),
      sortable: false,
      flex: 1,
      align: 'center',
      minWidth: 147,
    },
    {
      field: 'amount',
      headerClassName: 'gridheader',
      headerAlign: 'center',
      headerName: t(`admin:clientsPage.fxRequest.columnOptions.amount`),
      sortable: false,
      flex: 1,
      align: 'center',
    },
    {
      field: 'exchangeRate',
      headerClassName: 'gridheader',
      headerAlign: 'center',
      headerName: t(`admin:clientsPage.fxRequest.columnOptions.exchangeRate`),
      sortable: false,
      flex: 1,
      align: 'center',
      minWidth: 147,
    },
    {
      field: 'createdAt',
      headerClassName: 'gridheader',
      headerName: t(`admin:clientsPage.fxRequest.columnOptions.date`),
      sortable: false,
      flex: 1,
      minWidth: 147,
      headerAlign: 'center',
      valueFormatter: (row: any) => {
        return formatDateTime(new Date(row.value), language, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
      },
    },
  ];
};

export const moneyOptions = [
  { label: '$0 - $150,000', value: [0, 150_000] },
  { label: '$150,000 - $250,000', value: [150_000, 250_000] },
  { label: '$250,000 - $500,000', value: [250_000, 500_000] },
  { label: '$500,000 - $1,000,000', value: [500_000, 1_000_000] },
  { label: '$1,000,000 - $3,000,000 ', value: [1_000_000, 3_000_000] },
  { label: '$3,000,000 - $5,000,000 ', value: [3_000_000, 5_000_000] },
  { label: '$5,000,000 - $7,500,000', value: [5_000_000, 7_000_000] },
  { label: '$7,500,000 - $10,000,000', value: [7_000_000, 10_000_000] },
  { label: '$10,000,000++', value: [10_000_000, 999_999_999] },
];

export const getLanguageOptions = (location: CountryKeys) => {
  return [
    { language: `en-${location.toUpperCase()}`, title: 'English', key: 1 },
    { language: `es-${location.toUpperCase()}`, title: 'Español', key: 2 },
  ];
};

export const fxRadioQuestions = (t: TFunction) => [
  { value: 'sell', label: t('fx:form.fields.sell') },
  { value: 'buy', label: t('fx:form.fields.buy') },
];

export const bankAccountCountries = [
  'argentina',
  'australia',
  'austria',
  'belize',
  'belgium',
  'bolivia',
  'brazil',
  'canada',
  'croatia',
  'chile',
  'colombia',
  'costaRica',
  'cyprus',
  'czechRepublic',
  'denmark',
  'dominicanRepublic',
  'ecuador',
  'elSalvador',
  'finland',
  'france',
  'germany',
  'greece',
  'guatemala',
  'honduras',
  'hongKong',
  'india',
  'ireland',
  'italy',
  'japan',
  'lithuania',
  'luxembourg',
  'malta',
  'mexico',
  'netherlands',
  'newZealand',
  'nicaragua',
  'norway',
  'panama',
  'paraguay',
  'peru',
  'poland',
  'portugal',
  'puertoRico',
  'singapore',
  'spain',
  'sweden',
  'switzerland',
  'unitedArabEmirates',
  'uk',
  'usa',
  'uruguay',
];

export const productCESProductTranslationMapping = {
  invoice_funding_request: {
    title: 'servicing:fundingRequestSentModal.title',
    message: 'servicing:fundingRequestSentModal.description',
    button: 'servicing:fundingRequestSentModal.button',
    productCesModal: 'servicing:fundingRequestSentModal.productCESModal', // This is the prefix for all producCESModal translations
  },
};

export function getCountriesForShareholdersAndLegalReps(t: TFunction) {
  return [
    { label: t('common:countries.ar'), value: 'ar' },
    { label: t('common:countries.au'), value: 'au' },
    { label: t('common:countries.at'), value: 'at' },
    { label: t('common:countries.bs'), value: 'bs' },
    { label: t('common:countries.be'), value: 'be' },
    { label: t('common:countries.bz'), value: 'bz' },
    { label: t('common:countries.bm'), value: 'bm' },
    { label: t('common:countries.bo'), value: 'bo' },
    { label: t('common:countries.br'), value: 'br' },
    { label: t('common:countries.bg'), value: 'bg' },
    { label: t('common:countries.ca'), value: 'ca' },
    { label: t('common:countries.ky'), value: 'ky' },
    { label: t('common:countries.cl'), value: 'cl' },
    { label: t('common:countries.cn'), value: 'cn' },
    { label: t('common:countries.co'), value: 'co' },
    { label: t('common:countries.cr'), value: 'cr' },
    { label: t('common:countries.hr'), value: 'hr' },
    { label: t('common:countries.cy'), value: 'cy' },
    { label: t('common:countries.cz'), value: 'cz' },
    { label: t('common:countries.dk'), value: 'dk' },
    { label: t('common:countries.do'), value: 'do' },
    { label: t('common:countries.ec'), value: 'ec' },
    { label: t('common:countries.sv'), value: 'sv' },
    { label: t('common:countries.ee'), value: 'ee' },
    { label: t('common:countries.fj'), value: 'fj' },
    { label: t('common:countries.fi'), value: 'fi' },
    { label: t('common:countries.fr'), value: 'fr' },
    { label: t('common:countries.de'), value: 'de' },
    { label: t('common:countries.gr'), value: 'gr' },
    { label: t('common:countries.gd'), value: 'gd' },
    { label: t('common:countries.gt'), value: 'gt' },
    { label: t('common:countries.hn'), value: 'hn' },
    { label: t('common:countries.hk'), value: 'hk' },
    { label: t('common:countries.hu'), value: 'hu' },
    { label: t('common:countries.is'), value: 'is' },
    { label: t('common:countries.in'), value: 'in' },
    { label: t('common:countries.id'), value: 'id' },
    { label: t('common:countries.ie'), value: 'ie' },
    { label: t('common:countries.im'), value: 'im' },
    { label: t('common:countries.il'), value: 'il' },
    { label: t('common:countries.it'), value: 'it' },
    { label: t('common:countries.jm'), value: 'jm' },
    { label: t('common:countries.jp'), value: 'jp' },
    { label: t('common:countries.jo'), value: 'jo' },
    { label: t('common:countries.lv'), value: 'lv' },
    { label: t('common:countries.li'), value: 'li' },
    { label: t('common:countries.lt'), value: 'lt' },
    { label: t('common:countries.lu'), value: 'lu' },
    { label: t('common:countries.my'), value: 'my' },
    { label: t('common:countries.mv'), value: 'mv' },
    { label: t('common:countries.mt'), value: 'mt' },
    { label: t('common:countries.mu'), value: 'mu' },
    { label: t('common:countries.mx'), value: 'mx' },
    { label: t('common:countries.me'), value: 'me' },
    { label: t('common:countries.ms'), value: 'ms' },
    { label: t('common:countries.nl'), value: 'nl' },
    { label: t('common:countries.nz'), value: 'nz' },
    { label: t('common:countries.ni'), value: 'ni' },
    { label: t('common:countries.no'), value: 'no' },
    { label: t('common:countries.pa'), value: 'pa' },
    { label: t('common:countries.py'), value: 'py' },
    { label: t('common:countries.pe'), value: 'pe' },
    { label: t('common:countries.ph'), value: 'ph' },
    { label: t('common:countries.pl'), value: 'pl' },
    { label: t('common:countries.pt'), value: 'pt' },
    { label: t('common:countries.pr'), value: 'pr' },
    { label: t('common:countries.qa'), value: 'qa' },
    { label: t('common:countries.sc'), value: 'sc' },
    { label: t('common:countries.sg'), value: 'sg' },
    { label: t('common:countries.sk'), value: 'sk' },
    { label: t('common:countries.si'), value: 'si' },
    { label: t('common:countries.sb'), value: 'sb' },
    { label: t('common:countries.za'), value: 'za' },
    { label: t('common:countries.es'), value: 'es' },
    { label: t('common:countries.lk'), value: 'lk' },
    { label: t('common:countries.vc'), value: 'vc' },
    { label: t('common:countries.sr'), value: 'sr' },
    { label: t('common:countries.se'), value: 'se' },
    { label: t('common:countries.ch'), value: 'ch' },
    { label: t('common:countries.tw'), value: 'tw' },
    { label: t('common:countries.th'), value: 'th' },
    { label: t('common:countries.tt'), value: 'tt' },
    { label: t('common:countries.tr'), value: 'tr' },
    { label: t('common:countries.ae'), value: 'ae' },
    { label: t('common:countries.uk'), value: 'uk' },
    { label: t('common:countries.us'), value: 'us' },
    { label: t('common:countries.uy'), value: 'uy' },
    { label: t('common:countries.vn'), value: 'vn' },
  ];
}
