export default function ArrowRightIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.528 6.40869L19.3613 12.4087M19.3613 12.4087L13.528 18.4087M19.3613 12.4087L5.36133 12.4087"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const ArrowRightWithLine = () => {
  return (
    <svg width="22" height="12" viewBox="0 0 22 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5727 0.272721L10.4063 1.42636L14.1952 5.18181L0.818192 5.18181L0.818192 6.81817L14.1952 6.81817L10.398 10.5736L11.5727 11.7273L17.3636 5.99999L11.5727 0.272721Z" />
      <rect
        x="21.8182"
        y="11.7273"
        width="1.90909"
        height="11.4545"
        transform="rotate(-180 21.8182 11.7273)"
      />
    </svg>
  );
};
