import Grid from '@mui/material/Grid';
import Typography from '../Typography';

const PageHeader = ({ title, subtitle }: { title: string; subtitle?: string }) => {
  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5" fontWeight="700">
          {title}
        </Typography>
      </Grid>
      {subtitle && (
        <Grid item xs={12}>
          <Typography>{subtitle}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default PageHeader;
