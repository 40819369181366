import { ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Trans } from 'react-i18next';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { logEvent } from '@amplitude/analytics-browser';
import { camelize } from 'humps';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import { formatDate } from '../../utils/common';

import { getHolidays } from '../../services/products';

import { Holiday } from '../../types/holidays';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CloseOutlined } from '@mui/icons-material';
import {
  ArrowRightIcon,
  BuyerCheckIcon,
  LLCIcon,
  MarcoIcon,
  MiniNotepadIcon,
  MiniMoneyReceiveIcon,
} from '../../components/Icons';
import Typography from '../../componentsv2/Typography';
import Layout from '../../componentsv2/Layout';
import Button from '../../componentsv2/Button';
import Tooltip from '../../componentsv2/Tooltip';
import CopyToClipboardButton from '../../componentsv2/CopyToClipboardButton';
import ModalV2 from '../../components/ModalV2';
import Chip from '../../componentsv2/Chip';
import BankingIcon from '../../components/Icons/BankingIcon';
import PipelineBanner from './PipelineBanner';
import { useSnackbar } from '../../context';
import { useLazyTranslation } from '../../hooks/i18n';
import { useUserLocation } from '../../hooks/useUserLocation';
import { getApplications, updateProductStatus } from '../../services/application';
import { getCreditLineMainMetrics, getInvoicesAggregates } from '../../services/servicing';
import { useAppSelector } from '../../store';
import { COLORS } from '../../themev2';
import { Nullable } from '../../types';
import { APIApplication } from '../../types/application';
import { APIClient, InternalPipelineStatus } from '../../types/client';
import { CreditLineMetrics } from '../../types/servicing';
import { formatCreditLineCurrency } from '../../utils/helpers';
import {
  BANKING,
  BUYER_CREDIT_CHECK,
  CURRENT_APP_STEP_STORAGE_KEY,
  INVOICE_FINANCING,
  LLC,
  MY_DOCUMENTS,
} from '../../constants/auth';
import { APPLICATION_STARTED } from '../../constants/variables';
import { APPLICATION } from '../../constants/auth';
import HolidayNotice from './HolidayNotice';
import { creditLineEvents, homeEvents, servicingEvents, termSheetEvents } from '../../constants/events';
import classes from './Dashboard.module.scss';

const referralSteps = [
  {
    title: 'referral.modal.steps.copy.title',
    description: 'referral.modal.steps.copy.description',
    step: 1,
  },
  {
    title: 'referral.modal.steps.send.title',
    description: 'referral.modal.steps.send.description',
    step: 2,
  },
  {
    title: 'referral.modal.steps.get.title',
    description: 'referral.modal.steps.get.description',
    step: 3,
  },
];

const WARNING_MIN = 10;
const WARNING_MAX = 20;

export const ProgressBar = ({ progress, tooltipTitle }: { progress: number; tooltipTitle?: string }) => {
  return (
    <Tooltip title={tooltipTitle} placement="top">
      <div className={classes.progressWrapper}>
        <div
          className={clsx(
            classes.progress,
            progress > WARNING_MIN && progress <= WARNING_MAX && classes.warning,
            progress <= WARNING_MIN && classes.danger
          )}
          style={{
            height: '8px',
            width: `${progress}%`,
          }}></div>
      </div>
    </Tooltip>
  );
};

export type Product = {
  title: string;
  description: string;
  Icon: React.FunctionComponent<{ fillColor?: string }>;
  linkTo: string;
};

const products: Product[] = [
  {
    title: 'application:dashboard.products.llc.title',
    description: 'application:dashboard.products.llc.description',
    Icon: LLCIcon,
    linkTo: LLC,
  },
  {
    title: 'application:dashboard.products.banking.title',
    description: 'application:dashboard.products.banking.description',
    Icon: BankingIcon,
    linkTo: BANKING,
  },
  {
    title: 'application:dashboard.products.buyerCreditCheck.title',
    description: 'application:dashboard.products.buyerCreditCheck.description',
    Icon: BuyerCheckIcon,
    linkTo: BUYER_CREDIT_CHECK,
  },
];

const pipelineBannerActionItemMap = (
  internalPipelineStatus: Nullable<InternalPipelineStatus>,
  navigate: NavigateFunction,
  application: Nullable<APIApplication>
): { icon: ReactNode; onClick: Nullable<Function> } => {
  switch (internalPipelineStatus) {
    case 'application_received':
      return {
        icon: <MiniNotepadIcon />,
        onClick: () => navigate(INVOICE_FINANCING),
      };
    case 'termsheet_sent':
      return {
        icon: <MiniNotepadIcon />,
        onClick: () => {
          logEvent(termSheetEvents.VIEW_PROPOSAL);
          application?.proposalLink && window.open(application?.proposalLink, '_blank');
        },
      };
    case 'termsheet_received_and_document_collection':
      return {
        icon: <MiniNotepadIcon />,
        onClick: () => navigate(MY_DOCUMENTS),
      };
    case 'due_dilligence':
    case 'credit_committee':
      return {
        icon: <MiniNotepadIcon />,
        onClick: () => navigate(INVOICE_FINANCING),
      };
    case 'credit_committee_advanced':
    case 'legal':
      return {
        icon: <MiniNotepadIcon />,
        onClick: () => {
          logEvent(creditLineEvents.VIEW_MY_CREDIT);
          navigate(INVOICE_FINANCING);
        },
      };
    case 'onboarding':
    case 'closed':
      return {
        icon: <MiniMoneyReceiveIcon />,
        onClick: null,
      };
    default:
      return {
        icon: null,
        onClick: null,
      };
  }
};

const stepData = [
  { key: 1, progress: 33 },
  { key: 2, progress: 66 },
  { key: 3, progress: 80 },
];

const Dashboard = () => {
  const { isLoading: isLoadingTranslations, t } = useLazyTranslation(['common', 'application', 'servicing']);
  const { language, locale } = useUserLocation();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);
  const { internalPipelineStatus, companies, uuid } =
    useAppSelector((state) => state.client.client) || ({} as APIClient);
  const newCompany = useAppSelector((state) => state.client.newCompany);
  const { showSnackbar } = useSnackbar();

  const [application, setApplication] = useState<Nullable<APIApplication>>(null);
  const [isLoadingApp, setIsLoadingApp] = useState(true);
  const [isLoadingCreditMetrics, setIsLoadingCreditMetrics] = useState(true);
  const [isLoadingInvoicesAggregates, setIsLoadingInvoicesAggregates] = useState(false);
  const [creditLineMetrics, setCreditLineMetrics] = useState<Nullable<CreditLineMetrics>>(null);
  const [shouldDisplayReadyToStartFinancingBanner, setShouldDisplayReadyToStartFinancingBanner] =
    useState(false);
  const [showReferralModal, setShowReferralModal] = useState(false);

  const now = new Date();

  const applicationInProgress = application?.status === APPLICATION_STARTED;
  const showApp = (applicationInProgress || !application) && !newCompany?.productStatus.readyForFactoring;
  const hasAppliedToInvFin = application || !newCompany?.productStatus.readyForFactoring;
  const camelizedInternalPipelineStatus = camelize(internalPipelineStatus || '');

  const storedCurrentStep = Number(localStorage.getItem(CURRENT_APP_STEP_STORAGE_KEY)) || 0;
  const { icon, onClick } = pipelineBannerActionItemMap(internalPipelineStatus, navigate, application);
  const [holiday, setHoliday] = useState<Nullable<Holiday>>(null);

  const isLoading =
    isLoadingTranslations || isLoadingApp || isLoadingCreditMetrics || isLoadingInvoicesAggregates;

  const availableCredit =
    typeof creditLineMetrics?.availableCredit === 'number'
      ? `${formatCreditLineCurrency(language, creditLineMetrics?.availableCredit, 'USD', 2)}`
      : 'N/A';
  const creditUtilized =
    typeof creditLineMetrics?.netFundsEmployed === 'number'
      ? `${formatCreditLineCurrency(language, creditLineMetrics?.netFundsEmployed, 'USD', 2)}`
      : 'N/A';
  const creditLimit =
    typeof creditLineMetrics?.creditLineSize === 'number'
      ? `${formatCreditLineCurrency(language, creditLineMetrics?.creditLineSize, 'USD', 2)}`
      : 'N/A';

  const reserveEscrow =
    typeof creditLineMetrics?.reserveEscrow === 'number'
      ? `${formatCreditLineCurrency(language, creditLineMetrics?.reserveEscrow, 'USD', 2)}`
      : 'N/A';

  const availableCreditWithFallback =
    creditLineMetrics?.availableCredit && creditLineMetrics?.availableCredit > 0
      ? creditLineMetrics?.availableCredit
      : 0;


  // Set the holidays
  useEffect(() => {
    companies?.length > 0 &&
      getHolidays(companies[0].id).then((holidays) => {
        if (holidays.length) {
          setHoliday(holidays[0])
        }

      })
  }, [companies]);

  useEffect(() => {
    if (companies?.length > 0)
      getApplications(companies[0].id)
        .then((applications) => {
          if (applications.length > 0) {
            setApplication(applications[0]);
          }
        })
        .finally(() => {
          setIsLoadingApp(false);
        });
  }, [companies]);

  useEffect(() => {
    if (companies?.length > 0) {
      getCreditLineMainMetrics(companies[0].id)
        .then((creditLineMetrics) => {
          if (creditLineMetrics) {
            setCreditLineMetrics(creditLineMetrics);
          }
        })
        .finally(() => {
          setIsLoadingCreditMetrics(false);
        });
    }
  }, [companies]);

  useEffect(() => {
    if (
      application &&
      typeof application.flowMetadata?.shouldDisplayReadyToStartFinancingBanner === 'undefined'
    ) {
      setShouldDisplayReadyToStartFinancingBanner(true);
    }
  }, [application]);

  // We need to call the invoicesAggregates endpoint as long as we need to display the ready to start financing banner
  // Once we don't need to show the banner anymore, we won't be hitting the BE for the inv aggregates in this page.
  useEffect(() => {
    if (
      newCompany &&
      application &&
      typeof application.flowMetadata?.shouldDisplayReadyToStartFinancingBanner === 'undefined'
    ) {
      getInvoicesAggregates(newCompany.id)
        .then((data) => {
          if (data.totalInvoicesCount > 0) {
            const payload = {
              flowMetadata: {
                ...application.flowMetadata,
                shouldDisplayReadyToStartFinancingBanner: false,
              },
            };
            updateProductStatus(newCompany.id, newCompany.productStatus.id, payload);
          }
        })
        .finally(() => {
          setIsLoadingInvoicesAggregates(false);
        });
    }
  }, [newCompany, application]);

  useEffect(() => {
    logEvent(homeEvents.VIEW_PAGE);
  }, []);

  const handleOnClickUnderstood = async () => {
    const payload = {
      flowMetadata: {
        ...application?.flowMetadata,
        shouldDisplayFinancingNotAvailableBanner: false,
      },
    };
    if (newCompany) {
      await updateProductStatus(newCompany.id, newCompany.productStatus.id, payload).then(() => {
        if (application) {
          const updatedApp = {
            ...application,
            ...payload,
          };
          setApplication(updatedApp);
        }
      });
    }
  };

  return (
    <Layout>
      <Grid container rowSpacing={2}>

        <Grid
          item
          xs={12}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'initial' },
            display: { xs: 'none', md: 'flex' },
          }}>
          <Typography variant="h5" fontWeight="700">
            {`${t('common:welcome')}, ${user?.firstName}`}
          </Typography>
          <Typography fontWeight={600}>{formatDate(now, locale)}</Typography>
        </Grid>

        <HolidayNotice holiday={holiday} />
        {isLoading ? (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Skeleton
                  sx={{ borderRadius: '10px', height: '240px' }}
                  variant="rectangular"
                  animation="wave"
                />
              </Grid>
              <Grid item xs={12}>
                <Skeleton
                  sx={{ borderRadius: '10px', height: '240px' }}
                  variant="rectangular"
                  animation="wave"
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              {showApp ? (
                <Box
                  sx={{
                    minHeight: '260px',
                    padding: '2em',
                    borderRadius: '8px',
                    background: 'linear-gradient(117.96deg, #0B3458 28.65%, #03216D 78.13%)',
                    position: 'relative',
                    overflow: 'hidden',
                  }}>
                  <Grid container rowSpacing={1}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        position: 'absolute',
                        right: '-130px',
                        bottom: '-70px',
                        transform: 'rotate(-42deg)',
                        display: { xs: 'none', md: 'initial' },
                      }}>
                      <MarcoIcon />
                    </Grid>
                    <Grid item xs={12}>
                      {applicationInProgress ? (
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant="body1" sx={{ color: COLORS.brand.white }}>
                              {`${t('application:step', {
                                step: stepData[storedCurrentStep].key,
                              })} ${t('application:stepOf', {
                                steps: 3,
                              })} | `}
                              <span style={{ fontWeight: 600 }}>
                                {t(
                                  `application:dashboard.${application?.flowType === 'satws' ? 'appStepsWithSAT' : 'appSteps'
                                  }.${storedCurrentStep}`
                                )}
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={7}>
                            <ProgressBar progress={stepData[storedCurrentStep].progress} />
                          </Grid>
                        </Grid>
                      ) : (
                        <Typography variant="h5" sx={{ color: '#B8C8DE' }}>
                          {t('application:title')}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5" sx={{ color: '#FFFFFF' }}>
                        {t('application:dashboard.subtitle')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} sx={{ paddingTop: '48px !important' }}>
                      <Link to={APPLICATION} style={{ textDecoration: 'none' }}>
                        <Button endIcon={<ArrowRightIcon />}>
                          {t(`application:${applicationInProgress ? 'continueAppTitle' : 'startAppTitle'}`)}
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                application?.status !== 'application_rejected' &&
                internalPipelineStatus &&
                application && (
                  <PipelineBanner
                    heading={t(`application:dashboard.banner.${camelizedInternalPipelineStatus}.title`)}
                    title={t(`application:dashboard.banner.${camelizedInternalPipelineStatus}.header`)}
                    description={t(
                      `application:dashboard.banner.${camelizedInternalPipelineStatus}.description`
                    )}
                    Icon={icon}
                    button={t(`application:dashboard.banner.${camelizedInternalPipelineStatus}.button`)}
                    handleOnClick={onClick}
                    isHideable={
                      camelizedInternalPipelineStatus === 'closed' &&
                      !shouldDisplayReadyToStartFinancingBanner
                    }
                  />
                )
              )}
            </Grid>
            {camelizedInternalPipelineStatus === 'closed' && application?.status !== 'application_rejected' && (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={6} sx={{ display: 'flex', flex: 1 }}>
                    <Box
                      sx={{
                        backgroundColor: '#fff',
                        borderRadius: '12px',
                        padding: '2em',
                        width: '100%',
                      }}>
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <Typography sx={{ color: '#66758F', fontSize: '20px' }}>
                            {t('servicing:availableCreditLine')}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sx={{ display: 'flex', justifyContent: { xs: 'initial', md: 'flex-end' } }}>
                          <Link
                            onClick={() => logEvent(servicingEvents.GO_TO_CREDIT_DETAILS)}
                            to={INVOICE_FINANCING}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              color: COLORS.gray[1200],
                            }}>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                lineHeight: '22px',
                                color: COLORS.gray[1200],
                              }}>
                              {t('common:goToCreditDetails')}
                            </Typography>
                            <ArrowRightIcon />
                          </Link>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography sx={{ color: COLORS.brand.night, fontSize: '24px', fontWeight: '600' }}>
                            {availableCredit}
                          </Typography>
                        </Grid>
                        {typeof creditLineMetrics?.creditLineSize === 'number' && (
                          <Grid item xs={12}>
                            <ProgressBar
                              progress={
                                (availableCreditWithFallback / creditLineMetrics?.creditLineSize) * 100
                              }
                              tooltipTitle={`${t('servicing:creditUtilized')}: ${creditUtilized}`}
                            />
                          </Grid>
                        )}
                        <Grid item xs={6}>
                          <Typography textAlign="start">{t('servicing:reserveEscrow')}</Typography>
                          <Typography textAlign="start" sx={{ color: COLORS.indigo[500], fontWeight: 600 }}>
                            {reserveEscrow}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography textAlign="end">{t('servicing:creditLimit')}</Typography>
                          <Typography textAlign="end" sx={{ color: COLORS.indigo[500], fontWeight: 600 }}>
                            {creditLimit}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  {hasAppliedToInvFin ? (
                    <Grid item xs={12} md={6} sx={{ display: 'flex', flex: 1 }}>
                      <Box
                        sx={{
                          borderRadius: '12px',
                          background:
                            'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(85.45deg, rgba(221, 233, 253, 0.8) 5.71%, rgba(202, 230, 246, 0.8) 96.34%)',
                          padding: '2em',
                          maxWidth: '100%',
                        }}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                color: COLORS.secondary.greenGradient,
                                fontSize: '20px',
                                fontWeight: 700,
                                lineHeight: '30px',
                              }}>
                              {t('application:referral.title')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Trans t={t} i18nKey={'application:referral.description'}>
                              Share this link to get <span style={{ fontWeight: 600 }}>$1,000 bonus</span> and
                              your referral gets an up to{' '}
                              <span style={{ fontWeight: 600 }}>$500 discount.</span>
                            </Trans>
                            <br />
                            <Button
                              type="link"
                              color="secondary"
                              isFullHeight={false}
                              onClick={() => {
                                setShowReferralModal(true);
                                logEvent(homeEvents.CLICK_VIEW_REFERRAL_TERMS);
                              }}>
                              {t('application:referral.termsAndConditionsBtn')}
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                padding: '0.5em 1em',
                                background: COLORS.brand.white,
                                border: '1px solid #B8C8DE',
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}>
                              <Typography
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}>{`https://${process.env.REACT_APP_FRONTEND_URL}signup?referral_client=${uuid}`}</Typography>
                              <CopyToClipboardButton
                                textToCopy={`https://${process.env.REACT_APP_FRONTEND_URL}signup?referral_client=${uuid}`}
                                customSX={{ paddingY: 0 }}
                                onClick={() => {
                                  showSnackbar(t('application:referral.copiedMessage'), 'success');
                                  logEvent(homeEvents.CLICK_COPY_REFERRAL, { Uuid: uuid });
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6} sx={{ display: 'flex', flex: 1 }}>
                      <Box
                        sx={{
                          borderRadius: '12px',
                          background: 'var(--t-5, linear-gradient(270deg, #12938B 0%, #1087A1 100%))',
                          padding: '2em',
                        }}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                color: COLORS.brand.white,
                                fontSize: '20px',
                                fontWeight: 700,
                                lineHeight: '150%',
                              }}>
                              {t('application:discoverMarco.title')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              sx={{ color: COLORS.brand.white, fontSize: '16px', lineHeight: '150%' }}>
                              {t('application:discoverMarco.description')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <a
                              href="https://www.holamarco.co"
                              target="_blank"
                              rel="noreferrer"
                              style={{ textDecoration: 'none' }}>
                              <Button endIcon={<ArrowRightIcon />} color="white">
                                {t('application:discoverMarco.btnTitle')}
                              </Button>
                            </a>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {application?.status === 'application_rejected' &&
              application?.isReapply &&
              typeof application.flowMetadata?.shouldDisplayFinancingNotAvailableBanner === 'undefined' && (
                <Grid item xs={12}>
                  <PipelineBanner
                    heading={t(`application:dashboard.banner.rejected.title`)}
                    title={t(`application:dashboard.banner.rejected.header`)}
                    description={t(`application:dashboard.banner.rejected.description`)}
                    Icon={<MiniNotepadIcon />}
                    button={t(`application:dashboard.banner.rejected.button`)}
                    handleOnClick={handleOnClickUnderstood}
                    customSx={{ background: '#132B40' }}
                  />
                </Grid>
              )}
          </>
        )}
        <Grid item xs={12}>
          <Box>
            <Grid container>
              {products.map(({ title, description, Icon, linkTo }) => (
                <Grid item xs={12} md={4} key={title}>
                  {isLoadingTranslations ? (
                    <Skeleton variant="rectangular" height={220} animation="wave" />
                  ) : (
                    <Box
                      sx={{
                        padding: '2em',
                        backgroundColor: '#FBFCFE',
                        borderRadius: '12px',
                        height: '100%',
                        display: 'flex',
                      }}>
                      <Grid container>
                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Icon />
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                            flex: '1 1 0',
                            justifyContent: 'space-between',
                          }}>
                          <Typography variant="h6" fontWeight="700">
                            {t(title)}
                          </Typography>
                          <Typography variant="body1">{t(description)}</Typography>
                          <Link to={linkTo} style={{ color: COLORS.indigo[500] }}>
                            <Typography
                              sx={{
                                fontSize: '16px',
                                fontWeight: 600,
                                lineHeight: '24px',
                                textAlign: 'left',
                                color: COLORS.indigo[500],
                              }}>
                              {t('application:dashboard.products.buttonTitle')}
                            </Typography>
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {showReferralModal && (
        <ModalV2
          isAutoHeight
          size="large"
          handleClose={() => setShowReferralModal(false)}
          isOpen={showReferralModal}
          displayCloseButton={false}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight={600}>
                {t('application:referral.modal.title')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '24px' }}>
                {t('application:referral.modal.subtitle')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ borderColor: '#DDE6F6' }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight={700}>
                {t('application:referral.modal.howDoesItWork')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container rowSpacing={2}>
                {referralSteps.map(({ step, title, description }) => {
                  return (
                    <Grid item xs={12} key={step}>
                      <Grid container alignItems="center" rowSpacing={1}>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: { xs: '18px' },
                          }}>
                          <Chip label={step} sx={{ backgroundColor: '#18A19A', color: COLORS.brand.white }} />
                          <Typography
                            sx={{
                              fontSize: '16px',
                              color: COLORS.brand.night,
                              fontWeight: 600,
                              lineHeight: '24px',
                            }}>
                            {t(`application:${title}`)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ marginLeft: { xs: '50px' } }}>
                          <Typography
                            sx={{
                              color: COLORS.gray[1200],
                              fontSize: '14px',
                              fontWeight: 400,
                              lineHeight: '21px',
                            }}>
                            {t(`application:${description}`)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ fontWeight: 400, fontSize: '10px', lineHeight: '16px', color: COLORS.gray[1100] }}>
                {t('application:referral.modal.disclaimer')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                color="secondary"
                onClick={() => {
                  setShowReferralModal(false);
                }}>
                {t('application:referral.modal.btn')}
              </Button>
            </Grid>
          </Grid>
        </ModalV2>
      )}
    </Layout>
  );
};

export default Dashboard;
