import { useEffect } from 'react';
import { logEvent } from '@amplitude/analytics-browser';
import { Trans } from 'react-i18next';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import FormSection from '../../componentsv2/FormSection';
import { SelectField, TextField } from '../../componentsv2/Field';
import MultiSelectField from '../../componentsv2/Field/MultiSelectField';
import Button from '../../componentsv2/Button';
import Form from '../../componentsv2/Form';
import Typography from '../../componentsv2/Typography';
import { useTranslation } from '../../hooks/i18n';
import { CompanyStepSchema } from '../../schemas';
import { EmptyString, Nullable, SignupCountryKeys } from '../../types';
import { ApplicationStepProps } from '../../types/application';
import { mapCountryFromBackend } from '../../utils/helpers';
import { backendCountryValues, getCountries, getUSStates } from '../../constants/data';
import { esgOptions } from '../../constants/application';
import { invoiceEvents } from '../../constants/events';
import { zipCodesMap } from '../../utils/common';

export type CompanyState = {
  companyName?: string;
  companyTaxId?: string;
  addressCountry?: EmptyString<SignupCountryKeys>;
  stateOfIncorporation?: Nullable<string>;
  yearCompanyWasFounded?: Nullable<number>;
  esg?: string[];
  addressStreetOne?: string;
  addressCity?: string;
  addressZipCode?: string;
  addressState?: string;
  companyWebsite?: string;
  companyContactEmail?: string;
  companyContactName?: string;
  companyContactPhone?: string;
};

const CompanyStep = ({ onStepSubmit, applicationDraft }: ApplicationStepProps) => {
  const { t } = useTranslation('application');

  useEffect(() => {
    logEvent(invoiceEvents.STEP_ONE);
  }, []);

  const mappedCountry = applicationDraft?.addressCountry
    ? mapCountryFromBackend(applicationDraft.addressCountry)
    : '';

  const initialValues: CompanyState = {
    companyName: applicationDraft?.companyName ?? '',
    companyTaxId: applicationDraft?.companyTaxId ?? '',
    addressCountry: mappedCountry,
    stateOfIncorporation: applicationDraft?.stateOfIncorporation ?? null,
    yearCompanyWasFounded: applicationDraft.yearCompanyWasFounded ?? null,
    esg: applicationDraft?.esg ?? [],
    addressStreetOne: applicationDraft?.addressStreetOne ?? '',
    addressCity: applicationDraft?.addressCity ?? '',
    addressZipCode: applicationDraft?.addressZipCode ?? '',
    addressState: applicationDraft?.addressState ?? '',
    companyWebsite: applicationDraft?.companyWebsite ?? '',
    companyContactEmail: applicationDraft?.companyContactEmail ?? '',
    companyContactName: applicationDraft?.companyContactName ?? '',
    companyContactPhone: applicationDraft?.companyContactPhone ?? '',
  };

  const handleOnSubmit = (values: CompanyState) => {
    const mapValuesForBackend = {
      ...values,
      addressCountry: values['addressCountry'] ? backendCountryValues[values['addressCountry']] : '',
    };
    return onStepSubmit(mapValuesForBackend);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={CompanyStepSchema(t)}
        onSubmit={handleOnSubmit}
        validate={(values) => {
          const errors: Partial<typeof values> = {};
          const zipCodeRegex = zipCodesMap[values.addressCountry as SignupCountryKeys];
          if (zipCodeRegex && values.addressZipCode && !zipCodeRegex.test(values.addressZipCode)) {
            errors['addressZipCode'] = t('sections.generalInformation.fields.addressZipCodeError');
          }
          return errors;
        }}>
        {({ values }) => {
          return (
            <Form name="companyData" id="companyData" trackingEvent={invoiceEvents.EDIT_FIELD}>
              <FormSection title={t('sections.generalInformation.title')}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled
                      name="companyName"
                      placeholder={t('sections.generalInformation.fields.companyLegalNamePlaceholder')}
                      label={t('sections.generalInformation.fields.companyLegalNameLabel')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="companyTaxId"
                      placeholder={t('sections.generalInformation.fields.rfcPlaceholder')}
                      label={t('sections.generalInformation.fields.rfcLabel')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectField
                      disabled
                      name="addressCountry"
                      label={t('sections.generalInformation.fields.countryLabel')}
                      options={getCountries(t).map(({ countryKey, country }) => ({
                        value: countryKey,
                        label: country,
                      }))}
                    />
                  </Grid>
                  {mappedCountry === 'us' && (
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name="stateOfIncorporation"
                        label={t('sections.generalInformation.fields.stateOfIncorporationLabel')}
                        placeholder={t('sections.generalInformation.fields.stateOfIncorporationPlaceholder')}
                        options={getUSStates.map(({ stateKey, state }) => ({
                          value: stateKey,
                          label: state,
                        }))}
                        value={values.stateOfIncorporation ?? ''}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="addressStreetOne"
                      placeholder={t('sections.generalInformation.fields.businessStreetAddressPlaceholder')}
                      label={t('sections.generalInformation.fields.businessStreetAddressLabel')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="addressCity"
                      placeholder={t('sections.generalInformation.fields.cityPlaceholder')}
                      label={t('sections.generalInformation.fields.cityLabel')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="addressState"
                      placeholder={t('sections.generalInformation.fields.statePlaceholder')}
                      label={t('sections.generalInformation.fields.stateLabel')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="addressZipCode"
                      placeholder={t('sections.generalInformation.fields.addressZipCodePlaceholder')}
                      label={t('sections.generalInformation.fields.addressZipCodeLabel')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      optional
                      name="companyWebsite"
                      placeholder={t('sections.generalInformation.fields.websitePlaceholder')}
                      label={t('sections.generalInformation.fields.websiteLabel')}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: '3px' }}>
                    <TextField
                      name="yearCompanyWasFounded"
                      type="number"
                      placeholder={t('sections.generalInformation.fields.yearCompanyWasFoundedPlaceholder')}
                      label={t('sections.generalInformation.fields.yearCompanyWasFoundedLabel')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultiSelectField
                      name="esg"
                      label={t('sections.generalInformation.fields.esgLabel')}
                      placeholder={t('sections.generalInformation.fields.esgPlaceholder')}
                      options={esgOptions.map((value: string) => ({
                        value,
                        label: t(`application:sections.generalInformation.fields.esgOptions.${value}`),
                      }))}
                      moreInfoTooltip={
                        <Trans t={t} i18nKey={'sections.generalInformation.fields.esgTooltip'}>
                          <Typography sx={{ color: '#FFF' }}>
                            ESG criteria are a set of rules to follow when making business decisions. They
                            refer to activities that impact the environment, the company's relationship with
                            society, and the way in which the company is managed together with compliance with
                            regulations.
                          </Typography>
                          <Typography sx={{ color: '#FFF' }}>
                            Compliance with these criteria has no impact on the approval of the financing
                            line.
                          </Typography>
                        </Trans>
                      }
                    />
                  </Grid>
                </Grid>
              </FormSection>
              <FormSection title={t('sections.contactInformation.title')}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="companyContactName"
                      placeholder={t('sections.contactInformation.fields.fullNamePlaceholder')}
                      label={t('sections.contactInformation.fields.fullNameLabel')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled
                      name="companyContactEmail"
                      placeholder={t('sections.contactInformation.fields.emailPlaceholder')}
                      label={t('sections.contactInformation.fields.emailLabel')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="companyContactPhone"
                      placeholder={t('sections.contactInformation.fields.phoneNumberPlaceholder')}
                      label={t('sections.contactInformation.fields.phoneNumberLabel')}
                    />
                  </Grid>
                </Grid>
              </FormSection>
              <Grid container justifyContent="right" sx={{ marginTop: '2em' }}>
                <Grid item xs={12} md={12} sx={{ display: 'flex', gap: '1em' }}>
                  <Button disabled>{t('steps.company.returnBtn')}</Button>
                  <Button isSubmit>{t('steps.company.submitBtn')}</Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CompanyStep;
