export default function ExcelIcon() {
  return (
    <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.3992 20.2704L3.39937 20.2705C3.43252 20.2994 3.49086 20.3256 3.56445 20.3263H18.795C18.831 20.326 18.8648 20.3194 18.8939 20.3086L18.8941 20.3085C18.924 20.2974 18.9466 20.2829 18.9622 20.2689C18.9778 20.2548 18.9853 20.2425 18.9888 20.2347L18.9889 20.2345C18.9922 20.2271 18.9926 20.222 18.9925 20.2187L18.9922 20.2097V20.2008V3.31429C18.9922 1.91532 17.8581 0.78125 16.4592 0.78125H15.9922H3.56282C3.48965 0.78125 3.43139 0.807068 3.39838 0.835664L3.3992 20.2704ZM3.3992 20.2704C3.38341 20.2566 3.37541 20.2442 3.37155 20.2361C3.3678 20.2282 3.36719 20.2229 3.36719 20.2197V0.88406C3.36719 0.881301 3.36769 0.876467 3.37125 0.869004M3.3992 20.2704L3.37125 0.869004M3.37125 0.869004C3.37493 0.861312 3.38272 0.849236 3.39837 0.835676L3.37125 0.869004Z"
        stroke="#18A19A"
      />
      <path
        d="M12.8075 5.2079L11.1889 6.89377M11.1889 6.89377L9.57031 5.2079M11.1889 6.89377V2.84766"
        stroke="#18A19A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.105469" y="8.51562" width="22.1505" height="9.46496" rx="1" fill="#18A19A" />
      <path
        d="M7.72088 15.8639L6.59387 14.2093L5.62975 15.8639H4.15758L5.85786 13.1213L4.10547 10.5156H5.62975L6.72415 12.1248L7.66876 10.5156H9.13451L7.48636 13.2192L9.24529 15.8639H7.72088Z"
        fill="#FDFDFD"
      />
      <path d="M11.5358 14.8606H13.2491V15.8442H10.2461V10.5156H11.549L11.5358 14.8606Z" fill="#FDFDFD" />
      <path
        d="M18.0394 15.2033C17.8809 15.4505 17.6548 15.6472 17.388 15.77C17.0695 15.9159 16.722 15.9872 16.3718 15.9785C15.8462 16.0008 15.328 15.8471 14.8995 15.542C14.7049 15.4024 14.5449 15.22 14.4319 15.0087C14.3191 14.7975 14.2561 14.5632 14.248 14.3238H15.6161C15.6261 14.5036 15.7028 14.6731 15.8311 14.7993C15.8943 14.857 15.9683 14.9016 16.0487 14.9307C16.1292 14.9597 16.2147 14.9726 16.3002 14.9688C16.3712 14.9734 16.4424 14.9637 16.5095 14.9403C16.5767 14.9168 16.6385 14.8799 16.691 14.8319C16.7393 14.7861 16.7771 14.7302 16.8018 14.6685C16.8265 14.6067 16.8375 14.5401 16.8343 14.4737C16.8372 14.4112 16.8273 14.3489 16.8048 14.2905C16.7824 14.2322 16.748 14.1792 16.704 14.1349C16.6114 14.04 16.5006 13.9648 16.3783 13.9134C16.248 13.8547 16.0656 13.7831 15.8311 13.7049C15.5747 13.6074 15.3246 13.4942 15.0819 13.3662C14.86 13.2542 14.6697 13.0885 14.5283 12.8842C14.3672 12.6393 14.2874 12.3497 14.3002 12.0568C14.2944 11.7649 14.3785 11.4783 14.5412 11.2361C14.704 11.0003 14.9297 10.8153 15.1927 10.7018C15.5 10.5742 15.8305 10.5121 16.1633 10.5194C16.6697 10.4893 17.1705 10.6394 17.577 10.9429C17.7516 11.0836 17.8949 11.2597 17.997 11.4594C18.0991 11.6592 18.158 11.8784 18.1697 12.1025H16.7626C16.7443 11.9454 16.6784 11.7975 16.5737 11.679C16.4574 11.5761 16.3055 11.5224 16.1502 11.5292C16.0186 11.523 15.8889 11.5647 15.7854 11.6464C15.7384 11.6929 15.7017 11.7489 15.6782 11.8108C15.6545 11.8726 15.6446 11.9387 15.6487 12.0047C15.6462 12.1233 15.6907 12.238 15.7725 12.3239C15.8636 12.4131 15.9693 12.4858 16.0852 12.5389L16.6258 12.7474C16.9129 12.8389 17.1918 12.9545 17.4596 13.0927C17.6836 13.2072 17.8759 13.375 18.0199 13.5812C18.1852 13.8318 18.2672 14.1281 18.2544 14.428C18.2564 14.7015 18.1819 14.97 18.0394 15.2033Z"
        fill="#FDFDFD"
      />
    </svg>
  );
}
