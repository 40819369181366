import axios from './axios';
import { APIBuyerCreditCheck } from '../types/buyerCreditCheck';
import { APIInsuranceRequest } from '../types/insurance';
import { APILLC, LLCDocument } from '../types/llc';
import { APIFXRequest } from '../types/fxRequest';
import { ApplicationFlowType } from '../types/application';
import { APICompany } from '../types/client';
import { Log, PaginatedResponse } from './types';
import { Company, CompanyAdminResult, CompanyUserInvitation, ProductStatus } from '../types/company';
import { Nullable, UserGroups } from '../types';
import { CreditCommitteeFlow, CreditLineDetails, DebtorsSheetPreview } from '../types/creditCommitteeFlow';
import { ConnectedAccount, EnabledAccount } from '../types/banking';

export function adminGetBuyerCreditCheckByClientId(id: number): Promise<APIBuyerCreditCheck[]> {
  return axios
    .get(`/admins/companies/${id}/products/buyer_credit_check/requests/`)
    .then(({ data }) => data || []);
}

export function adminGetInsuranceRequestByClientId(id: number): Promise<APIInsuranceRequest[]> {
  return axios.get(`/admins/companies/${id}/products/insurances/requests/`).then(({ data }) => data || []);
}

export function adminGetFXRequestByClientId(id: number): Promise<APIFXRequest[]> {
  return axios.get(`/admins/companies/${id}/products/fx/requests/`).then(({ data }) => data || []);
}

export function changeApplicationFlow(id: number, flowType: ApplicationFlowType, companyId: number) {
  return axios
    .post(`/admins/companies/${companyId}/products/factoring/request/${id}/set_flow_type/`, {
      flowType,
    })
    .then((data) => data);
}

export function logInAsClient(id: number) {
  return axios.get(`/admins/company_users/${id}/impersonation_token/`).then(({ data }) => data);
}

export function adminGetLLC(companyId: number) {
  return axios
    .get<APILLC[]>(`/admins/companies/${companyId}/products/llc/company_llc/`)
    .then(({ data }) => data);
}

export function adminUploadLLCAsset(
  llcId: number,
  documentId: number,
  { files }: { files: File[] },
  companyId: number
) {
  const payload = new FormData();
  files.forEach((file) => {
    payload.append('asset', file, file.name);
    payload.append('file_name', file.name);
    payload.append('asset_type', 'document');
  });

  return axios
    .post<LLCDocument>(
      `/admins/companies/${companyId}/products/llc/company_llc/${llcId}/documents/${documentId}/assets/`,
      payload,
      {
        headers: {
          'Content-Type': `multipart/form-data; boundary=BoUnDaRyStRiNg`,
        },
      }
    )
    .then(({ data }) => data);
}

export function adminDeleteLLCDocumentFile(assetId: number) {
  return axios.delete<any>(`/assets/${assetId}/`).then(({ data }) => data);
}

export function adminAddNewDocument(companyId: number, llcId: number, values: any, files: File[]) {
  const payload = new FormData();
  files.forEach((file) => {
    payload.append('asset', file, file.name);
    payload.append('file_name', file.name);
    payload.append('asset_type', 'document');
  });

  payload.append('title_en', values.titleEn);
  payload.append('title_es', values.titleEs);
  payload.append('description_en', values.descriptionEn);
  payload.append('description_es', values.descriptionEs);
  payload.append('notify_user', values.notifyUser);

  return axios
    .post<LLCDocument>(
      `/admins/companies/${companyId}/products/llc/company_llc/${llcId}/generic_documents/`,
      payload,
      {
        headers: {
          'Content-Type': `multipart/form-data; boundary=BoUnDaRyStRiNg`,
        },
      }
    )
    .then(({ data }) => data);
}

export function adminDeleteGenericDocument(companyId: number, llcId: number, documentId: number) {
  return axios
    .delete<any>(
      `/admins/companies/${companyId}/products/llc/company_llc/${llcId}/generic_documents/${documentId}/`
    )
    .then(({ data }) => data);
}

export function adminSetCompanyUUID(companyId: number, payload: { uuid: string }) {
  return axios.post<APICompany>(`/admins/companies/${companyId}/set_uuid/`, payload).then(({ data }) => data);
}

export type QaAdminResult = {
  email: string;
  userId: number;
  companyId: number;
  applicationId: number;
  companyUuid: number;
};

export function adminCreateQaUser(payload: {
  country: string;
  password: string;
  invoiceFinancingStep: string;
}) {
  return axios
    .post<QaAdminResult>(`/admins/company_users/create_qa_account/`, {
      ...payload,
      factoringStep: payload.invoiceFinancingStep,
    })
    .then(({ data }) => data);
}

export function adminCheckCompanyExists(companyId: number) {
  return axios
    .get<{
      clientCompanyUuidExists: boolean;
      emailIdExists: boolean;
    }>(`/admins/companies/${companyId}/products/factoring/factoring_data/company_exists_in_lms/`)
    .then(({ data }) => data);
}

export function getCompanies(company: string) {
  return axios
    .get<PaginatedResponse<CompanyAdminResult>>(`/admins/companies/list/?company=${company}`)
    .then(({ data }) => data);
}

export function connectInvoiceWithShipment(payload: any) {
  return axios
    .post(`/admins/products/tracking/invoice_container_tracking_connection/`, payload)
    .then(({ data }) => data);
}

export function adminGetUserGroups() {
  return axios.get<UserGroups>(`/admins/credit_committee/groups/`).then(({ data }) => data);
}

export function adminGetPostCCFlows({
  status,
  page,
  pageSize,
}: {
  status: 'in_progress' | 'complete';
  page: number;
  pageSize: number;
}) {
  return axios
    .get<PaginatedResponse<CreditCommitteeFlow>>(
      `/admins/credit_committee/?status=${status}&page=${page}&page_size=${pageSize}`
    )
    .then(({ data }) => data);
}

export function adminGetOpportunitiesByName(companyName: string) {
  return axios
    .get<{ opportunityId: string; companyName: string; companyUuid: string }[]>(
      `/admins/credit_committee/opportunities?company_name=${companyName}`
    )
    .then(({ data }) => data);
}

export function adminGetPostCCFlowPreview(opportunityId: string, spreadsheetLink: string) {
  const formattedSpreadsheetLink = spreadsheetLink.split('#')[0];
  return axios
    .get<CreditCommitteeFlow>(
      `/admins/credit_committee/previsualize/structuring_spreadsheet/?spreadsheet_link=${formattedSpreadsheetLink}&opportunity_id=${opportunityId}`
    )
    .then(({ data }) => data);
}

export function adminCreateCreditCommitteeFlow(payload: CreditCommitteeFlow) {
  return axios.post<CreditCommitteeFlow>(`/admins/credit_committee/`, payload).then(({ data }) => data);
}

export function adminGetCcFlowDetail(id: string) {
  return axios.get<CreditCommitteeFlow>(`/admins/credit_committee/${id}/`).then(({ data }) => data);
}

export function adminGetCreditLineDetails(opportunityId: string) {
  return axios
    .get<CreditLineDetails>(`/admins/credit_committee/opportunity/${opportunityId}/credit_line_details`)
    .then(({ data }) => data);
}

export function adminGetDebtorsSheetPreview(spreadsheetLink: string) {
  return axios
    .get<DebtorsSheetPreview>(
      `/admins/credit_committee/previsualize/debtors_spreadsheet/?spreadsheet_link=${spreadsheetLink}`
    )
    .then(({ data }) => data);
}

export function adminCreateCCDebtors(
  ccFlowId: string,
  payload: {
    debtors: DebtorsSheetPreview['debtors'];
    debtorsSpreadsheetUrl: string;
    creditLineDetails: Partial<CreditLineDetails>;
  }
) {
  // remove null values from creditLineDetails
  const creditLineDetails = Object.entries(payload.creditLineDetails).reduce((acc, [key, value]) => {
    if (value !== null) {
      // @ts-ignore
      acc[key] = value;
    }
    return acc;
  }, {} as Partial<CreditLineDetails>);
  return axios
    .post(`/admins/credit_committee/${ccFlowId}/debtors`, {
      ...payload,
      creditLineDetails: {
        ...creditLineDetails,
        discount_fee_tier_1: creditLineDetails.discountFeeTier1,
        discount_fee_tier_2: creditLineDetails.discountFeeTier2,
      },
    })
    .then(({ data }) => data);
}

export function adminApproveMinute(ccFlowId: string) {
  return axios.post(`/admins/credit_committee/${ccFlowId}/approve_minute`).then(({ data }) => data);
}

export function adminRejectMinute(ccFlowId: string) {
  return axios.post(`/admins/credit_committee/${ccFlowId}/reject_minute`).then(({ data }) => data);
}

export function adminGetUserInvitations(companyId: number) {
  return axios
    .get<CompanyUserInvitation[]>(`/admins/companies/${companyId}/user_invitations/list/`)
    .then(({ data }) => data);
}

export function adminEditCompanyUserRole(companyId: number, companyUserRoleId: number, values: any) {
  if (values.role === 'owner') {
    return axios
      .post(
        `/admins/companies/${companyId}/company_user_roles/${companyUserRoleId}/assign_company_owner_role/`
      )
      .then(({ data }) => data);
  }
  return axios
    .patch(`/admins/companies/${companyId}/company_user_roles/${companyUserRoleId}/`, values)
    .then(({ data }) => data);
}

export function adminInviteUser(companyId: number, values: any) {
  return axios.post(`/admins/companies/${companyId}/user_invitations/`, values).then(({ data }) => data);
}

export function adminDeleteCompanyUser(companyId: number, companyUserRoleId: number) {
  return axios
    .delete(`/admins/companies/${companyId}/company_user_roles/${companyUserRoleId}/`)
    .then(({ data }) => data);
}

export function adminDeleteInvitation(companyId: number, invitationId: number) {
  return axios
    .delete(`/admins/companies/${companyId}/user_invitations/${invitationId}/`)
    .then(({ data }) => data);
}

export function adminResendInvitation(companyId: number, invitationId: number): Promise<any> {
  return axios
    .post(`/admins/companies/${companyId}/user_invitations/${invitationId}/resend/`)
    .then(({ data }) => data);
}

export function adminGetLinkedAccount(companyId: number) {
  return axios
    .get<{ linkedAccount: Nullable<ConnectedAccount>; enabledAccounts: EnabledAccount[] }>(
      `/admins/companies/${companyId}/products/banking/linked_account/`
    )
    .then(({ data }) => data);
}

export function adminLinkAccount(companyId: number, payload: { companyName: string; accountId: string }) {
  return axios
    .post(`/admins/companies/${companyId}/products/banking/link_account/`, payload)
    .then(({ data }) => data);
}

export function adminEnableAccountCreation(companyId: number, productStatusId: number) {
  return axios
    .post<ProductStatus>(
      `admins/companies/${companyId}/products/product_status/${productStatusId}/toggle_ready_for_banking_account_creation/`
    )
    .then(({ data }) => data);
}

export function adminEnableBanking(companyId: number, productStatusId: number) {
  return axios
    .post<ProductStatus>(
      `admins/companies/${companyId}/products/product_status/${productStatusId}/toggle_company_ready_for_banking/`
    )
    .then(({ data }) => data);
}

export function adminEnableGlobalAccount(companyId: number, globalAccountId: string, currency: string) {
  return axios
    .post(`/admins/companies/${companyId}/products/banking/enabled_accounts/`, { globalAccountId, currency })
    .then(({ data }) => data);
}

export function adminDisableGlobalAccount(companyId: number, enabledAccountId: number) {
  return axios
    .delete(`/admins/companies/${companyId}/products/banking/enabled_accounts/${enabledAccountId}/`)
    .then(({ data }) => data);
}

export function adminUnlinkAccount(companyId: number) {
  return axios
    .delete(`/admins/companies/${companyId}/products/banking/link_account/`)
    .then(({ data }) => data);
}

export function adminGetAuditLogs(filters?: any): Promise<PaginatedResponse<Log>> {
  return axios
    .get<PaginatedResponse<Log>>(`/admins/audit_logs/`, { params: filters || {} })
    .then(({ data }) => data);
}

export function updateLLCInformation(companyId: number, companyLLC: number, values: any) {
  return axios
    .patch(`/admins/companies/${companyId}/products/llc/company_llc/${companyLLC}/`, values)
    .then(({ data }) => data);
}

export function notifyCompanyLLCDocument(companyId: number, companyLLC: number, documentId: number) {
  return axios
    .post(
      `/admins/companies/${companyId}/products/llc/company_llc/${companyLLC}/documents/${documentId}/notify/`
    )
    .then(({ data }) => data);
}

export function notifyCompanyLLCGenericDocument(companyId: number, companyLLC: number, documentId: number) {
  return axios
    .post(
      `/admins/companies/${companyId}/products/llc/company_llc/${companyLLC}/generic_documents/${documentId}/notify/`
    )
    .then(({ data }) => data);
}

export function updateCompanyLLCDocument(
  companyId: number,
  companyLLC: number,
  documentId: number,
  payload: { signLink?: string; status: string }
) {
  return axios
    .patch(
      `/admins/companies/${companyId}/products/llc/company_llc/${companyLLC}/documents/${documentId}/`,
      payload
    )
    .then(({ data }) => data);
}

export function updateCompanyInformation(companyId: number, values: any): Promise<Company> {
  return axios.patch(`/admins/companies/${companyId}/update_company/`, values).then(({ data }) => data);
}
