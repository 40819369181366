export function getSidebarEvent(trackingName: string) {
  return `Menu - Click ${trackingName}`;
}

export const signupEvents = {
  VIEW_PAGE: 'Signup - View page',
  FORM_SUBMITTED: 'Signup - Form submitted',
  VIEW_SET_PASSWORD: 'Signup - View Set password',
  PASSWORD_SET: 'Signup - Password set',
  PASSWORD_RESET: 'Signup - Password reset',
  EDIT_FIELD: 'Signup - Edit Field',
  HOVER_COUNTRY: 'Signup - Hover Country Tooltip',
  VIEW_PREV_PRODUCT: 'Signup - View Previous Product',
  VIEW_NEXT_PRODUCT: 'Signup - View Next Product',
  VIEW_INVITATION_SIGNUP: 'Signup - View Invitation Signup',
  SUBMIT_INVITATION_SIGNUP: 'Signup - Submit Invitation Signup',
};

export const userInviteEvents = {
  VIEW_PAGE: 'User Invites - View page',
  EDIT_FIELD: 'User Invites - Edit Field',
  FORM_SUBMITTED: 'User Invites - Form submitted',
  DELETE_INVITE: 'User Invites - Delete Invite',
  RESEND_INVITE: 'User Invites - Resend Invite',
  EDIT_INVITE: 'User Invites - Edit Invite',
  DELETE_USER: 'User Invites - Delete User',
};

export const signinEvents = {
  VIEW_PAGE: 'Signin - View page',
  SIGNIN_ATTEMPT: 'Signin - Attempt',
  SIGIN_ERROR: 'Signin - Error',
  SIGNEDIN: 'Signin - Success',
};

export const homeEvents = {
  VIEW_PAGE: 'Home - View Page',
  APPLY_FOR_INVOICING: 'Home - Click Apply Invoice Financing',
  VIEW_INVOICE_FINANCING: 'Home - Click View Invoice Financing',
  CONTINUE_INVOICE_FINANCING: 'Home - Click Continue Invoice Financing',
  CONTINUE_INVOICE_FINANCING_NOTIFICATION: 'Home - Click Continue Application Notification',
  CONTINUE_INVOICE_FINANCING_NOTIFICATION_CLOSE: 'Home - Click Continue Application Notification - Close',
  CLICK_BUYER_CHECK: 'Home - Click Buyer Check',
  CLICK_LLC: 'Home - Click LLC',
  CLICK_FX: 'Home - Click FX',
  CLICK_INSURANCES: 'Home - Click Insurances',
  CLICK_VIEW_REFERRAL_TERMS: 'Home - Click View Referral Terms and Conditions',
  CLICK_COPY_REFERRAL: 'Home - Click Copy Referral',
};

export const invoiceEvents = {
  START: 'Invoice Financing - Application - Start',
  INTRO: 'Invoice Financing - Application - View Intro',
  STEP_ONE: 'Invoice Financing - Application - View Step 1',
  STEP_TWO: 'Invoice Financing - Application - View Step 2',
  STEP_THREE: 'Invoice Financing - Application - View Step 3',
  SUBMITTED: 'Invoice Financing - Application - Submitted',
  DOWNLOAD_TEMPLATE: 'Invoice Financing - Application - Download Template',
  UPLOAD_FILE: 'Invoice Financing - Application - Upload File',
  CHECKS_TERMS_AND_CONDITIONS: 'Invoice Financing - Application - Checks Terms and Conditions',
  EDIT_FIELD: 'Invoice Financing - Application - Edit Field',
  VIEW_PAGE: 'Invoice Financing - View page',
  ADD_CUSTOMER: 'Invoice Financing - Application - Click Add Customer',
  ADDED_CUSTOMER: 'Invoice Financing - Application - Customer Added',
  EDIT_CUSTOMER: 'Invoice Financing - Application - Click Edit Customer',
  EDITED_CUSTOMER: 'Invoice Financing - Application - Customer Edited',
  DELETE_CUSTOMER: 'Invoice Financing - Application - Click Delete Customer',
  DELETED_CUSTOMER: 'Invoice Financing - Application - Customer Deleted',
  CES_VIEW_SCORE: 'Invoice Financing - Application - CES View score',
  CES_EDIT_SCORE: 'Invoice Financing - Application - CES Edit score',
  CES_EXIT_SCORE: 'Invoice Financing - Application - CES Exit score',
  CES_SUBMIT_SCORE: 'Invoice Financing - Application - CES Submit score',
  CES_VIEW_FEEDBACK: 'Invoice Financing - Application - CES View feedback',
  CES_SUBMIT_FEEDBACK: 'Invoice Financing - Application - CES Submit feedback',
  CES_EXIT_FEEDBACK: 'Invoice Financing - Application - CES Exit feedback',
  CES_FINISHED_FEEDBACK: 'Invoice Financing - Application - CES View feedback submitted',
  START_FINANCING: 'Invoice Financing - Click Start Financing',
};

export const productCESEvents = {
  invoice_funding_request: {
    CES_VIEW_SCORE: 'Invoice Funding - CES View score',
    CES_EDIT_SCORE: 'Invoice Funding - CES Edit score',
    CES_EXIT_SCORE: 'Invoice Funding - CES Exit score',
    CES_SUBMIT_SCORE: 'Invoice Funding - CES Submit score',
    CES_VIEW_FEEDBACK: 'Invoice Funding - CES View feedback',
    CES_SUBMIT_FEEDBACK: 'Invoice Funding - CES Submit feedback',
    CES_EXIT_FEEDBACK: 'Invoice Funding - CES Exit feedback',
    CES_FINISHED_FEEDBACK: 'Invoice Funding - CES View feedback submitted',
  },
};

export type ProductKey = keyof typeof productCESEvents;

export const servicingEvents = {
  OPEN_WITHDRAWAL_MODAL: 'Servicing - Modal - Open Request Withdrawal Modal',
  SUBMIT_REQUEST_WITHDRAWAL: 'Servicing - Form - Submit Request Withdrawal',
  TOGGLED_FX_NEEDED: 'Servicing - Form - Toggled FX Needed',
  REQUEST_WITHDRAWAL_FORM: 'Servicing - Form - Edit Request Withdrawal Field',
  CLOSE_WITHDRAWAL_MODAL: 'Servicing - Modal - Close Request Withdrawal Modal',
  VIEW_OVERDUE: 'Servicing - Invoices - View Overdue',
  VIEW_IN_REVIEW: 'Servicing - Invoices - View In Review',
  GO_TO_CREDIT_DETAILS: 'Servicing - Dashboard - Click Go to Credit Details',
  VIEW_ALL_INVOICES: 'Servicing - Dashboard - Click View All Invoices',
  VIEW_ALL_TRANSFERS: 'Servicing - Dashboard - Click View All Transfers',
  VIEW_ALL_PAYMENTS: 'Servicing - Dashboard - Click View All Payments',
  INVOICES_TAB: 'Servicing - Dashboard - Click Invoices Tab',
  TRANSFERS_TAB: 'Servicing - Dashboard - Click Transfers Tab',
  PAYMENTS_TAB: 'Servicing - Dashboard - Click Payments Tab',
  OPEN_REACTIVATE_MODAL: 'Servicing - Open Reactivate Modal',
  CLOSE_REACTIVATE_MODAL: 'Servicing - Close Reactivate Modal',
  REQUEST_REACTIVATE_CREDIT_LINE: 'Servicing - Request Reactivate Credit Line',
  CLICK_INCREASE_CREDIT_LINE: 'Servicing - Click Increase Credit Line',
  INCREASE_CREDIT_LINE_EDIT_FIELD: 'Servicing - Increase Credit Line Edit Field',
  SUBMIT_INCREASE_CREDIT_LINE_REQUEST: 'Servicing - Submit Increase Credit Line Request',
  CLICK_FUNDING_REQUEST: 'Servicing - Click Funding Request',
  DOWNLOAD_FUNDING_TEMPLATE: 'Servicing - Download Funding Template',
  UPLOAD_FILE_FUNDING_REQUEST: 'Servicing - Upload File Funding Request',
  EDIT_FIELD_FUNDING_REQUEST: 'Servicing - Edit Field Funding Request',
  CLICK_NEXT_FUNDING_REQUEST_DRAWER: 'Servicing - Click Next Funding Request Drawer',
  CLICK_BACK_FUNDING_REQUEST_DRAWER: 'Servicing - Click Back Funding Request Drawer',
  CLICK_SUBMIT_FUNDING_REQUEST_DRAWER: 'Servicing - Click Submit Funding Request Drawer',
  CLICK_CLOSE_FUNDING_REQUEST_CONFIRMATION_MODAL:
    'Servicing - Click Close Funding Request Confirmation Modal',
};

export const termSheetEvents = {
  VIEW_PROPOSAL: 'Invoice Financing - Term Sheet - View proposal',
  CLOSE_CONFIRMATION_MODAL: 'Invoice Financing - Term Sheet - Close signed confirmation modal',
  VIEW_SIGNED_PROPOSAL: 'Invoice Financing - Term Sheet - View signed proposal',
};

export const docsCollectionEvents = {
  VIEW_DOCUMENTS_PAGE: 'Invoice Financing - Docs Collection - View documents page',
  CLICK_VIEW_GUIDE: 'Invoice Financing - Docs Collection - View Guide',
  CLICK_UPLOAD: 'Invoice Financing - Docs Collection - Click upload',
  UPLOAD_DOC: 'Invoice Financing - Docs Collection - Upload Document',
  PREVIEW_DOC: 'Invoice Financing - Docs Collection - Preview Document',
  DELETE_DOC: 'Invoice Financing - Docs Collection - Delete Document',
  APPROVE_DOC: 'Invoice Financing - Docs Collection - Approve Document',
  REJECT_DOC: 'Invoice Financing - Docs Collection - Reject Document',
  TOGGLE_DOC: 'Invoice Financing - Docs Collection - Toggle Document',
  DOWNLOAD_ALL_DOCS: 'Invoice Financing - Docs Collection - Download All Documents',
  // SHAREHOLDERS
  OPEN_ADD_SHAREHOLDER_MODAL: 'Invoice Financing - Docs Collection - Open Add Shareholder Modal',
  OPEN_EDIT_SHAREHOLDER_MODAL: 'Invoice Financing - Docs Collection - Open Edit Shareholder Modal',
  EDIT_SHAREHOLDER_FORM: 'Invoice Financing - Docs Collection - Edit Shareholder Form',
  UPLOAD_SHAREHOLDER_DOCUMENT: 'Invoice Financing - Docs Collection - Upload Shareholder Document(s)',
  SAVE_SHAREHOLDER_FORM: 'Invoice Financing - Docs Collection - Save Shareholder Form',
  DELETE_SHAREHOLDER: 'Invoice Financing - Docs Collection - Delete Shareholder',
  // LEGAL REPS
  OPEN_ADD_LEGAL_REP_MODAL: 'Invoice Financing - Docs Collection - Open Add Legal Representative Modal',
  OPEN_EDIT_LEGAL_REP_MODAL: 'Invoice Financing - Docs Collection - Open Edit Legal Representative Modal',
  EDIT_LEGAL_REP_FORM: 'Invoice Financing - Docs Collection - Edit Legal Representative Form',
  UPLOAD_LEGAL_REP_DOCUMENT: 'Invoice Financing - Docs Collection - Upload Legal Representative Document(s)',
  SAVE_LEGAL_REP_FORM: 'Invoice Financing - Docs Collection - Save Legal Representative Form',
  DELETE_LEGAL_REP: 'Invoice Financing - Docs Collection - Delete Legal Representative',
};

export const creditLineEvents = {
  VIEW_MY_CREDIT: 'Invoice Financing - Credit Line - View my credit',
  CLOSE_CONFIRMATION_MODAL: 'Invoice Financing - Credit Line - Close approved confirmation modal',
  VIEW_CREDIT_LINE_DETAILS: 'Invoice Financing - Credit Line - View credit line details',
  VIEW_CONTRACT: 'Invoice Financing - Credit Line - View contract',
};

export const LLCEvents = {
  VIEW_PAGE: 'LLC - View page',
  SUBMIT_REQUEST: 'LLC - Submit Request',
  FOLLOW_EMAIL_LINK: 'LLC - Follow Email Link',
  UPLOAD_DOCUMENT: 'LLC - Upload Document',
  VIEW_DOCUMENT: 'LLC - View Document',
  CLICK_SIGN_DOCUMENT: 'LLC - Click Sign Document',
  SET_LLC_STATUS: 'LLC - Set LLC Status',
  CLICK_COPY_EIN: 'LLC - Click Copy Ein',
  SET_LANGUAGE: 'LLC - Set Language',
  SEND_NOTIFICATION: 'LLC - Send Notification',
  UPDATE_DOCUMENT_SIGNATURE: 'LLC - Update Document Signature',
  DELETE_DOCUMENT: 'LLC - Delete Document',
  EDIT_COMPANY_INFORMATION_FIELD: 'LLC - Edit Company Information Field',
  EDIT_SHAREHOLDER_FIELD: 'LLC - Edit Shareholder Field',
  EDIT_LEGAL_REPRESENTATIVE_FIELD: 'LLC - Edit Legal Representative Field',
};

export const insurancesEvents = {
  VIEW_PAGE: 'Insurances - View page',
  VIEW_CARGO: 'Insurances - View Cargo page',
  VIEW_CREDIT: 'Insurances - View Credit page',
  SUBMIT_CARGO: 'Insurances - Submit Cargo request',
  SUBMIT_CREDIT: 'Insurances - Submit Credit request',
};

export const buyerCheckEvents = {
  VIEW_PAGE: 'Buyer Check - View page',
  EDIT_FIELD: 'Buyer Check - Edit field',
  SUBMIT: 'Buyer Check - Submit request',
};

export const FXEvents = {
  VIEW_PAGE: 'FX - View page',
  EDIT_FIELD: 'FX - Edit field',
  SUBMIT: 'FX - Submit request',
};

export const SATEvents = {
  CONNECT_SAT: 'Invoice Financing - Application - Click connect SAT',
  OPEN_SAT_HELP: 'Invoice Financing - Application - Click SAT Help',
  CLOSE_SAT_HELP: 'Invoice Financing - Application - Close SAT Help',
  CONNECT_SAT_SUCCESS: 'Invoice Financing - Application - SAT successful',
  CONNECT_SAT_ERROR: 'Invoice Financing - Application - SAT failed',
  TOGGLE_FLOW: 'Invoice Financing - Application - SAT Flow toggled',
};

export const adminEvents = {
  IMPERSONATE: 'Admin - Impersonate',
};

export const myAccountEvents = {
  VIEW_PAGE: 'My Account - View Page',
  EDIT_PERSONAL_INFO: 'My Account - Edit Personal Info',
  EDIT_COMPANY_INFO: 'My Account - Edit Company Info',
  CHANGE_PASSWORD: 'My Account - Change Password',
  CHANGE_NOTIFICATIONS: 'My Account - Change Notifications',
};

export const buyersSummaryEvents = {
  VIEW_DETAILS: `Buyer's Summary - View Buyer Details`,
  CLICK_ADD_BUYER: `Buyer's Summary - Click Add New Buyer`,
  CLICK_APPLY_FILTERS: `Buyer's Summary - Click Apply Filters`,
  CLICK_RESET_FILTERS: `Buyer's Summary - Click Reset Filters`,
  EDIT_FILTER: `Buyer's Summary - Edit Filter`,
  ADD_NEW_BUYER_EDIT_FIELD: `Buyer's Summary - Add New Buyer Edit Field`,
  SUBMIT_ADD_NEW_BUYER_REQUEST: `Buyer's Summary - Submit Add New Buyer Request`,
  CLICK_INCREASE_BUYER_SUBLIMIT_BUYER: `Buyer's Summary - Click Increase Buyer Sublimit`,
  INCREASE_BUYER_SUBLIMIT_EDIT_FIELD: `Buyer's Summary - Increase Buyer Sublimit Edit Field`,
  SUBMIT_INCREASE_BUYER_SUBLIMIT_REQUEST: `Buyer's Summary - Submit Buyer Sublimit Request`,
  CLICK_EXPORT_BUYERS: `Buyer's Summary - Click Export Buyers`,
};

export const bankAccountEvents = {
  VIEW_PAGE: 'Bank Accounts - View Page',
  OPEN_ADD_MODAL: 'Bank Accounts - Open Add Modal',
  OPEN_EDIT_MODAL: 'Bank Accounts - Open Edit Modal',
  OPEN_DETAILS_MODAL: 'Bank Accounts - Open Details Modal',
  EDIT_FIELD: 'Bank Accounts - Edit Field',
  ADD_FILES: 'Bank Accounts - Add Files',
  REMOVE_FILE: 'Bank Accounts - Remove file',
  SUBMIT_NEW_ACCOUNT: 'Bank Accounts - Submit New Account',
  SUBMIT_EDIT_ACCOUNT: 'Bank Accounts - Edit Account',
  ACCEPT_BANK_ACCOUNT: 'Bank Accounts - Accept Account',
  REJECT_BANK_ACCOUNT: 'Bank Accounts - Reject Account',
};

export const followupEvents = {
  CLICK_FOLLOW_UP_EMAIL: 'Followup - Click email CTA',
};

export const invoicesEvents = {
  ALL_TAB: 'Invoices - Click All Tab',
  FINANCED_TAB: 'Invoices - Click Financed Tab',
  IN_REVIEW_TAB: 'Invoices - Click In Review Tab',
  VIEW_INVOICE_DETAIL: 'Invoices - View Invoice Detail',
  CLICK_FILTERS: 'Invoices - Click Filters Button',
  CLICK_APPLY_FILTERS: 'Invoices - Click Apply Filters',
  CLICK_RESET_FILTERS: 'Invoices - Click Reset Filters',
  EDIT_FILTER: 'Invoices - Edit Filter',
  CLICK_EXPORT_INVOICES: 'Invoices - Click Export Invoices',
  CLICK_VIEW_TRANSFERS: 'Invoices - Click View Transfers',
  CLICK_FUNDING_REQUEST: 'Invoices - Click Funding Request',
};

export const bankingEvents = {
  VIEW_CONNECTED_ACCOUNTS_PAGE: 'Banking - Connected Accounts - View Page',
  VIEW_CONNECTED_ACCOUNT_DETAIL_PAGE: 'Banking - Connected Accounts - View Detail Page',
  ACTIVATE_CONNECTED_ACCOUNT: 'Banking - Connected Accounts - Activate Account',
  CREATE_KYC: 'Banking - Connected Accounts - Create KYC',
  VIEW_PAGE: 'Banking - View Product Page',
  SUBMIT_REQUEST: 'Banking - Submit Request',
  OPEN_ADD_FUNDS: 'Banking - Add Funds - Open Drawer',
  ADD_FUNDS_CHANGE_ACCOUNT: 'Banking - Add Funds - Change Account',
  ADD_FUNDS_TOGGLE_MORE_DETAILS: 'Banking - Add Funds - Toggle More Details',
  ADD_FUNDS_CLICK_COPY_ALL: 'Banking - Add Funds - Click Copy All Details',
  ADD_FUNDS_CLICK_COPY: 'Banking - Add Funds - Click Copy Details',
  VIEW_ACCOUNT_DETAIL_PAGE: 'Banking - Account Detail - View Page',
  VIEW_ACCOUNT_ALL_DETAILS: 'Banking - Account Detail - Show All Details',
  ALL_TRANSACTIONS_VIEW_PAGE: 'Banking - All Transactions - View Page',
  ALL_TRANSACTIONS_APPLY_FILTERS: 'Banking - All Transactions - Apply Filters',
  TRANSACTIONS_VIEW_TRANSACTION_DETAILS: 'Banking - All Transactions - View Transaction Details',
  TRANSACTIONS_CHANGE_PAGE: 'Banking - All Transactions - Change Page',
  TRANSFERS_VIEW_PAGE: 'Banking - Transfers - View Page',
  TRANSFERS_APPLY_FILTERS: 'Banking - Transfers - Apply Filters',
  TRANSFERS_CHANGE_PAGE: 'Banking - Transfers - Change Page',
  TRANSFERS_VIEW_DETAILS: 'Banking - Transfers - View Details',
};

export const reportingEvents = {
  CLICK_GENERATE_REPORT: 'Reporting - Click Generate Report',
  SUBMIT_GENERATE_REPORT: 'Reporting - Submit Generate Report',
  INSIGHTS_TAB: 'Reporting - Click Insights Tab',
  REPORTS_TAB: 'Reporting - Click Reports Tab',
  CLICK_VIEW_PRESENTATION: 'Reporting - Click View Presentation',
};

export const trackingEvents = {
  VIEW_TRACKING_PAGE: 'Tracking - View Page',
  VIEW_PUBLIC_TRACKING_PAGE: 'Tracking - View Public Page',
  CLICK_APPLY_FILTERS: 'Tracking - Click Apply Filters',
  CLICK_CLEAR_FILTERS: 'Tracking - Clear Click Filters',
  EDIT_FILTER: 'Tracking - Edit Filter',
  CLICK_CONNECT_INVOICE: 'Tracking - Click Connect Invoice',
  SUBMIT_CONNECT_INVOICE: 'Tracking - Submit Connect Invoice',
  CLICK_TRACK_SHIPMENT: 'Tracking - Click Track Shipment',
};

export const marcoWrappedEvents = {
  FOLLOW_EMAIL_LINK: 'Marco Wrapped - Follow Email Link',
  CLICK_HOME_BANNER: 'Marco Wrapped - Click Home Banner',
  CLICK_MODAL_NEXT_BUTTON: 'Marco Wrapped - Click Modal Next Button',
  CLICK_VEW_MORE_INSIGHTS_BUTTON: 'Marco Wrapped - Click View More Insights Button',
};

export const transfersEvents = {
  CLICK_APPLY_FILTERS: `Transfers - Click Apply Filters`,
  CLICK_RESET_FILTERS: `Transfers - Click Reset Filters`,
  EDIT_FILTER: `Transfers - Edit Filter`,
  VIEW_TRANSFER_DETAIL: 'Transfers - View Transfer Detail',
  SHOW_TRANSFER_ALLOCATIONS_DRAWER: 'Transfers - Show Transfer Allocations Drawer',
  SHOW_TRANSFER_DETAILS_DRAWER: 'Transfers - Show Transfer Details Drawer',
  SHOW_INVOICE_DETAILS_DRAWER: 'Transfers - Show Invoice Details Drawer',
};

export const notificationEvents = {
  OPEN_NOTIFICATION: 'Notification - Click',
};

export const paymentsEvents = {
  VIEW_PAYMENT_DETAIL: 'Payments - View Payment Detail',
  SHOW_PAYMENT_DETAILS_DRAWER: 'Payments - Show Payment Details Drawer',
  SHOW_PAYMENT_ALLOCATIONS_DRAWER: 'Payments - Show Payment Allocations Drawer',
  SHOW_INVOICE_DETAILS_DRAWER: 'Payments - Show Invoice Details Drawer',
  CLICK_APPLY_FILTERS: 'Payments - Click Apply Filters',
  CLICK_RESET_FILTERS: 'Payments - Click Reset Filters',
  EDIT_FILTER: 'Payments - Edit Filter',
};

export const CompanyEvents = {
  EDIT_COMPANY_INFORMATION_FIELD: 'Company - Edit Company Information Field',
};
