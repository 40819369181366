import { baseURL } from '../constants/api';
import { APIAsset } from '../types/application';
import { APIDocument, APIDocumentSession, IAsset, IDocumentCategory } from '../types/documents';

export function getStatusFromDocuments(documents: APIDocument[], status: string): string {
  /* Inferes a Category status based on its documents */
  if (!documents.length) return 'PENDING';
  if (!!documents[0].prevSessionRejectedComment && status === 'PENDING') return 'DENIED';
  return status;
}

export function getStatusFromSession(session: APIDocumentSession) {
  /* Inferes a Category status based on an API result */
  if (session.status === 'PENDING' && !!session.rejectedComment) return 'DENIED';
  return session.status;
}

export function groupDocuments(documentsConfiguration: IDocumentCategory[]) {
  /* Groups documents by the "group" attribute */
  return documentsConfiguration.reduce(
    (acc: Record<string, IDocumentCategory[]>, current: IDocumentCategory) => {
      if (!acc[current.group]) acc[current.group] = [];
      acc[current.group].push(current);
      return acc;
    },
    {}
  );
}

export function formatAPIAsset({ fileName, uuid, id }: APIAsset): IAsset {
  /* Transforms an API asset into our internal representation */
  return { id, fileName, url: `${baseURL}/assets/${uuid}`, isLoading: false };
}
