import { useEffect, useState } from 'react';
import { logEvent } from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import { Formik, FormikProps } from 'formik';
import Table from '../../componentsv2/Table';
import Form from '../../componentsv2/Form';
import { SelectField, TextField } from '../../componentsv2/Field';
import Button from '../../componentsv2/Button';
import MultiSelectField from '../../componentsv2/Field/MultiSelectField';
import AutocompleteField from '../../componentsv2/Field/AutocompleteField';
import Snackbar from '../../components/Snackbar';
import FormSection from '../../componentsv2/FormSection';
import CheckboxWrapper from '../../components/Fields/Checkbox';
import { AddIcon, TrashIcon } from '../../components/Icons';
import ConfirmationModal from '../../components/ConfirmationModal';
import { useTranslation } from '../../hooks/i18n';
import { addBuyer, deleteBuyer, editBuyer, getApplicationBuyers } from '../../services/application';
import { APIApplicationBuyerInfo, ApplicationStepProps } from '../../types/application';
import { CountryOptionForDebtor, Nullable } from '../../types';
import { AddCustomerSchema } from '../../schemas';
import { hasEmptyValues, mapCountryForDebtorFromBackend } from '../../utils/helpers';
import { backendCountryValuesForDebtors, getCountriesForDebtors, moneyOptions } from '../../constants/data';
import { invoiceEvents } from '../../constants/events';
import { paymentTermsOptions } from '../../constants/application';
import { COLORS } from '../../themev2';
import classes from './ApplicationStepper.module.scss';
import { useAppSelector } from '../../store';

const CustomAlert = styled(Alert)({
  '& .MuiAlert-icon': {
    color: 'white',
  },
});

const setAddCustomerInitialValues = (customer: Nullable<APIApplicationBuyerInfo>) => {
  let initialValues: AddCustomerState = {
    legalBusinessName: '',
    tradeName: null,
    addressCountry: '',
    addressBusinessStreet: '',
    addressCity: '',
    addressState: '',
    addressZipCode: '',
    taxId: '',
    paymentTerms: [],
    contactNameForPayables: null,
    contactEmailForPayables: null,
    knowsBuyersEIN: false,
    ein: null,
    duns: null,
    hasAdditionalAddress: false,
    addressZipCodeTwo: null,
    addressCityTwo: null,
    addressBusinessStreetTwo: null,
    addressStateTwo: null,
  };
  if (customer) {
    initialValues = {
      ...customer,
      addressCountry: customer?.addressCountry ? mapCountryForDebtorFromBackend(customer.addressCountry) : '',
      knowsBuyersEIN: !!customer.ein || !!customer.duns,
      hasAdditionalAddress: !!customer.addressBusinessStreetTwo,
    };
  }
  return initialValues;
};

export type FinancingState = {
  creditFacility: number[];
};

export type AddCustomerState = {
  legalBusinessName: string;
  tradeName: Nullable<string>;
  addressCountry: string;
  addressBusinessStreet: string;
  addressCity: string;
  addressState: string;
  addressZipCode: string;
  taxId: Nullable<string>;
  paymentTerms: string[];
  contactNameForPayables: Nullable<string>;
  contactEmailForPayables: Nullable<string>;
  knowsBuyersEIN?: boolean;
  ein?: Nullable<string>;
  duns?: Nullable<string>;
  hasAdditionalAddress?: boolean;
  addressZipCodeTwo?: Nullable<string>;
  addressCityTwo?: Nullable<string>;
  addressBusinessStreetTwo?: Nullable<string>;
  addressStateTwo?: Nullable<string>;
};

const FinancingStepCustomersList = ({
  onStepReturn,
  onStepSubmit,
  applicationDraft,
  isLoading,
  isLoadingStep,
}: ApplicationStepProps) => {
  const { t } = useTranslation('application');
  const { id } = applicationDraft;
  const [displayCustomerDialog, setDisplayCustomerDialog] = useState(false);
  const [areCustomersLoading, setAreCustomersLoading] = useState(true);
  const [isSubmittingCustomer, setIsSubmittingCustomer] = useState(false);
  const [customerDetails, setCustomerDetails] = useState<Nullable<APIApplicationBuyerInfo>>(null);
  const [clients, setClients] = useState<APIApplicationBuyerInfo[]>([]);
  const [displayDeletedClientMessage, setDisplayDeletedClientMessage] = useState(false);
  const [displayEditedClientMessage, setDisplayEditedClientMessage] = useState(false);
  const newCompany = useAppSelector((state) => state.client.newCompany);

  useEffect(() => {
    logEvent(invoiceEvents.STEP_THREE);
  }, []);

  useEffect(() => {
    if (id) {
      (async () => {
        setAreCustomersLoading(true);
        await getApplicationBuyers(id, newCompany!.id)
          .then((data) => {
            if (data.length > 0) {
              setClients(data);
            }
          })
          .finally(() => setAreCustomersLoading(false));
      })();
    }
  }, [id]);

  const initialValues: FinancingState = {
    creditFacility:
      applicationDraft?.moneyMin && applicationDraft?.moneyMax
        ? [applicationDraft.moneyMin, applicationDraft.moneyMax]
        : [],
  };

  const handleSubmit = (values: FinancingState) => {
    const {
      creditFacility: [moneyMin, moneyMax],
    } = values;
    const mappedValues = {
      moneyMin,
      moneyMax,
    };
    return onStepSubmit(mappedValues);
  };

  const handleSubmitCustomer = async (values: AddCustomerState) => {
    const mapValuesForBackend = {
      ...values,
      addressCountry:
        backendCountryValuesForDebtors[values.addressCountry as keyof typeof backendCountryValuesForDebtors],
      tradeName: values.tradeName?.length !== 0 ? values.tradeName : null,
      contactNameForPayables:
        values.contactNameForPayables?.length !== 0 ? values.contactNameForPayables : null,
      contactEmailForPayables:
        values.contactEmailForPayables?.length !== 0 ? values.contactEmailForPayables : null,
      taxId: values.taxId || null,
    };
    delete mapValuesForBackend.knowsBuyersEIN;
    delete mapValuesForBackend.hasAdditionalAddress;

    setIsSubmittingCustomer(true);
    if (customerDetails) {
      await editBuyer(id, customerDetails.id, mapValuesForBackend, newCompany!.id)
        .then((data) => {
          const index = clients.findIndex((client) => client.id === customerDetails.id);
          if (index !== -1) {
            let updatedClients = [...clients];
            updatedClients[index] = { ...updatedClients[index], ...data };
            setClients(updatedClients);
          }
          logEvent(invoiceEvents.EDITED_CUSTOMER, {
            'Legal Business Name': mapValuesForBackend.legalBusinessName,
            Country: mapValuesForBackend.addressCountry,
            'Tax Id': mapValuesForBackend.taxId,
            'Payment Terms': mapValuesForBackend.paymentTerms,
          });
          setDisplayEditedClientMessage(true);
        })
        .finally(() => {
          setIsSubmittingCustomer(false);
          setDisplayCustomerDialog(false);
          setCustomerDetails(null);
        });
    } else {
      await addBuyer(id, mapValuesForBackend, newCompany!.id)
        .then((data) => {
          setClients((prevClients) => [...prevClients, data]);
          logEvent(invoiceEvents.ADDED_CUSTOMER, {
            'Legal Business Name': mapValuesForBackend.legalBusinessName,
            Country: mapValuesForBackend.addressCountry,
            'Tax Id': mapValuesForBackend.taxId,
            'Payment Terms': mapValuesForBackend.paymentTerms,
          });
        })
        .finally(() => {
          setIsSubmittingCustomer(false);
          setDisplayCustomerDialog(false);
        });
    }
  };

  const handleDeleteCustomer = async (customerId: number) => {
    setIsSubmittingCustomer(true);
    await deleteBuyer(id, customerId, newCompany!.id)
      .then(() => {
        const updatedClients = clients.filter((client) => client.id !== customerId);
        setClients(updatedClients);
        logEvent(invoiceEvents.DELETED_CUSTOMER);
        setDisplayDeletedClientMessage(true);
      })
      .finally(() => {
        setIsSubmittingCustomer(false);
      });
  };

  const handleCloseAddCustomerDialog = () => {
    setCustomerDetails(null);
    setDisplayCustomerDialog(false);
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values }: FormikProps<FinancingState>) => {
          return (
            <Form trackingEvent={invoiceEvents.EDIT_FIELD}>
              <FormSection title={t('sections.financialNeeds.title')}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={6}>
                    <SelectField
                      name="creditFacility"
                      label={t('sections.financialNeeds.fields.creditFacilityLabel')}
                      placeholder={t('sections.financialNeeds.fields.creditFacilityPlaceholder')}
                      options={moneyOptions}
                    />
                  </Grid>
                </Grid>
              </FormSection>
              <FormSection title={t('sections.clientsInformation.title')}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12}>
                    <Typography>{t(`sections.clientsInformation.customers`)}</Typography>
                  </Grid>
                  <>
                    {areCustomersLoading && (
                      <Grid item xs={12}>
                        <Skeleton
                          sx={{ borderRadius: '10px' }}
                          variant="rectangular"
                          height={160}
                          animation="wave"
                        />
                      </Grid>
                    )}
                    {clients.length > 0 && !areCustomersLoading ? (
                      <Grid item xs={12}>
                        <Table
                          columns={[
                            {
                              field: 'legalBusinessName',
                              headerName: t(
                                'sections.clientsInformation.customersTable.columns.legalBusinessName'
                              ),
                            },
                            {
                              field: 'addressCountry',
                              headerName: t('sections.clientsInformation.customersTable.columns.country'),
                              renderCell: (params: APIApplicationBuyerInfo) => (
                                <Typography>
                                  {params.addressCountry
                                    ? t(
                                        `common:countries.${mapCountryForDebtorFromBackend(
                                          params.addressCountry
                                        )}`
                                      )
                                    : '-'}
                                </Typography>
                              ),
                            },
                            {
                              field: 'taxId',
                              headerName: t('sections.clientsInformation.customersTable.columns.taxId'),
                            },
                            {
                              field: 'paymentTerms',
                              headerName: t(
                                'sections.clientsInformation.customersTable.columns.paymentTerms'
                              ),
                              renderCell: (params: APIApplicationBuyerInfo) => (
                                <Typography>{params.paymentTerms?.sort().join(', ')}</Typography>
                              ),
                            },
                            {
                              headerName: t('sections.clientsInformation.customersTable.columns.actions'),
                              width: 250,
                              renderCell: (params: APIApplicationBuyerInfo) => (
                                <ConfirmationModal
                                  onConfirm={handleDeleteCustomer}
                                  title={t(
                                    'sections.clientsInformation.customersTable.confirmDeleteCustomerModal.title',
                                    { customer: params.legalBusinessName }
                                  )}
                                  body={
                                    <Grid container rowSpacing={2}>
                                      <Grid item xs={12}>
                                        <Grid container>
                                          <Grid item xs={12}>
                                            {t(
                                              'sections.clientsInformation.customersTable.confirmDeleteCustomerModal.descriptionLost'
                                            )}
                                          </Grid>
                                          <Grid item xs={12}>
                                            {t(
                                              'sections.clientsInformation.customersTable.confirmDeleteCustomerModal.descriptionUndone'
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Divider sx={{ borderColor: '#eee', paddingTop: '8px' }} />
                                      </Grid>
                                    </Grid>
                                  }
                                  cancelLabel={t(
                                    'sections.clientsInformation.customersTable.confirmDeleteCustomerModal.actions.cancel'
                                  )}
                                  confirmLabel={t(
                                    'sections.clientsInformation.customersTable.confirmDeleteCustomerModal.actions.delete'
                                  )}>
                                  {({ openModal }) => (
                                    <div
                                      style={{
                                        display: 'flex',
                                        gap: '8px',
                                        width: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}>
                                      <Button
                                        size="small"
                                        color="secondary"
                                        type="outline"
                                        onClick={() => {
                                          setCustomerDetails(params);
                                          setDisplayCustomerDialog(true);
                                          logEvent(invoiceEvents.EDIT_CUSTOMER);
                                        }}>
                                        {t('sections.clientsInformation.customersTable.actions.viewDetails')}
                                      </Button>
                                      <IconButton
                                        disabled={isSubmittingCustomer}
                                        onClick={() => {
                                          logEvent(invoiceEvents.DELETE_CUSTOMER);
                                          openModal(params.id);
                                        }}
                                        sx={{ marginLeft: '8px', color: '#930909' }}>
                                        <TrashIcon />
                                      </IconButton>
                                    </div>
                                  )}
                                </ConfirmationModal>
                              ),
                            },
                          ]}
                          data={clients}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12} sm={6} md={3}>
                      <Button
                        startIcon={<AddIcon />}
                        color="secondary"
                        onClick={() => {
                          logEvent(invoiceEvents.ADD_CUSTOMER);
                          setDisplayCustomerDialog(true);
                        }}>
                        {t('sections.clientsInformation.addCustomerDialog.button')}
                      </Button>
                    </Grid>
                  </>
                </Grid>
              </FormSection>
              <Grid container justifyContent="left" sx={{ marginTop: '2em' }}>
                <Grid item xs={12} md={12} sx={{ display: 'flex', gap: '1em' }}>
                  <Button type="outline" onClick={onStepReturn} disabled={isLoading}>
                    {t('steps.financing.returnBtn')}
                  </Button>
                  <Button
                    isSubmit
                    isLoading={isLoading || isLoadingStep}
                    disabled={hasEmptyValues(values) || clients?.length === 0}>
                    {t('steps.financing.submitBtn')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Dialog
        onClose={handleCloseAddCustomerDialog}
        open={displayCustomerDialog}
        aria-labelledby="add-customer-dialog"
        fullWidth
        sx={{ '.MuiDialog-paper': { borderRadius: '16px', boxShadow: 'none' } }}
        maxWidth="lg">
        <IconButton sx={{ position: 'absolute', top: 4, right: 4 }} onClick={handleCloseAddCustomerDialog}>
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ padding: { md: '2rem 8rem' } }}>
          <Typography variant="h5" sx={{ color: COLORS.green[600] }}>
            {t(`sections.clientsInformation.addCustomerDialog.title.${customerDetails ? 'edit' : 'add'}`)}
          </Typography>
          <Typography>
            {t(`sections.clientsInformation.addCustomerDialog.subtitle.${customerDetails ? 'edit' : 'add'}`)}
          </Typography>
          <Divider sx={{ borderColor: '#eee', marginY: '1em' }} />
          <Formik
            initialValues={setAddCustomerInitialValues(customerDetails)}
            validateOnBlur={true}
            validateOnChange={false}
            validationSchema={AddCustomerSchema(t)}
            onSubmit={handleSubmitCustomer}>
            {({ values, setFieldValue, dirty, touched, errors }: FormikProps<AddCustomerState>) => {
              return (
                <Form trackingEvent={invoiceEvents.EDIT_FIELD}>
                  <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="legalBusinessName"
                        label={t(
                          'sections.clientsInformation.addCustomerDialog.fields.legalBusinessName.label'
                        )}
                        placeholder={t(
                          'sections.clientsInformation.addCustomerDialog.fields.legalBusinessName.placeholder'
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        optional
                        name="tradeName"
                        label={t('sections.clientsInformation.addCustomerDialog.fields.tradeName.label')}
                        placeholder={t(
                          'sections.clientsInformation.addCustomerDialog.fields.tradeName.placeholder'
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ mt: 'auto' }}>
                      <FormControl fullWidth>
                        <AutocompleteField
                          name="addressCountry"
                          label={t(
                            'sections.clientsInformation.addCustomerDialog.fields.addressCountry.label'
                          )}
                          placeholder={t(
                            'sections.clientsInformation.addCustomerDialog.fields.addressCountry.placeholder'
                          )}
                          options={getCountriesForDebtors(t)
                            .sort((a: CountryOptionForDebtor, b: CountryOptionForDebtor) =>
                              a.country.localeCompare(b.country)
                            )
                            .map(({ country, countryKey }) => ({
                              value: countryKey,
                              label: country,
                            }))}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="addressBusinessStreet"
                        label={t(
                          'sections.clientsInformation.addCustomerDialog.fields.addressBusinessStreet.label'
                        )}
                        placeholder={t(
                          'sections.clientsInformation.addCustomerDialog.fields.addressBusinessStreet.placeholder'
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="addressCity"
                        label={t('sections.clientsInformation.addCustomerDialog.fields.addressCity.label')}
                        placeholder={t(
                          'sections.clientsInformation.addCustomerDialog.fields.addressCity.placeholder'
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="addressState"
                        label={t('sections.clientsInformation.addCustomerDialog.fields.addressState.label')}
                        placeholder={t(
                          'sections.clientsInformation.addCustomerDialog.fields.addressState.placeholder'
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="addressZipCode"
                        label={t('sections.clientsInformation.addCustomerDialog.fields.addressZipCode.label')}
                        placeholder={t(
                          'sections.clientsInformation.addCustomerDialog.fields.addressZipCode.placeholder'
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        optional
                        name="taxId"
                        label={t('sections.clientsInformation.addCustomerDialog.fields.taxId.label')}
                        placeholder={t(
                          'sections.clientsInformation.addCustomerDialog.fields.taxId.placeholder'
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MultiSelectField
                        name="paymentTerms"
                        label={t('sections.clientsInformation.addCustomerDialog.fields.paymentTerms.label')}
                        placeholder={t(
                          'sections.clientsInformation.addCustomerDialog.fields.paymentTerms.placeholder'
                        )}
                        options={paymentTermsOptions.map((value: string) => ({
                          value,
                          label: t(
                            `sections.clientsInformation.addCustomerDialog.fields.paymentTermsOptions.${value}`
                          ),
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        optional
                        name="contactNameForPayables"
                        label={t(
                          'sections.clientsInformation.addCustomerDialog.fields.contactNameForPayables.label'
                        )}
                        placeholder={t(
                          'sections.clientsInformation.addCustomerDialog.fields.contactNameForPayables.placeholder'
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        optional
                        name="contactEmailForPayables"
                        label={t(
                          'sections.clientsInformation.addCustomerDialog.fields.contactEmailForPayables.label'
                        )}
                        placeholder={t(
                          'sections.clientsInformation.addCustomerDialog.fields.contactEmailForPayables.placeholder'
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CheckboxWrapper
                        className={classes.checkboxWrapper}
                        name="knowsBuyersEIN"
                        fullWidth
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('knowsBuyersEIN', e.target.checked);
                          if (!e.target.checked) {
                            setFieldValue('ein', null);
                            setFieldValue('duns', null);
                          }
                        }}
                        checked={values.knowsBuyersEIN}
                        label={t('sections.clientsInformation.addCustomerDialog.fields.knowsBuyersEIN.label')}
                      />
                    </Grid>
                    {values.knowsBuyersEIN && (
                      <>
                        <Grid item xs={12}>
                          <TextField
                            name="ein"
                            label={t('sections.clientsInformation.addCustomerDialog.fields.ein.label')}
                            placeholder={t(
                              'sections.clientsInformation.addCustomerDialog.fields.ein.placeholder'
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            type="number"
                            name="duns"
                            label={t('sections.clientsInformation.addCustomerDialog.fields.duns.label')}
                            placeholder={t(
                              'sections.clientsInformation.addCustomerDialog.fields.duns.placeholder'
                            )}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <CheckboxWrapper
                        className={classes.checkboxWrapper}
                        name="hasAdditionalAddress"
                        fullWidth
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('hasAdditionalAddress', e.target.checked);
                          if (!e.target.checked) {
                            setFieldValue('addressBusinessStreetTwo', null);
                            setFieldValue('addressCityTwo', null);
                            setFieldValue('addressStateTwo', null);
                            setFieldValue('addressZipCodeTwo', null);
                          }
                        }}
                        checked={values.hasAdditionalAddress}
                        label={t(
                          'sections.clientsInformation.addCustomerDialog.fields.hasAdditionalAddress.label'
                        )}
                      />
                    </Grid>
                    {values.hasAdditionalAddress && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="addressBusinessStreetTwo"
                            label={t(
                              'sections.clientsInformation.addCustomerDialog.fields.addressBusinessStreetTwo.label'
                            )}
                            placeholder={t(
                              'sections.clientsInformation.addCustomerDialog.fields.addressBusinessStreetTwo.placeholder'
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="addressCityTwo"
                            label={t(
                              'sections.clientsInformation.addCustomerDialog.fields.addressCityTwo.label'
                            )}
                            placeholder={t(
                              'sections.clientsInformation.addCustomerDialog.fields.addressCityTwo.placeholder'
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="addressStateTwo"
                            label={t(
                              'sections.clientsInformation.addCustomerDialog.fields.addressStateTwo.label'
                            )}
                            placeholder={t(
                              'sections.clientsInformation.addCustomerDialog.fields.addressStateTwo.placeholder'
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="addressZipCodeTwo"
                            label={t(
                              'sections.clientsInformation.addCustomerDialog.fields.addressZipCodeTwo.label'
                            )}
                            placeholder={t(
                              'sections.clientsInformation.addCustomerDialog.fields.addressZipCodeTwo.placeholder'
                            )}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <Button
                        color="secondary"
                        fullWidth
                        isLoading={isSubmittingCustomer}
                        disabled={!!customerDetails && !dirty}
                        isSubmit>
                        {t('sections.clientsInformation.addCustomerDialog.submitButton')}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={displayDeletedClientMessage}
        handleOnClose={() => setDisplayDeletedClientMessage(false)}>
        <CustomAlert severity="success" className={classes.alertMessage}>
          {t('sections.clientsInformation.successfulCustomerDeletion')}
        </CustomAlert>
      </Snackbar>
      <Snackbar open={displayEditedClientMessage} handleOnClose={() => setDisplayEditedClientMessage(false)}>
        <CustomAlert severity="success" className={classes.alertMessage}>
          {t('sections.clientsInformation.successfulCustomerEdition')}
        </CustomAlert>
      </Snackbar>
    </>
  );
};

export default FinancingStepCustomersList;
