export default function LargeBuildingsIcon() {
  return (
    <svg width="399" height="300" viewBox="0 0 432 335" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.6"
        d="M192.375 67.8813C191.375 71.6313 191 75.7563 191 80.3813V117.881H128.5V92.8813C128.5 79.1313 139.75 67.8813 153.5 67.8813H192.375Z"
        fill="url(#paint0_linear_1597_31924)"
      />
      <path
        d="M191 255.381V117.881H128.5C103.5 117.881 91 130.381 91 155.381V255.381C91 280.381 103.5 292.881 128.5 292.881H228.5C203.5 292.881 191 280.381 191 255.381ZM150.375 230.381C150.375 235.506 146.125 239.756 141 239.756C135.875 239.756 131.625 235.506 131.625 230.381V180.381C131.625 175.256 135.875 171.006 141 171.006C146.125 171.006 150.375 175.256 150.375 180.381V230.381Z"
        fill="url(#paint1_linear_1597_31924)"
      />
      <path
        opacity="0.4"
        d="M241 292.881H228.5C203.5 292.881 191 280.381 191 255.381V80.3813C191 55.3813 203.5 42.8813 228.5 42.8813H303.5C328.5 42.8813 341 55.3813 341 80.3813V255.381C341 280.381 328.5 292.881 303.5 292.881H291"
        fill="url(#paint2_linear_1597_31924)"
      />
      <path
        d="M291 242.881V292.881H241V242.881C241 236.006 246.625 230.381 253.5 230.381H278.5C285.375 230.381 291 236.006 291 242.881ZM241 189.756C235.875 189.756 231.625 185.506 231.625 180.381V117.881C231.625 112.756 235.875 108.506 241 108.506C246.125 108.506 250.375 112.756 250.375 117.881V180.381C250.375 185.506 246.125 189.756 241 189.756ZM291 189.756C285.875 189.756 281.625 185.506 281.625 180.381V117.881C281.625 112.756 285.875 108.506 291 108.506C296.125 108.506 300.375 112.756 300.375 117.881V180.381C300.375 185.506 296.125 189.756 291 189.756Z"
        fill="url(#paint3_linear_1597_31924)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1597_31924"
          x1="186.893"
          y1="89.6788"
          x2="147.908"
          y2="63.0825"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0E3354" />
          <stop offset="1" stopColor="#134178" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1597_31924"
          x1="216.7"
          y1="194.173"
          x2="112.131"
          y2="150.296"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0E3354" />
          <stop offset="1" stopColor="#134178" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1597_31924"
          x1="328.127"
          y1="151.869"
          x2="206.459"
          y2="112.884"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0E3354" />
          <stop offset="1" stopColor="#134178" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1597_31924"
          x1="294.475"
          y1="188.885"
          x2="235.331"
          y2="177.107"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0E3354" />
          <stop offset="1" stopColor="#134178" />
        </linearGradient>
      </defs>
    </svg>
  );
}
