import List from '@mui/material/List';
import {
  DocumentTextIcon,
  PasswordCheckIcon,
  BuildingsIcon,
  ArrangeHorizontalSquareIcon,
  SecuritySafeIcon,
  MessageTextIcon,
  HomeIcon,
  VerticalSlashIcon,
  ShipIcon,
} from '../../components/Icons';
import { useAppSelector } from '../../store';
import {
  BANKING,
  BANKING_CONNECTED_ACCOUNTS,
  BANKING_RECENT_TRANSACTIONS,
  BANKING_REPORTS,
  BANKING_TRANSACTIONS,
  BUYER_CREDIT_CHECK,
  BUYER_SUMMARY,
  PAYMENTS,
  CONTACT,
  CURRENCY_EXCHANGE,
  DASHBOARD,
  TRANSFERS,
  INSURANCES,
  INVOICES,
  INVOICE_FINANCING,
  LEGAL_ASSISTANT,
  LLC,
  POST_CC,
  REPORTING,
  TRACKING,
  LOGS,
  BANKING_RECIPIENTS,
  BANKING_TRANSFERS,
} from '../../constants/auth';
import AppMenuItem from './AppMenuItem';
import { SidebarElement } from '.';
import FolderIcon from '../../components/Icons/FolderIcon';
import BankIcon from '../../components/Icons/BankIcon';
import LogsIcon from '../../components/Icons/LogsIcon';
import { Company } from '../../types/company';

export const defaultLinks = (company?: Company): SidebarElement[] => {
  return [
    { name: 'dashboard', route: DASHBOARD, Icon: HomeIcon, trackingName: 'Home' },
    {
      name: 'application',
      route: INVOICE_FINANCING,
      Icon: DocumentTextIcon,
      trackingName: 'Invoice Financing',
      items: company?.productStatus.readyForFactoring
        ? [
            {
              name: 'invoices',
              route: INVOICES,
              Icon: VerticalSlashIcon,
              trackingName: 'Invoices',
            },
            {
              name: 'transfers',
              route: TRANSFERS,
              Icon: VerticalSlashIcon,
              trackingName: 'Transfers',
            },
            {
              name: 'payments',
              route: PAYMENTS,
              Icon: VerticalSlashIcon,
              trackingName: 'Payments',
            },
            {
              name: 'buyerSummary',
              route: BUYER_SUMMARY,
              Icon: VerticalSlashIcon,
              trackingName: 'Buyer Summary',
            },
            {
              name: 'reporting',
              route: REPORTING,
              Icon: VerticalSlashIcon,
              trackingName: 'Reporting',
            },
          ]
        : [],
    },
    {
      name: 'usLLC',
      route: LLC,
      Icon: BuildingsIcon,
      trackingName: 'LLC',
    },
    {
      name: 'banking',
      route: BANKING,
      Icon: BankIcon,
      trackingName: 'Banking',
      items: [
        ...(company?.productStatus.readyForBanking
          ? [
              {
                name: 'transactions',
                route: BANKING_TRANSACTIONS,
                Icon: VerticalSlashIcon,
                trackingName: 'Banking Transactions',
              },
              {
                name: 'transfers',
                route: BANKING_TRANSFERS,
                Icon: VerticalSlashIcon,
                trackingName: 'Banking Transfers',
              },
              {
                name: 'recipients',
                route: BANKING_RECIPIENTS,
                Icon: VerticalSlashIcon,
                trackingName: 'Banking Recipients',
              },
            ]
          : []),
      ],
    },
    {
      name: 'buyerCreditCheck',
      route: BUYER_CREDIT_CHECK,
      Icon: PasswordCheckIcon,
      trackingName: 'Buyer Check',
    },
    {
      name: 'fx',
      route: CURRENCY_EXCHANGE,
      Icon: ArrangeHorizontalSquareIcon,
      trackingName: 'FX',
    },
    {
      name: 'insurances',
      route: INSURANCES,
      Icon: SecuritySafeIcon,
      trackingName: 'Insurances',
    },
    {
      name: 'getHelp',
      route: CONTACT,
      Icon: MessageTextIcon,
      trackingName: 'Contact',
    },
  ];
};

const adminLinks: SidebarElement[] = [
  { name: 'dashboard', route: DASHBOARD, Icon: HomeIcon, trackingName: 'Home' },
  {
    name: 'marta',
    route: LEGAL_ASSISTANT,
    isAbsoluteRoute: true,
    trackingName: 'Legal assistant',
    Icon: PasswordCheckIcon,
  },
  {
    name: 'Credit Committee',
    route: POST_CC,
    trackingName: 'Post Credit Committee',
    Icon: DocumentTextIcon,
  },
  {
    name: 'bankingAdmin',
    route: BANKING_REPORTS,
    trackingName: 'Banking Connected Accounts',
    Icon: FolderIcon,
    items: [
      {
        name: 'Accounts',
        route: BANKING_CONNECTED_ACCOUNTS,
        trackingName: 'Connected Accounts',
        Icon: VerticalSlashIcon,
      },
      {
        name: 'Transactions',
        route: BANKING_RECENT_TRANSACTIONS,
        trackingName: 'Recent Transactions',
        Icon: VerticalSlashIcon,
      },
    ],
  },
  {
    name: 'tracking',
    route: TRACKING,
    trackingName: 'Tracking',
    Icon: ShipIcon,
  },
  {
    name: 'Logs',
    route: LOGS,
    trackingName: 'Logs',
    Icon: LogsIcon,
  },
];

type AppMenuProps = {
  isAdmin: boolean;
  handleOnClickItemCallback: () => void;
  isDrawerOpened?: boolean;
};

const AppMenu = ({ isAdmin, handleOnClickItemCallback, isDrawerOpened }: AppMenuProps) => {
  const company = useAppSelector((state) => state.client.newCompany);

  const getLinksForUserType = (isAdmin: boolean) => {
    return isAdmin ? adminLinks : defaultLinks(company);
  };

  return (
    <List>
      {getLinksForUserType(isAdmin).map((item) => {
        return (
          <AppMenuItem
            {...item}
            key={item.name}
            onClickItemCallback={handleOnClickItemCallback}
            isDrawerOpened={isDrawerOpened}
          />
        );
      })}
    </List>
  );
};

export default AppMenu;
