export default function LLCIcon() {
  return (
    <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.6"
        d="M42.4582 17.1984C42.1248 18.4484 41.9998 19.8234 41.9998 21.365V33.865H21.1665V25.5317C21.1665 20.9484 24.9165 17.1984 29.4998 17.1984H42.4582Z"
        fill="url(#paint0_linear_1115_6754)"
      />
      <path
        d="M41.9998 79.6984V33.8651H21.1665C12.8332 33.8651 8.6665 38.0318 8.6665 46.3651V79.6984C8.6665 88.0318 12.8332 92.1984 21.1665 92.1984H54.4998C46.1665 92.1984 41.9998 88.0318 41.9998 79.6984ZM28.4582 71.3651C28.4582 73.0734 27.0415 74.4901 25.3332 74.4901C23.6248 74.4901 22.2082 73.0734 22.2082 71.3651V54.6984C22.2082 52.9901 23.6248 51.5734 25.3332 51.5734C27.0415 51.5734 28.4582 52.9901 28.4582 54.6984V71.3651Z"
        fill="url(#paint1_linear_1115_6754)"
      />
      <path
        opacity="0.4"
        d="M58.6667 92.1984H54.5C46.1667 92.1984 42 88.0318 42 79.6984V21.3651C42 13.0318 46.1667 8.86511 54.5 8.86511H79.5C87.8333 8.86511 92 13.0318 92 21.3651V79.6984C92 88.0318 87.8333 92.1984 79.5 92.1984H75.3333"
        fill="url(#paint2_linear_1115_6754)"
      />
      <path
        d="M75.3332 75.5318V92.1984H58.6665V75.5318C58.6665 73.2401 60.5415 71.3651 62.8332 71.3651H71.1665C73.4582 71.3651 75.3332 73.2401 75.3332 75.5318ZM58.6665 57.8234C56.9582 57.8234 55.5415 56.4068 55.5415 54.6984V33.8651C55.5415 32.1568 56.9582 30.7401 58.6665 30.7401C60.3748 30.7401 61.7915 32.1568 61.7915 33.8651V54.6984C61.7915 56.4068 60.3748 57.8234 58.6665 57.8234ZM75.3332 57.8234C73.6248 57.8234 72.2082 56.4068 72.2082 54.6984V33.8651C72.2082 32.1568 73.6248 30.7401 75.3332 30.7401C77.0415 30.7401 78.4582 32.1568 78.4582 33.8651V54.6984C78.4582 56.4068 77.0415 57.8234 75.3332 57.8234Z"
        fill="url(#paint3_linear_1115_6754)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1115_6754"
          x1="19.3922"
          y1="36.9317"
          x2="45.7195"
          y2="27.5667"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E57D3" />
          <stop offset="0.520833" stopColor="#2D95CD" />
          <stop offset="0.520933" stopColor="#2D96CD" />
          <stop offset="0.521033" stopColor="#2D96CC" />
          <stop offset="1" stopColor="#2EC4B6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1115_6754"
          x1="4.84706"
          y1="102.932"
          x2="65.775"
          y2="89.6021"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E57D3" />
          <stop offset="0.520833" stopColor="#2D95CD" />
          <stop offset="0.520933" stopColor="#2D96CD" />
          <stop offset="0.521033" stopColor="#2D96CC" />
          <stop offset="1" stopColor="#2EC4B6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1115_6754"
          x1="37.8333"
          y1="107.532"
          x2="105.59"
          y2="96.2118"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E57D3" />
          <stop offset="0.520833" stopColor="#2D95CD" />
          <stop offset="0.520933" stopColor="#2D96CD" />
          <stop offset="0.521033" stopColor="#2D96CC" />
          <stop offset="1" stopColor="#2EC4B6" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1115_6754"
          x1="53.6318"
          y1="103.507"
          x2="85.2134"
          y2="100.228"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E57D3" />
          <stop offset="0.520833" stopColor="#2D95CD" />
          <stop offset="0.520933" stopColor="#2D96CD" />
          <stop offset="0.521033" stopColor="#2D96CC" />
          <stop offset="1" stopColor="#2EC4B6" />
        </linearGradient>
      </defs>
    </svg>
  );
}
