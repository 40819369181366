import MUITable from '@mui/material/Table';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Typography from '../Typography';
import { Nullable } from '../../types';
import { COLORS } from '../../themev2';
import { logEvent } from '@amplitude/analytics-browser';

export type ColumnField = {
  field?: string;
  headerName: string;
  width?: number;
  renderCell?: (params: any) => React.ReactElement;
  headerAlign?: TableCellProps['align'];
};

type PageNumPaginationSettings = {
  count: number;
  handlePageChange: (event: any, newPage: number) => void;
  page: number;
  rowsPerPage?: number;
  trackingEvent?: string;
};

type InfinitePaginationSettings = {
  firstPage?: number;
  hasMore: boolean;
  page: number;
  rowsPerPage?: number;
  handlePageChange: (event: any, newPage: number) => void;
  trackingEvent?: string;
};

type PaginationSettings = PageNumPaginationSettings | InfinitePaginationSettings;

export type CustomTableProps = {
  columns: ColumnField[];
  data: any[];
  paginationSettings?: Nullable<PaginationSettings>;
  isLoading?: boolean;
  onClickRow?: Nullable<(row: any) => void>;
  emptyMessageTitle?: string;
  emptyMessageSubtitle?: string;
  minWidth?: number;
};

const isPageNumPaginationSettings = (
  paginationSettings: PaginationSettings
): paginationSettings is PageNumPaginationSettings => {
  return (paginationSettings as PageNumPaginationSettings).count !== undefined;
};

const PaginationComponent = ({
  paginationSettings,
  isLoading,
}: {
  paginationSettings: PaginationSettings;
  isLoading: boolean;
}) => {
  const DEFAULT_ROWS_PER_PAGE = 10;
  if (isPageNumPaginationSettings(paginationSettings)) {
    const {
      count,
      handlePageChange,
      page,
      rowsPerPage = DEFAULT_ROWS_PER_PAGE,
      trackingEvent,
    } = paginationSettings;
    return (
      <TablePagination
        component="div"
        count={count}
        onPageChange={(e, newPage) => {
          !isLoading && handlePageChange(e, newPage);
          !!trackingEvent && logEvent(trackingEvent, { newPage });
        }}
        rowsPerPageOptions={[10]}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    );
  }

  const {
    rowsPerPage = DEFAULT_ROWS_PER_PAGE,
    page,
    handlePageChange,
    hasMore,
    firstPage = 0,
    trackingEvent,
  } = paginationSettings;

  return (
    <TablePagination
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: 0,
        '& .MuiToolbar-root': {
          padding: 0,
        },
      }}
      count={-1}
      component="div"
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(e, newPage) => {
        handlePageChange(e, newPage);
        !!trackingEvent && logEvent(trackingEvent, { newPage });
      }}
      rowsPerPageOptions={[10]}
      labelDisplayedRows={({ from, to, count }) => ``}
      ActionsComponent={({ onPageChange }) => (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: 0 }}>
          <Box
            sx={{
              height: '36px',
              width: '36px',
              border: `1px solid ${COLORS.gray[900]}`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
            }}>
            <ChevronLeftIcon
              onClick={
                page === firstPage
                  ? undefined
                  : () => {
                      onPageChange(null, page - 1);
                    }
              }
              sx={{
                color: page === firstPage ? COLORS.gray[1000] : COLORS.gray[1200],
                cursor: page === firstPage ? 'initial' : 'pointer',
              }}
            />
          </Box>
          <Box
            sx={{
              height: '36px',
              width: '36px',
              border: `1px solid ${COLORS.gray[900]}`,
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '-1px',
            }}>
            <ChevronRightIcon
              onClick={
                hasMore
                  ? () => {
                      onPageChange(null, page + 1);
                    }
                  : undefined
              }
              sx={{
                color: hasMore ? COLORS.gray[1200] : COLORS.gray[1000],
                cursor: hasMore ? 'pointer' : 'initial',
              }}
            />
          </Box>
        </Box>
      )}
    />
  );
};

const Table = ({
  columns = [],
  data = [],
  paginationSettings = null,
  isLoading = false,
  onClickRow = null,
  emptyMessageTitle = '',
  emptyMessageSubtitle = '',
  minWidth = 650,
}: CustomTableProps) => {
  return (
    <TableContainer component={Paper}>
      <MUITable sx={{ minWidth }} aria-label="table">
        <TableHead sx={{ backgroundColor: COLORS.gray[400] }}>
          <TableRow>
            {columns.map(({ field, headerName, width, headerAlign = 'left' }) => (
              <TableCell
                key={field || headerName}
                sx={{ color: COLORS.brand.night, fontWeight: 500, fontSize: '16px', lineHeight: '24px' }}
                align={headerAlign}
                width={width}>
                {headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={columns.length} align="center">
                <CircularProgress sx={{ color: COLORS.brand.night }} />
              </TableCell>
            </TableRow>
          ) : data.length > 0 ? (
            data.map((row: any) => (
              <TableRow
                key={row.id}
                onClick={() => {
                  onClickRow && onClickRow(row);
                }}
                sx={{
                  '&:hover': {
                    cursor: onClickRow ? 'pointer' : 'initial',
                    backgroundColor: COLORS.gray[300],
                    '& td:last-of-type': {
                      '& .MuiSvgIcon-root': {
                        visibility: 'visible',
                      },
                    },
                  },
                  '& td:last-of-type': {
                    '& .MuiSvgIcon-root': {
                      visibility: 'hidden',
                    },
                  },
                }}>
                {columns.map((column, columnIndex) => (
                  <TableCell
                    key={column.field || column.headerName}
                    align="left"
                    sx={{ borderBottom: 'none' }}>
                    {columnIndex === columns.length - 1 && onClickRow ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {column.renderCell ? (
                          column.renderCell(row)
                        ) : (
                          <Typography>{column?.field ? row[column.field] : '-'}</Typography>
                        )}
                        <ChevronRightIcon
                          sx={{
                            verticalAlign: 'bottom',
                            ml: 1,
                            color: COLORS.gray[1200],
                            borderRadius: '8px',
                          }}
                        />
                      </div>
                    ) : column.renderCell ? (
                      column.renderCell(row)
                    ) : (
                      <Typography>{column?.field ? row[column.field] : '-'}</Typography>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} sx={{ height: { xs: 'initial', md: '150px' } }}>
                {emptyMessageTitle && (
                  <Typography
                    textAlign="center"
                    variant="h6"
                    sx={{ color: COLORS.brand.night, fontWeight: 700, fontSize: '20px', lineHeight: '30px' }}>
                    {emptyMessageTitle}
                  </Typography>
                )}
                {emptyMessageSubtitle && (
                  <Typography textAlign="center" variant="h6">
                    {emptyMessageSubtitle}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MUITable>
      {!!paginationSettings && (
        <PaginationComponent paginationSettings={paginationSettings} isLoading={isLoading} />
      )}
    </TableContainer>
  );
};

export default Table;
