import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import * as service from '../services/application';
import { Nullable } from '../types';
import { APINotification } from '../types/client';

type Status = 'loading' | 'idle' | 'failed';

export type AlertsState = {
  alerts: APINotification[];
  status: Status;
  error: Nullable<string>;
};

const alertsInitialState: AlertsState = {
  alerts: [],
  error: null,
  status: 'idle',
};

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState: alertsInitialState,
  reducers: {
    resetAlertsSlice: () => alertsInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAlerts.fulfilled, (state, action) => {
        state.alerts = action.payload;
      })
      .addCase(readAlert.fulfilled, (state, action) => {
        state.alerts = state.alerts.filter((alert) => alert.id !== action.payload.id);
      })
      .addMatcher(isFulfilled(getAlerts, readAlert), (state) => {
        state.status = 'idle';
      })
      .addMatcher(isPending(getAlerts, readAlert), (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addMatcher(isRejected(getAlerts, readAlert), (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  },
});

export const getAlerts = createAsyncThunk('alerts/getAlerts', async () => {
  const alerts = await service.getAlerts();
  return alerts;
});

export const readAlert = createAsyncThunk('alerts/readAlert', async (alertId: number) => {
  const markedAsReadAlert = await service.readAlert(alertId);
  return markedAsReadAlert;
});

export const { resetAlertsSlice } = alertsSlice.actions;
