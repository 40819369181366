import { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Layout from '../../componentsv2/Layout';
import Typography from '../../componentsv2/Typography';
import Form from '../../componentsv2/Form';
import { TextField } from '../../componentsv2/Field';
import Button from '../../componentsv2/Button';
import CopyToClipboardButton from '../../componentsv2/CopyToClipboardButton';
import SimpleBackdrop from '../../components/Backdrop';
import { useSnackbar } from '../../context';
import { useAppSelector } from '../../store';
import { useLazyTranslation } from '../../hooks/i18n';
import { ContactFormValues, sendContactForm } from '../../services/contact';
import { getCompanyInformation } from '../../services/servicing';
import { APICompanyInformation } from '../../types/servicing';
import { Nullable } from '../../types';
import { COLORS } from '../../themev2';
import Uploader from '../../componentsv2/Uploader';

const AccountManagerSVG = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M12.1113 22.0156C17.6343 22.0156 22.1113 17.5386 22.1113 12.0156C22.1113 6.49263 17.6343 2.01562 12.1113 2.01562C6.58833 2.01562 2.11133 6.49263 2.11133 12.0156C2.11133 17.5386 6.58833 22.0156 12.1113 22.0156Z"
      fill="#FDFDFD"
    />
    <path
      d="M10.8513 16.2958C10.6613 16.2958 10.4713 16.2258 10.3213 16.0758C10.1819 15.9346 10.1036 15.7442 10.1036 15.5458C10.1036 15.3474 10.1819 15.1569 10.3213 15.0158L13.3213 12.0158L10.3213 9.01578C10.1819 8.87464 10.1036 8.68421 10.1036 8.48578C10.1036 8.28735 10.1819 8.09692 10.3213 7.95578C10.6113 7.66578 11.0913 7.66578 11.3813 7.95578L14.9113 11.4858C15.2013 11.7758 15.2013 12.2558 14.9113 12.5458L11.3813 16.0758C11.2313 16.2258 11.0413 16.2958 10.8513 16.2958Z"
      fill="#FDFDFD"
    />
  </svg>
);

const ContactPage = () => {
  const { t, isLoading: isLoadingTranslations } = useLazyTranslation(['client', 'common']);
  const { client, newCompany } = useAppSelector((state) => state.client);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [files, setFiles] = useState<File[]>([]);
  const [companyInfo, setCompanyInfo] = useState<Nullable<APICompanyInformation>>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (newCompany?.id) {
      getCompanyInformation(newCompany.id).then((info) => {
        setCompanyInfo(info);
      });
    }
  }, [newCompany?.id]);

  if (isLoadingTranslations || !client || !newCompany) {
    return <SimpleBackdrop isLoading />;
  }
  return (
    <Layout>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">{t('client:contact.title')}</Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box sx={{ borderRadius: '8px', backgroundColor: '#FBFCFE', padding: '2em 3em' }}>
            <Formik
              validationSchema={Yup.object().shape({
                name: Yup.string().required(t('common:requiredField')),
                email: Yup.string().required(t('common:requiredField')),
                subject: Yup.string().required(t('common:requiredField')),
                description: Yup.string().required(t('common:requiredField')),
              })}
              initialValues={{
                name: client.name || '',
                email: client.email || '',
                subject: '',
                description: '',
              }}
              onSubmit={(values: ContactFormValues, { resetForm }) => {
                setIsSubmitting(true);
                sendContactForm(newCompany.id, values, files)
                  .then(() => {
                    showSnackbar(t('client:contact.form.success'), 'success');
                    resetForm();
                    setFiles([]);
                    fileInputRef.current!.value = '';
                  })
                  .finally(() => {
                    setIsSubmitting(false);
                  });
              }}>
              <Form>
                <Grid container rowSpacing={1}>
                  <Grid item xs={12}>
                    <TextField name="name" label={t('client:contact.form.name.label')} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField name="email" label={t('client:contact.form.email.label')} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField name="subject" label={t('client:contact.form.subject.label')} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      label={t('client:contact.form.description.label')}
                      multiline
                      minRows={4}
                      sx={{
                        '& .MuiInputBase-multiline': {
                          padding: '0px', // only god knows why this had unwanted padding
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ marginBottom: '0.5em', paddingTop: '8px' }}>
                      {t('client:contact.form.files.label')}
                    </Typography>
                    <Uploader
                      files={files}
                      isUploading={false}
                      deleteFile={(name: string) => {
                        setFiles((prev) => prev.filter((file) => file.name !== name));
                      }}
                      onDrop={(files) => {
                        setFiles((prev) => [...prev, ...files]);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: '1em' }}>
                    <Button isSubmit fullWidth isLoading={isSubmitting}>
                      {t('client:contact.form.submit')}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </Box>
        </Grid>
        {companyInfo && (
          <Grid item xs={12} md={5}>
            <Box sx={{ borderRadius: '8px', backgroundColor: '#4F5A84', padding: '2em 3em' }}>
              <Grid container rowSpacing={1}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5em' }}>
                  <AccountManagerSVG />
                  <Typography sx={{ lineHeight: '27px', fontSize: '18px', color: COLORS.indigo[100] }}>
                    {t('client:contact.accountManager')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{ fontWeight: 700, lineHeight: '32px', fontSize: '24px', color: COLORS.brand.paper }}>
                    {companyInfo?.accountManagerFullName || '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ color: COLORS.brand.paper, lineHeight: '27px', fontSize: '18px' }}>
                      ops@marcofi.com
                    </Typography>
                    <CopyToClipboardButton
                      textToCopy="ops@marcofi.com"
                      customSX={{ color: COLORS.brand.paper }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
};

export default ContactPage;
