export default function MiniInboxIcon() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M16.7234 4H8.34345C4.70345 4 2.53345 6.17 2.53345 9.81V16.18C2.53345 19.83 4.70345 22 8.34345 22H16.7134C20.3534 22 22.5234 19.83 22.5234 16.19V9.81C22.5334 6.17 20.3634 4 16.7234 4Z"
        fill="currentColor"
      />
      <path
        d="M21.8334 12.23H18.3534C17.3734 12.23 16.5034 12.77 16.0634 13.65L15.2234 15.31C15.0234 15.71 14.6234 15.96 14.1834 15.96H10.9034C10.5934 15.96 10.1534 15.89 9.86345 15.31L9.02345 13.66C8.81084 13.2343 8.48412 12.8761 8.07974 12.6254C7.67537 12.3746 7.20926 12.2412 6.73345 12.24H3.23345C2.84345 12.24 2.53345 12.55 2.53345 12.94V16.2C2.53345 19.83 4.71345 22 8.35345 22H16.7334C20.1634 22 22.2734 20.12 22.5334 16.78V12.93C22.5334 12.55 22.2234 12.23 21.8334 12.23ZM15.0644 6.47C14.9233 6.33052 14.7329 6.2523 14.5344 6.2523C14.336 6.2523 14.1456 6.33052 14.0044 6.47L13.2844 7.19V2C13.2844 1.59 12.9444 1.25 12.5344 1.25C12.1244 1.25 11.7844 1.59 11.7844 2V7.19L11.0644 6.47C10.9233 6.33052 10.7329 6.2523 10.5344 6.2523C10.336 6.2523 10.1456 6.33052 10.0044 6.47C9.71445 6.76 9.71445 7.24 10.0044 7.53L12.0044 9.53C12.0144 9.54 12.0244 9.54 12.0244 9.55C12.0844 9.61 12.1644 9.66 12.2444 9.7C12.3444 9.73 12.4344 9.75 12.5344 9.75C12.6344 9.75 12.7244 9.73 12.8144 9.69C12.9044 9.65 12.9844 9.6 13.0644 9.53L15.0644 7.53C15.3544 7.24 15.3544 6.76 15.0644 6.47Z"
        fill="currentColor"
      />
    </svg>
  );
}
