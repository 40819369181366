import { useEffect, useState } from 'react';
import { logEvent } from '@amplitude/analytics-browser';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '../../componentsv2/Chip';
import Divider from '@mui/material/Divider';
import { getLLC, setSignableDocumentInProcess, submitLLC } from '../../services/llc';
import { useTranslation } from '../../hooks/i18n';
import ModalV2 from '../../components/ModalV2';
import Layout from '../../componentsv2/Layout';
import Button from '../../componentsv2/Button';
import Typography from '../../componentsv2/Typography';
import PageHeader from '../../componentsv2/PageHeader';
import Detail from '../../componentsv2/Detail';
import { COLORS } from '../../themev2';
import { LLCEvents } from '../../constants/events';
import classes from './USLLC.module.scss';
import { Nullable } from '../../types';
import StatusTracker from '../../componentsv2/StatusTrackerv2';
import {
  ArrowRightIcon,
  MessageSentIcon,
  TickCircleIcon,
  MiniNotepadIcon,
  MiniInboxIcon,
  BuildingsIcon,
  StarIcon,
} from '../../components/Icons';
import { baseURL } from '../../constants/api';
import CopyToClipboardButton from '../../componentsv2/CopyToClipboardButton';
import { useAppSelector } from '../../store';
import SimpleBackdrop from '../../components/Backdrop';
import { useUserLocation } from '../../hooks/useUserLocation';
import { APILLC, LLCDocument } from '../../types/llc';
import { useMediaQuery, useTheme } from '@mui/material';

const steps = [
  {
    key: 'formationOfLLC',
    step: 1,
  },
  {
    key: 'readyToOperate',
    step: 2,
  },
  {
    key: 'apply',
    step: 3,
  },
];

const reasonsToApply = [
  { key: 0, title: 'boost' },
  { key: 1, title: 'attract' },
  { key: 2, title: 'access' },
];

const statusColorsMap = {
  PENDING: { backgroundColor: '#FCF0DB', color: '#AC8318' },
  SIGNED: { backgroundColor: '#E1FAED', color: '#11705F' },
  IN_PROCESS: { backgroundColor: COLORS.gray[500], color: COLORS.gray[1200] },
};

function extractDocumentByType(
  documents: LLCDocument[],
  documentType: LLCDocument['documentType']
): LLCDocument | undefined {
  let extracted;

  const index = documents.findIndex((document) => document.documentType === documentType);
  if (index) {
    extracted = documents.splice(index, 1)[0];
  }
  return extracted;
}

function orderDocuments(documents: LLCDocument[]): LLCDocument[] {
  let result = [...documents];
  const einDocument = extractDocumentByType(result, 'EIN_DOCUMENT');
  const w9form = extractDocumentByType(result, 'FORM_W9');
  const _8821form = extractDocumentByType(result, 'FORM_8821');
  return [
    ...(einDocument ? [einDocument] : []),
    ...result,
    ...(_8821form ? [_8821form] : []),
    ...(w9form ? [w9form] : []),
  ];
}

const USLLC = () => {
  const { t } = useTranslation(['usLLC']);
  const { language } = useUserLocation();
  const client = useAppSelector((state) => state.client);
  const companyId = client.client?.companies[0].id;
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmittingLLC, setIsSubmittingLLC] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showEinCongratsModal, setShowEinCongratsModal] = useState(false);
  const [llc, setLlc] = useState<Nullable<APILLC>>(null);
  const isEinReady = !!llc?.taxId && llc.status === 'REGISTRATION';
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (!companyId) return;
    getLLC(companyId)
      .then((result) => {
        if (result.length > 0) {
          setLlc(result[0]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [companyId]);

  useEffect(() => {
    logEvent(LLCEvents.VIEW_PAGE, { Status: llc?.status });
  }, [llc?.status]);

  useEffect(() => {
    if (isEinReady && !localStorage.getItem('einSuccessDismissed')) {
      setShowEinCongratsModal(true);
    }
  }, [isEinReady]);

  const handleSubmitForLLC = async () => {
    setIsSubmittingLLC(true);
    await submitLLC(language, companyId!)
      .then((result) => {
        logEvent(LLCEvents.SUBMIT_REQUEST);
        setShowConfirmationModal(true);
        setLlc(result);
      })
      .finally(() => setIsSubmittingLLC(false));
  };

  const closeCongratsModal = () => {
    setShowEinCongratsModal(false);
    localStorage.setItem('einSuccessDismissed', 'true');
  };

  if (isLoading) return <SimpleBackdrop hasOpaqueBackground isLoading />;

  return (
    <Layout>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <PageHeader title={t('title')} subtitle={t('subtitle')} />
        </Grid>
        {!llc ? (
          <>
            <Grid item xs={12} lg={7}>
              <Box className={classes.boxWrapper}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <Typography fontWeight={600}>{t('howDoesItWork.description')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" fontWeight={700}>
                      {t('howDoesItWork.title')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      {steps.map(({ step, key }) => {
                        return (
                          <Grid item xs={12} key={step}>
                            <Grid container alignItems="center" rowSpacing={1}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: { xs: '18px' },
                                }}>
                                <Chip
                                  label={step}
                                  sx={{ backgroundColor: '#18A19A', color: COLORS.brand.white }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    color: COLORS.brand.night,
                                    fontWeight: 600,
                                    lineHeight: '24px',
                                  }}>
                                  {t(`howDoesItWork.${key}.title`)}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sx={{ marginLeft: { xs: '50px' } }}>
                                <Typography
                                  sx={{
                                    color: COLORS.gray[1200],
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    lineHeight: '21px',
                                  }}>
                                  {t(`howDoesItWork.${key}.description`)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={handleSubmitForLLC}
                      isLoading={isSubmittingLLC}
                      endIcon={<ArrowRightIcon />}
                      fullWidth>
                      {t('howDoesItWork.button')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Box className={classes.whyOpenAnLLC}>
                <Grid container>
                  <Grid item xs={12}>
                    <svg
                      width="100"
                      height="101"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        opacity="0.6"
                        d="M42.125 17.1282C41.7917 18.3782 41.6667 19.7532 41.6667 21.2948V33.7948H20.8333V25.4615C20.8333 20.8782 24.5833 17.1282 29.1667 17.1282H42.125Z"
                        fill="#F5F9FF"
                      />
                      <path
                        d="M41.6667 79.628V33.7947H20.8333C12.5 33.7947 8.33334 37.9613 8.33334 46.2947V79.628C8.33334 87.9613 12.5 92.128 20.8333 92.128H54.1667C45.8333 92.128 41.6667 87.9613 41.6667 79.628ZM28.125 71.2947C28.125 73.003 26.7083 74.4197 25 74.4197C23.2917 74.4197 21.875 73.003 21.875 71.2947V54.628C21.875 52.9197 23.2917 51.503 25 51.503C26.7083 51.503 28.125 52.9197 28.125 54.628V71.2947Z"
                        fill="#F5F9FF"
                      />
                      <path
                        opacity="0.4"
                        d="M58.3333 92.128H54.1667C45.8333 92.128 41.6667 87.9613 41.6667 79.628V21.2947C41.6667 12.9613 45.8333 8.79468 54.1667 8.79468H79.1667C87.5 8.79468 91.6667 12.9613 91.6667 21.2947V79.628C91.6667 87.9613 87.5 92.128 79.1667 92.128H75"
                        fill="#F5F9FF"
                      />
                      <path
                        d="M75 75.4613V92.128H58.3333V75.4613C58.3333 73.1697 60.2083 71.2947 62.5 71.2947H70.8333C73.125 71.2947 75 73.1697 75 75.4613ZM58.3333 57.753C56.625 57.753 55.2083 56.3363 55.2083 54.628V33.7947C55.2083 32.0863 56.625 30.6697 58.3333 30.6697C60.0417 30.6697 61.4583 32.0863 61.4583 33.7947V54.628C61.4583 56.3363 60.0417 57.753 58.3333 57.753ZM75 57.753C73.2917 57.753 71.875 56.3363 71.875 54.628V33.7947C71.875 32.0863 73.2917 30.6697 75 30.6697C76.7083 30.6697 78.125 32.0863 78.125 33.7947V54.628C78.125 56.3363 76.7083 57.753 75 57.753Z"
                        fill="#F5F9FF"
                      />
                    </svg>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ color: COLORS.brand.white, fontWeight: '700' }}>
                      {t('infoBox.title')}
                    </Typography>
                  </Grid>
                  {reasonsToApply.map(({ key, title }) => (
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: '20px' }} key={key}>
                      <Box>
                        <TickCircleIcon />
                      </Box>
                      <Typography variant="body1" sx={{ color: COLORS.brand.paper }}>
                        {t(`infoBox.reasons.${title}`)}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Box className={classes.boxWrapper}>
                <Grid container>
                  {!isEinReady && (
                    <>
                      <Grid item xs={12}>
                        <StatusTracker
                          statuses={[
                            {
                              label: t('llcRequest.status.REQUEST.label'),
                              value: 'REQUEST',
                              icon: <MiniInboxIcon />,
                            },
                            {
                              label: t('llcRequest.status.DOCUMENTS.label'),
                              value: 'DOCUMENTS',
                              icon: <MiniNotepadIcon isCurrentColor />,
                            },
                            {
                              label: t('llcRequest.status.REGISTRATION.label'),
                              value: 'REGISTRATION',
                              icon: <BuildingsIcon />,
                            },
                          ]}
                          currentStatus={llc.status}
                          isComplete={isEinReady}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <Typography fontWeight={600} sx={{ color: COLORS.cian[800] }}>
                      {t(`llcRequest.status.${llc.status}.label`)}
                    </Typography>
                    {isEinReady ? (
                      <>
                        <Typography variant="h6" fontWeight={700}>
                          {t(`llcRequest.ein.title`)}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: '4px',
                            alignItems: { xs: 'initial', sm: 'center' },
                            flexDirection: { xs: 'column', sm: 'row' },
                          }}>
                          <Typography fontWeight={600} sx={{ color: COLORS.gray[1200] }}>
                            {llc?.taxIdType}:
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                            <Typography style={{ color: COLORS.indigo[500] }}>{llc.taxId}</Typography>
                            <CopyToClipboardButton
                              textToCopy={llc.taxId!}
                              onClick={() => {
                                logEvent(LLCEvents.CLICK_COPY_EIN);
                              }}
                            />
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Typography variant="h6" fontWeight={700}>
                          {t(`llcRequest.status.${llc.status}.title`)}
                        </Typography>
                        <Typography fontWeight={600} sx={{ color: COLORS.gray[1200] }}>
                          {t(`llcRequest.status.${llc.status}.description`)}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {llc.companyName && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    background: COLORS.gray[700],
                    borderRadius: '12px',
                    backgroundColor: '#FFFFFF',
                    padding: '1em 2em',
                  }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: '18px',
                          lineHeight: '27px',
                          color: COLORS.brand.night,
                        }}>
                        {llc.companyName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ borderColor: '#DEE9FA' }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={6} md={3}>
                          <Detail
                            value={llc.status ? t(`llcRequest.status.${llc.status}.label`) : '-'}
                            title={t('details.status')}
                          />
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Detail
                            value={llc.companyFormationDate || '-'}
                            title={t('details.companyFormationDate')}
                            isCopyable
                          />
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Detail
                            value={`${
                              llc.addressStreet
                                ? `${llc?.addressStreet}, ${llc?.addressCity}, ${llc?.addressState}`
                                : '-'
                            }`}
                            title={t('details.address')}
                            isCopyable
                          />
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Detail value={llc.taxId || '-'} title={t('details.taxIdNumber')} isCopyable />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
            {orderDocuments(llc.documents).map((document) =>
              // only show document with uploaded assets or signable ones
              document.assets.length > 0 || !!document.signLink ? (
                <Grid item xs={12}>
                  <Box className={classes.boxWrapper}>
                    <Grid container justifyContent="center" alignItems="center" gap="1em" sx={{ flex: 1 }}>
                      {document.isSignable && (
                        <Grid item xs={12} md={1}>
                          <Typography>
                            <Chip
                              sx={{ ...statusColorsMap[document.status] }}
                              label={t(`llcRequest.documentStatus.${document.status}`)}
                            />
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={12} md>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                            paddingLeft: isMdDown ? 0 : '2.5em',
                          }}>
                          <Typography sx={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                            {t(`llcRequest.documents.${document.documentType}.title`)}
                          </Typography>
                          <Typography>
                            {t(`llcRequest.documents.${document.documentType}.description`)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={isMdDown ? 12 : undefined}>
                        {document.isSignable && document.status !== 'SIGNED' ? (
                          <a href={document.signLink || '#'} target="_blank" rel="noopener noreferrer">
                            <Button
                              onClick={() => {
                                setSignableDocumentInProcess(companyId!, llc.id, document.id).then(
                                  (result) => {
                                    logEvent(LLCEvents.CLICK_SIGN_DOCUMENT, {
                                      Document: document.documentType,
                                    });
                                    setLlc((prev) => ({
                                      ...prev!,
                                      documents: prev!.documents.map((doc) =>
                                        doc.id === result.id ? result : doc
                                      ),
                                    }));
                                  }
                                );
                              }}>
                              {t('llcRequest.buttons.sign')}
                            </Button>
                          </a>
                        ) : (
                          <a
                            href={
                              document?.assets[0]?.uuid
                                ? `${baseURL}/assets/${document?.assets[0]?.uuid}`
                                : '#'
                            }
                            target="_blank"
                            rel="noopener noreferrer">
                            <Button
                              type="outline"
                              onClick={() => {
                                logEvent(LLCEvents.VIEW_DOCUMENT, { Document: document.documentType });
                              }}>
                              {t('llcRequest.buttons.view')}
                            </Button>
                          </a>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ) : null
            )}
            {llc.genericDocuments.map(({ titleEn, titleEs, descriptionEn, descriptionEs, assets }) => {
              return (
                <Grid item xs={12}>
                  <Box className={classes.boxWrapper}>
                    <Grid container justifyContent="center" alignItems="center" gap="1em" sx={{ flex: 1 }}>
                      <Grid item xs={12} md>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                            paddingLeft: isMdDown ? 0 : '2.5em',
                          }}>
                          <Typography sx={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                            {language === 'es' ? titleEs : titleEn}
                          </Typography>
                          <Typography>{language === 'es' ? descriptionEs : descriptionEn}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={isMdDown ? 12 : undefined}>
                        <a
                          href={assets[0]?.uuid ? `${baseURL}/assets/${assets[0]?.uuid}` : '#'}
                          target="_blank"
                          rel="noopener noreferrer">
                          <Button type="outline" onClick={() => {}}>
                            {t('llcRequest.buttons.view')}
                          </Button>
                        </a>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              );
            })}
          </>
        )}
      </Grid>
      {showConfirmationModal && (
        <ModalV2
          isOpen={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
          isAutoHeight>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <MessageSentIcon />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" textAlign="center" fontWeight={700}>
                {t('confirmationModal.thanks')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                textAlign="center"
                sx={{ color: COLORS.gray[1100], paddingBottom: '1em' }}>
                {t('confirmationModal.message')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth onClick={() => setShowConfirmationModal(false)} color="secondary">
                {t('confirmationModal.confirmButtonTitle')}
              </Button>
            </Grid>
          </Grid>
        </ModalV2>
      )}
      {showEinCongratsModal && (
        <ModalV2
          isOpen={showEinCongratsModal}
          handleClose={() => closeCongratsModal()}
          sx={{ paddingX: '4em', minHeight: '450px' }}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <StarIcon />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                textAlign="center"
                sx={{
                  fontSize: '32px',
                  background:
                    'linear-gradient(74.44deg, #0BAAA1 -10.53%, #2D95CD 46.22%, #2D96CD 46.23%, #2D96CC 46.24%, #2EC4B6 98.43%)',
                  backgroundClip: 'text',
                  '-webkit-background-clip': 'text',
                  color: 'transparent',
                }}>
                {t('llcRequest.ein.modal.title')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ whiteSpace: 'pre-wrap', fontSize: '20px' }}
                fontWeight={700}
                textAlign="center">
                {t('llcRequest.ein.modal.subtitle')}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: '2em' }}>
              <Typography textAlign="center" sx={{ color: COLORS.gray[1100] }}>
                {t('llcRequest.ein.modal.description')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth onClick={() => closeCongratsModal()} color="secondary">
                {t('llcRequest.ein.modal.button')}
              </Button>
            </Grid>
          </Grid>
        </ModalV2>
      )}
    </Layout>
  );
};

export default USLLC;
