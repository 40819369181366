export default function MessageLikedIcon() {
  return (
    <svg width="89" height="88" viewBox="0 0 89 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M79.7611 0.166748H67.5111C62.2194 0.166748 59.0527 3.33341 59.0527 8.62508V20.8751C59.0527 26.1667 62.2194 29.3334 67.5111 29.3334H79.7611C85.0527 29.3334 88.2194 26.1667 88.2194 20.8751V8.62508C88.2194 3.33341 85.0527 0.166748 79.7611 0.166748ZM82.4277 15.5001C80.8861 20.4167 75.5111 23.0834 73.6361 23.0834C71.7611 23.0834 66.4277 20.5001 64.8444 15.5001C64.5944 14.7501 64.4694 13.9167 64.4694 13.1251C64.4694 10.4167 65.8444 7.62508 68.7194 6.70841C70.4277 6.16675 72.2611 6.45841 73.5527 7.54175C74.8861 6.50008 76.7194 6.16675 78.4694 6.70841C82.3027 7.91675 83.5111 12.2084 82.4277 15.5001Z"
        fill="url(#paint0_linear_290_20791)"
      />
      <path
        opacity="0.4"
        d="M79.7624 29.3333H67.5124C62.2207 29.3333 59.054 26.1666 59.054 20.8749V8.62492C59.054 6.95825 59.3874 5.54159 59.9707 4.33325H21.554C10.054 4.33325 0.720703 13.6249 0.720703 25.0833V49.9999V54.1666C0.720703 65.6249 10.054 74.9166 21.554 74.9166H27.804C28.929 74.9166 30.429 75.6666 31.1374 76.5832L37.3874 84.8749C40.1374 88.5416 44.6374 88.5416 47.3874 84.8749L53.6374 76.5832C54.429 75.5416 55.679 74.9166 56.9707 74.9166H63.2207C74.7207 74.9166 84.054 65.6249 84.054 54.1666V28.3749C82.8457 28.9999 81.429 29.3333 79.7624 29.3333Z"
        fill="url(#paint1_linear_290_20791)"
      />
      <path
        d="M42.3874 46.0001C40.054 46.0001 38.2207 44.1251 38.2207 41.8334C38.2207 39.5417 40.0957 37.6667 42.3874 37.6667C44.679 37.6667 46.554 39.5417 46.554 41.8334C46.554 44.1251 44.7207 46.0001 42.3874 46.0001Z"
        fill="url(#paint2_linear_290_20791)"
      />
      <path
        d="M59.0534 46.0001C56.7201 46.0001 54.8867 44.1251 54.8867 41.8334C54.8867 39.5417 56.7617 37.6667 59.0534 37.6667C61.3451 37.6667 63.2201 39.5417 63.2201 41.8334C63.2201 44.1251 61.3867 46.0001 59.0534 46.0001Z"
        fill="url(#paint3_linear_290_20791)"
      />
      <path
        d="M25.7194 46.0001C23.3861 46.0001 21.5527 44.1251 21.5527 41.8334C21.5527 39.5417 23.4277 37.6667 25.7194 37.6667C28.0111 37.6667 29.8861 39.5417 29.8861 41.8334C29.8861 44.1251 28.0527 46.0001 25.7194 46.0001Z"
        fill="url(#paint4_linear_290_20791)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_290_20791"
          x1="85.3067"
          y1="14.7499"
          x2="62.3748"
          y2="13.7875"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BAAA1" />
          <stop offset="1" stopColor="#0B8DAA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_290_20791"
          x1="75.732"
          y1="45.9786"
          x2="10.2126"
          y2="43.2275"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BAAA1" />
          <stop offset="1" stopColor="#0B8DAA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_290_20791"
          x1="45.7218"
          y1="41.8334"
          x2="39.1699"
          y2="41.5584"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BAAA1" />
          <stop offset="1" stopColor="#0B8DAA" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_290_20791"
          x1="62.3879"
          y1="41.8334"
          x2="55.8359"
          y2="41.5584"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BAAA1" />
          <stop offset="1" stopColor="#0B8DAA" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_290_20791"
          x1="29.0539"
          y1="41.8334"
          x2="22.5019"
          y2="41.5584"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BAAA1" />
          <stop offset="1" stopColor="#0B8DAA" />
        </linearGradient>
      </defs>
    </svg>
  );
}
