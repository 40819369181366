export default function MiniMoneyReceiveIcon() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.8115 6.24998H20.1715C19.9815 5.97998 19.7815 5.72998 19.5715 5.47998L18.8115 6.24998Z" />
      <path d="M18.522 4.42007C18.272 4.21007 18.022 4.01007 17.752 3.82007V5.18007L18.522 4.42007Z" />
      <path d="M19.5815 5.47994L22.5315 2.52994C22.8215 2.23994 22.8215 1.75994 22.5315 1.46994C22.2415 1.17994 21.7615 1.17994 21.4715 1.46994L18.5215 4.41994C18.9015 4.74994 19.2515 5.10994 19.5815 5.47994Z" />
      <path d="M17.7517 3C17.7517 2.59 17.4117 2.25 17.0017 2.25C16.6017 2.25 16.2817 2.57 16.2617 2.96C16.7817 3.21 17.2817 3.49 17.7517 3.82V3Z" />
      <path d="M21.7519 7C21.7519 6.59 21.4119 6.25 21.0019 6.25H20.1719C20.5019 6.72 20.7919 7.22 21.0319 7.74C21.4319 7.72 21.7519 7.4 21.7519 7Z" />
      <path d="M12.752 14.75H13.052C13.442 14.75 13.752 14.4 13.752 13.97C13.752 13.43 13.602 13.35 13.262 13.23L12.752 13.05V14.75Z" />
      <path d="M21.042 7.74C21.032 7.74 21.022 7.75 21.002 7.75H17.002C16.902 7.75 16.812 7.73 16.712 7.69C16.532 7.61 16.382 7.47 16.302 7.28C16.272 7.19 16.252 7.1 16.252 7V3C16.252 2.99 16.262 2.98 16.262 2.96C14.962 2.35 13.522 2 12.002 2C6.48195 2 2.00195 6.48 2.00195 12C2.00195 17.52 6.48195 22 12.002 22C17.522 22 22.002 17.52 22.002 12C22.002 10.48 21.652 9.04 21.042 7.74ZM13.752 11.82C14.392 12.04 15.252 12.51 15.252 13.98C15.252 15.23 14.262 16.26 13.052 16.26H12.752V16.51C12.752 16.92 12.412 17.26 12.002 17.26C11.592 17.26 11.252 16.92 11.252 16.51V16.26H11.172C9.84195 16.26 8.75195 15.14 8.75195 13.76C8.75195 13.34 9.09195 13 9.50195 13C9.91195 13 10.252 13.34 10.252 13.75C10.252 14.3 10.662 14.75 11.172 14.75H11.252V12.53L10.252 12.18C9.61195 11.96 8.75195 11.49 8.75195 10.02C8.75195 8.77 9.74195 7.74 10.952 7.74H11.252V7.5C11.252 7.09 11.592 6.75 12.002 6.75C12.412 6.75 12.752 7.09 12.752 7.5V7.75H12.832C14.162 7.75 15.252 8.87 15.252 10.25C15.252 10.66 14.912 11 14.502 11C14.092 11 13.752 10.66 13.752 10.25C13.752 9.7 13.342 9.25 12.832 9.25H12.752V11.47L13.752 11.82Z" />
      <path d="M10.252 10.03C10.252 10.57 10.402 10.65 10.742 10.77L11.252 10.95V9.25H10.952C10.572 9.25 10.252 9.6 10.252 10.03Z" />
    </svg>
  );
}
