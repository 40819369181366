import { COLORS } from '../../themev2';

export default function DocumentTextIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M20.1152 10.4351H17.3609C15.1022 10.4351 13.2629 8.50512 13.2629 6.13512V3.24512C13.2629 2.69512 12.834 2.24512 12.3098 2.24512H8.26898C5.33363 2.24512 2.96057 4.24512 2.96057 7.81512V16.6751C2.96057 20.2451 5.33363 22.2451 8.26898 22.2451H15.7598C18.6952 22.2451 21.0682 20.2451 21.0682 16.6751V11.4351C21.0682 10.8851 20.6394 10.4351 20.1152 10.4351Z"
      />
      <path d="M16.0211 2.48013C15.6111 2.07013 14.9011 2.35013 14.9011 2.92013V6.41013C14.9011 7.87013 15.9401 9.03994 17.4501 9.03994C18.4001 9.04994 19.7201 9.04994 20.8501 9.04994C21.4201 9.04994 21.7201 8.37994 21.3201 7.97994C19.8801 6.52994 17.5011 3.96013 16.0211 2.48013ZM13.5201 13.9799H7.52008C7.11008 13.9799 6.77008 13.6399 6.77008 13.2299C6.77008 12.8199 7.11008 12.4799 7.52008 12.4799H13.5201C13.9301 12.4799 14.2701 12.8199 14.2701 13.2299C14.2701 13.6399 13.9301 13.9799 13.5201 13.9799ZM11.5201 17.9799H7.52008C7.11008 17.9799 6.77008 17.6399 6.77008 17.2299C6.77008 16.8199 7.11008 16.4799 7.52008 16.4799H11.5201C11.9301 16.4799 12.2701 16.8199 12.2701 17.2299C12.2701 17.6399 11.9301 17.9799 11.5201 17.9799Z" />
    </svg>
  );
}
