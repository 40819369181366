import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '../../componentsv2/Typography';
import Section from '../../componentsv2/Section';
import Detail from '../../componentsv2/Detail';
import Shareholders from '../ClientDocuments/Shareholders';
import { getCompanyLegalRepresentatives, getCompanyShareholders } from '../../services/documents';
import { APICompany } from '../../types/client';
import { APILegalRepresentative, APIShareholder } from '../../types/documents';
import LegalRepresentatives from '../ClientDocuments/LegalRepresentatives';
import { formatDate } from '../../utils/common';
import { nullDate } from '../../constants/servicing';

const CompanyTab = ({ company, t }: { company: APICompany; t: TFunction }) => {
  const [shareholders, setShareholders] = useState<APIShareholder[]>([]);
  const [legalRepresentatives, setLegalRepresentatives] = useState<APILegalRepresentative[]>([]);
  const [isLoadingShareholders, setIsLoadingShareholders] = useState(false);
  const [isLoadingLegalRepresentatives, setIsLoadingLegalRepresentatives] = useState(false);

  const loadShareholders = (companyId: number) => {
    setIsLoadingShareholders(true);
    return getCompanyShareholders(companyId)
      .then((shareholders) => {
        setShareholders(shareholders);
      })
      .finally(() => {
        setIsLoadingShareholders(false);
      });
  };

  const loadLegalRepresentatives = (companyId: number) => {
    setIsLoadingLegalRepresentatives(true);
    return getCompanyLegalRepresentatives(companyId)
      .then((legalRepresentatives) => {
        setLegalRepresentatives(legalRepresentatives);
      })
      .finally(() => {
        setIsLoadingLegalRepresentatives(false);
      });
  };

  useEffect(() => {
    if (company.id) {
      loadShareholders(company.id);
      loadLegalRepresentatives(company.id);
    }
  }, [company]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Section sx={{ paddingLeft: '3.5em !important' }}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Typography fontWeight="600" sx={{ fontSize: '18px' }}>
                {t('companyInfo.title')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="stretch">
                <Grid item xs={6} md={3}>
                  <Detail title={t('companyInfo.name')} value={company.name || '-'} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <Detail
                    title={t('companyInfo.companyAddress')}
                    value={
                      company.addressStreetOne
                        ? `${company.addressStreetOne}, ${company.addressZipCode}, ${company.addressCity}${
                            company.addressState ? `, ${company.addressState}` : ''
                          }, ${t(`user:preapproval.location.options.${company.country}`)}`
                        : '-'
                    }
                    isCopyable={!!company.addressStreetOne}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <Detail
                    title={t('companyInfo.companyFormationDate')}
                    value={
                      company.formationDate
                        ? formatDate(
                            new Date(company.formationDate),
                            'en-US',
                            {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            },
                            false,
                            true
                          )
                        : nullDate
                    }
                    isCopyable={!!company.formationDate}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <Detail
                    title={t('companyInfo.taxIdNumber')}
                    value={company.taxId ? `${company.taxIdType} - ${company.taxId}` : '-'}
                    isCopyable={!!company.taxId}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Section>
      </Grid>
      <Grid item xs={12}>
        {isLoadingShareholders ? (
          <Skeleton variant="rectangular" height={80} animation="wave" />
        ) : (
          <Shareholders
            shareholders={shareholders}
            companyId={company.id}
            loadShareholders={loadShareholders}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {isLoadingLegalRepresentatives ? (
          <Skeleton variant="rectangular" height={80} animation="wave" />
        ) : (
          <LegalRepresentatives
            companyId={company.id}
            loadLegalRepresentatives={loadLegalRepresentatives}
            legalRepresentatives={legalRepresentatives}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CompanyTab;
