import EventIcon from '@mui/icons-material/Event';
import Typography from '@mui/material/Typography';
import { TFunction, Trans } from 'react-i18next';
import { CarouselItem, FilterType, SignupCountryKeys } from '../types';
import { dateFilterOptions, statusFilterOptions } from './application';
import {
  buyerFilterOptions,
  fundingTypeFilterOptions,
  invoiceFilterOptions,
  noaStatusFilterOptions,
  paymentsCategoryFilterOptions,
  paymentsStatusFilterOptions,
} from './servicing';
import { CategoryIcon, MoneyIcon, SearchIcon, StatusIcon } from '../components/Icons';

export const clientTableFilters: FilterType[] = [
  {
    name: 'company',
    type: 'text',
    title: 'admin:clientsPage.gridFilters.clientTitle',
    placeholder: 'admin:clientsPage.gridFilters.genericFilterPlaceholder',
    size: 4,
  },
  {
    name: 'status',
    type: 'select',
    title: 'admin:clientsPage.gridFilters.statusTitle',
    placeholder: 'admin:clientsPage.gridFilters.statusFilterPlaceholder',
    size: 5,
    options: statusFilterOptions,
    tKey: 'admin:clientsPage.gridFilters.statusSelectOptions',
  },
  {
    name: 'ordering',
    type: 'select',
    title: 'admin:clientsPage.gridFilters.orderingTitle',
    placeholder: 'admin:clientsPage.gridFilters.dateFilterPlaceholder',
    size: 3,
    options: dateFilterOptions,
    tKey: 'admin:clientsPage.gridFilters.dateOptions',
    Icon: <EventIcon />,
  },
  {
    name: 'deletedOnly',
    type: 'checkbox',
    title: 'admin:clientsPage.gridFilters.deletedOnlyTitle',
    size: 3,
  },
];

export const invoicesTableFilters: FilterType[] = [
  {
    name: 'invoice',
    type: 'text',
    title: 'servicing:invoicesTable.filters.invoiceNumber.title',
    placeholder: 'servicing:invoicesTable.filters.invoiceNumber.placeholder',
    size: 2,
    showLabel: false,
    Icon: <SearchIcon />,
  },
  {
    name: 'buyer',
    type: 'text',
    title: 'servicing:invoicesTable.filters.buyer.title',
    placeholder: 'servicing:invoicesTable.filters.buyer.placeholder',
    size: 2,
    showLabel: false,
    Icon: <SearchIcon />,
  },
  {
    name: 'status',
    type: 'select',
    title: 'servicing:invoicesTable.filters.invoiceStatus.title',
    size: 2.5,
    options: invoiceFilterOptions,
    tKey: (value) => `servicing:invoicesTable.tableColumns.invoiceStatusMap.${value}.label`,
    placeholder: 'servicing:invoicesTable.filters.invoiceStatus.placeholder',
    Icon: <StatusIcon />,
    showLabel: false,
  },
  {
    name: 'dates',
    type: 'dateRange',
    title: '',
    placeholder: 'servicing:invoicesTable.filters.dates.placeholder',
    size: 4.5,
    showLabel: false,
  },
];

export const buyersTableFilters: FilterType[] = [
  {
    name: 'buyer',
    type: 'text',
    placeholder: 'servicing:buyerSummary.filters.buyer',
    size: 4,
    Icon: <SearchIcon />,
  },
  {
    name: 'noaStatusFilter',
    type: 'select',
    options: noaStatusFilterOptions,
    tKey: (value) => `servicing:buyerSummary.tableColumns.noaStatusMap.${value}`,
    placeholder: 'servicing:buyerSummary.filters.noaStatusFilter',
    size: 4,
    Icon: <StatusIcon />,
  },
  {
    name: 'status',
    type: 'select',
    options: buyerFilterOptions,
    tKey: (value) => `servicing:buyerSummary.buyerStatusMap.${value}`,
    placeholder: 'servicing:buyerSummary.filters.status',
    size: 3,
    Icon: <StatusIcon />,
  },
];

export const buyerCreditCheckTableFilters: FilterType[] = [
  {
    name: 'companyName',
    type: 'text',
    title: 'admin:clientsPage.buyerCreditCheck.gridFilters.companyNameTitle',
    placeholder: 'admin:clientsPage.buyerCreditCheck.gridFilters.genericFilterPlaceholder',
    size: 3,
  },
  {
    name: 'country',
    type: 'text',
    title: 'admin:clientsPage.buyerCreditCheck.gridFilters.countryTitle',
    placeholder: 'admin:clientsPage.buyerCreditCheck.gridFilters.genericFilterPlaceholder',
    size: 3,
  },
  {
    name: 'ordering',
    type: 'select',
    title: 'admin:clientsPage.buyerCreditCheck.gridFilters.orderingTitle',
    placeholder: 'admin:clientsPage.gridFilters.dateFilterPlaceholder',
    size: 3,
    options: dateFilterOptions,
    tKey: 'admin:clientsPage.gridFilters.dateOptions',
    Icon: <EventIcon />,
  },
];

export const fxRequestTableFilters: FilterType[] = [
  {
    name: 'ordering',
    type: 'select',
    title: 'admin:clientsPage.buyerCreditCheck.gridFilters.orderingTitle',
    placeholder: 'admin:clientsPage.gridFilters.dateFilterPlaceholder',
    size: 3,
    options: dateFilterOptions,
    tKey: 'admin:clientsPage.gridFilters.dateOptions',
    Icon: <EventIcon />,
  },
];

export const getProductsByCountry = (countryKey: SignupCountryKeys, t: TFunction) => {
  const defaultProducts: CarouselItem[] = [
    {
      image: '/images/products/financing.png',
      title: (
        <Typography variant="h4" sx={{ textAlign: 'center' }}>
          {t('products.financing.title')}
        </Typography>
      ),
      description: t('products.financing.description'),
    },
    {
      image: '/images/products/buyerCheck.png',
      title: (
        <Typography variant="h4" sx={{ textAlign: 'center' }}>
          {t('products.buyerCheck.title')}
        </Typography>
      ),
      description: t('products.buyerCheck.description'),
    },
    {
      image: '/images/products/llc.png',
      title: (
        <Trans t={t} i18nKey={'products.llc.title'}>
          <Typography variant="h4" sx={{ textAlign: 'center' }}>
            Establish an LLC
          </Typography>
          <Typography variant="h4" sx={{ textAlign: 'center' }}>
            in the US
          </Typography>
        </Trans>
      ),
      description: t('products.llc.description'),
    },
  ];
  switch (countryKey) {
    case 'mx':
      return [
        ...defaultProducts,
        {
          image: '/images/products/fx.png',
          title: (
            <Typography variant="h4" sx={{ textAlign: 'center' }}>
              {t('products.fx.title')}
            </Typography>
          ),
          description: t('products.fx.description'),
        },
        {
          image: '/images/products/insurance.png',
          title: (
            <Typography variant="h4" sx={{ textAlign: 'center' }}>
              {t('products.insurance.title')}
            </Typography>
          ),
          description: t('products.insurance.description'),
        },
      ];
    default:
      return defaultProducts;
  }
};

export const trackingTableFilters: FilterType[] = [
  {
    name: 'containerNumber',
    type: 'text',
    title: 'Container ID',
    placeholder: 'Enter a container ID',
    size: 5,
  },
  {
    name: 'dischargePort',
    type: 'text',
    title: 'Destination port',
    placeholder: 'Enter a destination port',
    size: 5,
  },
];

export const fundingsTableFilters: FilterType[] = [
  {
    name: 'transactionId',
    type: 'text',
    placeholder: 'servicing:fundings.filters.transactionId',
    size: 8.5,
    Icon: <SearchIcon />,
  },
  {
    name: 'paymentType',
    type: 'select',
    options: fundingTypeFilterOptions,
    tKey: (value) => `servicing:fundings.filters.paymentTypeMap.${value}`,
    placeholder: 'servicing:fundings.filters.paymentType',
    size: 2.5,
    Icon: <StatusIcon />,
  },
];

export const transactionsTableFilters: FilterType[] = [
  {
    name: 'dates',
    type: 'dateRange',
    title: '',
    placeholder: 'Choose dates (up to 7 days)',
    size: 10,
    showLabel: false,
  },
];

export const paymentsTableFilters: FilterType[] = [
  {
    name: 'batchNumber',
    type: 'text',
    title: 'servicing:payments.filters.batchNumber.title',
    placeholder: 'servicing:payments.filters.batchNumber.placeholder',
    size: 2.5,
    showLabel: false,
    Icon: <SearchIcon />,
  },
  {
    name: 'status',
    type: 'select',
    title: 'servicing:payments.filters.status.title',
    size: 2,
    options: paymentsStatusFilterOptions,
    tKey: (value) => `servicing:payments.tableColumns.statusMap.${value}`,
    placeholder: 'servicing:payments.filters.status.placeholder',
    Icon: <StatusIcon />,
    showLabel: false,
  },
  {
    name: 'category',
    type: 'select',
    title: 'servicing:payments.filters.category.title',
    size: 2.5,
    options: paymentsCategoryFilterOptions,
    tKey: (value) => `servicing:payments.tableColumns.categoryMap.${value}`,
    placeholder: 'servicing:payments.filters.category.placeholder',
    Icon: <CategoryIcon />,
    showLabel: false,
  },
  {
    name: 'postDate',
    type: 'dateRange',
    title: '',
    placeholder: 'servicing:payments.filters.dates.placeholder',
    size: 4,
    showLabel: false,
    disableFuture: true,
  },
];

export const transfersStatusFilterOptions = ['pending', 'approved'];

export const transfersCategoryFilterOptions = ['funding', 'release', 'return_of_funds'];

export const transfersTableFilters: FilterType[] = [
  {
    name: 'batchNumber',
    type: 'text',
    title: 'servicing:transfers.filters.batchNumber.title',
    placeholder: 'servicing:transfers.filters.batchNumber',
    size: 2.5,
    showLabel: false,
    Icon: <SearchIcon />,
  },
  {
    name: 'transferCategory',
    type: 'select',
    title: 'servicing:transfers.filters.category.title',
    size: 2.5,
    options: transfersCategoryFilterOptions,
    tKey: (value) => `servicing:transfers.tableColumns.categoryMap.${value}`,
    placeholder: 'servicing:transfers.filters.category',
    Icon: <CategoryIcon />,
    showLabel: false,
  },
  {
    name: 'dates',
    type: 'dateRange',
    title: '',
    placeholder: 'servicing:transfers.filters.dates.placeholder',
    size: 5,
    showLabel: false,
    disableFuture: true,
  },
  {
    name: 'minAmount',
    type: 'number',
    title: 'servicing:transfers.filters.minAmount.title',
    placeholder: 'servicing:transfers.filters.minAmount',
    size: 2,
    showLabel: false,
    Icon: <MoneyIcon />,
  },
  {
    name: 'maxAmount',
    type: 'number',
    title: 'servicing:transfers.filters.maxAmount.title',
    placeholder: 'servicing:transfers.filters.maxAmount',
    size: 2,
    showLabel: false,
    Icon: <MoneyIcon />,
  },
];
