import { ReactNode, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { COLORS } from '../../themev2';
import { SxProps, Theme } from '@mui/material';

const CollapsibleCard = ({
  title,
  content,
  sx = {},
}: {
  title: string;
  content: ReactNode;
  sx?: SxProps<Theme>;
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{
        border: `1px solid ${COLORS.gray[700]}`,
        borderRadius: '10px',
        cursor: 'pointer',
        ...sx,
      }}
      onClick={handleExpandClick}>
      <CardHeader
        titleTypographyProps={{ variant: 'body1', sx: { fontWeight: 600, fontSize: '18px' } }}
        title={title}
        action={
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            sx={
              expanded
                ? { transform: 'rotate(180deg)' }
                : {
                    transform: 'rotate(0deg)',
                    marginLeft: 'auto',
                    transition: 'transform 0.2s',
                  }
            }>
            <ExpandMore />
          </IconButton>
        }
      />
      <Collapse in={expanded} unmountOnExit>
        <CardContent>{content}</CardContent>
      </Collapse>
    </Card>
  );
};

export default CollapsibleCard;
