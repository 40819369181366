import { useRef, useState } from 'react';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { logEvent } from '@amplitude/analytics-browser';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { UploadFile } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import Typography from '../../componentsv2/Typography';
import InfoDrawer from '../../componentsv2/InfoDrawer';
import Form from '../../componentsv2/Form';
import { DateField, SelectField, TextField } from '../../componentsv2/Field';
import Button from '../../componentsv2/Button';
import Table from '../../componentsv2/Table';
import { TrashIcon } from '../../components/Icons';
import { deleteDocumentAsset, uploadShareholderAssets } from '../../services/documents';
import { useTranslation } from '../../hooks/i18n';
import { emailRegExp } from '../../constants/forms';
import { docsCollectionEvents } from '../../constants/events';
import { baseURL } from '../../constants/api';
import { getCountriesForShareholdersAndLegalReps } from '../../constants/data';
import { Nullable } from '../../types';
import { APIShareholder, ShareholderFormValues } from '../../types/documents';
import { formatAssetsAsFiles, juridicalTaxIdTypes, physicalTaxIdTypes } from './utils';
import { COLORS } from '../../themev2';

const AddEditShareholderDrawer = ({
  shareholder,
  companyId,
  onAddShareholder,
  onEditShareholder,
  handleClose,
  showDrawer,
  isAdmin = false,
  isAccepted = false,
}: {
  shareholder: Nullable<APIShareholder>;
  companyId: number;
  onAddShareholder: (companyId: number, values: ShareholderFormValues, files: File[]) => Promise<any>;
  onEditShareholder: (
    companyId: number,
    shareholderId: number,
    values: ShareholderFormValues
  ) => Promise<any>;
  handleClose: () => void;
  showDrawer: boolean;
  isAdmin?: boolean;
  isAccepted?: boolean;
}) => {
  const { t } = useTranslation();
  const isEdit = !!shareholder;
  // when creating, array starts empty... when editing it starts with the shareholder assets
  const [files, setFiles] = useState<File[]>(
    isEdit ? (formatAssetsAsFiles(shareholder!.assets) as any as File[]) : []
  );
  const uploaderRef = useRef<any>(null);

  return (
    <Formik
      initialValues={{
        personType: shareholder?.personType || 'physical',
        fullName: shareholder?.fullName || '',
        nationality: shareholder?.nationality || '',
        personIdNumber: shareholder?.personIdNumber || '',
        percentageShare: shareholder?.percentageShare || 0,
        taxIdType: shareholder?.taxIdType || '',
        dateOfBirth: shareholder?.dateOfBirth ? dayjs(shareholder.dateOfBirth) : null,
        address: shareholder?.address || '',
        email: shareholder?.email || '',
        phoneNumber: shareholder?.phoneNumber || '',
      }}
      validationSchema={Yup.object().shape({
        fullName: Yup.string().required(t('requiredField')),
        personType: Yup.string().required(t('requiredField')),
        nationality: Yup.string().required(t('requiredField')),
        personIdNumber: Yup.string().required(t('requiredField')),
        percentageShare: Yup.number()
          .min(1, t('minValue', { value: 1 }))
          .max(100, t('maxValue', { value: 100 }))
          .required(t('requiredField')),
        email: Yup.string().required(t('requiredField')).matches(emailRegExp, t('emailInvalidError')),
      })}
      onSubmit={(values) => {
        const mappedValues = {
          ...values,
          dateOfBirth: values.dateOfBirth ? dayjs(values.dateOfBirth).format('YYYY-MM-DD') : '',
        };
        if (!isEdit) {
          onAddShareholder(companyId, mappedValues, files);
        } else {
          onEditShareholder(companyId, shareholder!.id, mappedValues);
        }
        logEvent(docsCollectionEvents.SAVE_SHAREHOLDER_FORM, {
          'Full Name': values.fullName,
          'Type Of Person': values.personType,
          Participation: values.percentageShare,
          'Identification Number': values.personIdNumber,
          Email: values.email,
          Files: files.map((f) => f.name),
        });
      }}>
      {({ isSubmitting, values, setFieldValue, submitForm }) => {
        return (
          <InfoDrawer
            showDrawer={showDrawer}
            handleClose={handleClose}
            submit={submitForm}
            isSubmitting={isSubmitting}
            disableSubmit={isAccepted}
            buttonLabel={
              isEdit ? t('client:documents.shareholders.editBtn') : t('client:documents.shareholders.addBtn')
            }>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '24px',
                    lineHeight: '36px',
                    color: COLORS.gray[1200],
                  }}>
                  {isAccepted
                    ? t('client:documents.shareholders.viewFormTitle')
                    : isEdit
                    ? t('client:documents.shareholders.editFormTitle')
                    : t('client:documents.shareholders.addFormTitle')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: COLORS.gray[1100],
                  }}>
                  {t('client:documents.shareholders.formDescription')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Form trackingEvent={docsCollectionEvents.EDIT_SHAREHOLDER_FORM}>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <SelectField
                        disabled={isAccepted}
                        name="personType"
                        label={t('client:documents.shareholders.personType.label')}
                        placeholder={t('client:documents.shareholders.personType.placeholder')}
                        options={[
                          { label: t('client:documents.shareholders.physical'), value: 'physical' },
                          { label: t('client:documents.shareholders.juridical'), value: 'juridical' },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        disabled={isAccepted}
                        name="fullName"
                        label={t(`client:documents.shareholders.fullName.label.${values.personType}`)}
                        placeholder={t(`client:documents.shareholders.fullName.label.${values.personType}`)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SelectField
                        disabled={isAccepted}
                        name="nationality"
                        label={t(`client:documents.shareholders.nationality.label.${values.personType}`)}
                        placeholder={t(
                          `client:documents.shareholders.nationality.label.${values.personType}`
                        )}
                        options={getCountriesForShareholdersAndLegalReps(t)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        disabled={isAccepted}
                        name="percentageShare"
                        label={t('client:documents.shareholders.percentageShare.label')}
                        placeholder={t('client:documents.shareholders.percentageShare.placeholder')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SelectField
                        disabled={isAccepted}
                        optional
                        name="taxIdType"
                        label={t(`client:documents.shareholders.taxIdType.label`)}
                        placeholder={t(`client:documents.shareholders.taxIdType.placeholder`)}
                        options={values.personType === 'juridical' ? juridicalTaxIdTypes : physicalTaxIdTypes}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        disabled={isAccepted}
                        name="personIdNumber"
                        label={t(`client:documents.shareholders.personIdNumber.label.${values.personType}`)}
                        placeholder={t(
                          `client:documents.shareholders.personIdNumber.label.${values.personType}`
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DateField
                        disableFuture
                        optional
                        disabled={isAccepted}
                        name="dateOfBirth"
                        label={t(`client:documents.shareholders.dateOfBirth.label.${values.personType}`)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        optional
                        disabled={isAccepted}
                        name="address"
                        label={t('client:documents.shareholders.address.label')}
                        placeholder={t('client:documents.shareholders.address.placeholder')}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: '0' }}>
                      <TextField
                        disabled={isAccepted}
                        name="email"
                        label={t('client:documents.shareholders.email.label')}
                        placeholder={t('client:documents.shareholders.email.placeholder')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        disabled={isAccepted}
                        optional
                        name="phoneNumber"
                        label={t('client:documents.shareholders.phoneNumber.label')}
                        placeholder={t('client:documents.shareholders.phoneNumber.placeholder')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <input
                        disabled={isAccepted}
                        type="file"
                        multiple
                        style={{ display: 'none' }}
                        ref={uploaderRef}
                        onChange={async (e: any) => {
                          const _files = Array.from(e.target.files) as File[];
                          if (isEdit) {
                            await uploadShareholderAssets(companyId, shareholder!.id, _files, isAdmin);
                          }
                          logEvent(docsCollectionEvents.UPLOAD_SHAREHOLDER_DOCUMENT);
                          setFiles((prev) => [...prev, ..._files]);
                        }}
                      />
                      <Table
                        columns={[
                          {
                            headerName: t('client:documents.shareholders.documents'),
                            renderCell: () => {
                              return (
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  <Typography>{t('client:documents.shareholders.passport')}</Typography>
                                </Box>
                              );
                            },
                          },
                          {
                            headerName: t('client:documents.shareholders.files'),
                            renderCell: () => {
                              return (
                                <>
                                  {files.map((file: any, index) => {
                                    const url = !!file.uuid
                                      ? `${baseURL}/assets/${file.uuid}`
                                      : URL.createObjectURL(new Blob([file]));

                                    return (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}>
                                        <a href={url} download={file.name} target="_blank" rel="noreferrer">
                                          <Typography>{file.name}</Typography>
                                        </a>
                                        <IconButton
                                          disabled={isAccepted}
                                          onClick={() => {
                                            if (isEdit) {
                                              deleteDocumentAsset(
                                                companyId,
                                                shareholder!.categoryId,
                                                file.id,
                                                isAdmin
                                              );
                                            }
                                            setFiles((prev) => prev.filter((_, _index) => _index !== index));
                                          }}>
                                          <TrashIcon size="sm" />
                                        </IconButton>
                                      </Box>
                                    );
                                  })}
                                </>
                              );
                            },
                          },
                          {
                            headerName: t('client:documents.shareholders.actions'),
                            renderCell: () => (
                              <>
                                <Button
                                  disabled={isAccepted}
                                  type="link"
                                  color="secondary"
                                  startIcon={<UploadFile />}
                                  onClick={() => {
                                    uploaderRef.current && uploaderRef.current.click();
                                  }}>
                                  {t('client:documents.shareholders.uploadBtn')}
                                </Button>
                              </>
                            ),
                          },
                        ]}
                        data={[{ document: 'Passport', files }]}></Table>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
            </Grid>
          </InfoDrawer>
        );
      }}
    </Formik>
  );
};

export default AddEditShareholderDrawer;
