import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Unarchive from '@mui/icons-material/Unarchive';
import FilterListIcon from '@mui/icons-material/FilterList';
import TableFilters from '../../components/TableFilters';
import {
  clearFilters,
  deleteClients as deleteClientsThunk,
  getClients as getClientsThunk,
  restoreClients as restoreClientsThunk,
  setFilters,
  setPageNumber,
} from '../../store/clientsSlice';
import { mapFiltersForBackend } from '../../utils/helpers';
import { backendCountryValues, getClientsColumns, getCountries } from '../../constants/data';
import { PAGE_SIZE } from '../../constants/auth';
import { clientTableFilters } from '../../constants/common';
import { useAppDispatch, useAppSelector } from '../../store';
import Button from '../../componentsv2/Button';
import { useTranslation } from '../../hooks/i18n';
import CustomDataGrid from '../../componentsv2/DataGrid';
import ModalV2 from '../../components/ModalV2';
import Typography from '../../componentsv2/Typography';
import Form from '../../componentsv2/Form';
import { Formik } from 'formik';
import { SelectField, TextField } from '../../componentsv2/Field';
import { CountryOption, Nullable } from '../../types';
import { QaAdminResult, adminCreateQaUser } from '../../services/admin';
import { useSnackbar } from '../../context';
import { COLORS } from '../../themev2';
import ConfirmDialog from '../../componentsv2/ConfirmDialog';

const stepDescription: Record<string, string> = {
  signup_completed: `- The user is registered \n - The password is set`,
  application_started: `- The user is registered \n - The password is set \n - An application is started (no fields filled)`,
  application_completed: `- The user is registered \n - The password is set \n - An application is started \n - The application is completed (if the country is mexico, the satws part is skipped since it can take 10 minutes)`,
  termsheet_sent: `- The user is registered \n - The password is set \n - The application is completed \n - The termsheet is sent (the doc link is hardcoded, can't use pandadocs, so you can't sign it, you must advance to next status on SF)`,
  termsheet_signed: `- The user is registered \n - The password is set \n - The application is completed \n - The termsheet is signed (the doc link is hardcoded, can't use pandadocs)`,
  credit_committee: `- The user is registered \n - The password is set \n - The application is completed \n - The termsheet is signed (the doc link is hardcoded, can't use pandadocs) \n - Credit committee status, but the process didn't happen yet`,
  credit_committee_completed: `- The user is registered \n - The password is set \n - The application is completed \n - The termsheet is signed (the doc link is hardcoded, can't use pandadocs) \n - Credit committee status, the credit committee process is finished`,
  mpsa_pending: `- The user is registered \n - The password is set \n - The application is completed \n - The termsheet is signed (the doc link is hardcoded, can't use pandadocs) \n - The credit committee process is finished \n - Legal status, the mpsa is created, but is not signed yet, (the doc link is hardcoded, can't use pandadocs, so you can't sign it, you must advance to next status on SF)`,
  mpsa_signed: `- The user is registered \n - The password is set \n - The application is completed \n - The termsheet is signed (the doc link is hardcoded, can't use pandadocs) \n - The credit committee process is finished \n - Legal status, the mpsa is signed (the doc link is hardcoded, can't use pandadocs)`,
  closed_won: `- The user is registered \n - The password is set \n - The application is completed \n - The termsheet is signed (the doc link is hardcoded, can't use pandadocs) \n - The credit committee process is finished \n The mpsa is signed (the doc link is hardcoded, can't use pandadocs) \n The status is closed won`,
};

const Clients = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showFilters, setShowFilters] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedClientIds, setSelectedClientIds] = useState<string[]>([]);
  const { clients, isLoading, clientsCount, filters, pageNumber, changes } = useAppSelector(
    (state) => state.clients
  );
  const [showUserCreationModal, setShowUserCreationModal] = useState(false);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [createdUser, setCreatedUser] = useState<Nullable<QaAdminResult>>(null);
  const { i18n, t } = useTranslation();
  const { showSnackbar } = useSnackbar();

  const isProductionEnv = process.env.REACT_APP_ENVIRONMENT === 'production';

  const chooseClient = (params: any) => {
    const {
      row: { uuid },
    } = params;
    navigate(`/companies/${uuid}`);
  };

  const handleNewFilters = (filters: any) => {
    dispatch(setFilters(filters));
  };

  useEffect(() => {
    let mappedBackendFilters = '';
    const searchParamsFilters = mapFiltersForBackend(filters);
    if (searchParamsFilters) {
      mappedBackendFilters = `&${searchParamsFilters}`;
    }
    dispatch(getClientsThunk({ pageNumber: pageNumber + 1, filters: mappedBackendFilters }));
  }, [dispatch, pageNumber, filters, changes]);

  return (
    <Grid container>
      <ModalV2
        isOpen={showUserCreationModal}
        handleClose={() => {
          setShowUserCreationModal(false);
          setCreatedUser(null);
        }}
        isAutoHeight>
        <Grid container>
          {createdUser ? (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">Company created successfully!</Typography>
              </Grid>
              <Grid item xs={12}>
                <Link to={`/companies/${createdUser.companyUuid}`} target="_blank">
                  View company detail
                </Link>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">Create custom company</Typography>
              </Grid>
              <Grid item xs={12}>
                <Formik
                  initialValues={{
                    name: '',
                    companyName: '',
                    country: 'mexico',
                    password: '',
                    invoiceFinancingStep: '',
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string(),
                    companyName: Yup.string(),
                    country: Yup.string().required(t(`requiredField`)),
                    password: Yup.string().required(t(`requiredField`)),
                    invoiceFinancingStep: Yup.string().required(t(`requiredField`)),
                  })}
                  onSubmit={(values: any) => {
                    setIsCreatingUser(true);
                    adminCreateQaUser(values)
                      .then(async (result) => {
                        await dispatch(getClientsThunk({ pageNumber: pageNumber + 1, filters: '' }));
                        showSnackbar('User created successfully', 'success');
                        setCreatedUser(result);
                      })
                      .catch(() => {
                        showSnackbar('Oops! something went wrong', 'error');
                      })
                      .finally(() => {
                        setIsCreatingUser(false);
                      });
                  }}>
                  {({ values }) => (
                    <Form>
                      <TextField name="name" optional label="User name" />
                      <TextField name="companyName" optional label="Company name" />
                      <SelectField
                        label="Country"
                        name="country"
                        options={getCountries(t)
                          .sort((a: CountryOption, b: CountryOption) => a.country.localeCompare(b.country))
                          .map(({ country, countryKey }) => ({
                            value: backendCountryValues[countryKey],
                            label: country,
                          }))}
                      />
                      <TextField type="password" name="password" label="Password" />
                      <SelectField
                        name="invoiceFinancingStep"
                        label="Step"
                        options={[
                          { value: 'signup_completed', label: 'Signup completed' },
                          { value: 'application_started', label: 'Application started' },
                          { value: 'application_completed', label: 'Application completed' },
                          { value: 'termsheet_sent', label: 'Termsheet sent' },
                          { value: 'termsheet_signed', label: 'Termsheet signed' },
                          { value: 'credit_committee', label: 'Credit committee' },
                          { value: 'credit_committee_completed', label: 'Credit comittee completed' },
                          { value: 'mpsa_pending', label: 'MPSA pending' },
                          { value: 'mpsa_signed', label: 'MPSA signed' },
                          { value: 'closed_won', label: 'Closed won' },
                        ]}
                      />
                      {values.invoiceFinancingStep && (
                        <Box
                          sx={{
                            marginTop: '1em',
                            whiteSpace: 'pre-line',
                            background: COLORS.gray[200],
                            padding: '0.5em',
                            lineHeight: '2',
                          }}>
                          {stepDescription[values.invoiceFinancingStep]}
                        </Box>
                      )}
                      <Box sx={{ marginTop: '2em' }}>
                        <Button isSubmit fullWidth isLoading={isCreatingUser}>
                          Submit
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </>
          )}
        </Grid>
      </ModalV2>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1em' }}>
        {!isProductionEnv && (
          <Button
            onClick={() => {
              setShowUserCreationModal(true);
            }}>
            Create custom company
          </Button>
        )}
        <Button
          startIcon={<FilterListIcon />}
          onClick={() => setShowFilters((prevState) => !prevState)}
          type="outline">
          {t(`admin:clientsPage.gridFilters.title`)}
        </Button>
      </Grid>
      {showFilters && (
        <Grid item xs={12}>
          <TableFilters
            filterOptions={clientTableFilters}
            initialValues={filters}
            applyFilters={handleNewFilters}
            clearFilters={() => dispatch(clearFilters())}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <CustomDataGrid
          paginationSettings={{
            rowCount: clientsCount,
            onPageChange: (newPage) => {
              dispatch(setPageNumber(newPage));
            },
          }}
          tableName={'Clients'}
          rows={isLoading ? [] : clients}
          isLoading={isLoading}
          pageSize={PAGE_SIZE}
          columns={getClientsColumns(i18n.language, i18n.t)}
          onCellClick={chooseClient}
          hideToolbar={true}
          currentPage={pageNumber}
          checkboxSelection={true}
          actions={[
            {
              label: t(`admin:clientsPage.actions.labels.delete`),
              onClick: (ids) => {
                setSelectedClientIds(ids);
                setShowConfirmation(true);
              },
              shouldRender: !filters.deletedOnly,
              Icon: <DeleteOutlineIcon />,
            },
            {
              label: t(`admin:clientsPage.actions.labels.restore`),
              onClick: (ids) => {
                dispatch(restoreClientsThunk(ids));
              },
              shouldRender: filters.deletedOnly,
              Icon: <Unarchive />,
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default Clients;
