import { COLORS } from '../../themev2';

export default function BuildingsIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.6"
        d="M10.11 4.24512C10.03 4.54512 10 4.87512 10 5.24512V8.24512H5V6.24512C5 5.14512 5.9 4.24512 7 4.24512H10.11Z"
      />
      <path d="M10 19.2451V8.24512H5C3 8.24512 2 9.24512 2 11.2451V19.2451C2 21.2451 3 22.2451 5 22.2451H13C11 22.2451 10 21.2451 10 19.2451ZM6.75 17.2451C6.75 17.6551 6.41 17.9951 6 17.9951C5.59 17.9951 5.25 17.6551 5.25 17.2451V13.2451C5.25 12.8351 5.59 12.4951 6 12.4951C6.41 12.4951 6.75 12.8351 6.75 13.2451V17.2451Z" />
      <path
        opacity="0.4"
        d="M14 22.2451H13C11 22.2451 10 21.2451 10 19.2451V5.24512C10 3.24512 11 2.24512 13 2.24512H19C21 2.24512 22 3.24512 22 5.24512V19.2451C22 21.2451 21 22.2451 19 22.2451H18"
      />
      <path d="M18 18.2451V22.2451H14V18.2451C14 17.6951 14.45 17.2451 15 17.2451H17C17.55 17.2451 18 17.6951 18 18.2451ZM14 13.9951C13.59 13.9951 13.25 13.6551 13.25 13.2451V8.24512C13.25 7.83512 13.59 7.49512 14 7.49512C14.41 7.49512 14.75 7.83512 14.75 8.24512V13.2451C14.75 13.6551 14.41 13.9951 14 13.9951ZM18 13.9951C17.59 13.9951 17.25 13.6551 17.25 13.2451V8.24512C17.25 7.83512 17.59 7.49512 18 7.49512C18.41 7.49512 18.75 7.83512 18.75 8.24512V13.2451C18.75 13.6551 18.41 13.9951 18 13.9951Z" />
    </svg>
  );
}
