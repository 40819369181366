export const typicalTermsOptions = ['15', '30', '45', '60', '75', '90', '120', '120+', 'others'];

export const typicalIncoTermsOptions = [
  'notApplicable',
  'EXW',
  'FCA',
  'FAS',
  'FOB',
  'CFR',
  'CIF',
  'CPT',
  'CIP',
  'DAP',
  'DPU',
  'DDP',
] as const;

export const qualityChecksOptions = [
  'noQualityChecks',
  'yesInternalChecks',
  'yesBuyerChecks',
  'yesBuyerAndInternalChecks',
  'yesThirdPartyChecks',
  'yesSamplesExchangedChecks',
  'others',
];

export const frequencyOfSalesOptions = [
  'weekly',
  'biWeekly',
  'monthly',
  'quarterly',
  'semiAnnually',
  'annually',
  'asOrdersComeIn',
];

export const esgOptions = [
  'notApplicable',
  'womenLeadership',
  'bCorpOrESGCertification',
  'microbusiness',
  'industries',
] as const;

export const paymentTermsOptions = ['CAD', '10', '15', '30', '45', '90', '120'];

export const appStatuses = ['application', 'termSheet', 'creditDecision', 'legalDocuments', 'onboarding'];

export const statuses = ['signUp', ...appStatuses];

export const currencyOptions = ['usd', 'eur', 'mxn', 'gbp', 'aud', 'cad', 'other'];

export const statusFilterOptions = [
  'signup_started',
  'signup_completed',
  'application_started',
  'application_completed',
  'termsheet_sent',
  'termsheet_signed',
  'due_diligence',
  'credit_committee',
  'legal',
  'onboarding',
  'closed_won',
];

export const dateFilterOptions = ['-created_at', 'created_at'];

export enum ApplicationFlow {
  Satws = 'satws',
  Traditional = 'traditional',
}
