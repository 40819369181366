import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEBTOR_PENDING } from '../constants/variables';

export interface FormDebtorState {
  debtor_name?: string;
  debtor_email?: string;
  debtor_phone?: string;
  debtor_address?: string;
  contact_name?: string;
  contact_phone?: string;
  tax_id?: string;
  balance_settled?: number;
  client_financial_account?: number;
  duns?: number;
  initial_balance?: number;
  linked_clients?: number[];
  total_credit_line?: number;
  contact_info?: string;
  debtor_status?: string;
  individual_limit?: number;
}

const FormDebtorInitialState: FormDebtorState = {
  balance_settled: 0,
  client_financial_account: 1,
  contact_info: 'contact_info',
  debtor_status: DEBTOR_PENDING,
  duns: 0,
  initial_balance: 0,
  linked_clients: [],
  total_credit_line: 0,
  debtor_name: '',
  debtor_email: '',
  debtor_phone: '',
  debtor_address: '',
  contact_name: '',
  contact_phone: '',
  tax_id: '',
};

export interface FormInvoiceState {
  invoice_key?: number;
  debtor?: number;
  financial_account?: number;
  invoice_number?: number;
  invoice_date?: string;
  sch?: string;
  due_date?: string;
  invoice_amount?: string;
  age?: number;
  balance?: string;
  fee_escrow?: string;
  reserve_escrow?: string;
  earned_fee?: string;
  additional_fee?: string;
  buyout_amount?: string;
  invoice_status?: string;
  balance_settle?: string;
  client?: string;
}

const FormInvoiceInitialState: FormInvoiceState = {};

export interface FormApplicationFilesState {
  ar_files?: any;
  product_type: string;
  application_status: string;
}

const FormApplicationFilesInitialState: FormApplicationFilesState = {
  product_type: 'Product type 1',
  application_status: 'Application status 1',
};

export interface FormApplicationState {
  application_status?: string;
  id?: number;
  debt_provider_facility_currently?: string;
  operate_with_trade_insurance_policy?: string;
  insurance_provider?: string;
  seasonality_business?: string;
  subject_to_paca?: string;
  quality_checks?: string;
  practice_progress_billings?: string;
  practice_installments_billings?: string;
  client?: number;
  sale_last_12_month?: string;
}

const FormApplicationInitialState: FormApplicationState = { insurance_provider: '' };

export interface FormModalUploadState {
  step?: number;
  typeDocuments?: string;
}

const FormModalUploadInitialState: FormModalUploadState = { step: 1 };

export interface FormUserSignupState {
  name?: string;
  role?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
}

const FormUserSignupInitialState: FormUserSignupState = {
  password: '',
  email: '',
};

export interface FormResetPasswordState {
  email?: string;
}

const FormResetPasswordInitialState: FormResetPasswordState = {};

export interface FormEmailVerifierState {
  email?: string;
}

const FormEmailVerifierInitialState: FormEmailVerifierState = {};

export interface FormNewPasswordState {
  password: string;
}

const FormNewPasswordInitialState: FormNewPasswordState = {
  password: '',
};

export interface ClientStateRoot {
  client?: any;
}

const ClientInitialState: ClientStateRoot = { client: { client_pipeline_status: '' } };

// Slice
export const root = createSlice({
  name: 'form',
  initialState: {
    Token: null,
    AuthIs: false,
    AdminIs: false,
    IsLoading: false,
    ShowSideBar: false, // show/hide side bar
    FormStage: 1, // default page stage to show on page load
    BasicModal: false,
    ShowModalUpload: false,
    ApplicationIs: false,
    Applications: [],
    ApplicationsStage: [],
    FinancialAccount: null,
    Debtors: [],
    DebtorsStage: [],
    Clients: [],
    Debtor: { linked_clients: [], noa_status: '', requested_limit: null },
    DebtorInvoices: [],
    User: { first_name: '', last_name: '', id: 0 },
    Client: ClientInitialState,
    ClientsStage: [],
    ActionCenterValue: '',
    SelectedDebtor: { id: null, debtor_name: null },
    AssetsUploaded: [],
    Invoices: [],
    SelectedInvoice: { invoice_number: 0 },
    InvoicesStage: [],
    AssetsApproved: false,
    InvoiceUploaded: false,
    FormInvoice: FormInvoiceInitialState,
    FormDebtor: FormDebtorInitialState,
    FormUserSignup: FormUserSignupInitialState,
    FormNewPassword: FormNewPasswordInitialState,
    FormResetPassword: FormResetPasswordInitialState,
    FormEmailVerifier: FormEmailVerifierInitialState,
    FormApplication: FormApplicationInitialState,
    FormApplicationFiles: FormApplicationFilesInitialState,
    FormModalUpload: FormModalUploadInitialState,
  },
  reducers: {
    formStage: (state, action) => {
      state.FormStage = action.payload;
    },
    adminIs: (state, action) => {
      state.AdminIs = action.payload;
    },
    setToken: (state, action) => {
      state.Token = action.payload;
    },
    authIs: (state, action) => {
      state.AuthIs = action.payload;
    },
    isLoading: (state, action) => {
      state.IsLoading = action.payload;
    },
    setActionCenterValue: (state, action) => {
      state.ActionCenterValue = action.payload;
    },
    showSideBar: (state, action) => {
      state.ShowSideBar = action.payload;
    },
    basicModal: (state, action) => {
      state.BasicModal = action.payload;
    },
    showModalUpload: (state, action) => {
      state.ShowModalUpload = action.payload;
    },
    applicationIs: (state, action) => {
      state.Applications = action.payload;
    },
    applicationsStage: (state, action) => {
      state.ApplicationsStage = action.payload;
    },
    applications: (state, action) => {
      state.Applications = action.payload;
    },
    assetsUploaded: (state, action) => {
      state.AssetsUploaded = action.payload;
    },
    setDebtors: (state, action) => {
      state.Debtors = action.payload;
    },
    setDebtor: (state, action) => {
      state.Debtor = action.payload;
    },
    setDebtorInvoices: (state, action) => {
      state.DebtorInvoices = action.payload;
    },
    debtorsStage: (state, action) => {
      state.DebtorsStage = action.payload;
    },
    setSelectedDebtor: (state, action) => {
      state.SelectedDebtor = action.payload;
    },
    setClients: (state, action) => {
      state.Clients = action.payload;
    },
    setClient: (state, action: PayloadAction<ClientStateRoot>) => {
      state.Client = { ...state.Client, ...action.payload };
    },
    setUser: (state, action) => {
      state.User = action.payload;
    },
    clientsStage: (state, action) => {
      state.ClientsStage = action.payload;
    },
    setInvoices: (state, action) => {
      state.Invoices = action.payload;
    },
    invoicesStage: (state, action) => {
      state.InvoicesStage = action.payload;
    },
    invoiceUploaded: (state, action) => {
      state.InvoiceUploaded = action.payload;
    },
    selectedInvoice: (state, action) => {
      state.SelectedInvoice = action.payload;
    },
    setFinancialAccount: (state, action) => {
      state.FinancialAccount = action.payload;
    },
    formDebtor: (state, action: PayloadAction<FormDebtorState>) => {
      state.FormDebtor = { ...state.FormDebtor, ...action.payload };
    },
    formInvoice: (state, action: PayloadAction<FormInvoiceState>) => {
      state.FormInvoice = { ...state.FormDebtor, ...action.payload };
    },
    formApplication: (state, action: PayloadAction<FormApplicationState>) => {
      state.FormApplication = { ...state.FormApplication, ...action.payload };
    },
    formApplicationFiles: (state, action: PayloadAction<FormApplicationFilesState>) => {
      state.FormApplicationFiles = { ...state.FormApplicationFiles, ...action.payload };
    },
    formModalUpload: (state, action: PayloadAction<FormModalUploadState>) => {
      state.FormModalUpload = { ...state.FormModalUpload, ...action.payload };
    },
    formSignup: (state, action: PayloadAction<FormUserSignupState>) => {
      state.FormUserSignup = { ...state.FormUserSignup, ...action.payload };
    },
  },
});

// Actions
export const {
  setToken, //Not used
  authIs, //Not used
  adminIs, //Not used
  isLoading,
  assetsUploaded,
  setInvoices,
  invoicesStage,
  setDebtors,
  setDebtor,
  setDebtorInvoices,
  debtorsStage,
  setClients, //Not used
  setClient, //Not used
  setUser,
  clientsStage,
  setActionCenterValue,
  setSelectedDebtor,
  formStage,
  showSideBar,
  formApplication, //Not used
  formSignup,
  formDebtor,
  formInvoice,
  basicModal,
  applications, //Not used
  applicationIs, //Not used
  applicationsStage, //Not used
  invoiceUploaded,
  selectedInvoice,
  formApplicationFiles, //Not used
  formModalUpload,
  showModalUpload,
  setFinancialAccount,
} = root.actions;
