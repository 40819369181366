import AdminDashboard from './AdminDashboard';
import ClientDashboard from './ClientDashboard';
import { useAuth } from '../../hooks';

const Dashboard = () => {
  const { isAdmin } = useAuth();

  return isAdmin ? <AdminDashboard /> : <ClientDashboard />;
};

export default Dashboard;
