export default function DownloadIcon({ size = 'md' }: { size?: string }) {
  const width = size === 'sm' ? '14' : '20';
  const height = size === 'sm' ? '14' : '20';
  return (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9440_923)">
        <path
          d="M19.2344 21.7891C19.7885 21.7891 20.2344 21.3432 20.2344 20.7891C20.2344 20.2349 19.7885 19.7891 19.2344 19.7891L5.23438 19.7891C4.68021 19.7891 4.23438 20.2349 4.23438 20.7891C4.23438 21.3432 4.68021 21.7891 5.23438 21.7891L19.2344 21.7891ZM11.5052 16.1391C11.6927 16.3391 11.9594 16.4557 12.2344 16.4557C12.5094 16.4557 12.7719 16.3432 12.9635 16.1391L18.2969 10.4724C18.676 10.0682 18.6552 9.43906 18.2552 9.0599C17.8552 8.68073 17.2219 8.70156 16.8427 9.10156L13.2344 12.9349L13.2344 9.45573L13.2344 4.12239C13.2344 3.56823 12.7885 3.12239 12.2344 3.12239C11.6802 3.12239 11.2344 3.56823 11.2344 4.12239L11.2344 9.45573L11.2344 12.9349L7.63021 9.10573C7.25104 8.70156 6.61771 8.68489 6.21771 9.06406C5.81771 9.44323 5.79688 10.0766 6.17604 10.4766L11.5094 16.1432L11.5052 16.1391Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_9440_923">
          <rect width="16" height="21.3333" fill="white" transform="translate(20.2344 23.123) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
}
