import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { logEvent } from '@amplitude/analytics-browser';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { useLazyTranslation } from '../../hooks/i18n';
import { getSidebarEvent } from '../../constants/events';
import { COLORS } from '../../themev2';
import Typography from '../Typography';
import Tooltip from '../Tooltip';

export type AppMenuItemType = {
  name: string;
  route: string;
  Icon: React.FunctionComponent<any> | null;
  trackingName: string;
  isAbsoluteRoute?: boolean;
};

export type AppMenuItemProps = AppMenuItemType & {
  items?: AppMenuItemType[];
  onClickItemCallback: () => void;
  isDrawerOpened?: boolean;
};

const AppMenuItem = ({
  name,
  route,
  Icon,
  trackingName,
  isAbsoluteRoute = false,
  items = [],
  onClickItemCallback,
  isDrawerOpened,
}: AppMenuItemProps) => {
  const isExpandable = items && items.length > 0;
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useLazyTranslation();

  const isContained = location.pathname.startsWith(route);
  const isActive = route === location.pathname || (isExpandable && !isDrawerOpened && isContained);
  const isElementOpen = isActive || (isExpandable && isContained);

  const handleListItemClick = () => {
    logEvent(getSidebarEvent(trackingName));
    if (isAbsoluteRoute) {
      window.open(route, '_blank');
    }
    navigate(route);
    onClickItemCallback();
  };

  const MenuItemRoot = (
    <ListItem
      onClick={handleListItemClick}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
      }}>
      {!!Icon && (
        <Tooltip title={t(name)} placement="right" disableHoverListener={isDrawerOpened}>
          <ListItemIcon
            sx={{
              color: isActive ? COLORS.brand.green : COLORS.brand.paper,
              marginLeft: '8px',
            }}>
            <Icon fillColor={isActive ? COLORS.brand.green : COLORS.brand.paper} />
          </ListItemIcon>
        </Tooltip>
      )}
      <ListItemText>
        <Typography
          sx={{
            color: isActive ? COLORS.brand.green : COLORS.brand.paper,
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '21px',
            display: isDrawerOpened ? 'initial' : 'none',
          }}>
          {t(name)}
        </Typography>
      </ListItemText>
    </ListItem>
  );

  const MenuItemChildren =
    isExpandable && isDrawerOpened ? (
      <Collapse in={isElementOpen} timeout="auto" unmountOnExit>
        <List>
          {items.map((item, index) => (
            <AppMenuItem
              {...item}
              key={index}
              onClickItemCallback={onClickItemCallback}
              isDrawerOpened={isDrawerOpened}
            />
          ))}
        </List>
      </Collapse>
    ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

export default AppMenuItem;
