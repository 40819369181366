import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Circle from '@mui/icons-material/Circle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { CarouselItem } from '../../types';
import classes from './Carousel.module.scss';

type CarouselProps = {
  items: CarouselItem[];
  onPrev?: () => void;
  onNext?: () => void;
};

const Carousel = ({ items, onPrev, onNext }: CarouselProps) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  useEffect(() => {
    setCurrentItemIndex(0);
  }, [items]);

  if (items?.length === 0) {
    return null;
  }

  const { image, title, description } = items[currentItemIndex] ?? {};

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '399px',
            height: '300px',
          }}>
          {image}
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: '700',
            lineHeight: '48px',
            background:
              'linear-gradient(74.44deg, #4E57D3 -10.53%, #2D95CD 46.22%, #2D96CD 46.23%, #2D96CC 46.24%, #2EC4B6 98.43%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            whiteSpace: 'pre-line',
          }}>
          {title}
        </Typography>
        <Typography sx={{ textAlign: 'center', marginTop: '1em', maxWidth: '60%' }}>{description}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <KeyboardArrowLeftIcon
              className={classes.arrowButton}
              onClick={() => {
                if (currentItemIndex !== 0) {
                  setCurrentItemIndex((prev) => prev - 1);
                } else {
                  setCurrentItemIndex(items.length - 1);
                }
                onPrev && onPrev();
              }}
            />
          </Box>
          <Box
            style={{
              margin: '0 2.5em',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '12px',
            }}>
            {items.map((_, index) => {
              return (
                <Circle
                  sx={{ width: '12px', fill: index === currentItemIndex ? '#03216D' : '#C8D0E5' }}
                  key={index}
                />
              );
            })}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <KeyboardArrowRightIcon
              className={classes.arrowButton}
              onClick={() => {
                setCurrentItemIndex((prev) => (prev + 1) % items.length);
                onNext && onNext();
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Carousel;
