import { useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useErrorHandler } from 'react-error-boundary';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import axios from '.';
import {
  useAppDispatch,
  logout,
  resetClientState,
  resetClientsState,
  resetAlertsSlice,
  resetApplicationSlice,
  resetAssetsSlice,
  setMaxRetriesError,
  setAdminTakeoverClient,
} from '../../store';
import { refreshToken } from '../auth';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  ADMIN_TAKEOVER_TOKEN_STORAGE_KEY,
  CURRENT_IMPERSONATED_COMPANY_UUID,
  REFRESH_TOKEN_STORAGE_KEY,
} from '../../constants/auth';
import { useSnackbar } from '../../context';

export const HTTPInterceptor = () => {
  const [responseInterceptor, setResponseInterceptor] = useState(0);
  const [requestInterceptor, setRequestInterceptor] = useState(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const { showSnackbar } = useSnackbar();

  const logoutUser = () => {
    return dispatch(logout())
      .unwrap()
      .then(() => {
        dispatch(setMaxRetriesError(true));
        navigate('/login');
        dispatch(resetClientState());
        dispatch(resetClientsState());
        dispatch(resetAlertsSlice());
        dispatch(resetApplicationSlice());
        dispatch(resetAssetsSlice());
      });
  };

  const addRequestInterceptor = () => {
    const requestInterceptor = axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
      if (!config.headers) {
        config.headers = {};
      }
      const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
      const adminTakeoverToken = localStorage.getItem(ADMIN_TAKEOVER_TOKEN_STORAGE_KEY);

      config.headers = {
        ...config.headers, // Keep existing headers
        ...(token && { Authorization: `Bearer ${token}` }),
        ...(adminTakeoverToken && { 'Impersonation-Token': adminTakeoverToken }),
      };

      if (config.params) {
        config.params = decamelizeKeys(config.params);
      }
      if (config.data && !(config.data instanceof FormData)) {
        config.data = decamelizeKeys(config.data);
      }
      return config;
    });
    setRequestInterceptor(requestInterceptor);
  };

  const removeRequestInterceptor = () => {
    axios.interceptors.request.eject(requestInterceptor);
    setRequestInterceptor(0);
  };

  const addResponseInterceptor = () => {
    const responseInterceptor = axios.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response.data && response.headers['content-type'] === 'application/json') {
          response.data = camelizeKeys(response.data);
        }
        return response;
      },
      async (error) => {
        if (error.response) {
          // If the response is a 404 there is no point on retrying
          if (error.response.status === 404) {
            return Promise.reject(error);
          }
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const {
            response: { status },
          } = error || {};
          const originalRequest = error.config;
          const token = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
          const adminTakeoverToken = localStorage.getItem(ADMIN_TAKEOVER_TOKEN_STORAGE_KEY);
          if (status === 403 && adminTakeoverToken) {
            showSnackbar(
              `Sorry you don't have the permission to do this action. If you think this is a mistake, please contact Caleb`,
              'error'
            );
          }
          if (status === 401 && adminTakeoverToken) {
            localStorage.removeItem(ADMIN_TAKEOVER_TOKEN_STORAGE_KEY);
            const companyUuid = localStorage.getItem(CURRENT_IMPERSONATED_COMPANY_UUID);
            localStorage.removeItem(CURRENT_IMPERSONATED_COMPANY_UUID);
            dispatch(setAdminTakeoverClient(null));
            window.location.replace(companyUuid ? `/companies/${companyUuid}` : '/dashboard');
            return;
          }
          if (error.response && status === 401 && error.config.url === '/auth/token/refresh/') {
            return logoutUser();
          } else {
            if (error.response.data.error_code === 'two_factor_authentication_invalid_code') {
              const twoFactor = window.prompt('Please input your 2FA code');
              if (twoFactor) {
                originalRequest.headers['X-2FA-TOKEN'] = twoFactor;
                return axios(originalRequest);
              } else {
                // Handle the case when user cancels the prompt
                console.log('2FA prompt cancelled by the user.');
                return Promise.reject(error);
              }
            }
            if (error.response.status === 401 && token) {
              const { access, refresh } = await refreshToken(token);
              localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, access);
              localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refresh);
              return axios(originalRequest);
            }
            if (status.toString()[0] === '5') {
              handleError(error);
            }
            return Promise.reject(error);
          }
        } else if (error.request) {
          // The request was made but no response was received
          logoutUser();
          return Promise.reject(error);
        } else {
          // Something happened in setting up the request that triggered an Error
          logoutUser();
          return Promise.reject(error);
        }
      }
    );
    setResponseInterceptor(responseInterceptor);
  };

  const removeResponseInterceptor = () => {
    axios.interceptors.response.eject(responseInterceptor);
    setResponseInterceptor(0);
  };

  useEffect(() => {
    addRequestInterceptor();
    addResponseInterceptor();
    return () => {
      removeResponseInterceptor();
      removeRequestInterceptor();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Fragment />;
};
