import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { root } from './rootSlice';
import { termSheet } from './termSheet';
import { api } from '../services/api';
import { authSlice } from './authSlice';
import { applicationSlice } from './applicationSlice';
import { assetsSlice } from './assetsSlice';
import { alertsSlice } from './alertsSlice';
import { clientsSlice } from './clientsSlice';
import { clientSlice } from './clientSlice';
import { anonymousSlice } from './anonymousSlice';

export const store = configureStore({
  reducer: {
    [root.name]: root.reducer,
    [termSheet.name]: termSheet.reducer,
    [api.reducerPath]: api.reducer,
    [authSlice.name]: authSlice.reducer,
    [applicationSlice.name]: applicationSlice.reducer,
    [assetsSlice.name]: assetsSlice.reducer,
    [alertsSlice.name]: alertsSlice.reducer,
    [clientsSlice.name]: clientsSlice.reducer,
    [clientSlice.name]: clientSlice.reducer,
    [anonymousSlice.name]: anonymousSlice.reducer,
  },
  middleware: (gDM) =>
    gDM({
      serializableCheck: false,
    }).concat(api.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export * from './hooks';
export * from './rootSlice';
export * from './termSheet';
export * from './authSlice';
export * from './applicationSlice';
export * from './assetsSlice';
export * from './alertsSlice';
export * from './clientSlice';
export * from './clientsSlice';
export * from './anonymousSlice';
