import { baseURL } from './api';

export const ACCESS_TOKEN_STORAGE_KEY = 'access_token';
export const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';
export const ADMIN_TAKEOVER_TOKEN_STORAGE_KEY = 'takeover_token';
export const CURRENT_USER = 'current_user';
export const CURRENT_IMPERSONATED_COMPANY_UUID = 'current_company';
export const filesURL =
  process.env.REACT_APP_S3_APP_ASSETS_BUCKET ||
  'https://marco-platform-app-assets-development.s3.amazonaws.com';

export const MAX_RETRIES = 3;
export const APPLICATION_VERSION = process.env.REACT_APP_APPLICATION_VERSION || 'local';
export const PAGE_SIZE = 10;
export const CURRENT_APP_STEP_STORAGE_KEY = 'current_app_step_storage_key';
export const APP_FLOW = 'app_flow';

//Routes
export const DASHBOARD = '/dashboard';
export const ADMIN_DASHBOARD = '/admin-dashboard';
export const CLIENT = '/companies/:id/*';
export const NOT_FOUND = '/*';
export const BUYER_CREDIT_CHECK = '/buyer-credit-check';
export const INSURANCES = '/insurances';
export const LLC = '/llc';
export const CURRENCY_EXCHANGE = '/fx';
export const VERIFY_EMAIL_SENT = '/verifyemailsent';
export const LOGIN = '/login';
export const RESET_PASSWORD = '/reset-password';
export const EMAIL_VERIFICATION = '/email-verification';
export const CREATE_NEW_PASSWORD = '/create-new-password';
export const SIGNUP = '/signup';
export const INVITATION_SIGNUP = '/signup-with-invitation';
export const INVOICE_FINANCING = '/invoice-financing';
export const APPLICATION = '/invoice-financing/application';
export const MY_DOCUMENTS = '/my-documents';
export const BUYER_SUMMARY = `${INVOICE_FINANCING}/buyer-summary`;
export const REPORTING = `${INVOICE_FINANCING}/reporting`;
export const INVOICES = `${INVOICE_FINANCING}/invoices`;
export const MY_ACCOUNT = '/my-account';
export const MY_ACCOUNT_ADMIN = '/my-account-admin';
export const LEGAL_ASSISTANT = `${baseURL}/structuringai/`;
export const CONTACT = '/contact';
export const BANKING_REPORTS = '/banking-admin';
export const BANKING_CONNECTED_ACCOUNTS = '/banking-admin/connected-accounts';
export const BANKING_CONNECTED_ACCOUNT_DETAIL = '/banking-admin/connected-accounts/:id';
export const BANKING_RECENT_TRANSACTIONS = '/banking-admin/recent-transactions';
export const PAYMENTS = `${INVOICE_FINANCING}/payments`;
export const PAYMENT_DETAILS = `${PAYMENTS}/:id`;
export const TRACKING = '/tracking';
export const TRACK_INVOICE = '/track-invoice';
export const TRACK_INVOICE_ROUTE = `${TRACK_INVOICE}/:uuid`;
export const POST_CC = `/credit-committee`;
export const POST_CC_DETAIL = `/credit-committee/:id`;
export const BANKING = '/banking';
export const BANKING_ACCOUNT_DETAIL = '/banking/:name';
export const BANKING_TRANSACTIONS = '/banking/transactions';
export const BANKING_TRANSFERS = '/banking/transfers';
export const BANKING_RECIPIENTS = '/banking/recipients';
export const BANKING_ADD_RECIPIENTS = '/banking/recipients/new';
export const LOGS = '/logs';
export const TRANSFERS = `${INVOICE_FINANCING}/transfers`;
export const TRANSFER_DETAILS = `${TRANSFERS}/:id`;

export function path(
  pathTemplate: string,
  params: { [key: string]: string | number },
  query?: { [key: string]: string }
): string {
  let url = pathTemplate;

  // Replace each placeholder in the template with the actual value from params
  for (const key in params) {
    // @ts-ignore
    url = url.replace(`:${key}`, params[key]);
  }

  // Add query parameters to the URL using URLSearchParams
  if (query) {
    const searchParams = new URLSearchParams();
    for (const key in query) {
      searchParams.append(key, query[key]);
    }
    url = `${url}?${searchParams.toString()}`;
  }

  return url;
}
