import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '../Typography';

interface MoreMenuProps {
  items: { label: string; onClick: () => void; color?: string }[];
}

const MoreMenu: React.FC<MoreMenuProps> = ({ items }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {items.map(({ label, onClick, color = 'initial' }) => (
          <MenuItem
            sx={{ minWidth: '160px' }}
            onClick={() => {
              onClick();
              handleClose();
            }}>
            <Typography sx={{ fontSize: '16px', color: color }}>{label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MoreMenu;
