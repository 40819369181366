import axios from './axios';
import { Client, PaginatedResponse } from './types';
import { PAGE_SIZE } from '../constants/auth';
import { APIClient, APIClientDetail, APICompany, ThirdPartyConnections } from '../types/client';
import { APIApplication, APIAsset } from '../types/application';
import { Company, CompanyAdminResult, CompanyUser, CompanyUserInvitation, UserRole } from '../types/company';
import { FactoringRequest } from '../types/factoring';
import {
  getClientPipelineStatus,
  getCountryKey,
  getInternalPipelineStatus,
  statusTrackerMap,
  transformCompanyUser,
  transformFactoringRequest,
} from '../store';
import { Nullable } from '../types';

function transformCompanyToClient(company: CompanyAdminResult): Client {
  return {
    ...company,
    companyUuid: company.uuid,
    companyName: company.name,
    name: company.userName,
  } as any as Client;
}

function transformCompanyToClientDetail(company: CompanyDetail): APIClientDetail {
  const { factoringRequest, ...rest } = company;
  const { name, id, institutionalPhone, country, initialUser } = rest;
  const clientPipelineStatus = getClientPipelineStatus(factoringRequest);
  return {
    ...rest,
    id,
    name: initialUser.user.firstName,
    email: initialUser.user.email,
    companyName: name,
    contactPhone: initialUser.contactPhone,
    companyTaxId: '', // dummy
    user: 0, // dummy
    clientPipelineStatus: clientPipelineStatus,
    internalPipelineStatus: company.productStatus.readyForFactoring
      ? 'closed'
      : getInternalPipelineStatus(factoringRequest),
    statusTracker: statusTrackerMap[clientPipelineStatus],
    leadId: '', // dummy
    country: getCountryKey(country),
    referralId: '', // dummy
    referralName: '', // dummy
    companies: [rest] as any as APICompany[],
    applicationCompletion: factoringRequest?.completionPercent,
  } as any as APIClientDetail;
}

export async function getClients(pageNumber: number, filters: string): Promise<PaginatedResponse<Client>> {
  const companies = await axios
    .get<PaginatedResponse<CompanyAdminResult>>(
      `/admins/companies/list/?page_size=${PAGE_SIZE}&page=${pageNumber}${filters}`
    )
    .then(({ data }) => data);

  return { ...companies, results: companies.results.map(transformCompanyToClient) };
}

type CompanyDetail = Company & { factoringRequest: FactoringRequest; stage: string; status: string };

export async function getClientDetail(id: string): Promise<{
  client: APIClientDetail;
  applications: APIApplication[];
  companyUser: CompanyUser;
  applicationCompletion: number;
  signupLink: Nullable<string>;
  newCompany: any;
}> {
  const company = await axios.get<CompanyDetail>(`/admins/companies/${id}/`).then(({ data }) => data);
  return {
    client: transformCompanyToClientDetail(company),
    applications: company.factoringRequest
      ? [transformFactoringRequest(company.factoringRequest, company)]
      : [],
    companyUser: company.initialUser,
    applicationCompletion: company.factoringRequest?.completionPercent,
    signupLink: company.signupLink,
    newCompany: company,
  };
}

export function getClientApplications(id: number): Promise<APIApplication[]> {
  return axios.get(`/clients/${id}/applications/`).then(({ data }) => data);
}

export function getClientActiveFactoringRequest(companyId: number) {
  return axios
    .get<FactoringRequest | ''>(`/companies/${companyId}/products/factoring/request/`)
    .then(({ data }) => data);
}

export function currentUserDetail(): Promise<CompanyUser> {
  return axios.get<CompanyUser>(`/company_users/me/`).then(({ data }) => data);
}

export function getAssets(): Promise<APIAsset[]> {
  return axios.get(`/company_users/me/assets/`).then(({ data }) => data || []);
}

export function getAssetsByUserId(id: number): Promise<APIAsset[]> {
  return axios.get(`/clients/${id}/assets/`).then(({ data }) => data || []);
}

export function deleteClients(ids: string[]): Promise<any> {
  return axios.post(`/clients/bulk_delete/`, { ids }).then(({ data }) => data);
}

export function restoreClients(ids: string[]): Promise<any> {
  return axios.patch(`/clients/bulk_delete/`, { ids }).then(({ data }) => data);
}

export function syncClient(id: number): Promise<APIClient> {
  return axios.post(`/clients/${id}/sync/`).then(({ data }) => data);
}

export function syncFSClient(companyUuid: string): Promise<APIClient> {
  return axios.post(`/admins/companies/lms_write/${companyUuid}/`).then(({ data }) => data);
}

export function thirdPartyConnections(companyId: number, applicationId: number) {
  return axios
    .get<ThirdPartyConnections>(
      `/companies/${companyId}/products/factoring/request/${applicationId}/third_party_services/`
    )
    .then(({ data }) => data);
}

export function connectToThirdPartyServices(companyId: number, factoringRequestId: number) {
  return axios
    .post(`/companies/${companyId}/products/factoring/request/${factoringRequestId}/third_party_services/`, {
      satWs: true,
    })
    .then(({ data }) => data);
}

export async function updateClient(payload: { name: string; contactPhone: string }) {
  const companyUser = await axios.patch<CompanyUser>(`/company_users/me/`, payload).then(({ data }) => data);
  return transformCompanyUser(companyUser);
}

export function updateCompany(
  companyId: number,
  payload: Partial<{
    addressStreetOne: string;
    addressZipCode: string;
    addressCity: string;
    addressState: string;
  }>
) {
  return axios.patch<APICompany>(`/companies/${companyId}/`, payload).then(({ data }) => data);
}

export function updateUserPreferences(payload: { language: string }) {
  return axios.patch(`/users/me/preferences/`, payload);
}

export function getCompanyUserInvitations(companyId: number) {
  return axios
    .get<CompanyUserInvitation[]>(`/companies/${companyId}/user_invitations/`)
    .then(({ data }) => data);
}

export function inviteUser(
  companyId: number,
  payload: { userName: string; userEmail: string; role: UserRole }
) {
  return axios
    .post<CompanyUserInvitation>(`/companies/${companyId}/user_invitations/`, payload)
    .then(({ data }) => data);
}

export function changePassword(payload: { oldPassword: string; newPassword: string }): Promise<any> {
  return axios.patch('/password/change-password/', payload);
}

export function resendInvitation(companyId: number, invitationId: number): Promise<any> {
  return axios
    .post(`/companies/${companyId}/user_invitations/${invitationId}/resend/`)
    .then(({ data }) => data);
}

export function deleteInvitation(companyId: number, invitationId: number): Promise<any> {
  return axios.delete(`/companies/${companyId}/user_invitations/${invitationId}/`).then(({ data }) => data);
}

export function editMember(companyId: number, userRoleId: number, payload: { role: UserRole }): Promise<any> {
  return axios
    .patch(`/companies/${companyId}/company_user_roles/${userRoleId}/`, payload)
    .then(({ data }) => data);
}

export function deleteMember(companyId: number, userRoleId: number): Promise<any> {
  return axios.delete(`/companies/${companyId}/company_user_roles/${userRoleId}/`).then(({ data }) => data);
}
