import { useEffect, useState } from 'react';
import { logEvent } from '@amplitude/analytics-browser';
import { TFunction } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '../../componentsv2/Chip';
import PageHeader from '../../componentsv2/PageHeader';
import { Tabs, Tab } from '../../componentsv2/Tabs';
import { ArrowRightIcon, MessageSentIcon } from '../../components/Icons';
import ModalV2 from '../../components/ModalV2';
import CollapsibleCard from '../../componentsv2/CollapsibleCard';
import Layout from '../../componentsv2/Layout';
import Typography from '../../componentsv2/Typography';
import Button from '../../componentsv2/Button';
import { submitInsurance, getInsurance } from '../../services/insurance';
import { useTranslation } from '../../hooks/i18n';
import { COLORS } from '../../themev2';
import { insurancesEvents } from '../../constants/events';
import { Nullable } from '../../types';
import classes from './Insurances.module.scss';
import { useUserLocation } from '../../hooks/useUserLocation';
import { useAppSelector } from '../../store';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ marginTop: '2em' }}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

type InsuranceType = 'cargo' | 'payment';

type CommonCardProps = {
  tFunc: TFunction;
  insuranceType?: InsuranceType;
};

const stepsByInsuranceType = (insuranceType: InsuranceType) => {
  return [
    {
      title: 'limitCalculation.heading',
      description: `limitCalculation.text.${insuranceType}`,
      step: 1,
    },
    {
      title: 'workWithYourClients.heading',
      description: `workWithYourClients.text.${insuranceType}`,
      step: 2,
    },
    {
      title: 'makeAClaim.heading',
      description: `makeAClaim.text.${insuranceType}`,
      step: 3,
    },
  ];
};

function HowDoesItWorkCard({ tFunc, insuranceType = 'cargo' }: CommonCardProps) {
  return (
    <Grid container rowSpacing={2}>
      {stepsByInsuranceType(insuranceType).map(({ step, title, description }) => {
        return (
          <Grid item xs={12} key={step}>
            <Grid container alignItems="center" rowSpacing={1}>
              <Grid item xs={3} md={2} lg={1}>
                <Chip label={step} sx={{ backgroundColor: '#18A19A', color: COLORS.brand.white }} />
              </Grid>
              <Grid item xs={9} md={10} lg={11}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    color: COLORS.brand.night,
                    fontWeight: 600,
                    lineHeight: '24px',
                  }}>
                  {tFunc(`howDoesItWork.${title}`)}
                </Typography>
              </Grid>
              <Grid item xs={3} md={2} lg={1}></Grid>
              <Grid item xs={9} md={10} lg={11}>
                <Typography
                  sx={{
                    color: COLORS.gray[1200],
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '21px',
                  }}>
                  {tFunc(`howDoesItWork.${description}`)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}

function CoveredMeansOfTransportation({ tFunc }: CommonCardProps) {
  const meansOfTransportation = ['air', 'land', 'maritime'];
  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <ul style={{ paddingInlineStart: '32px' }}>
          {meansOfTransportation.slice(0, 10).map((item) => (
            <li style={{ padding: '4px 0' }} key={item}>
              <Typography>{tFunc(`insuranceTypes.cargo.transportation.${item}`)}</Typography>
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
}

function RisksCovered({ tFunc }: CommonCardProps) {
  const risksCovered = [
    'transit',
    'theft',
    'moisture',
    'contact',
    'breakage',
    'cellar',
    'maneuvers',
    'customs',
    'returns',
    'pollution',
    'interruption',
    'variations',
    'gross',
    'collision',
    'boats',
    'jettison',
    'pickpocketing',
  ];
  return (
    <Grid container>
      <Grid item xs={6}>
        <ul style={{ paddingInlineStart: '32px' }}>
          {risksCovered.slice(0, 10).map((item) => (
            <li style={{ padding: '8px 0' }} key={item}>
              <Typography>{tFunc(`insuranceTypes.cargo.risks.${item}`)}</Typography>
            </li>
          ))}
        </ul>
      </Grid>
      <Grid item xs={6}>
        <ul style={{ paddingInlineStart: '32px' }}>
          {risksCovered.slice(10).map((item) => (
            <li style={{ padding: '8px 0' }} key={item}>
              <Typography>{tFunc(`insuranceTypes.cargo.risks.${item}`)}</Typography>
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
}

const submittedInitialValue = {
  cargo: false,
  payment: false,
};

const Insurances = () => {
  const { t } = useTranslation(['insurances']);
  const { language } = useUserLocation();
  const [value, setValue] = useState(0);
  const [insuranceType, setInsuranceType] = useState<Nullable<InsuranceType>>('cargo');
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState<Record<InsuranceType, boolean>>(submittedInitialValue);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const newCompany = useAppSelector((state) => state.client.newCompany);

  const isDisabledSubmitButton =
    isLoading ||
    (submitted.cargo && insuranceType === 'cargo') ||
    (submitted.payment && insuranceType === 'payment');

  const handleSubmitForInsurance = () => {
    if (insuranceType) {
      setIsLoading(true);
      submitInsurance(insuranceType, language, newCompany!.id)
        .then((data) => {
          const event =
            insuranceType === 'cargo' ? insurancesEvents.SUBMIT_CARGO : insurancesEvents.SUBMIT_CREDIT;
          logEvent(event);
          setShowConfirmationModal(true);
          setSubmitted((prev) => ({ ...prev, [data.insuranceType]: true }));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    logEvent(insurancesEvents.VIEW_PAGE);
  }, []);

  useEffect(() => {
    if (insuranceType) {
      const event = insuranceType === 'cargo' ? insurancesEvents.VIEW_CARGO : insurancesEvents.VIEW_CREDIT;
      logEvent(event);
    }
  }, [insuranceType]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (newCompany?.id)
        await getInsurance(newCompany.id)
          .then((data) => {
            setSubmitted(
              data.reduce(
                (acc, current) => ({ ...acc, [current.insuranceType]: true }),
                submittedInitialValue
              )
            );
          })
          .finally(() => setIsLoading(false));
    })();
  }, [newCompany]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setInsuranceType(newValue === 0 ? 'cargo' : 'payment');
  };

  return (
    <Layout>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <PageHeader title={t('title')} subtitle={t('subtitle')} />
        </Grid>
        <Grid item xs={12} lg={7} md={8}>
          <Box className={classes.formWrapper}>
            <Grid container>
              <Grid item xs={12}>
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} variant="fullWidth">
                      <Tab label={t('insuranceTypePicker.options.cargo')} />
                      <Tab label={t('insuranceTypePicker.options.payment')} />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <Grid container rowSpacing={3}>
                      <Grid item xs={12}>
                        <Typography sx={{ whiteSpace: 'pre-line' }}>
                          {t('insuranceTypes.cargo.description')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <CollapsibleCard
                          title={t('howDoesItWork.title')}
                          content={<HowDoesItWorkCard tFunc={t} insuranceType="cargo" />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CollapsibleCard
                          title={t('insuranceTypes.cargo.coveredMeans')}
                          content={<CoveredMeansOfTransportation tFunc={t} />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CollapsibleCard
                          title={t('insuranceTypes.cargo.risksCovered')}
                          content={<RisksCovered tFunc={t} />}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Grid container rowSpacing={3}>
                      <Grid item xs={12}>
                        <Typography>{t('insuranceTypes.payment.tradePaymentInsurance')}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>{t('insuranceTypes.payment.insolvent')}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <CollapsibleCard
                          title={t('howDoesItWork.title')}
                          content={<HowDoesItWorkCard tFunc={t} insuranceType="payment" />}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  disabled={isDisabledSubmitButton}
                  isLoading={isLoading}
                  onClick={handleSubmitForInsurance}
                  endIcon={<ArrowRightIcon />}>
                  {t('howDoesItWork.buttonTitle')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} lg={5} md={8}>
          <Box className={classes.boxWrapper}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ color: COLORS.brand.white, fontWeight: '700' }}>
                  {t('infoBox.title')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ color: COLORS.brand.white, fontWeight: '400' }}>
                  {t('infoBox.subtitle')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-start" alignItems="flex-start">
                  <Grid item xs={3} md={2} lg={1}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5.14285 17.0283H4.22856C3.21867 17.0283 2.39999 16.2096 2.39999 15.1997V6.97115C2.39999 5.96126 3.21867 5.14258 4.22856 5.14258H12.4571C13.467 5.14258 14.2857 5.96126 14.2857 6.97115V15.1997C14.2857 16.2096 13.467 17.0283 12.4571 17.0283H11.5428M15.6571 17.0283H15.2C14.695 17.0283 14.2857 16.619 14.2857 16.114V8.34258C14.2857 7.83763 14.695 7.42829 15.2 7.42829H17.5034C17.7812 7.42829 18.0439 7.55455 18.2174 7.77143L21.3996 11.7493C21.5293 11.9114 21.6 12.1128 21.6 12.3204V16.114C21.6 16.619 21.1907 17.0283 20.6857 17.0283M10.6286 16.5711C10.6286 17.8335 9.60521 18.8569 8.34285 18.8569C7.08048 18.8569 6.05714 17.8335 6.05714 16.5711C6.05714 15.3088 7.08048 14.2854 8.34285 14.2854C9.60521 14.2854 10.6286 15.3088 10.6286 16.5711ZM20.2286 16.5711C20.2286 17.8335 19.2052 18.8569 17.9428 18.8569C16.6805 18.8569 15.6571 17.8335 15.6571 16.5711C15.6571 15.3088 16.6805 14.2854 17.9428 14.2854C19.2052 14.2854 20.2286 15.3088 20.2286 16.5711Z"
                        stroke="#6AD0CB"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </Grid>
                  <Grid item xs={9} md={10} lg={11}>
                    <div>
                      <Typography variant="body1" sx={{ color: COLORS.brand.paper, fontWeight: '600' }}>
                        {t(`infoBox.cargo.title`)}
                      </Typography>
                      <Typography variant="body1" sx={{ color: COLORS.brand.paper, fontWeight: '400' }}>
                        {t(`infoBox.cargo.description`)}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-start" alignItems="flex-start">
                  <Grid item xs={3} md={2} lg={1}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17 9V7C17 6.46957 16.7893 5.96086 16.4142 5.58579C16.0391 5.21071 15.5304 5 15 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V13C3 13.5304 3.21071 14.0391 3.58579 14.4142C3.96086 14.7893 4.46957 15 5 15H7M9 9H19C20.1046 9 21 9.89543 21 11V17C21 18.1046 20.1046 19 19 19H9C7.89543 19 7 18.1046 7 17V11C7 9.89543 7.89543 9 9 9ZM16 14C16 15.1046 15.1046 16 14 16C12.8954 16 12 15.1046 12 14C12 12.8954 12.8954 12 14 12C15.1046 12 16 12.8954 16 14Z"
                        stroke="#6AD0CB"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Grid>
                  <Grid item xs={9} md={10} lg={11}>
                    <div>
                      <Typography variant="body1" sx={{ color: COLORS.brand.paper, fontWeight: '600' }}>
                        {t(`infoBox.payment.title`)}
                      </Typography>
                      <Typography variant="body1" sx={{ color: COLORS.brand.paper, fontWeight: '400' }}>
                        {t(`infoBox.payment.description`)}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <a
                  href="https://www.holamarco.co/seguros"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none' }}>
                  <Button color="white" endIcon={<ArrowRightIcon />}>
                    {t('infoBox.buttonTitle')}
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {showConfirmationModal && (
        <ModalV2 isOpen={showConfirmationModal} handleClose={() => setShowConfirmationModal(false)}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <MessageSentIcon />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" textAlign="center">
                {t('confirmationModal.thanks')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" textAlign="center">
                {t('confirmationModal.message')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth onClick={() => setShowConfirmationModal(false)} color="secondary">
                {t('confirmationModal.confirmButtonTitle')}
              </Button>
            </Grid>
          </Grid>
        </ModalV2>
      )}
    </Layout>
  );
};

export default Insurances;
