export default function ClearIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6797 22.6172C18.1797 22.6172 22.6797 18.1172 22.6797 12.6172C22.6797 7.11719 18.1797 2.61719 12.6797 2.61719C7.17969 2.61719 2.67969 7.11719 2.67969 12.6172C2.67969 18.1172 7.17969 22.6172 12.6797 22.6172Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.84961 15.4491L15.5096 9.78906"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5096 15.4491L9.84961 9.78906"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
