import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as service from '../services/clients';
import { Client, PaginatedResponse } from '../services/types';

export type ClientsState = {
  clients: Client[];
  isLoading: boolean;
  clientsCount: number;
  filters: {
    client: string;
    status: string[];
    ordering: string;
    deletedOnly: boolean;
  };
  pageNumber: number;
  changes: number;
};

const clientsInitialState: ClientsState = {
  clients: [],
  isLoading: true,
  clientsCount: 0,
  filters: {
    client: '',
    status: [],
    ordering: '',
    deletedOnly: false,
  },
  pageNumber: 0,
  changes: 0,
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState: clientsInitialState,
  reducers: {
    resetClientsState: () => clientsInitialState,
    setFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    clearFilters: (state) => {
      state.filters = {
        client: '',
        status: [],
        ordering: '',
        deletedOnly: false,
      };
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClients.fulfilled, (state, action) => {
        state.clients = action.payload.results;
        state.clientsCount = action.payload.count;
        state.isLoading = false;
      })
      .addCase(getClients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteClients.fulfilled, (state) => {
        state.isLoading = false;
        state.changes += 1;
      })
      .addCase(deleteClients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(restoreClients.fulfilled, (state) => {
        state.isLoading = false;
        state.changes += 1;
      })
      .addCase(restoreClients.pending, (state) => {
        state.isLoading = true;
      });
  },
});

export const getClients = createAsyncThunk(
  'clients/getClients',
  async ({
    pageNumber,
    filters = '',
  }: {
    pageNumber: number;
    filters?: string;
  }): Promise<PaginatedResponse<Client>> => {
    return await service.getClients(pageNumber, filters);
  }
);

export const deleteClients = createAsyncThunk(
  'clients/deleteClients',
  async (ids: string[]): Promise<any> => {
    return await service.deleteClients(ids);
  }
);

export const restoreClients = createAsyncThunk(
  'clients/restoreClients',
  async (ids: string[]): Promise<any> => {
    return await service.restoreClients(ids);
  }
);

export const { resetClientsState, setFilters, clearFilters, setPageNumber } = clientsSlice.actions;
