import { createTheme } from '@mui/material';

export const COLORS = {
  brand: {
    night: '#283952',
    green: '#14B2A9',
    paper: '#F5F9FF',
    white: '#FDFDFD',
  },
  secondary: {
    indigo: '#4E57D3',
    cian: '#1A97C0',
    galacticGradient: '#1A97C0',
    greenGradient: 'linear-gradient(258.77deg, #2EC4B6 5.72%, #0F9890 90.8%)',
    neutralGreenGradient: 'linear-gradient(to right, #034357, #188E88)',
  },
  feedback: { error: '#C5464', danger: '#C54646', lightDanger: '#e36262' },
  green: {
    900: '#074743',
    800: '#0C6A65',
    700: '#108E87',
    600: '#18A19A',
    500: '#43C1BA',
    400: '#72D0CB',
    300: '#A1E0DC',
    200: '#D0EFED',
    100: '#E7F7F6',
    50: '#F5FDFC',
  },
  indigo: {
    900: '#030778',
    800: '#161B9B',
    700: '#3639C6',
    600: '#4E57D3',
    500: '#4F55FD',
    400: '#698AFF',
    300: '#8DA6FF',
    200: '#A9BCFF',
    100: '#DBE3FF',
    50: '#ECF1FC',
  },
  cian: {
    900: '#034357',
    800: '#0B607D',
    700: '#137FA3',
    600: '#1A97C0',
    500: '#45B4D9',
    400: '#6DCEEF',
    300: '#9DDCF1',
    200: '#CEEDF8',
    100: '#E6F6FB',
    50: '#F4FCFF',
  },
  gray: {
    1400: '#132B40',
    1300: '#273B58',
    1200: '#3B4567',
    1100: '#4F5A84',
    1000: '#91A2BB',
    900: '#B8C8DE',
    800: '#D9E1F2',
    700: '#DDE6F8',
    600: '#E4EEFC',
    500: '#DEE9FA',
    400: '#EBF2FC',
    300: '#F1F6FD',
    200: '#F6FAFE',
    100: '#FAFCFE',
    50: '#FFFFFF',
  },
};

export const lightTheme = createTheme({
  components: {
    MuiPaper: {
      defaultProps: { elevation: 0 },
    },
    MuiTextField: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiGrid: {
      defaultProps: { spacing: 3 },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTypography: {
      defaultProps: { sx: { fontFamily: 'Open Sauce One' } },
    },
    MuiDialog: {
      defaultProps: { BackdropProps: { sx: { background: `${COLORS.gray[1300]}cc` } } },
    },
    MuiDrawer: {
      defaultProps: { BackdropProps: { sx: { background: `${COLORS.gray[1300]}cc` } } },
    },
    MuiModal: {
      defaultProps: { BackdropProps: { sx: { background: `transparent` } } },
    },
    MuiSkeleton: {
      defaultProps: { sx: { backgroundColor: COLORS.gray[600], borderRadius: '12px' } },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent', // Set the hover background color to transparent
          },
        },
      },
    },
  },
  typography: {
    fontSize: 14,
    h1: {
      fontSize: 60,
      lineHeight: '150%',
    },
    h2: {
      fontSize: 48,
      lineHeight: '150%',
    },
    h3: {
      fontSize: 40,
      lineHeight: '150%',
      fontWeight: 300,
    },
    h4: {
      fontSize: 32,
      lineHeight: '150%',
      fontWeight: 300,
    },
    h5: {
      fontSize: 24,
      lineHeight: '150%',
    },
    h6: {
      fontSize: 20,
      lineHeight: '150%',
    },
    body1: {
      fontSize: 14,
      fontFamily: 'Open Sauce One',
    },
    body2: {
      fontSize: 16,
    },
  },
  palette: {
    background: {
      default: COLORS.gray[200],
    },
    primary: {
      main: COLORS.indigo[600],
    },
    secondary: {
      main: COLORS.indigo[600],
    },
  },
});
