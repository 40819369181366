import { decamelize } from 'humps';
import {
  APIGetDiscounts,
  APIGetTransfers,
  APIGetIncreaseBuyerSublimitRequests,
  APIGetInvoiceAggregates,
  APIGetInvoices,
  APIGetMarcoWrapped,
  AvailableCreditLine,
  BankAccount,
  BuyerDetails,
  BuyersCompanyInformation,
  CreditFacility,
  CreditFacilityBalance,
  CreditLineMetrics,
  WithdrawMoney,
  ReportParams,
  APICompanyContacts,
  APICompanyInformation,
  APIGetPayments,
  APIGetPaymentDetails,
  APIWithdrawalRequest,
  APIGetTransfersDetails,
} from '../types/servicing';
import axios from './axios';

export function getCreditLineMainMetrics(companyId: number): Promise<CreditLineMetrics> {
  return axios
    .get(`/companies/${companyId}/products/factoring/factoring_data/credit_summary/`)
    .then(({ data }) => {
      const simplifiedMetrics: CreditLineMetrics = {
        availableCredit: null,
        creditLineSize: null,
        netFundsEmployed: null,
        cashReserves: null,
        reserveEscrow: null,
      };

      Object.keys(data).forEach((key) => {
        const metric = data[key];
        if (metric && typeof metric.amount === 'number') {
          simplifiedMetrics[key as keyof CreditLineMetrics] = metric.amount;
        }
      });

      return simplifiedMetrics;
    });
}

export function getCreditFacilities(companyId: number): Promise<CreditFacility[]> {
  return axios
    .get(`/companies/${companyId}/products/factoring/factoring_data/credit_facilities/`)
    .then(({ data }) => data);
}

export function getCreditFacilityBalance(
  companyId: number,
  facilityId: number
): Promise<CreditFacilityBalance> {
  return axios
    .get(`/companies/${companyId}/products/factoring/factoring_data/factoring_credit_facility_balances/`)
    .then(({ data }) => {
      const simplifiedDetails: CreditFacilityBalance = {
        advanceRate: null,
        availableCredit: null,
        creditLineSize: null,
        netFundsEmployed: null,
      };

      Object.keys(data).forEach((key) => {
        const detail = data[key];
        if (detail && typeof detail.amount === 'number') {
          simplifiedDetails[key as keyof CreditFacilityBalance] = detail.amount;
        } else {
          simplifiedDetails[key as keyof CreditFacilityBalance] = detail;
        }
      });

      return simplifiedDetails;
    });
}

export function getBuyersCompanyInformation(
  companyId: number,
  skip: number,
  filters?: any
): Promise<BuyersCompanyInformation> {
  const params = new URLSearchParams();
  params.append('skip', `${skip}`);
  params.append('limit', `10`);
  filters &&
    Object.keys(filters).forEach((key) => {
      params.append(key, filters[key]);
    });
  return axios
    .get(`/companies/${companyId}/products/factoring/factoring_data/buyers_balances?${params.toString()}`)
    .then(({ data }) => data);
}

export function getBuyerDetails(companyId: number, buyerId: string): Promise<BuyerDetails> {
  return axios
    .get(`/companies/${companyId}/products/factoring/factoring_data/buyer_information/${buyerId}/`)
    .then(({ data }) => data);
}

export function getAvailableCreditLine(companyId: number): Promise<AvailableCreditLine> {
  return axios
    .get(`/companies/${companyId}/products/factoring/factoring_data/factoring_credit_facility_balances/`)
    .then(({ data }) => data);
}

export function getClientBanks(companyId: number): Promise<BankAccount[]> {
  return axios
    .get(`/companies/${companyId}/products/factoring/factoring_data/bank_information/`)
    .then(({ data }) => data);
}

export function submitWithdrawalRequest(
  companyId: number,
  payload: WithdrawMoney
): Promise<APIWithdrawalRequest> {
  return axios
    .post(`/companies/${companyId}/products/factoring/services/request_withdrawal/`, payload)
    .then(({ data }) => data);
}

export function getFactoredInvoices(
  companyId: number,
  limit = 10,
  skip = 0,
  filters?: any,
  isAdmin?: boolean
): Promise<APIGetInvoices> {
  const params = new URLSearchParams();
  params.append('skip', `${skip}`);
  params.append('limit', `${limit}`);
  filters &&
    Object.keys(filters).forEach((key) => {
      if (filters[key] === 'openAndOverdue') {
        params.append(key, 'open');
        params.append(key, 'overdue');
      } else {
        params.append(key, filters[key]);
      }
    });
  return axios
    .get(
      `${
        isAdmin ? '/admins' : ''
      }/companies/${companyId}/products/factoring/factoring_data/financing_invoices/?${params.toString()}`
    )
    .then(({ data }) => data);
}

export function getInvoicesAggregates(companyId: number, filters?: any): Promise<APIGetInvoiceAggregates> {
  const params = new URLSearchParams();
  filters &&
    Object.keys(filters).forEach((key) => {
      if (filters[key] === 'openAndOverdue') {
        params.append(key, 'open');
        params.append(key, 'overdue');
      } else {
        params.append(key, filters[key]);
      }
    });

  return axios
    .get(
      `/companies/${companyId}/products/factoring/factoring_data/invoice_fin_aggregates/?${params.toString()}`
    )
    .then(({ data }) => data);
}

export function requestReactivationCreditLines(companyId: number): Promise<any> {
  return axios
    .post(`/companies/${companyId}/products/factoring/services/request_credit_line_reactivation/`)
    .then(({ data }) => data);
}

export function getRequestReactivationCreditLines(companyId: number): Promise<any> {
  return axios
    .get(`/companies/${companyId}/products/factoring/services/request_credit_line_reactivation/`)
    .then(({ data }) => data);
}

export function exportInvoices(companyId: number, filters?: any): Promise<Blob> {
  const params = new URLSearchParams();
  filters &&
    Object.keys(filters).forEach((key) => {
      if (filters[key] === 'openAndOverdue') {
        params.append(key, 'open');
        params.append(key, 'overdue');
      } else {
        params.append(key, filters[key]);
      }
    });
  return axios
    .get(
      `/companies/${companyId}/products/factoring/factoring_data/financing_invoices/export/?${params.toString()}`,
      {
        responseType: 'blob',
      }
    )
    .then((response) => response.data);
}

export function submitAddNewBuyerRequest(companyId: number, payload: any) {
  return axios
    .post(`/companies/${companyId}/products/factoring/services/request_add_buyer/`, payload)
    .then(({ data }) => data);
}

export function submitIncreaseBuyerSublimitRequest(companyId: number, payload: any) {
  return axios
    .post(`/companies/${companyId}/products/factoring/services/request_buyer_sublimit_increase/`, payload)
    .then(({ data }) => data);
}

export function getIncreaseBuyerSublimitRequests(
  companyId: number
): Promise<APIGetIncreaseBuyerSublimitRequests> {
  return axios
    .get(`/companies/${companyId}/products/factoring/services/request_buyer_sublimit_increase/`)
    .then(({ data }) => data);
}

export function submitIncreaseCreditLineRequest(companyId: number, payload: any) {
  return axios
    .post(`/companies/${companyId}/products/factoring/services/request_credit_line_increase/`, payload)
    .then(({ data }) => data);
}

export function getPayments(companyId: number, limit = 10, skip = 0, filters?: any): Promise<APIGetPayments> {
  const params = new URLSearchParams();
  params.append('skip', `${skip}`);
  params.append('limit', `${limit}`);
  filters && Object.keys(filters).forEach((key) => params.append(key, filters[key]));
  return axios
    .get(`/companies/${companyId}/products/factoring/factoring_data/payments/?${params.toString()}`)
    .then(({ data }) => data);
}

export function getPaymentDetails(
  companyId: number,
  limit = 10,
  skip = 0,
  filters?: any
): Promise<APIGetPaymentDetails> {
  const params = new URLSearchParams();
  params.append('skip', `${skip}`);
  params.append('limit', `${limit}`);
  filters && Object.keys(filters).forEach((key) => params.append(key, filters[key]));
  return axios
    .get(`/companies/${companyId}/products/factoring/factoring_data/payments/details/?${params.toString()}`)
    .then(({ data }) => data);
}

export function getDiscounts(companyId: number, invoiceId: number): Promise<APIGetDiscounts> {
  return axios
    .get(
      `/companies/${companyId}/products/factoring/factoring_data/invoice_financing/${invoiceId}/discounts/`
    )
    .then(({ data }) => data);
}

export function generateReport(
  companyId: number,
  reportKey: string,
  reportParams: ReportParams
): Promise<Blob> {
  const params = new URLSearchParams();
  Object.keys(reportParams).forEach((key) => {
    params.append(decamelize(key), reportParams[key]);
  });
  return axios
    .get(
      `/companies/${companyId}/products/factoring/factoring_data/${reportKey}/export/?${params.toString()}`,
      {
        responseType: 'blob',
      }
    )
    .then(({ data }) => data);
}

export function getMarcoWrapped(companyId: number): Promise<APIGetMarcoWrapped> {
  return axios
    .get(`/companies/${companyId}/products/factoring/factoring_data/wrapped/`)
    .then(({ data }) => data);
}

export function exportBuyers(companyId: number, filters?: any): Promise<Blob> {
  const params = new URLSearchParams();
  filters &&
    Object.keys(filters).forEach((key) => {
      params.append(key, filters[key]);
    });
  return axios
    .get(
      `/companies/${companyId}/products/factoring/factoring_data/buyers_balances/export/?${params.toString()}`,
      {
        responseType: 'blob',
      }
    )
    .then((response) => response.data);
}

export function getCompanyContacts(companyId: number): Promise<APICompanyContacts> {
  return axios
    .get(`/companies/${companyId}/products/factoring/factoring_data/contacts/`)
    .then(({ data }) => data);
}

export function submitFundingRequest(companyId: number, payload: FormData): Promise<any> {
  return axios
    .post(`/companies/${companyId}/products/factoring/services/request_invoice_funding/`, payload)
    .then(({ data }) => data);
}

export function getCompanyInformation(companyId: number): Promise<APICompanyInformation> {
  return axios
    .get(`/companies/${companyId}/products/factoring/factoring_data/company_information/`)
    .then(({ data }) => data);
}

export function getLastWithdrawalRelease(companyId: number): Promise<APIWithdrawalRequest> {
  return axios
    .get(`/companies/${companyId}/products/factoring/services/request_withdrawal/`)
    .then(({ data }) => data);
}

export function getTransfers(
  companyId: number,
  limit = 10,
  skip = 0,
  filters?: any
): Promise<APIGetTransfers> {
  const params = new URLSearchParams();
  params.append('skip', `${skip}`);
  params.append('limit', `${limit}`);
  filters &&
    Object.keys(filters).forEach((key) => {
      params.append(key, filters[key]);
    });
  return axios
    .get(`/companies/${companyId}/products/factoring/factoring_data/transfers/?${params.toString()}`)
    .then(({ data }) => data);
}

export function getTransferDetails(
  companyId: number,
  limit = 10,
  skip = 0,
  filters?: any
): Promise<APIGetTransfersDetails> {
  const params = new URLSearchParams();
  params.append('skip', `${skip}`);
  params.append('limit', `${limit}`);
  filters && Object.keys(filters).forEach((key) => params.append(key, filters[key]));
  return axios
    .get(`/companies/${companyId}/products/factoring/factoring_data/transfers/details/?${params.toString()}`)
    .then(({ data }) => data);
}
