export default function SmallDocumentIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="url(#paint0_linear_2225_210)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 9H8.25"
        stroke="url(#paint1_linear_2225_210)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 15H8.25"
        stroke="url(#paint2_linear_2225_210)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2225_210"
          x1="4.1258"
          y1="14.524"
          x2="15.943"
          y2="20.7955"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0B3458" />
          <stop offset="1" stopColor="#03216D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2225_210"
          x1="9.04717"
          y1="9.6262"
          x2="9.38438"
          y2="10.9684"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0B3458" />
          <stop offset="1" stopColor="#03216D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2225_210"
          x1="9.04717"
          y1="15.6262"
          x2="9.38438"
          y2="16.9684"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0B3458" />
          <stop offset="1" stopColor="#03216D" />
        </linearGradient>
      </defs>
    </svg>
  );
}
