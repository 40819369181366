import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '../../componentsv2/Chip';
import Table from '../../componentsv2/Table';
import Typography from '../../componentsv2/Typography';
import Section from '../../componentsv2/Section';
import { useTranslation } from '../../hooks/i18n';
import Button from '../../componentsv2/Button';
import { logEvent } from '@amplitude/analytics-browser';
import { bankAccountEvents, myAccountEvents } from '../../constants/events';
import { AddIcon, CrossIcon, TrashIcon } from '../../components/Icons';
import {
  ApprovedBankAccount,
  BankAccountFormValues,
  BankAccountRequest,
  isMxAccount,
  isRowAccount,
  isUsaAccount,
} from '../../types/bankAccount';
import { Divider, IconButton, Skeleton, TextField, useMediaQuery, useTheme } from '@mui/material';
import ModalV2 from '../../components/ModalV2';
import AccountInformationForm from './AccountInformationForm';
import { SelectField } from '../../componentsv2/Field';
import Form from '../../componentsv2/Form';
import { Formik } from 'formik';
import { bankAccountCountries } from '../../constants/data';
import { baseURL } from '../../constants/api';
import { UploadFile } from '@mui/icons-material';
import { APIAsset } from '../../types/application';
import { TFunction } from 'react-i18next';
import {
  addBankAccountRequest,
  adminApproveBankAccountRequest,
  adminGetApprovedBankAccounts,
  adminGetBankAccountRequests,
  adminRejectBankAccountRequest,
  deleteBankAccountAsset,
  editBankAccountRequest,
  getApprovedBankAccounts,
  getBankAccountRequests,
  uploadBankAccountAssets,
} from '../../services/bankAccounts';
import { COLORS } from '../../themev2';
import ErrorMessage from '../../components/ErrorMessage';
import { useSnackbar } from '../../context';

function formatAssetsAsFiles(assets: APIAsset[]): File[] {
  return assets.map((asset) => ({ ...asset, name: asset.fileName })) as any;
}

const statusColorsMap: Record<string, object> = {
  APPROVED: { backgroundColor: '#E1FAED', color: '#11705F' },
  IN_REVIEW: { backgroundColor: '#E8F0FC', color: '#3B4567' },
  REJECTED: { backgroundColor: '#FEE3E3', color: '#930909' },
};

const bankAccountInitialValues: BankAccountFormValues = {
  companyLegalName: '',
  bankName: '',
  bankAccountNumber: '',
  accountType: '',
  country: 'usa',
  currency: 'USD',
  bankingDataType: '',
  abaRoutingNumber: '',
  treasuryFinanceEmail: '',
  clabe: '',
  swiftCode: '',
  ownerAddress: '',
  state: '',
  city: '',
  postalCode: '',
};

const requiredForCountries = (countries: string[], t: TFunction) =>
  Yup.string().when('country', {
    is: (country: string) => countries.includes(country),
    then: Yup.string().required(t('common:requiredField')),
  });

const optionalForCountries = (countries: string[], t: TFunction) =>
  Yup.string().when('country', {
    is: (country: string) => !countries.includes(country),
    then: Yup.string().required(t('common:requiredField')),
  });

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    companyLegalName: Yup.string().required(t('common:requiredField')),
    bankName: Yup.string().required(t('common:requiredField')),
    accountType: Yup.string().required(t('common:requiredField')),
    currency: Yup.string().required(t('common:requiredField')),
    bankingDataType: requiredForCountries(['usa'], t),
    bankAccountNumber: Yup.string().when('country', {
      is: (country: string) => country !== 'mexico',
      then: Yup.string().required(t('common:requiredField')),
    }),
    abaRoutingNumber: requiredForCountries(['usa'], t),
    clabe: requiredForCountries(['mexico'], t),
    swiftCode: Yup.string().when('country', {
      is: (country: string) => country !== 'usa',
      then: Yup.string().required(t('common:requiredField')),
    }),
    ownerAddress: optionalForCountries(['usa', 'mexico'], t),
    state: optionalForCountries(['usa', 'mexico'], t),
    city: optionalForCountries(['usa', 'mexico'], t),
    postalCode: optionalForCountries(['usa', 'mexico'], t),
  });

const MobileFiles = ({
  files,
  disabled,
  onEditFiles,
  onDeleteFile,
}: {
  files: File[];
  disabled: boolean;
  onEditFiles: (files: File[]) => void;
  onDeleteFile: (file: File) => void;
}) => {
  const { t } = useTranslation(['myAccount', 'user']);
  const uploaderRef = useRef<any>(null);

  return (
    <Section sx={{ background: COLORS.gray[200] }}>
      <input
        type="file"
        multiple
        style={{ display: 'none' }}
        ref={uploaderRef}
        onChange={async (e: any) => {
          const _files = Array.from(e.target.files) as File[];
          onEditFiles(_files);
          e.target.value = null;
        }}
      />
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography sx={{ fontWeight: 600 }}>
                    {t('bankAccounts.requiredDocs.bankLetter')}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    disabled={disabled}
                    onClick={() => {
                      uploaderRef.current && uploaderRef.current.click();
                    }}>
                    <UploadFile sx={{ color: COLORS.indigo[500] }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            {files.map((file, index) => (
              <Grid item key={file.name}>
                <Typography
                  sx={{
                    backgroundColor: '#E1FAED',
                    color: '#11705F',
                    padding: '0.2em 1em',
                    borderRadius: '20px',
                  }}>
                  {file.name}
                  <IconButton
                    disabled={disabled}
                    onClick={() => {
                      onDeleteFile(file);
                    }}>
                    <CrossIcon size="sm" />
                  </IconButton>
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
};

const DesktopFiles = ({
  files,
  disabled,
  onEditFiles,
  onDeleteFile,
}: {
  files: File[];
  disabled: boolean;
  onEditFiles: (files: File[]) => void;
  onDeleteFile: (file: File) => void;
}) => {
  const { t } = useTranslation(['myAccount', 'user']);
  const uploaderRef = useRef<any>(null);

  return (
    <>
      <input
        type="file"
        multiple
        style={{ display: 'none' }}
        ref={uploaderRef}
        onChange={async (e: any) => {
          const _files = Array.from(e.target.files) as File[];
          onEditFiles(_files);
          e.target.value = null;
        }}
      />
      <Table
        data={[{}]}
        columns={[
          {
            headerName: t('bankAccounts.requiredDocs.documents'),
            renderCell: () => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Typography>{t('bankAccounts.requiredDocs.bankLetter')}</Typography>
                </Box>
              );
            },
          },
          {
            headerName: t('bankAccounts.requiredDocs.files'),
            renderCell: () => {
              return files.length > 0 ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    {files.map((file: any, index) => {
                      const url = !!file.uuid
                        ? `${baseURL}/assets/${file.uuid}`
                        : URL.createObjectURL(new Blob([file]));

                      return (
                        <Box
                          key={index}
                          sx={{
                            display: 'flex',
                            justifyContent: { xs: 'initial', md: 'center' },
                            alignItems: 'center',
                            backgroundColor: '#E1FAED',
                            color: '#11705F',
                            padding: '0.2em 1em',
                            borderRadius: '20px',
                            gap: '8px',
                          }}>
                          <a
                            href={url}
                            download={file.name}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              textDecoration: 'none',
                              flex: 1,
                            }}>
                            <Typography>{file.name}</Typography>
                          </a>
                          <IconButton
                            disabled={disabled}
                            sx={{ padding: 0 }}
                            onClick={() => {
                              onDeleteFile(file);
                            }}>
                            <CrossIcon size="sm" />
                          </IconButton>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    sx={{
                      backgroundColor: '#FCF0DB',
                      color: '#AC8318',
                      padding: '0.2em 1em',
                      borderRadius: '20px',
                    }}>
                    {t('bankAccounts.requiredDocs.pending')}
                  </Typography>
                </Box>
              );
            },
          },
          {
            headerName: t('bankAccounts.requiredDocs.actions'),
            renderCell: () => (
              <>
                <Button
                  disabled={disabled}
                  type="link"
                  color="secondary"
                  startIcon={<UploadFile />}
                  onClick={() => {
                    uploaderRef.current && uploaderRef.current.click();
                  }}>
                  {t('bankAccounts.requiredDocs.uploadBtn')}
                </Button>
              </>
            ),
          },
        ]}></Table>
    </>
  );
};

type ClientBankAccountsProps = { companyId: number; isAdmin?: boolean };

const ClientBankAccounts = ({ companyId, isAdmin = false }: ClientBankAccountsProps) => {
  const { t } = useTranslation(['myAccount', 'user']);
  const [isLoadingBankAccountRequests, setIsLoadingBankAccountRequests] = useState(true);
  const [bankAccountRequests, setBankAccountRequests] = useState<BankAccountRequest[]>([]);
  const [approvedBankAccounts, setApprovedBankAccounts] = useState<ApprovedBankAccount[]>([]);
  const [isLoadingApprovedBankAccounts, setIsLoadingApprovedBankAccounts] = useState(false);
  const [currentBankAccount, setCurrentBankAccount] = useState<number>();
  const [files, setFiles] = useState<File[]>([]);
  const [showBankAccountModal, setShowBankAccountModal] = useState(false);
  const [isSubmittingBankAccount, setIsSubmittingBankAccount] = useState(false);
  const [showRejectionConfirmationModal, setShowRejectConfirmationModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const { showSnackbar } = useSnackbar();

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const isEdit = currentBankAccount !== undefined;
  const _bankAccountRequests = [
    ...bankAccountRequests.filter((bankAccount) => (isAdmin ? true : bankAccount.status !== 'APPROVED')), // filter out approved ones only for clients
  ];
  const isEdittingDisabled =
    isEdit &&
    currentBankAccount !== undefined &&
    _bankAccountRequests[currentBankAccount].status !== 'REJECTED';

  function handleEditFiles(bankAccountRequest: BankAccountRequest | undefined, files: File[]) {
    if (isAdmin) return; // prevent admins from accidentally editing files

    setFiles((prev: any) => [...prev, ...files]);
    if (isEdit) {
      uploadBankAccountAssets(companyId, bankAccountRequest!.id, files).then(({ data }) => {
        updateRequests(data);
      });
    }
    logEvent(bankAccountEvents.ADD_FILES, { files: files.map((f) => f.name) });
  }

  function handleDeleteFile(bankAccountRequest: BankAccountRequest | undefined, file: File) {
    if (isAdmin) return; // prevent admins from accidentally deleting files

    setFiles((prev: any[]) => prev.filter((fi) => fi !== file));
    if (isEdit) {
      deleteBankAccountAsset(companyId, bankAccountRequest!.id, (file as unknown as APIAsset).id);
    }
    logEvent(bankAccountEvents.REMOVE_FILE, { file: file.name });
  }

  function updateRequests(result: BankAccountRequest) {
    // updates bank account requests with endpoint result
    setBankAccountRequests((prev) => prev.map((request) => (request.id === result.id ? result : request)));
  }

  useEffect(() => {
    if (!isAdmin) logEvent(bankAccountEvents.VIEW_PAGE);
  }, []);

  useEffect(() => {
    if (currentBankAccount !== undefined)
      setFiles(formatAssetsAsFiles(_bankAccountRequests[currentBankAccount!].assets));
  }, [currentBankAccount]);

  useEffect(() => {
    setIsLoadingBankAccountRequests(true);
    const _getBankAccountRequests = isAdmin ? adminGetBankAccountRequests : getBankAccountRequests;
    _getBankAccountRequests(companyId)
      .then((data) => {
        setBankAccountRequests(data);
      })
      .finally(() => {
        setIsLoadingBankAccountRequests(false);
      });
  }, []);

  useEffect(() => {
    setIsLoadingApprovedBankAccounts(true);
    const _getApprovedBankAccounts = isAdmin ? adminGetApprovedBankAccounts : getApprovedBankAccounts;
    _getApprovedBankAccounts(companyId)
      .then((data) => {
        setApprovedBankAccounts(data);
      })
      .finally(() => {
        setIsLoadingApprovedBankAccounts(false);
      });
  }, []);

  if (isLoadingBankAccountRequests || isLoadingApprovedBankAccounts) {
    return <Skeleton variant="rectangular" height={200} animation="wave" />;
  }

  return (
    <Box sx={{ display: 'flex', minHeight: isAdmin ? 'initial' : '100vh', flexDirection: 'column' }}>
      <Section>
        <Grid container>
          <Grid item xs>
            <Typography fontWeight="600" sx={{ fontSize: '18px' }}>
              {t(`bankAccounts.${isAdmin ? 'adminTitle' : 'title'}`)}
            </Typography>
          </Grid>
          {!isAdmin && (
            <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: { md: 'flex-end' } }}>
              <Button
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => {
                  logEvent(bankAccountEvents.OPEN_ADD_MODAL);
                  setShowBankAccountModal(true);
                }}>
                {t('bankAccounts.addAccount')}
              </Button>
            </Grid>
          )}
          {approvedBankAccounts.length > 0 && (
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '2em' }}>
              {approvedBankAccounts.map(({ bankName, bankCurrency, bankAccountNo }, index) => (
                <Grid container key={index} alignItems="center">
                  <Grid item xs={7} md={10}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} md={2}>
                        <Chip
                          label={
                            <Typography fontWeight={600} sx={{ color: 'inherit' }}>
                              {t(`bankAccounts.status.APPROVED`)}
                            </Typography>
                          }
                          sx={{ ...statusColorsMap['APPROVED'] }}
                        />
                      </Grid>
                      <Grid item xs={12} md>
                        <Typography sx={{ fontWeight: 600 }}>{bankName}</Typography>
                        <Typography>
                          {bankCurrency} - {bankAccountNo}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '2em' }}>
            {_bankAccountRequests.length > 0 ? (
              _bankAccountRequests.map(({ accountInformation, status, rejectedComment, id }, index) => (
                <Grid container key={index} alignItems="center">
                  <Grid item xs={7} md>
                    <Grid container alignItems="center">
                      <Grid item xs={12} md={2}>
                        <Chip
                          label={
                            <Typography fontWeight={600} sx={{ color: 'inherit' }}>
                              {t(`bankAccounts.status.${status}`)}
                            </Typography>
                          }
                          sx={{ ...statusColorsMap[status] }}
                        />
                      </Grid>
                      <Grid item xs={12} md>
                        <Typography sx={{ fontWeight: 600 }}>{accountInformation.bankName}</Typography>
                        <Typography>
                          {`${accountInformation.currency} - ${t(
                            `bankAccounts.accountType.options.${accountInformation.accountType}`
                          )}`}
                        </Typography>
                        {isMxAccount(accountInformation) && (
                          <Typography>
                            {`${accountInformation.companyLegalName} - ${accountInformation.clabe}`}
                          </Typography>
                        )}
                        {isUsaAccount(accountInformation) && (
                          <Typography>
                            {`${accountInformation.companyLegalName} - ${t(
                              `bankAccounts.bankingDataType.options.${accountInformation.bankingDataType}`
                            )}`}
                          </Typography>
                        )}
                        {isRowAccount(accountInformation) && (
                          <Typography>{accountInformation.companyLegalName}</Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    md={isAdmin ? 3 : 2}
                    sx={{ display: 'flex', justifyContent: 'flex-end', gap: '4px' }}>
                    <Button
                      type="link"
                      color="secondary"
                      onClick={() => {
                        setCurrentBankAccount(index);
                        setShowBankAccountModal(true);
                        logEvent(
                          status === 'REJECTED'
                            ? bankAccountEvents.OPEN_EDIT_MODAL
                            : bankAccountEvents.OPEN_DETAILS_MODAL,
                          accountInformation
                        );
                      }}>
                      {t(`bankAccounts.edit.${status}`)}
                    </Button>
                    {isAdmin && (
                      <>
                        <Button
                          onClick={() => {
                            if (
                              window.confirm(
                                'Are you sure you want to approve this account?\n\n\nIMPORTANT: make sure it is already loaded on Factorsoft!'
                              )
                            ) {
                              adminApproveBankAccountRequest(companyId, id).then((result) => {
                                updateRequests(result);
                                logEvent(
                                  bankAccountEvents.ACCEPT_BANK_ACCOUNT,
                                  bankAccountRequests[currentBankAccount!]
                                );
                              });
                            }
                          }}>
                          {t(`bankAccounts.approve`)}
                        </Button>
                        <Button
                          color="danger"
                          onClick={() => {
                            setCurrentBankAccount(index);
                            setShowRejectConfirmationModal(true);
                          }}>
                          {t(`bankAccounts.reject`)}
                        </Button>
                      </>
                    )}
                  </Grid>
                  {rejectedComment && status === 'REJECTED' && (
                    <Grid item xs={12}>
                      <ErrorMessage errorMessage={rejectedComment} />{' '}
                    </Grid>
                  )}
                  {index !== _bankAccountRequests.length - 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </Grid>
              ))
            ) : (
              <Typography> {t(`bankAccounts.${isAdmin ? 'adminDescription' : 'description'}`)}</Typography>
            )}
          </Grid>
          {showBankAccountModal && (
            <ModalV2
              sx={{ width: '900px', height: '600px' }}
              isOpen={showBankAccountModal}
              handleClose={() => {
                setCurrentBankAccount(undefined);
                setShowBankAccountModal(false);
                setFiles([]);
              }}>
              <Grid container>
                <Formik
                  validationSchema={validationSchema(t)}
                  initialValues={{
                    country:
                      currentBankAccount !== undefined
                        ? _bankAccountRequests[currentBankAccount].country
                        : 'usa',
                    ...(currentBankAccount !== undefined
                      ? _bankAccountRequests[currentBankAccount].accountInformation
                      : bankAccountInitialValues),
                  }}
                  onSubmit={async (values) => {
                    setIsSubmittingBankAccount(true);
                    if (isEdit) {
                      const result = await editBankAccountRequest(
                        companyId,
                        _bankAccountRequests[currentBankAccount!].id,
                        values as BankAccountFormValues
                      );
                      updateRequests(result);
                    } else {
                      const result = await addBankAccountRequest(
                        companyId,
                        values as BankAccountFormValues,
                        files
                      );
                      setBankAccountRequests((prev) => [...prev, result]);
                    }
                    logEvent(
                      isEdit ? bankAccountEvents.SUBMIT_EDIT_ACCOUNT : bankAccountEvents.SUBMIT_NEW_ACCOUNT,
                      { ...values, ...(files.length ? { files: files.map((f) => f.name) } : {}) }
                    );
                    setIsSubmittingBankAccount(false);
                    showSnackbar(
                      t(`bankAccounts.${isEdit ? 'successfullyUpdated' : 'successfullyCreated'}`),
                      'success'
                    );
                    setShowBankAccountModal(false);
                    setFiles([]);
                  }}>
                  {({ values, isValid, dirty }) => (
                    <Form trackingEvent={bankAccountEvents.EDIT_FIELD}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            {isEdit ? t('bankAccounts.editTitle') : t('bankAccounts.addTitle')}
                          </Typography>
                          <Typography>{t('bankAccounts.modalDescription')}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <SelectField
                            disabled={isEdittingDisabled}
                            name="country"
                            label={t('bankAccounts.country.label')}
                            options={bankAccountCountries
                              .sort((a, b) =>
                                t(`user:preapproval.location.options.${a}`).localeCompare(
                                  t(`user:preapproval.location.options.${b}`)
                                )
                              )
                              .map((country) => ({
                                value: country,
                                label: t(`user:preapproval.location.options.${country}`),
                              }))}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <AccountInformationForm country={values.country} disabled={isEdittingDisabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography sx={{ fontWeight: 600, marginBottom: '1em' }}>
                            {t('bankAccounts.requiredDocs.title')}
                          </Typography>
                          {isMdDown ? (
                            <Grid item xs={12}>
                              <MobileFiles
                                files={files}
                                disabled={isEdittingDisabled}
                                onEditFiles={(files: File[]) => {
                                  handleEditFiles(_bankAccountRequests[currentBankAccount!], files);
                                }}
                                onDeleteFile={(file: File) => {
                                  handleDeleteFile(_bankAccountRequests[currentBankAccount!], file);
                                }}
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <DesktopFiles
                                files={files}
                                disabled={isEdittingDisabled}
                                onEditFiles={(files: File[]) => {
                                  handleEditFiles(_bankAccountRequests[currentBankAccount!], files);
                                }}
                                onDeleteFile={(file: File) => {
                                  handleDeleteFile(_bankAccountRequests[currentBankAccount!], file);
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            isLoading={isSubmittingBankAccount}
                            isSubmit
                            fullWidth
                            color="secondary"
                            disabled={!dirty || !isValid || files.length < 1 || isSubmittingBankAccount}>
                            {t(`bankAccounts.saveBtn`)}
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </ModalV2>
          )}
          {showRejectionConfirmationModal && (
            <ModalV2
              isOpen={showRejectionConfirmationModal}
              handleClose={() => {
                setShowRejectConfirmationModal(false);
              }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography>Add rejection reason</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    minRows={4}
                    name="reason"
                    onChange={(e) => {
                      setRejectionReason(e.target.value);
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ gap: '4px', display: 'flex', justifyContent: 'center' }}>
                    <Button
                      color="danger"
                      onClick={() => {
                        if (rejectionReason) {
                          adminRejectBankAccountRequest(
                            companyId,
                            bankAccountRequests[currentBankAccount!].id,
                            rejectionReason
                          ).then((result) => {
                            updateRequests(result);
                            logEvent(
                              bankAccountEvents.REJECT_BANK_ACCOUNT,
                              bankAccountRequests[currentBankAccount!]
                            );
                          });
                          setRejectionReason('');
                          setShowRejectConfirmationModal(false);
                        } else {
                          alert('Please add a rejection reason');
                        }
                      }}>
                      Confirm
                    </Button>
                    <Button
                      type="outline"
                      color="danger"
                      onClick={() => {
                        setRejectionReason('');
                        setShowRejectConfirmationModal(false);
                      }}>
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </ModalV2>
          )}
        </Grid>
      </Section>
    </Box>
  );
};

export default ClientBankAccounts;
