import { APIAsset } from '../../types/application';
import { COLORS } from '../../themev2';

export const statusColorsMap: Record<string, object> = {
  PENDING: { backgroundColor: '#FCF0DB', color: '#AC8318' },
  SIGNED: { backgroundColor: '#E1FAED', color: '#11705F' },
  ACCEPTED: { backgroundColor: '#E1FAED', color: '#11705F' },
  INREVIEW: { backgroundColor: COLORS.gray[500], color: COLORS.gray[1200] },
  DENIED: { backgroundColor: '#FEE3E3', color: '#930909' },
};

export function formatAssetsAsFiles(assets: APIAsset[]): File[] {
  return assets.map((asset) => ({ ...asset, name: asset.fileName })) as any;
}

export const juridicalTaxIdTypes = [
  { label: 'EIN', value: 'EIN' },
  { label: 'RFC', value: 'RFC' },
  { label: 'NIT', value: 'NIT' },
  { label: 'RUT', value: 'RUT' },
  { label: 'RUC', value: 'RUC' },
  { label: 'CUIT', value: 'CUIT' },
  { label: 'CNPJ', value: 'CNPJ' },
  { label: 'BN', value: 'BN' },
  { label: 'Other', value: 'OTHER' },
];

export const physicalTaxIdTypes = [
  { label: 'ITIN', value: 'ITIN' },
  { label: 'RFC', value: 'RFC' },
  { label: 'SIN', value: 'SIN' },
  { label: 'NIT', value: 'NIT' },
  { label: 'RUT', value: 'RUT' },
  { label: 'RUC', value: 'RUC' },
  { label: 'CUIL', value: 'CUIL' },
  { label: 'CPF', value: 'CPF' },
  { label: 'Other', value: 'OTHER' },
];
