export default function BankingIcon() {
  return (
    <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M52.1502 9.74414L89.6502 24.744C91.1085 25.3274 92.2752 27.0773 92.2752 28.619V42.4524C92.2752 44.744 90.4002 46.619 88.1086 46.619H13.1086C10.8169 46.619 8.94189 44.744 8.94189 42.4524V28.619C8.94189 27.0773 10.1086 25.3274 11.5669 24.744L49.0669 9.74414C49.9002 9.41081 51.3169 9.41081 52.1502 9.74414Z"
        fill="url(#paint0_linear_9497_1902)"
      />
      <path
        opacity="0.4"
        d="M92.2752 92.4528H8.94189V79.9528C8.94189 77.6611 10.8169 75.7861 13.1086 75.7861H88.1086C90.4002 75.7861 92.2752 77.6611 92.2752 79.9528V92.4528Z"
        fill="url(#paint1_linear_9497_1902)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.693 46.6191V75.7858H16.2764V46.6191H26.693ZM46.1103 46.6191V75.7858H35.6937V46.6191H46.1103ZM65.5236 46.6191V75.7858H55.1069V46.6191H65.5236ZM84.9409 46.6191V75.7858H74.5243V46.6191H84.9409Z"
        fill="url(#paint2_linear_9497_1902)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.65039 92.4531C1.65039 90.7272 3.0495 89.3281 4.77539 89.3281H96.4421C98.168 89.3281 99.5671 90.7272 99.5671 92.4531C99.5671 94.179 98.168 95.5781 96.4421 95.5781H4.77539C3.0495 95.5781 1.65039 94.179 1.65039 92.4531Z"
        fill="url(#paint3_linear_9497_1902)"
      />
      <path
        d="M50.6086 36.2031C54.0604 36.2031 56.8586 33.4049 56.8586 29.9531C56.8586 26.5013 54.0604 23.7031 50.6086 23.7031C47.1569 23.7031 44.3586 26.5013 44.3586 29.9531C44.3586 33.4049 47.1569 36.2031 50.6086 36.2031Z"
        fill="#FDFDFD"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9497_1902"
          x1="1.99745"
          y1="53.45"
          x2="85.4695"
          y2="1.27834"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E57D3" />
          <stop offset="0.520833" stopColor="#2D95CD" />
          <stop offset="0.520933" stopColor="#2D96CD" />
          <stop offset="0.521033" stopColor="#2D96CC" />
          <stop offset="1" stopColor="#2EC4B6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9497_1902"
          x1="1.99745"
          y1="95.5195"
          x2="41.5035"
          y2="40.5181"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E57D3" />
          <stop offset="0.520833" stopColor="#2D95CD" />
          <stop offset="0.520933" stopColor="#2D96CD" />
          <stop offset="0.521033" stopColor="#2D96CC" />
          <stop offset="1" stopColor="#2EC4B6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9497_1902"
          x1="10.5543"
          y1="81.1525"
          x2="77.4543"
          y2="37.2982"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E57D3" />
          <stop offset="0.520833" stopColor="#2D95CD" />
          <stop offset="0.520933" stopColor="#2D96CD" />
          <stop offset="0.521033" stopColor="#2D96CC" />
          <stop offset="1" stopColor="#2EC4B6" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9497_1902"
          x1="-6.50933"
          y1="96.7281"
          x2="0.300257"
          y2="67.0226"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E57D3" />
          <stop offset="0.520833" stopColor="#2D95CD" />
          <stop offset="0.520933" stopColor="#2D96CD" />
          <stop offset="0.521033" stopColor="#2D96CC" />
          <stop offset="1" stopColor="#2EC4B6" />
        </linearGradient>
      </defs>
    </svg>
  );
}
