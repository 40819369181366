import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Button, { ButtonColor } from '../Button';
import Box from '@mui/material/Box';

export type ConfirmDialogProps = {
  open: boolean;
  title: string;
  content: string | React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  color?: ButtonColor;
  isLoading?: boolean;
};

const ConfirmDialog = ({
  title,
  content,
  onCancel,
  onConfirm,
  open,
  color = 'primary',
  isLoading = false,
}: ConfirmDialogProps) => {
  return (
    <Dialog open={open} sx={{ maxWidth: '100%' }}>
      <Box sx={{ padding: '2em' }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            type="outline"
            color={color}
            onClick={() => {
              onCancel();
            }}>
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            color={color}
            onClick={() => {
              onConfirm();
            }}>
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ConfirmDialog;
