import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/system';
import Typography from '../../componentsv2/Typography';
import { COLORS } from '../../themev2';
import { Nullable } from '../../types';
import Button from '../../componentsv2/Button';

type BannerProps = {
  heading: string;
  title: string;
  description: string;
  button: string;
  Icon: ReactNode;
  handleOnClick?: Nullable<Function>;
  isHideable?: boolean;
  customSx?: SxProps<Theme>;
};

const PipelineBanner = ({
  heading,
  title,
  description,
  button,
  Icon,
  handleOnClick = null,
  isHideable = false,
  customSx = {
    background: 'linear-gradient(298.1deg, #0E3354 25.23%, #134178 83.53%)',
  },
}: BannerProps) => {
  if (isHideable) return null;
  return (
    <Box sx={{ minHeight: '150px', padding: '2em', borderRadius: '8px', ...customSx }}>
      <Grid container columnGap={1} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} md={8}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <Typography sx={{ display: 'flex', alignItems: 'center', gap: '4px', color: '#D3F0FA' }}>
                {Icon}
                {heading}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ color: COLORS.brand.white, fontWeight: 700 }}>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ color: '#D3F0FA' }}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {!!handleOnClick && (
          <Grid
            item
            xs={12}
            md={3}
            sx={{ display: 'flex', justifyContent: { xs: 'initial', md: 'flex-end' } }}>
            <Button
              fullWidth
              onClick={() => {
                handleOnClick();
              }}>
              {button}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PipelineBanner;
