import { COLORS } from '../../themev2';

export default function HomeIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M18.67 2.24512H16.77C14.59 2.24512 13.44 3.39512 13.44 5.57512V7.47512C13.44 9.65512 14.59 10.8051 16.77 10.8051H18.67C20.85 10.8051 22 9.65512 22 7.47512V5.57512C22 3.39512 20.85 2.24512 18.67 2.24512ZM7.24 13.6751H5.34C3.15 13.6751 2 14.8251 2 17.0051V18.9051C2 21.0951 3.15 22.2451 5.33 22.2451H7.23C9.41 22.2451 10.56 21.0951 10.56 18.9151V17.0151C10.57 14.8251 9.42 13.6751 7.24 13.6751Z"
      />
      <path d="M6.29 10.8251C6.85337 10.8251 7.41123 10.7142 7.93171 10.4986C8.4522 10.283 8.92513 9.96697 9.32349 9.56861C9.72185 9.17024 10.0379 8.69732 10.2534 8.17683C10.469 7.65634 10.58 7.09849 10.58 6.53512C10.58 5.97175 10.469 5.41389 10.2534 4.89341C10.0379 4.37292 9.72185 3.89999 9.32349 3.50163C8.92513 3.10327 8.4522 2.78727 7.93171 2.57167C7.41123 2.35608 6.85337 2.24512 6.29 2.24512C5.15222 2.24512 4.06104 2.6971 3.25651 3.50163C2.45198 4.30616 2 5.39734 2 6.53512C2 7.6729 2.45198 8.76407 3.25651 9.56861C4.06104 10.3731 5.15222 10.8251 6.29 10.8251ZM17.71 22.2471C18.8478 22.2471 19.939 21.7951 20.7435 20.9906C21.548 20.1861 22 19.0949 22 17.9571C22 16.8193 21.548 15.7282 20.7435 14.9236C19.939 14.1191 18.8478 13.6671 17.71 13.6671C16.5722 13.6671 15.481 14.1191 14.6765 14.9236C13.872 15.7282 13.42 16.8193 13.42 17.9571C13.42 19.0949 13.872 20.1861 14.6765 20.9906C15.481 21.7951 16.5722 22.2471 17.71 22.2471Z" />
    </svg>
  );
}
