import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import TopBar from '../../componentsv2/TopBar';
import MarcoLogo from '../../componentsv2/MarcoLogo';
import Typography from '../../componentsv2/Typography';
import { TextField } from '../../componentsv2/Field';
import Button from '../../componentsv2/Button';
import axios from '../../services/axios';
import { useLazyTranslation } from '../../hooks/i18n';
import { isValidEmail } from '../ResetPassword';
import { LOGIN } from '../../constants/auth';

// TODO: Use formik for the email, to validate, use the button as a submit, etc.
const EmailVerification = () => {
  const { t } = useLazyTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  const onSubmit = (email: string) => {
    setIsLoading(true);
    axios
      .post(`/signup/verify-email/`, { email })
      .then((response) => {
        navigate('/verifyemailsent', { state: { email } });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
      <TopBar>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <IconButton href="/">
            <MarcoLogo />
          </IconButton>
        </Box>
      </TopBar>
      <Box component="main" sx={{ flexGrow: 1, p: 4, backgroundColor: '#F1F6FD' }}>
        <Container
          sx={{
            maxWidth: { xs: '100%', sm: 400, md: 550 },
            padding: '1em 0em',
          }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h4" textAlign={{ xs: 'center', md: 'left' }} fontWeight={700}>
                {t('emailVerification.title')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                textAlign={{ xs: 'center', md: 'left' }}
                sx={{ fontSize: '18px', lineHeight: '27px', color: '#66758F' }}>
                {t('emailVerification.description')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                label={t('emailVerification.emailLabel')}
                placeholder={t('emailVerification.emailPlaceholder')}
                onChange={(value) => {
                  setEmail(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '5em' }}>
              <Button
                fullWidth
                color="secondary"
                isLoading={isLoading}
                disabled={!email || isLoading}
                onClick={() => {
                  if (isValidEmail(email)) onSubmit(email);
                }}>
                {t('emailVerification.submit')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="outline"
                color="secondary"
                fullWidth
                onClick={() => {
                  navigate(LOGIN);
                }}>
                {t('emailVerification.cancel')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default EmailVerification;
