import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { logEvent } from '@amplitude/analytics-browser';
import { setUserId } from '@amplitude/analytics-browser';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import {
  LargeBuildingsIcon,
  LargeBuyerCheckIcon,
  LargeCheckMarkIcon,
  LargeCurrencyExchangeIcon,
  LargeInsurancesIcon,
  LargeBankingIcon,
} from '../../components/Icons';
import { ButtonSizes } from '../../components/Button';
import ErrorMessage from '../../components/ErrorMessage';
import Button from '../../componentsv2/Button';
import TopBar from '../../componentsv2/TopBar';
import MarcoLogo from '../../componentsv2/MarcoLogo';
import Typography from '../../componentsv2/Typography';
import Form from '../../componentsv2/Form';
import Carousel from '../../componentsv2/Carousel';
import { TextField } from '../../componentsv2/Field';
import { useTranslation } from '../../hooks/i18n';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useAuth } from '../../hooks';
import { getLoginSchema } from '../../schemas';
import { FormLoginState } from '../../types';
import { signinEvents } from '../../constants/events';
import { RESET_PASSWORD, SIGNUP } from '../../constants/auth';
import { COLORS } from '../../themev2';
import { getRedirectPage, getUrlParams } from '../../utils/helpers';

const Login = () => {
  useEffect(() => {
    logEvent(signinEvents.VIEW_PAGE);
  }, []);

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const { login, user, isLoading } = useAuth();
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (user) {
      const urlParams = getUrlParams();
      navigate(getRedirectPage(urlParams) || '/'); // TODO: check `next` URL param to redirect
    }
  }, [user, navigate]);

  const submit = ({ email, password }: { email: string; password: string }) => {
    logEvent(signinEvents.SIGNIN_ATTEMPT, {
      Email: email,
    });
    login({ email, password })
      .then(() => {
        logEvent(signinEvents.SIGNEDIN, { Email: email });
        setUserId(email);
      })
      .catch((error) => {
        logEvent(signinEvents.SIGIN_ERROR, {
          Email: email,
          Error: error,
        });
        setError(true);
      });
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <TopBar>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <IconButton href="/">
              <MarcoLogo />
            </IconButton>
          </Box>
        </TopBar>
      </Box>
      <Grid container spacing={0} style={{ height: 'calc(100vh - 67px)', display: 'flex' }}>
        {!isMobile && (
          <Grid
            item
            md={6}
            sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '1em',
                textAlign: 'center',
                padding: '6em',
              }}>
              <Box sx={{ width: '100%' }}>
                <Carousel
                  items={[
                    {
                      title: t('products.financing.title'),
                      description: t('products.financing.description'),
                      image: <LargeCheckMarkIcon />,
                    },
                    {
                      title: t('products.buyerCheck.title'),
                      description: t('products.buyerCheck.description'),
                      image: <LargeBuyerCheckIcon />,
                    },
                    {
                      title: t('products.llc.title'),
                      description: t('products.llc.description'),
                      image: <LargeBuildingsIcon />,
                    },
                    {
                      title: t('products.fx.title'),
                      description: t('products.fx.description'),
                      image: <LargeCurrencyExchangeIcon />,
                    },
                    {
                      title: t('products.insurance.title'),
                      description: t('products.insurance.description'),
                      image: <LargeInsurancesIcon />,
                    },
                    {
                      title: t('products.banking.title'),
                      description: t('products.banking.description'),
                      image: <LargeBankingIcon />,
                    },
                  ]}
                />
              </Box>
            </Box>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            height: '100%',
            background: '#FBFCFE',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography variant="h5" sx={{ fontWeight: 600, textAlign: 'center', width: '60%' }}>
            {t('login.title')}
          </Typography>
          <Box sx={{ width: '70%' }}>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={{ password: '', email: '' }}
              enableReinitialize
              validationSchema={getLoginSchema(t)}
              onSubmit={submit}>
              {({ values }: FormikProps<FormLoginState>) => (
                <Form name="form-login" id="form-login">
                  <Box sx={{ marginY: '3em', width: '100%' }}>
                    <TextField
                      fullWidth
                      label={t('login.emailLabel')}
                      name="email"
                      placeholder={t('login.emailPlaceholder')}
                    />
                    <TextField
                      fullWidth
                      type="password"
                      name="password"
                      placeholder={t('login.passwordPlaceholder')}
                      label={t('login.passwordLabel')}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
                      <Link to={RESET_PASSWORD} style={{ color: COLORS.gray[1200] }}>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            lineHeight: '24px',
                            textAlign: 'center',
                            color: COLORS.gray[1200],
                          }}>
                          {t('login.forgotPassword')}
                        </Typography>
                      </Link>
                    </Box>
                    {error && (
                      <ErrorMessage
                        errorMessage={t('login.credentialsError')}
                        onDismiss={() => {
                          setError(false);
                        }}
                      />
                    )}
                  </Box>
                  <Button fullWidth color="secondary" size={ButtonSizes.Large} isLoading={isLoading} isSubmit>
                    {t('login.buttonText')}
                  </Button>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '8px',
                      padding: '1em',
                    }}>
                    <Typography>{t('login.noAccount')}</Typography>
                    <Link to={SIGNUP} style={{ color: COLORS.indigo[500] }}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          lineHeight: '24px',
                          textAlign: 'left',
                          color: COLORS.indigo[500],
                        }}>
                        {t('login.register')}
                      </Typography>
                    </Link>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
