import { useContext, useState } from 'react';
import { FormikContext } from 'formik';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '../Typography';
import CheckboxField from './CheckboxField';
import { FormTrackingContext } from '../Form';
import { logFieldChange } from '../../utils/tracking';
import InfoButton from '../InfoButton';
import { CommonProps } from './types';
import { COLORS } from '../../themev2';

type MultiSelectFieldProps = CommonProps & {
  value?: string[];
  options: { value: string; label: string }[];
};

export default function MultiSelectField({
  fullWidth = true,
  error,
  name,
  label,
  onChange,
  value = [],
  options,
  placeholder,
  helperText,
  eventLogger = logFieldChange,
  moreInfoTooltip = null,
  disabled = false,
}: MultiSelectFieldProps) {
  const formik = useContext(FormikContext);
  const _error = error || (formik?.touched[name] && formik?.errors[name]);
  const [selected, setSelected] = useState(((!!value.length && value) || formik?.values[name]) as string[]);
  const { trackingEvent } = useContext(FormTrackingContext);

  return (
    <FormControl fullWidth={fullWidth} error={!!_error}>
      <FormLabel sx={{ padding: '8px 0', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <Typography>{label}</Typography>
          {moreInfoTooltip && <InfoButton title={moreInfoTooltip} />}
        </div>
      </FormLabel>
      <Select
        color="secondary"
        disabled={disabled}
        multiple
        value={selected}
        onChange={(e) => {
          const value = e.target.value;
          formik && formik.setFieldValue(name, value);
          onChange && onChange(value);
        }}
        onBlur={(e) => {
          const value = e.target.value;
          trackingEvent && eventLogger(trackingEvent, name, value);
        }}
        variant="outlined"
        displayEmpty
        renderValue={(value) =>
          !!value.length ? (
            value.map((v) => options.find((option) => option.value === v)?.label).join(', ')
          ) : (
            <Typography style={{ color: COLORS.gray[1000] }}>{placeholder}</Typography>
          )
        }
        sx={{
          background: disabled ? COLORS.gray[300] : COLORS.brand.white,
          borderColor: COLORS.gray[1000],
          '.MuiSelect-select': {
            padding: '12px',
          },
        }}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <CheckboxField
              checked={selected.includes(option.value)}
              label={option.label}
              onChange={(checked) => {
                if (checked) {
                  setSelected((prev) => [...prev, option.value]);
                } else {
                  setSelected((prev) => prev.filter((elem) => elem !== option.value));
                }
              }}
            />
          </MenuItem>
        ))}
      </Select>
      {(_error || helperText) && (
        <FormHelperText>
          <Typography sx={{ color: 'inherit' }}>{_error || helperText}</Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
}
