import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormikContext } from 'formik';
import { useContext } from 'react';
import { logFieldChange } from '../../utils/tracking';
import { FormTrackingContext } from '../Form';
import Typography from '../Typography';
import { CommonProps } from './types';
import InfoButton from '../InfoButton';
import { COLORS } from '../../themev2';
import { styled } from '@mui/system';

const CustomRadio = styled(Radio)(({ theme, disabled }) => ({
  color: disabled ? theme.palette.action.disabled : COLORS.gray[900],
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: 'transparent', // Ensure there's no hover background color change
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.5rem',
  },
}));

type RadioFieldProps = CommonProps & {
  value?: string;
  options: { value: string; label: string; subLabel?: string; disabled?: boolean }[];
};

export default function RadioField({
  error,
  name,
  label,
  options,
  onChange,
  value,
  eventLogger = logFieldChange,
  moreInfoTooltip = null,
}: RadioFieldProps) {
  const formik = useContext(FormikContext);
  const _error = error || (formik?.touched[name] && formik?.errors[name]);
  const { trackingEvent } = useContext(FormTrackingContext);

  return (
    <FormControl error={!!_error}>
      <FormLabel id={name} sx={{ padding: '8px 0', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <Typography>{label}</Typography>
          {moreInfoTooltip && <InfoButton title={moreInfoTooltip} />}
        </div>
      </FormLabel>
      <RadioGroup row aria-labelledby={name} name={name} value={value || formik?.values[name]}>
        {options.map(({ value, label, subLabel, disabled }) => (
          <Box
            key={value}
            sx={{
              padding: '16px',
              borderRadius: '8px',
              backgroundColor: disabled ? '#f7f7f7' : 'transparent',
              marginBottom: '8px',
            }}>
            <FormControlLabel
              key={label}
              control={
                <CustomRadio
                  color="secondary"
                  disabled={disabled}
                  value={value}
                  onChange={(e) => {
                    formik && formik.setFieldValue(name, value);
                    onChange && onChange(value);
                    trackingEvent && eventLogger(trackingEvent, name, value);
                  }}
                  sx={{
                    '&:hover': {
                      background: 'none',
                    },
                  }}
                />
              }
              label={label}
            />
            {subLabel && (
              <Typography sx={{ paddingLeft: '2.1em', marginBottom: '1em', color: COLORS.gray[1100] }}>
                {subLabel}
              </Typography>
            )}
          </Box>
        ))}
      </RadioGroup>
      <FormHelperText error={!!_error}>{_error}</FormHelperText>
    </FormControl>
  );
}
