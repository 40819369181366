import {
  Identify,
  logEvent as amplitudeLogEvent,
  identify,
  logEvent,
  setUserId,
} from '@amplitude/analytics-browser';
import { LLCEvents, followupEvents, marcoWrappedEvents, notificationEvents} from '../constants/events';

// FIXME: this should be built declaratively in our forms one good day
export const getFormEventTrackingProps = (trackingName: string) => {
  return {
    onChange: (e: any) => {
      e.target.hasChanged = true;
    },
    onBlur: (e: any) => {
      e.target.hasChanged && amplitudeLogEvent(trackingName, { Field: e.target.name, Value: e.target.value });
      e.target.hasChanged = false;
    },
  };
};

export function logFieldChange(eventName: string, fieldName: string, value: string): void {
  amplitudeLogEvent(eventName, { Field: fieldName, Value: value });
}

type UserIdentificationParams = {
  country: string;
  companyName: string;
  name: string;
  email: string;
  language: string;
};

export function identifyUser({ country, companyName, name, email, language }: UserIdentificationParams) {
  const identifyObj = new Identify();
  identifyObj.set('Country of Incorporation', country);
  identifyObj.set('Company name', companyName);
  identifyObj.set('User name', name);
  identifyObj.set('User email', email);
  identifyObj.set('Language', language);
  setUserId(email);
  return identify(identifyObj);
}

export function logUrlParamsEvents(urlParams: Record<string, string>) {
  if (urlParams['llc_status']) {
    logEvent(LLCEvents.FOLLOW_EMAIL_LINK, { Status: urlParams['llc_status'] });
  }
  if (urlParams['llc_notification']) {
    logEvent(LLCEvents.FOLLOW_EMAIL_LINK, { Notification: urlParams['llc_notification'] });
  }
  if (urlParams['followup']) {
    logEvent(followupEvents.CLICK_FOLLOW_UP_EMAIL, { id: urlParams['followup'] });
  }
  if (urlParams['marco_wrapped']) {
    logEvent(marcoWrappedEvents.FOLLOW_EMAIL_LINK);
  }
  if (urlParams['notification_type']) {
    logEvent(notificationEvents.OPEN_NOTIFICATION, { notification_type: urlParams['notification_type'] });
  }

}
