export default function ArrowLeftIcon() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.1947 18L5.36133 12M5.36133 12L11.1947 6M5.36133 12H19.3613"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const ArrowLeftWithLine = () => {
  return (
    <svg width="22" height="12" viewBox="0 0 22 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.4273 11.7272L11.5937 10.5736L7.80481 6.81816H21.1818V5.1818H7.80481L11.602 1.42634L10.4273 0.272705L4.63635 5.99998L10.4273 11.7272Z" />
      <rect x="0.181763" y="0.272705" width="1.90909" height="11.4545" />
    </svg>
  );
};
