import {
  APIDocumentCategory,
  APIDocumentSession,
  APILegalRepresentative,
  APIShareholder,
  AddClientCategoryPayload,
  LegalRepresentativeFormValues,
  ShareholderFormValues,
} from '../types/documents';
import axios from './axios';

export function addCompanyShareholder(
  companyId: number,
  values: ShareholderFormValues,
  files: File[],
  isAdmin: boolean
): Promise<APIShareholder> {
  const data = new FormData();
  data.append('person_type', values.personType);
  data.append('full_name', values.fullName);
  data.append('nationality', values.nationality);
  data.append('person_id_number', values.personIdNumber);
  data.append('percentage_share', values.percentageShare.toString());
  data.append('tax_id_type', values.taxIdType);
  data.append('date_of_birth', values.dateOfBirth);
  data.append('address', values.address);
  data.append('email', values.email);
  data.append('phone_number', values.phoneNumber);
  files.forEach((file) => {
    data.append('asset', file, file.name);
    data.append('file_name', file.name);
    data.append('asset_type', 'shareholder');
  });
  const response = axios.post<Partial<APIShareholder>, APIShareholder>(
    `${isAdmin ? '/admins' : ''}/document_collection/companies/${companyId}/shareholders/`,
    data
  );
  return response;
}

export function uploadShareholderAssets(
  companyId: number,
  shareholderId: number,
  files: File[],
  isAdmin: boolean
) {
  const data = new FormData();
  files.forEach((file) => {
    data.append('asset', file, file.name);
    data.append('file_name', file.name);
    data.append('asset_type', 'shareholder2');
  });
  return axios.post(
    `${
      isAdmin ? '/admins' : ''
    }/document_collection/companies/${companyId}/shareholders/${shareholderId}/assets/`,
    data
  );
}

export function deleteDocumentAsset(
  companyId: number,
  documentId: number,
  assetId: number,
  isAdmin: boolean = false
): Promise<any> {
  return axios
    .delete(
      `${
        isAdmin ? '/admins' : ''
      }/document_collection/companies/${companyId}/documents/${documentId}/assets/${assetId}/`
    )
    .then(({ data }) => data);
}

export function editCompanyShareholder(
  companyId: number,
  shareholderId: number,
  values: ShareholderFormValues,
  isAdmin: boolean
): Promise<any> {
  return axios
    .patch(
      `${isAdmin ? '/admins' : ''}/document_collection/companies/${companyId}/shareholders/${shareholderId}/`,
      values
    )
    .then(({ data }) => data);
}

export function deleteCompanyShareholder(
  companyId: number,
  shareholderId: number,
  isAdmin: boolean
): Promise<any> {
  return axios
    .delete(
      `${isAdmin ? '/admins' : ''}/document_collection/companies/${companyId}/shareholders/${shareholderId}/`
    )
    .then(({ data }) => data);
}

export function addCompanyLegalRepresentative(
  companyId: number,
  values: LegalRepresentativeFormValues,
  files: File[],
  isAdmin: boolean
): Promise<APILegalRepresentative> {
  const data = new FormData();
  data.append('full_name', values.fullName);
  data.append('email', values.email);
  data.append('person_id_number', values.personIdNumber);
  data.append('person_type', values.personType);
  data.append('nationality', values.nationality);
  data.append('tax_id_type', values.taxIdType);
  data.append('date_of_birth', values.dateOfBirth);
  data.append('address', values.address);
  data.append('email', values.email);
  data.append('phone_number', values.phoneNumber);
  files.forEach((file) => {
    data.append('asset', file, file.name);
    data.append('file_name', file.name);
    data.append('asset_type', 'shareholder');
  });
  const response = axios.post<Partial<APILegalRepresentative>, APILegalRepresentative>(
    `${isAdmin ? '/admins' : ''}/document_collection/companies/${companyId}/legal_representatives/`,
    data
  );
  return response;
}

export function editCompanyLegalRepresentative(
  companyId: number,
  legalRepresentativeId: number,
  values: LegalRepresentativeFormValues,
  isAdmin: boolean
): Promise<any> {
  return axios
    .patch(
      `${
        isAdmin ? '/admins' : ''
      }/document_collection/companies/${companyId}/legal_representatives/${legalRepresentativeId}/`,
      values
    )
    .then(({ data }) => data);
}

export function deleteCompanyLegalRepresentative(
  companyId: number,
  legalRepresentativeId: number,
  isAdmin: boolean
): Promise<any> {
  return axios
    .delete(
      `${
        isAdmin ? '/admins' : ''
      }/document_collection/companies/${companyId}/legal_representatives/${legalRepresentativeId}/`
    )
    .then(({ data }) => data);
}

export function uploadLegalRepresentativeAssets(
  companyId: number,
  legalRepresentativeId: number,
  files: File[],
  isAdmin: boolean
) {
  const data = new FormData();
  files.forEach((file) => {
    data.append('asset', file, file.name);
    data.append('file_name', file.name);
    data.append('asset_type', 'shareholder2');
  });
  return axios.post(
    `${
      isAdmin ? '/admins' : ''
    }/document_collection/companies/${companyId}/legal_representatives/${legalRepresentativeId}/assets/`,
    data
  );
}

export function getCompanyShareholders(companyId: number): Promise<APIShareholder[]> {
  return axios.get(`/document_collection/companies/${companyId}/shareholders/`).then(({ data }) => data);
}

export function getCompanyLegalRepresentatives(companyId: number): Promise<APILegalRepresentative[]> {
  return axios
    .get(`/document_collection/companies/${companyId}/legal_representatives/`)
    .then(({ data }) => data);
}

export function getCompanyDocumentCategories(companyId: number): Promise<APIDocumentCategory[]> {
  return axios.get(`/document_collection/companies/${companyId}/documents/`).then(({ data }) => data);
}

export function adminGetCompanyDocumentCategories(companyId: number): Promise<APIDocumentCategory[]> {
  return axios.get(`/admins/document_collection/companies/${companyId}/documents/`).then(({ data }) => data);
}

export function adminGetCompanyShareholders(companyId: number): Promise<APIShareholder[]> {
  return axios
    .get(`/admins/document_collection/companies/${companyId}/shareholders/`)
    .then(({ data }) => data);
}

export function adminGetCompanyLegalRepresentatives(companyId: number): Promise<APILegalRepresentative[]> {
  return axios
    .get(`/admins/document_collection/companies/${companyId}/legal_representatives/`)
    .then(({ data }) => data);
}

export function adminToggleClientDocumentCategory(
  companyId: number,
  categoryId: number
): Promise<APIDocumentCategory> {
  return axios
    .post(`/admins/document_collection/companies/${companyId}/categories/${categoryId}/toggle/`)
    .then(({ data }) => data);
}

export function adminAcceptClientDocumentCategory(
  companyId: number,
  categoryId: number
): Promise<APIDocumentCategory> {
  return axios
    .post(`/admins/document_collection/companies/${companyId}/categories/${categoryId}/accept/`)
    .then(({ data }) => data);
}

export function adminRejectClientDocumentCategory(
  companyId: number,
  categoryId: number,
  rejectionComment: string
): Promise<APIDocumentCategory> {
  return axios
    .post(`/admins/document_collection/companies/${companyId}/categories/${categoryId}/reject/`, {
      rejectedComment: rejectionComment,
    })
    .then(({ data }) => data);
}

export function adminCreateClientDocumentCategory(
  companyId: number,
  payload: AddClientCategoryPayload
): Promise<APIDocumentCategory> {
  return axios
    .post(`/admins/document_collection/companies/${companyId}/categories/`, { ...payload, enabled: true })
    .then(({ data }) => data);
}

export function uploadDocumentAssets(
  companyId: number,
  documentId: number,
  data: FormData
): Promise<APIDocumentSession> {
  return axios
    .post(`document_collection/companies/${companyId}/documents/${documentId}/assets/`, data, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=BoUnDaRyStRiNg`,
      },
    })
    .then(({ data }) => data);
}

export function downloadAllDocuments(companyId: number): Promise<any> {
  return axios
    .get(`/admins/document_collection/companies/${companyId}/download_documents/`)
    .then(({ data }) => data);
}
