import { useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { logEvent } from '@amplitude/analytics-browser';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import ErrorMessage from '../../components/ErrorMessage';
import ModalV2 from '../../components/ModalV2';
import { ArrowRightIcon, MessageSentIcon } from '../../components/Icons';
import Button from '../../componentsv2/Button';
import { RadioField, SelectField } from '../../componentsv2/Field';
import MoneyField from '../../componentsv2/Field/MoneyField';
import Typography from '../../componentsv2/Typography';
import Form from '../../componentsv2/Form';
import { getFXRates, submitFXRequest } from '../../services/fx';
import { useTranslation } from '../../hooks/i18n';
import { useUserLocation } from '../../hooks/useUserLocation';
import { Nullable } from '../../types';
import { APICurrencyOptions, CurrencyKeys } from '../../types/fx';
import { formatCurrency, formattedToNumber, mapMoneyToNumber } from '../../utils/helpers';
import { backendCurrencyValues, currencyCodes, fxRadioQuestions, getCurrencies } from '../../constants/data';
import { FXEvents } from '../../constants/events';
import { COLORS } from '../../themev2';
import { useAppSelector } from '../../store';

const previewFXRequest = (
  amount: string,
  exchangeRate: number,
  currency?: APICurrencyOptions,
  locale?: string
) => {
  const amountAsNumber = mapMoneyToNumber(amount);
  const mappedAmount = formatCurrency(parseFloat((amountAsNumber * exchangeRate).toFixed(2)), locale);

  const [, value] = mappedAmount.split('XXX');

  return `${currency} ${value?.trim()}`;
};

const amountFor = (
  operationOption: string,
  currency: APICurrencyOptions,
  toCurrency: APICurrencyOptions,
  amount: string,
  exchangeRate: number,
  language: string
) => {
  let mappedForAmount = '';
  if (operationOption === 'sell') {
    mappedForAmount = `${currency} ${amount}`;
  } else if (operationOption === 'buy') {
    mappedForAmount = `${previewFXRequest(amount, exchangeRate, toCurrency, language)}`;
  }
  return mappedForAmount;
};

const amountToReceive = (
  operationOption: string,
  currency: APICurrencyOptions,
  toCurrency: APICurrencyOptions,
  amount: string,
  exchangeRate: number,
  language: string
) => {
  let mappedAmount = '';
  if (operationOption === 'sell') {
    mappedAmount = `${previewFXRequest(amount, exchangeRate, toCurrency, language)}`;
  } else if (operationOption === 'buy') {
    mappedAmount = `${currency} ${amount}`;
  }
  return mappedAmount;
};

export type FXState = {
  operationOption: string;
  amount: string;
  currency: CurrencyKeys;
};

const fxInitialValues = {
  operationOption: 'sell',
  amount: '',
  currency: currencyCodes[1],
};

const FXForm = () => {
  const { t } = useTranslation(['fx']);
  const { locale, language } = useUserLocation();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exchangeRate, setExchangeRate] = useState<Nullable<number>>(null);
  const [otherCurrency, setOtherCurrency] = useState<CurrencyKeys>(currencyCodes[0]);
  const newCompany = useAppSelector((state) => state.client.newCompany);

  const formRef = useRef<FormikProps<FXState>>(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getFXRates(newCompany!.id, {
        fromCurrency: backendCurrencyValues[formRef?.current?.values?.currency ?? fxInitialValues.currency],
        toCurrency: backendCurrencyValues[otherCurrency],
      })
        .then((data) => {
          setExchangeRate(parseFloat(data.exchangeRate.toFixed(4)));
        })
        .finally(() => setIsLoading(false));
    })();
  }, [newCompany, otherCurrency]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Formik
          initialValues={fxInitialValues}
          onSubmit={async (values, { resetForm }) => {
            if (exchangeRate && values.amount) {
              const amount = formattedToNumber(values.amount);
              await submitFXRequest(newCompany!.id, {
                metadata: {
                  operationType: values.operationOption,
                  currency: backendCurrencyValues[values.currency],
                  otherCurrency: backendCurrencyValues[otherCurrency],
                  amount,
                },
                fromCurrency:
                  backendCurrencyValues[values.operationOption === 'sell' ? values.currency : otherCurrency],
                toCurrency:
                  backendCurrencyValues[values.operationOption === 'sell' ? otherCurrency : values.currency],
                amount,
                exchangeRate,
                language,
              })
                .then(() => {
                  logEvent(FXEvents.SUBMIT, {
                    'Operation Type': values.operationOption,
                    Currency: backendCurrencyValues[values.currency],
                    'Other Currency': backendCurrencyValues[otherCurrency],
                    Amount: amount,
                  });
                  setSubmitted(true);
                  resetForm();
                  setOtherCurrency(currencyCodes[0]);
                })
                .catch(() => setError(true));
            }
          }}
          innerRef={formRef}>
          {({ values, isSubmitting, setFieldValue }: FormikProps<FXState>) => {
            return (
              <Form name="fx-exchange" id="fx-exchange" trackingEvent={FXEvents.EDIT_FIELD}>
                <Grid container rowSpacing={2}>
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <RadioField name="operationOption" label="" options={fxRadioQuestions(t)} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container rowSpacing={2}>
                      <Grid item xs={12} md={6}>
                        <MoneyField
                          name="amount"
                          label={t('form.fields.amount')}
                          placeholder={t('form.fields.amount')}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <SelectField
                          name="currency"
                          label={t('form.fields.currency')}
                          options={getCurrencies(t).map(({ currency, currencyKey }) => ({
                            value: currencyKey,
                            label: currency,
                          }))}
                          onChange={(newCurrency) => {
                            if (newCurrency === 'us') {
                              setOtherCurrency('mx');
                            } else {
                              setOtherCurrency('us');
                            }
                          }}
                          renderOption={({ label, value }) => (
                            <MenuItem key={value} value={value}>
                              <img
                                src={`/images/countryFlags/${value}.svg`}
                                alt={value.toUpperCase()}
                                style={{ width: '24px', height: '24px', marginRight: '8px' }}
                              />
                              {label}
                            </MenuItem>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {!isLoading && (
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="body1" sx={{ fontWeight: '700' }}>
                        {`${t('form.exchangeRate')}:`}
                      </Typography>
                      <Typography variant="body1">{`1 ${t(`form.currencyOptions.${values.currency}`)} = ${t(
                        `form.currencyOptions.${otherCurrency}`
                      )} ${exchangeRate}`}</Typography>
                    </Grid>
                  )}
                  {values.amount && exchangeRate && !isLoading && (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          padding: '12px',
                          borderRadius: '12px',
                          backgroundColor: COLORS.indigo[100],
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                        <Typography>{`${t('form.receive')}:`}</Typography>
                        <div style={{ display: 'flex', gap: '5px' }}>
                          <Typography sx={{ fontWeight: '600', color: COLORS.indigo[500] }}>
                            {amountToReceive(
                              values.operationOption,
                              backendCurrencyValues[values.currency],
                              backendCurrencyValues[otherCurrency],
                              values.amount.toString(),
                              exchangeRate,
                              locale
                            )}
                          </Typography>
                          <Typography sx={{ fontWeight: '600', color: COLORS.indigo[500] }}>
                            {t('form.amount', {
                              amount: amountFor(
                                values.operationOption,
                                backendCurrencyValues[values.currency],
                                backendCurrencyValues[otherCurrency],
                                values.amount,
                                exchangeRate,
                                locale
                              ),
                            })}
                          </Typography>
                        </div>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      isLoading={isSubmitting}
                      fullWidth
                      disabled={isSubmitting || !exchangeRate}
                      isSubmit
                      endIcon={<ArrowRightIcon />}>
                      {t('form.submitRequest')}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>
                      {t('form.disclaimer')}
                    </Typography>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
        {!!error && <ErrorMessage errorMessage={t('form.error')} />}
        {submitted && (
          <ModalV2 isOpen={submitted} handleClose={() => setSubmitted(false)}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <MessageSentIcon />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" textAlign="center">
                  {t('confirmationModal.thanks')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" textAlign="center">
                  {t('confirmationModal.message')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth onClick={() => setSubmitted(false)} color="secondary">
                  {t('confirmationModal.confirmButtonTitle')}
                </Button>
              </Grid>
            </Grid>
          </ModalV2>
        )}
      </Grid>
    </Grid>
  );
};

export default FXForm;
