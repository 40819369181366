export default function LogoutIcon() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.56662 7.55999C9.87662 3.95999 11.7266 2.48999 15.7766 2.48999H15.9066C20.3766 2.48999 22.1666 4.27999 22.1666 8.74999V15.27C22.1666 19.74 20.3766 21.53 15.9066 21.53H15.7766C11.7566 21.53 9.90662 20.08 9.57662 16.54"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66663 12H15.5466"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3166 8.64999L16.6666 12L13.3166 15.35"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
