import { ChangeEvent, useEffect, useState } from 'react';
import { logEvent } from '@amplitude/analytics-browser';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useTranslation } from '../../hooks/i18n';
import { invoiceEvents } from '../../constants/events';
import Button from '../Button';
import EaseScore from '../../componentsv2/EaseScore';
import Modal from '../../componentsv2/Modal';
import { MessageLikedIcon, MessageSentIcon } from '../../components/Icons';
import { submitCES } from '../../services/application';

const exitEventMap = {
  1: invoiceEvents.CES_EXIT_SCORE,
  2: invoiceEvents.CES_EXIT_FEEDBACK,
  3: invoiceEvents.CES_FINISHED_FEEDBACK,
};

type CESStepProps = {
  onSubmit: ({ score, feedback }: { score?: number; feedback?: string }) => void;
};

type UsabilityStepProps = {
  values: { score?: number | null; feedback?: string };
};

const SuccessStep = ({ onSubmit }: CESStepProps) => {
  const { t } = useTranslation('application');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <MessageSentIcon />
      </Grid>
      <Grid item xs={12}>
        <Typography
          textAlign="center"
          variant="h5"
          sx={{ color: '#283952', fontWeight: '700', fontSize: '24px' }}>
          {t('confirmationModal.success.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography textAlign="center" sx={{ color: '#71819C' }}>
          {t('confirmationModal.success.message')}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '24px' }}>
        <Button fullWidth onClick={() => onSubmit({})}>
          {t('confirmationModal.success.button')}
        </Button>
      </Grid>
    </Grid>
  );
};

const EaseScoreStep = ({ onSubmit }: CESStepProps) => {
  const { t } = useTranslation('application');
  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    logEvent(invoiceEvents.CES_VIEW_SCORE);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          textAlign="center"
          variant="h5"
          sx={{ color: '#283952', fontWeight: '700', fontSize: '24px', lineHeight: '36px' }}>
          {t('confirmationModal.easeScore.title')}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '24px' }}>
        <EaseScore
          handleOnChange={(val) => {
            setValue(val);
            logEvent(invoiceEvents.CES_EDIT_SCORE, { Score: val });
          }}
          value={value}
          difficultLabel={t('confirmationModal.easeScore.difficult')}
          easyLabel={t('confirmationModal.easeScore.easy')}
        />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '24px' }}>
        <Button
          fullWidth
          onClick={() => {
            logEvent(invoiceEvents.CES_SUBMIT_SCORE, { Score: value });
            value && onSubmit({ score: value });
          }}
          disabled={!value}>
          {t('confirmationModal.easeScore.button')}
        </Button>
      </Grid>
    </Grid>
  );
};

const UsabilityStep = ({ values: { score }, onSubmit }: CESStepProps & UsabilityStepProps) => {
  const { t } = useTranslation('application');
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    logEvent(invoiceEvents.CES_VIEW_FEEDBACK, {
      Score: score,
      Message: score && score > 3 ? 'Positive' : 'Negative',
    });
  }, [score]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          textAlign="center"
          variant="h5"
          sx={{ color: '#283952', fontWeight: '600', fontSize: '20px', lineHeight: '30px' }}>
          {t(`confirmationModal.usability.${score && score > 3 ? 'contentTitle' : 'improveTitle'}`)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          minRows={4}
          fullWidth
          placeholder={t('confirmationModal.usability.textareaPlaceholder')}
          value={value}
          onChange={handleChange}
          InputProps={{ style: { color: '#283952' } }}
          sx={{
            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#B4C4DC',
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#B4C4DC',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#B4C4DC',
            },
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '24px' }}>
        <Button
          fullWidth
          disabled={!value}
          onClick={() => {
            logEvent(invoiceEvents.CES_SUBMIT_FEEDBACK, { Score: score, Feedback: value });
            onSubmit({ feedback: value });
          }}>
          {t('confirmationModal.usability.button')}
        </Button>
      </Grid>
    </Grid>
  );
};

const SubmittedStep = ({ onSubmit }: CESStepProps) => {
  const { t } = useTranslation('application');
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <MessageLikedIcon />
      </Grid>
      <Grid item xs={12}>
        <Typography
          textAlign="center"
          variant="h5"
          sx={{ color: '#283952', fontWeight: '700', fontSize: '24px' }}>
          {t('confirmationModal.submitted.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography textAlign="center" sx={{ color: '#71819C' }}>
          {t('confirmationModal.submitted.message')}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '24px' }}>
        <Button
          fullWidth
          onClick={() => {
            logEvent(invoiceEvents.CES_FINISHED_FEEDBACK);
            onSubmit({});
          }}>
          {t('confirmationModal.submitted.button')}
        </Button>
      </Grid>
    </Grid>
  );
};

const CESSteps = [SuccessStep, EaseScoreStep, UsabilityStep, SubmittedStep];

type ApplicationCESProps = {
  handleClose: () => void;
  isOpen: boolean;
  applicationId: number;
  companyId: number;
};

const ApplicationCES = ({ handleClose, isOpen, applicationId, companyId }: ApplicationCESProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [easeScore, setEaseScore] = useState<number | null>(null);

  const StepComponent = CESSteps[currentStep];

  const handleOnSubmit = ({ score, feedback }: { score?: number; feedback?: string }) => {
    if (currentStep !== CESSteps.length - 1) {
      if (currentStep === 1 && score) {
        setEaseScore(score);
        submitCES(applicationId, { score: score }, companyId);
      }
      if (currentStep === 2 && feedback) {
        submitCES(applicationId, { comment: feedback }, companyId);
      }
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      setCurrentStep(0);
      handleClose();
    }
  };

  const handleOnClose = () => {
    //Log amplitude event with the step exit message
    currentStep > 0 && logEvent(exitEventMap[currentStep as keyof typeof exitEventMap]);
    handleClose();
  };

  return (
    <Modal
      handleClose={handleOnClose}
      isOpen={isOpen}
      displayCloseButton={currentStep !== 0}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}>
      <StepComponent onSubmit={handleOnSubmit} values={{ score: easeScore }} />
    </Modal>
  );
};

export default ApplicationCES;
