import { TFunction } from 'react-i18next';
import { BuyerCreditCheckState } from '../viewsv2/BuyerCreditCheck/BuyerCreditCheckForm';
import { BusinessState } from '../containers/ApplicationStepperv2/BusinessStep';
import { CompanyState } from '../containers/ApplicationStepperv2/CompanyStep';
import { CountriesForDebtorsKeys, CountryKeys, SignupCountryKeys } from '../types';
import { APIInvoiceAppStatus, PipelineStatus } from '../types/application';
import { LandingPage } from '../types/signup';
import { InternalPipelineStatus } from '../types/client';
import { FileExtensions } from '../constants/variables';
import { backendCountryValues, backendCountryValuesForDebtors } from '../constants/data';
import { APPLICATION, BUYER_CREDIT_CHECK, CURRENCY_EXCHANGE, INSURANCES, LLC } from '../constants/auth';
import { formatNumericValue } from './common';

export const hasEmptyValues = (obj: {}) => {
  return Object.values(obj).some((x: any) => x === null || x === '' || (Array.isArray(x) && x?.length === 0));
};

export const hasEmptyValuesCompanyAndBusiness = (
  companyAndBusinessValues: Partial<CompanyState & BusinessState>
) => {
  let companyAndBusinessValuesCopy = { ...companyAndBusinessValues };
  if (companyAndBusinessValuesCopy.operatesWithCreditInsurance !== 'yes') {
    delete companyAndBusinessValuesCopy.insuranceProvider;
  }
  if (companyAndBusinessValuesCopy.usingFinancialInstitutions !== 'yes') {
    delete companyAndBusinessValuesCopy.financialInstitution;
    delete companyAndBusinessValuesCopy.financialInstitutionType;
  }
  return hasEmptyValues(companyAndBusinessValuesCopy);
};

export const hasEmptyValuesCompany = (companyValues: CompanyState) => {
  let companyValuesCopy = { ...companyValues };
  return hasEmptyValues(companyValuesCopy);
};

export const hasEmptyValuesBusiness = (businessValues: BusinessState) => {
  let businessValuesCopy = { ...businessValues };
  if (businessValuesCopy.operatesWithCreditInsurance !== 'yes') {
    delete businessValuesCopy.insuranceProvider;
  }
  if (businessValuesCopy.usingFinancialInstitutions !== 'yes') {
    delete businessValuesCopy.financialInstitution;
    delete businessValuesCopy.financialInstitutionType;
  }
  return hasEmptyValues(businessValuesCopy);
};

export const hasEmptyValuesBuyerCreditCheck = (buyerCreditValues: BuyerCreditCheckState) => {
  let buyerCreditValuesCopy = { ...buyerCreditValues };
  if (!buyerCreditValuesCopy.hasAdditionalAddress) {
    delete buyerCreditValuesCopy.secondaryAddressCity;
    delete buyerCreditValuesCopy.secondaryAddressState;
    delete buyerCreditValuesCopy.secondaryAddressStreet;
    delete buyerCreditValuesCopy.secondaryAddressZipCode;
  }
  if (!buyerCreditValuesCopy.knowsBuyersEIN) {
    delete buyerCreditValuesCopy.ein;
    delete buyerCreditValuesCopy.duns;
  }
  if (buyerCreditValuesCopy.ein && !buyerCreditValuesCopy.duns) {
    delete buyerCreditValuesCopy.duns;
  }
  if (buyerCreditValuesCopy.duns && !buyerCreditValuesCopy.ein) {
    delete buyerCreditValuesCopy.ein;
  }
  delete buyerCreditValuesCopy.tradeName;
  return hasEmptyValues(buyerCreditValuesCopy);
};

export const getFileExtension = (fileName: string) => {
  return fileName?.split('.').pop()?.toLowerCase() ?? '';
};

export const isImageFile = (fileType: string) => {
  return (
    fileType === FileExtensions.JPEG || fileType === FileExtensions.JPG || fileType === FileExtensions.PNG
  );
};

export const isPreviewable = (fileType: string) => {
  return fileType === FileExtensions.XLS || fileType === FileExtensions.XLSX;
};

export const mapSourceFile = (extension: string, filePath: string) => {
  let source = '';
  if (extension === 'xlsx' || extension === 'xls') {
    source = `https://view.officeapps.live.com/op/embed.aspx?src=${filePath}`;
  } else if (extension === 'pdf') {
    source = `${filePath}#view=fitH`;
  } else {
    source = `${process.env.NODE_ENV === 'development' ? 'http://localhost:8000/' : ''}${filePath}`;
  }
  return source;
};

export const mapMoneyToNumber = (amount: string) => {
  // First, ensure all decimal separators (commas) are standardized to dots.
  // Then, replace all non-digit characters except the (now standardized) decimal point.
  const standardizedAmount = amount.replace(/,/g, '.').replace(/[^\d.]/g, '');
  return Number(standardizedAmount);
};

export const formatCurrency = (
  value: number,
  locale = 'en-US',
  minimumFractionDigits = 0,
  maximumFractionDigits = 0
) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'XXX',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);
};

export const stringCurrencyFormatter = (n: string, language = 'en-US') => {
  const digit = language === 'en-US' ? ',' : '.';
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, digit);
};

export const sortByTranslation = (arr: string[], t: TFunction, translationKey: string) => {
  return arr.sort((a, b) => {
    const translationA = t(`${translationKey}.${a}`);
    const translationB = t(`${translationKey}.${b}`);
    return translationA.localeCompare(translationB);
  });
};

export const removeEmptyFields = (object: Record<string, any>) => {
  return Object.keys(object).forEach((key) => {
    if (Array.isArray(object[key]) && object[key].length === 0) {
      delete object[key];
    } else if (!object[key]) {
      delete object[key];
    }
  });
};

export const mapFiltersForBackend = (filters: any) => {
  let copyFilters = { ...filters };
  removeEmptyFields(copyFilters);
  return new URLSearchParams(copyFilters).toString();
};

export const hasLowerCase = (str: string) => {
  return /[a-z]/.test(str);
};

export const hasUpperCase = (str: string) => {
  return /[A-Z]/.test(str);
};

export const hasDigit = (str: string) => {
  return /[0-9]/.test(str);
};

export const mapCountryFromBackend = (country: string) => {
  return Object.entries(backendCountryValues).find(([_, value]) => value === country)?.[0] as CountryKeys;
};

export const mapCountryForDebtorFromBackend = (country: string) => {
  return Object.entries(backendCountryValuesForDebtors).find(
    ([_, value]) => value === country
  )?.[0] as CountriesForDebtorsKeys;
};

export const generateGreetingMessage = (username: string, t: TFunction) => {
  const date = new Date();
  const hours = date.getHours();
  let greeting = '';
  if (hours < 12 && hours > 5) greeting = `${t('common:greeting.morning')}`;
  else if (hours >= 12 && hours <= 18) greeting = `${t('common:greeting.afternoon')}`;
  else if (hours > 18 && hours <= 24) greeting = `${t('common:greeting.evening')}`;
  else greeting = `${t('common:greeting.night')}`;

  return `${greeting}, ${username}!`;
};

export const mapCurrentStepForAmplitude = (appStatus: APIInvoiceAppStatus, currentStep: string) => {
  let step = 'Intro';
  if (currentStep && !isNaN(Number(currentStep)) && appStatus === 'application_started') {
    step = `In Step ${Number(currentStep)}`;
  } else if (appStatus === 'application_submitted' || appStatus === 'application_pending_submit') {
    step = 'Submitted';
  }
  return step;
};

export const phoneCountryCodes: Record<SignupCountryKeys, { code: number; placeholder: string }> = {
  co: { code: 57, placeholder: '320 1234567' },
  ec: { code: 593, placeholder: '96 123 4567' },
  mx: { code: 52, placeholder: '55 1234 5678' },
  pe: { code: 51, placeholder: '944 123 456' },
  us: { code: 1, placeholder: '(305) 123-4567' },
  ca: { code: 1, placeholder: '416-123-4567' },
  hk: { code: 852, placeholder: '2212 3456' },
  ae: { code: 971, placeholder: '2 694 1234' },
  dk: { code: 45, placeholder: '33 12 34 56' },
  do: { code: 1, placeholder: '809-123-4567' },
  sv: { code: 503, placeholder: '1234 5678' },
  gt: { code: 502, placeholder: '1234 5678' },
  hn: { code: 504, placeholder: '1234-5678' },
  ni: { code: 505, placeholder: '2212 3456' },
  pa: { code: 507, placeholder: '1234-5678' },
  pl: { code: 48, placeholder: '605-555-555' },
  pr: { code: 1, placeholder: '787-123-4567' },
  ar: { code: 54, placeholder: '9 11 1234-5678' },
  bo: { code: 591, placeholder: '77123456' },
  br: { code: 55, placeholder: '21 99123-4567' },
  bz: { code: 501, placeholder: '223-1234' },
  cl: { code: 56, placeholder: '9 1234 5678' },
  py: { code: 595, placeholder: '981 123456' },
  uy: { code: 598, placeholder: '99 123 456' },
  cr: { code: 506, placeholder: '2212 3456' },
  es: { code: 34, placeholder: '676 12 34 56' },
  ch: { code: 41, placeholder: '44 221 96 36' },
  in: { code: 91, placeholder: '8130292966' },
  uk: { code: 44, placeholder: '20 7946 0857' },
};

export const mapLandingPages: Record<LandingPage, string> = {
  llc: LLC,
  buyer_check: BUYER_CREDIT_CHECK,
  insurance: INSURANCES,
  fx: CURRENCY_EXCHANGE,
  factoring: APPLICATION,
};

// TODO: This function should be removed, we don't have internalPipelineStatus anymore (TBR)
export const getPipelineStatus = (internalPipelineStatus: InternalPipelineStatus): PipelineStatus => {
  let status: PipelineStatus = 'application';
  if (internalPipelineStatus === 'application_sent') {
    return status;
  } else if (
    internalPipelineStatus === 'application_received' ||
    internalPipelineStatus === 'termsheet_sent' ||
    internalPipelineStatus === 'termsheet_received_and_document_collection' ||
    internalPipelineStatus === 'due_dilligence' ||
    internalPipelineStatus === 'credit_committee'
  )
    status = 'proposal';
  else if (
    internalPipelineStatus === 'credit_committee_advanced' ||
    internalPipelineStatus === 'legal' ||
    internalPipelineStatus === 'onboarding'
  ) {
    status = 'contract';
  } else {
    status = 'closed';
  }
  return status;
};

export function formatCreditLineCurrency(
  language: string,
  number: number,
  currencyCode: string,
  decimalPlaces: number
) {
  return formatNumericValue(number, decimalPlaces, language, currencyCode);
}

export function getUrlParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const result: Record<string, string> = {};
  for (const [key, value] of urlParams.entries()) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

export function getRedirectPage(urlParams: Record<string, string>): string | undefined {
  if (urlParams['next']) {
    return urlParams['next'];
  }
  if (urlParams['llc_status'] || urlParams['llc_notification']) {
    return '/llc';
  }
}

export function formatDateWithTimezoneOffset(date: string, includeTime: boolean = true): string {
  // Function to pad numbers
  const pad = (num: number): string => num.toString().padStart(2, '0');

  // Create a date object from the input UTC timestamp
  const _date: Date = new Date(date);

  // Define options for Intl.DateTimeFormat based on whether time should be included
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    ...(includeTime && { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }),
  };

  // Use Intl.DateTimeFormat to format the date in Eastern Time
  const formatter: Intl.DateTimeFormat = new Intl.DateTimeFormat('en-US', {
    ...options,
    timeZone: 'America/New_York',
  });
  const parts: Intl.DateTimeFormatPart[] = formatter.formatToParts(_date);

  // Extract and pad date and time parts
  const year: string = parts.find((part) => part.type === 'year')!.value;
  const month: string = pad(parseInt(parts.find((part) => part.type === 'month')!.value));
  const day: string = pad(parseInt(parts.find((part) => part.type === 'day')!.value));
  let formattedDate: string = `${year}-${month}-${day}`;

  if (includeTime) {
    const hour: string = pad(parseInt(parts.find((part) => part.type === 'hour')!.value));
    const minute: string = pad(parseInt(parts.find((part) => part.type === 'minute')!.value));
    const second: string = pad(parseInt(parts.find((part) => part.type === 'second')!.value));
    formattedDate += ` ${hour}:${minute}:${second}`;
  }

  // Return the formatted date string
  return formattedDate;
}

// Takes a string formatted as $ 1,000.00 and returns 1000.00
export function formattedToNumber(value: string) {
  // Replace commas with dots before removing currency symbols
  value = value.replace(/,/g, '.');

  return parseFloat(value.replace(/[$,]/g, ''));
}

export function shouldEnableReleaseButton(createdAt: string): boolean {
  const createdAtDate: Date = new Date(createdAt);
  const currentDate: Date = new Date();

  if (isNaN(createdAtDate.getTime())) {
    return true;
  }

  // Calculate the difference in milliseconds
  const diffInMilliseconds: number = currentDate.getTime() - createdAtDate.getTime();
  const diffInHours: number = diffInMilliseconds / (1000 * 60 * 60); // Convert milliseconds to hours

  return diffInHours > 3;
}
