import axios from './axios';
import { BuyerCreditCheckState } from '../viewsv2/BuyerCreditCheck/BuyerCreditCheckForm';

export type BuyerCreditCheckPayload = BuyerCreditCheckState & {
  client?: number;
};

export function submitBuyerCreditCheck(
  buyerCreditCheck: BuyerCreditCheckPayload,
  companyId: number
): Promise<any> {
  return axios
    .post(`/companies/${companyId}/products/buyer_credit_check/request/`, buyerCreditCheck)
    .then(({ data }) => data);
}
