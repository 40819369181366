import { useState } from 'react';
import { logEvent } from '@amplitude/analytics-browser';
import { Formik, FormikProps } from 'formik';
import Grid from '@mui/material/Grid';
import ErrorMessage from '../../components/ErrorMessage';
import ModalV2 from '../../components/ModalV2';
import { ArrowRightIcon, MessageSentIcon } from '../../components/Icons';
import Button from '../../componentsv2/Button';
import Typography from '../../componentsv2/Typography';
import { CheckboxField, SelectField, TextField } from '../../componentsv2/Field';
import { submitBuyerCreditCheck } from '../../services/buyerCreditCheck';
import { useTranslation } from '../../hooks/i18n';
import { useAppSelector } from '../../store';
import { BuyerCreditCheckSchema } from '../../schemas/buyerCreditCheck.schema';
import { backendCountryValues, getCountries } from '../../constants/data';
import { buyerCheckEvents } from '../../constants/events';
import Form from '../../componentsv2/Form';
import { useUserLocation } from '../../hooks/useUserLocation';

// TODO: Delete the hasEmptyValuesBuyerCreditCheck from utils/helpers

export type BuyerCreditCheckState = {
  companyName?: string;
  tradeName?: string;
  addressCountry?: string;
  addressStreet?: string;
  addressCity?: string;
  addressState?: string;
  addressZipCode?: string;
  secondaryAddressStreet?: string;
  secondaryAddressCity?: string;
  secondaryAddressState?: string;
  secondaryAddressZipCode?: string;
  knowsBuyersEIN?: boolean;
  hasAdditionalAddress?: boolean;
  ein?: string;
  duns?: string;
};

const BuyerCreditCheckForm = () => {
  const { t } = useTranslation('buyerCreditCheck');
  const { language } = useUserLocation();
  const { id } = useAppSelector((state) => state.client);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const newCompany = useAppSelector((state) => state.client.newCompany);

  return (
    <>
      <Formik
        initialValues={{
          companyName: '',
          addressCountry: 'us',
          tradeName: '',
          addressStreet: '',
          addressCity: '',
          addressState: '',
          addressZipCode: '',
          secondaryAddressStreet: '',
          secondaryAddressCity: '',
          secondaryAddressState: '',
          secondaryAddressZipCode: '',
          ein: '',
          duns: '',
          knowsBuyersEIN: false,
          hasAdditionalAddress: false,
        }}
        validationSchema={BuyerCreditCheckSchema(t)}
        onSubmit={async (values, { resetForm }) => {
          const mappedValuesForBackend = {
            ...values,
            addressCountry: backendCountryValues['us'],
            client: id,
            ein: values.knowsBuyersEIN ? values.ein : '',
            duns: values.knowsBuyersEIN ? values.duns : '',
            secondaryAddressStreet: values.hasAdditionalAddress ? values.secondaryAddressStreet : '',
            secondaryAddressCity: values.hasAdditionalAddress ? values.secondaryAddressCity : '',
            secondaryAddressState: values.hasAdditionalAddress ? values.secondaryAddressState : '',
            secondaryAddressZipCode: values.hasAdditionalAddress ? values.secondaryAddressZipCode : '',
            language,
          };
          setIsLoading(true);
          await submitBuyerCreditCheck(mappedValuesForBackend, newCompany!.id)
            .then(() => {
              logEvent(buyerCheckEvents.SUBMIT);
              setSubmitted(true);
              resetForm();
            })
            .catch(() => setError(true))
            .finally(() => {
              setIsLoading(false);
            });
        }}>
        {({ values, isSubmitting }: FormikProps<BuyerCreditCheckState>) => {
          return (
            <Form
              name="buyer-credit-check"
              id="buyer-credit-check"
              trackingEvent={buyerCheckEvents.EDIT_FIELD}>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="companyName"
                    fullWidth
                    label={t('form.fields.legalBusinessName')}
                    placeholder={t('form.fields.legalBusinessName')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    optional
                    name="tradeName"
                    fullWidth
                    label={t('form.fields.tradeName')}
                    placeholder={t('form.fields.tradeName')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectField
                    name="addressCountry"
                    label={t('form.fields.country')}
                    placeholder={t('form.fields.country')}
                    disabled
                    options={getCountries(t).map(({ countryKey, country }) => ({
                      value: countryKey,
                      label: country,
                    }))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="addressStreet"
                    label={t('form.fields.businessStreetAddress')}
                    placeholder={t('form.fields.businessStreetAddress')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="addressCity"
                    label={t('form.fields.city')}
                    placeholder={t('form.fields.city')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="addressState"
                    label={t('form.fields.state')}
                    placeholder={t('form.fields.state')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="addressZipCode"
                    label={t('form.fields.zipCode')}
                    placeholder={t('form.fields.zipCode')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckboxField name="knowsBuyersEIN" label={t('form.fields.knowsBuyersEINLabel')} />
                </Grid>
                {values.knowsBuyersEIN && (
                  <Grid item xs={12}>
                    <Grid container rowSpacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          name="ein"
                          placeholder={t('form.fields.einPlaceholder')}
                          label={t('form.fields.einLabel')}
                          moreInfoTooltip={
                            <Typography sx={{ color: '#FFF' }}>{t('form.fields.einTooltip')}</Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          type="number"
                          name="duns"
                          placeholder={t('form.fields.dunsPlaceholder')}
                          label={t('form.fields.dunsLabel')}
                          moreInfoTooltip={
                            <Typography sx={{ color: '#FFF' }}>{t('form.fields.dunsTooltip')}</Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <CheckboxField
                    name="hasAdditionalAddress"
                    label={t('form.fields.hasAdditionalAddressLabel')}
                  />
                </Grid>
                {values.hasAdditionalAddress && (
                  <Grid item xs={12}>
                    <Grid container rowSpacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          name="secondaryAddressStreet"
                          label={t('form.fields.additionalBusinessStreetAddress')}
                          placeholder={t('form.fields.additionalBusinessStreetAddress')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="secondaryAddressCity"
                          label={t('form.fields.city')}
                          placeholder={t('form.fields.city')}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="secondaryAddressState"
                          label={t('form.fields.state')}
                          placeholder={t('form.fields.state')}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="secondaryAddressZipCode"
                          label={t('form.fields.zipCode')}
                          placeholder={t('form.fields.zipCode')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    disabled={isSubmitting}
                    isSubmit
                    fullWidth
                    isLoading={isLoading}
                    endIcon={<ArrowRightIcon />}>
                    {t('form.submitRequest')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {!!error && <ErrorMessage errorMessage={t('form.error')} />}
      {submitted && (
        <ModalV2 isOpen={submitted} handleClose={() => setSubmitted(false)}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <MessageSentIcon />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" textAlign="center">
                {t('confirmationModal.thanks')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" textAlign="center">
                {t('confirmationModal.message')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth onClick={() => setSubmitted(false)} color="secondary">
                {t('confirmationModal.confirmButtonTitle')}
              </Button>
            </Grid>
          </Grid>
        </ModalV2>
      )}
    </>
  );
};

export default BuyerCreditCheckForm;
