import Grid from '@mui/material/Grid';
import Section from '../../componentsv2/Section';
import { useLazyTranslation } from '../../hooks/i18n';
import Typography from '../../componentsv2/Typography';
import SwitchToggle from '../../components/Switch';
import { Divider } from '@mui/material';
import { COLORS } from '../../themev2';
import { useState } from 'react';
import { NotificationCategories } from '../../types/notifications';
import { settingsCategories } from '../../constants/notifications';
import { setNotificationPreferences, useAppDispatch, useAppSelector } from '../../store';
import Button from '../../componentsv2/Button';
import { updateNotificationPreferences } from '../../services/notifications';
import { useSnackbar } from '../../context';
import { logEvent } from '@amplitude/analytics-browser';
import { myAccountEvents } from '../../constants/events';

// FIXME: improve data structure once we have more than one setting per type
const settingTypesMapping = {
  paymentNotifications: 'financingUpdates',
  dueInvoicesNotifications: 'financingUpdates',
  bankingNotifications: 'accountUpdates',
};

const NotificationsSettings = () => {
  const { t } = useLazyTranslation(['myAccount']);
  const newCompanyUser = useAppSelector((state) => state.client.newCompanyUser);
  const { showSnackbar } = useSnackbar();
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useAppDispatch();
  const settingsValues = newCompanyUser?.notificationPreferences;

  function getAllowNotificationsInitialValue() {
    if (!settingsValues) return false;
    return settingsCategories.some((category) => settingsValues[category]);
  }
  const [allowNotifications, setAllowNotifications] = useState(getAllowNotificationsInitialValue());

  const toggleAllowNotifications = () => {
    const value = !allowNotifications;
    setAllowNotifications(value);
    const newSettingsValues = {} as Record<NotificationCategories, boolean>;
    for (const key of settingsCategories) {
      newSettingsValues[key] = value;
    }
    setSettingsValues(newSettingsValues);
  };

  const setSettingsValues = (value: any) => {
    logEvent(myAccountEvents.CHANGE_NOTIFICATIONS, { ...value });
    dispatch(setNotificationPreferences(value));
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Section
          sx={{
            background: '#E4EEFC',
            borderBottomLeftRadius: allowNotifications ? 0 : 'initial',
            borderBottomRightRadius: allowNotifications ? 0 : 'initial',
          }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={11}>
              <Typography sx={{ fontWeight: 600, color: COLORS.gray[1400] }}>
                {t('myAccount:notifications.allowEmailNotifications')}
              </Typography>
            </Grid>
            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <SwitchToggle
                name=""
                label=""
                checked={allowNotifications}
                onChange={() => {
                  toggleAllowNotifications();
                }}
              />
            </Grid>
          </Grid>
        </Section>
      </Grid>
      {settingsValues && (
        <Grid item xs={12}>
          {allowNotifications && (
            <Section
              sx={{
                borderTopLeftRadius: allowNotifications ? 0 : 'initial',
                borderTopRightRadius: allowNotifications ? 0 : 'initial',
              }}>
              <Grid container>
                {settingsCategories.map((setting, index, arr) => {
                  return (
                    <Grid item xs={12}>
                      <Grid container>
                        {/* FIXME: improve data structure once we have more than one setting per type */}
                        {['paymentNotifications', 'bankingNotifications'].includes(setting) && (
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                color: COLORS.gray[1300],
                                fontWeight: 600,
                                fontSize: '14px',
                                lineHeight: '21px',
                              }}>
                              {t(
                                `myAccount:notifications.types.${
                                  settingTypesMapping[setting as keyof typeof settingTypesMapping]
                                }`
                              )}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={11}>
                          <Typography sx={{ color: COLORS.gray[1300], fontWeight: 600 }}>
                            {t(`myAccount:notifications.categories.${setting}.title`)}
                          </Typography>
                          <Typography sx={{ color: COLORS.gray[1200] }}>
                            {t(`myAccount:notifications.categories.${setting}.description`)}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <SwitchToggle
                            name=""
                            label=""
                            checked={settingsValues[setting]}
                            onChange={() => {
                              setSettingsValues({ [setting]: !settingsValues[setting] });
                            }}
                          />
                        </Grid>
                        {index < arr.length - 1 && (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Section>
          )}
        </Grid>
      )}
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2em' }}>
        <Button
          isLoading={isSaving}
          onClick={() => {
            setIsSaving(true);
            updateNotificationPreferences(settingsValues)
              .then(() => {
                showSnackbar(t('myAccount:notifications.savedMsg'), 'success');
              })
              .finally(() => {
                setIsSaving(false);
              });
          }}>
          {t('myAccount:notifications.saveBtn')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default NotificationsSettings;
