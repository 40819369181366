import { COLORS } from '../../themev2';

export default function ArrangeHorizontalSquareIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M16.19 2.74512H7.81C4.17 2.74512 2 4.91512 2 8.55512V16.9251C2 20.5751 4.17 22.7451 7.81 22.7451H16.18C19.82 22.7451 21.99 20.5751 21.99 16.9351V8.55512C22 4.91512 19.83 2.74512 16.19 2.74512Z"
      />
      <path d="M17.85 14.2751C17.7709 14.091 17.6242 13.9442 17.44 13.8651C17.3486 13.8252 17.2498 13.8047 17.15 13.8051H6.85001C6.44001 13.8051 6.10001 14.1451 6.10001 14.5551C6.10001 14.9651 6.44001 15.3051 6.85001 15.3051H15.35L13.59 17.0651C13.3 17.3551 13.3 17.8351 13.59 18.1251C13.74 18.2751 13.93 18.3451 14.12 18.3451C14.31 18.3451 14.5 18.2751 14.65 18.1251L17.69 15.0851C17.76 15.0151 17.81 14.9351 17.85 14.8451C17.92 14.6616 17.92 14.4587 17.85 14.2751ZM6.15001 11.2151C6.23001 11.3951 6.37001 11.5451 6.56001 11.6251C6.65001 11.6651 6.75001 11.6851 6.85001 11.6851H17.16C17.57 11.6851 17.91 11.3451 17.91 10.9351C17.91 10.5251 17.57 10.1851 17.16 10.1851H8.66001L10.42 8.42512C10.71 8.13512 10.71 7.65512 10.42 7.36512C10.2787 7.22588 10.0884 7.14783 9.89001 7.14783C9.69165 7.14783 9.50127 7.22588 9.36001 7.36512L6.32001 10.3951C6.25001 10.4651 6.19001 10.5551 6.15001 10.6451C6.08002 10.8287 6.08002 11.0316 6.15001 11.2151Z" />
    </svg>
  );
}
