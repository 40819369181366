import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { logEvent } from '@amplitude/analytics-browser';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Skeleton from '@mui/material/Skeleton';
import CloseIcon from '@mui/icons-material/Close';
import CollapsibleCard from '../../componentsv2/CollapsibleCard';
import Button from '../../componentsv2/Button';
import ErrorMessage from '../../components/ErrorMessage';
import FormSection from '../../componentsv2/FormSection';
import { ArrowLeftIcon } from '../../components/Icons';
import SocialProof from './SocialProof';
import { useTranslation } from '../../hooks/i18n';
import { thirdPartyConnections, connectToThirdPartyServices } from '../../services/clients';
import { ApplicationStepProps } from '../../types/application';
import { Nullable } from '../../types';
import { invoiceEvents, SATEvents } from '../../constants/events';
import { COLORS } from '../../themev2';
import { useAppSelector } from '../../store';

const InfoDrawer = ({ open, onClose }: { open: boolean; onClose: Function }) => {
  const infoCards = ['benefits', 'notRegistered', 'dontKnow', 'lostPassword', 'noHandy', 'representative'];
  const { t } = useTranslation();

  return (
    <Drawer
      anchor="right"
      open={open}
      sx={{ borderRadius: '20px' }}
      onBackdropClick={() => {
        onClose();
      }}>
      <Box sx={{ padding: '2em', display: 'flex', flexDirection: 'column', gap: '1em' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5" sx={{ flex: 1 }}>
            {t('application:sections.taxInformation.drawer.title')}
          </Typography>
          <IconButton
            onClick={() => {
              onClose();
            }}>
            <CloseIcon />
          </IconButton>
        </Box>
        {infoCards.map((name) => (
          <CollapsibleCard
            key={name}
            title={t(`application:sections.taxInformation.drawer.${name}.title`)}
            content={
              <div
                dangerouslySetInnerHTML={{
                  __html: t(`application:sections.taxInformation.drawer.${name}.description`),
                }}
              />
            }
            sx={{ width: '500px' }}
          />
        ))}
      </Box>
    </Drawer>
  );
};

function SuccessIcon() {
  return (
    <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M46.049 8.57911L23.1323 17.1624C18.7573 18.8291 15.174 23.9958 15.174 28.7041V62.4541C15.174 65.8291 17.3823 70.2875 20.0907 72.2875L43.0073 89.4124C47.049 92.4541 53.674 92.4541 57.7157 89.4124L80.6323 72.2875C83.3407 70.2458 85.549 65.8291 85.549 62.4541V28.7041C85.549 24.0374 81.9657 18.8291 77.5907 17.2041L54.674 8.62078C52.3407 7.70411 48.424 7.70411 46.049 8.57911Z"
        fill="url(#paint0_linear_399_24806)"
      />
      <path
        d="M44.7907 59.2959C43.999 59.2959 43.2073 59.0042 42.5823 58.3792L35.874 51.6709C35.2928 51.0828 34.9669 50.2894 34.9669 49.4626C34.9669 48.6358 35.2928 47.8423 35.874 47.2542C37.0823 46.0459 39.0823 46.0459 40.2907 47.2542L44.7907 51.7542L60.499 36.0459C61.7073 34.8376 63.7073 34.8376 64.9157 36.0459C66.124 37.2542 66.124 39.2542 64.9157 40.4626L46.999 58.3792C46.374 59.0042 45.5823 59.2959 44.7907 59.2959Z"
        fill="url(#paint1_linear_399_24806)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_399_24806"
          x1="78.521"
          y1="49.8104"
          x2="23.1611"
          y2="47.8584"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BAAA1" />
          <stop offset="1" stopColor="#0B8DAA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_399_24806"
          x1="62.7406"
          y1="47.2176"
          x2="38.5082"
          y2="45.9186"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BAAA1" />
          <stop offset="1" stopColor="#0B8DAA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

function ErrorIcon() {
  return (
    <svg width="85" height="84" viewBox="0 0 85 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M59.8407 0.616089H24.924C9.75732 0.616089 0.715656 9.65776 0.715656 24.8244V59.6994C0.715656 74.9078 9.75732 83.9494 24.924 83.9494H59.799C74.9657 83.9494 84.0073 74.9078 84.0073 59.7411V24.8244C84.049 9.65776 75.0073 0.616089 59.8407 0.616089Z"
        fill="#C54646"
      />
      <path
        d="M46.799 42.2827L56.3823 32.6994C57.5907 31.491 57.5907 29.491 56.3823 28.2827C55.7943 27.7015 55.0008 27.3756 54.174 27.3756C53.3472 27.3756 52.5537 27.7015 51.9657 28.2827L42.3823 37.866L32.799 28.2827C32.2109 27.7015 31.4175 27.3756 30.5907 27.3756C29.7639 27.3756 28.9704 27.7015 28.3823 28.2827C27.174 29.491 27.174 31.491 28.3823 32.6994L37.9657 42.2827L28.3823 51.866C27.174 53.0744 27.174 55.0744 28.3823 56.2827C29.0073 56.9077 29.799 57.1994 30.5907 57.1994C31.3823 57.1994 32.174 56.9077 32.799 56.2827L42.3823 46.6994L51.9657 56.2827C52.5907 56.9077 53.3823 57.1994 54.174 57.1994C54.9657 57.1994 55.7573 56.9077 56.3823 56.2827C57.5907 55.0744 57.5907 53.0744 56.3823 51.866L46.799 42.2827Z"
        fill="#C54646"
      />
    </svg>
  );
}

function SecureDataDisclaimer() {
  const { t } = useTranslation(['application']);
  return (
    <Box
      sx={{
        marginY: '2em',
        display: 'flex',
        paddingLeft: '2em',
        borderLeft: `1px solid ${COLORS.gray[1100]}`,
      }}>
      <Box>
        <Typography
          sx={{
            color: COLORS.gray[1100],
          }}>
          {t('application:sections.taxInformation.secureData.title')}
        </Typography>
        <Typography
          sx={{
            color: COLORS.gray[1200],
          }}>
          <Trans t={t} i18nKey={'application:sections.taxInformation.secureData.description'}>
            <span style={{ fontWeight: 600 }}>Secure connection by Satws.</span> Your credentials are not
            saved and your information is not shared with anyone.
          </Trans>
        </Typography>
      </Box>
    </Box>
  );
}

const TaxStep = ({
  onStepReturn,
  onStepSubmit,
  isLoading: isLoadingApp,
  applicationDraft,
}: ApplicationStepProps) => {
  const { t } = useTranslation('application');
  const [connectionStatus, setConnectionStatus] =
    useState<Nullable<'connected' | 'error' | 'errorWithRFC'>>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shouldConnectThirdPartyServices, setShouldConnectThirdPartyServices] = useState<boolean>(false);
  const newCompany = useAppSelector((state) => state.client.newCompany);

  useEffect(() => {
    logEvent(invoiceEvents.STEP_TWO);
  }, []);

  useEffect(() => {
    if (shouldConnectThirdPartyServices) {
      setIsLoading(true);
      connectToThirdPartyServices(newCompany!.id, applicationDraft.id)
        .then((data) => {
          if (data.satws) setConnectionStatus('connected');
          else setConnectionStatus('errorWithRFC');
        })
        .finally(() => setIsLoading(false));
    }
  }, [shouldConnectThirdPartyServices]);

  useEffect(() => {
    if (connectionStatus === 'connected') {
      logEvent(SATEvents.CONNECT_SAT_SUCCESS);
    } else if (connectionStatus === 'error') {
      logEvent(SATEvents.CONNECT_SAT_ERROR);
    }
  }, [connectionStatus]);

  // Check if user is already connected to SAT
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await thirdPartyConnections(newCompany!.id, applicationDraft.id)
        .then((data) => {
          if (data?.satws) {
            setConnectionStatus('connected');
          }
        })
        .finally(() => setIsLoading(false));
    })();
  }, []);

  // Configure SatWS widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'satws-widget-id';
    script.async = true;
    script.type = 'text/javascript';
    script.src = process.env.REACT_APP_SATWS_SRC || '';
    script.setAttribute('data-key', process.env.REACT_APP_SATWS_DATA_KEY || '');
    script.setAttribute('data-sandbox', process.env.REACT_APP_SATWS_DATA_SANDBOX || '');

    document.body.appendChild(script);

    window.addEventListener('widgetScriptLoaded', (e) => {
      // @ts-ignore
      window.Satws.subscribe('errorOnRegister', () => {
        setConnectionStatus('error');
      });

      // @ts-ignore
      window.Satws.subscribe('registerWithSuccess', () => {
        setShouldConnectThirdPartyServices(true);
      });
    });
  }, []);

  const handleViewSATHelp = () => {
    setOpenModal(true);
    logEvent(SATEvents.OPEN_SAT_HELP);
  };

  const handleCloseSATHelp = () => {
    setOpenModal(false);
    logEvent(SATEvents.CLOSE_SAT_HELP);
  };

  return (
    <>
      <InfoDrawer open={openModal} onClose={handleCloseSATHelp} />
      {isLoading && (
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={120} animation="wave" />
        </Grid>
      )}
      {!isLoading && connectionStatus === 'connected' && (
        <>
          <FormSection>
            <Box sx={{ padding: '1em', marginBottom: '3em', display: 'flex', alignItems: 'center' }}>
              <Box>
                <SuccessIcon />
              </Box>
              <Box sx={{ marginLeft: '1em' }}>
                <Typography variant="h5" sx={{ color: COLORS.green[600], fontWeight: 600 }}>
                  {t('sections.taxInformation.succcessfulConnection')}
                </Typography>
                <Typography>{t('sections.taxInformation.clickNext')}</Typography>
              </Box>
            </Box>
          </FormSection>
          <Box sx={{ display: 'flex', gap: '1em' }}>
            <Button onClick={onStepReturn} type="outline">
              {t('steps.tax.returnBtn')}
            </Button>
            <Button onClick={() => onStepSubmit({})} isLoading={isLoadingApp || isLoading}>
              {t('steps.tax.submitBtn')}
            </Button>
          </Box>
        </>
      )}
      {!isLoading && connectionStatus === 'errorWithRFC' && (
        <>
          <FormSection>
            <Box sx={{ padding: '1em', marginBottom: '3em', display: 'flex', alignItems: 'center' }}>
              <Box>
                <ErrorIcon />
              </Box>
              <Box sx={{ marginLeft: '1em', maxWidth: { xs: '100%', md: '52%' } }}>
                <Typography variant="h5" sx={{ color: COLORS.feedback.danger, fontWeight: 700 }}>
                  {t('sections.taxInformation.errorWithRFC.title')}
                </Typography>
                <Typography>
                  <Trans t={t} i18nKey={'sections.taxInformation.errorWithRFC.description'}>
                    <span style={{ fontWeight: '600' }}>
                      Your credentials are working correctly, but do not match the RFC provided before. Please
                      go to the previous step and modify the RFC to match.
                    </span>
                  </Trans>
                </Typography>
              </Box>
            </Box>
          </FormSection>
          <Box sx={{ display: 'flex', gap: '1em' }}>
            <Button onClick={onStepReturn} startIcon={<ArrowLeftIcon />}>
              {t('sections.taxInformation.errorWithRFC.returnBtn')}
            </Button>
          </Box>
        </>
      )}
      {!isLoading && connectionStatus !== 'connected' && connectionStatus !== 'errorWithRFC' && (
        <>
          <SecureDataDisclaimer />
          <Grid item xs={12} md={8}>
            <Grid
              container
              columnGap={{ xl: 4, md: 4, xs: 2 }}
              rowGap={2}
              sx={{ justifyContent: { xs: 'center', md: 'inherit' } }}>
              <Grid item xs={6} md={5} sx={{ display: 'flex', gap: '1em' }}>
                <Button
                  type="outline"
                  onClick={() => {
                    onStepReturn();
                  }}
                  disabled={isLoadingApp}>
                  {t('steps.business.returnBtn')}
                </Button>
                <Button
                  onClick={() => {
                    // @ts-ignore
                    window.Satws.init();
                    logEvent(SATEvents.CONNECT_SAT);
                  }}
                  isLoading={isLoadingApp || isLoading}>
                  {t('steps.tax.connectSATBtn')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button type="link" onClick={handleViewSATHelp} color="secondary">
                  {t('sections.taxInformation.whyConnectSAT')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {connectionStatus === 'error' && (
            <Grid item xs={12} md={8}>
              <ErrorMessage errorMessage={t('sections.taxInformation.connectionRequired')} />
            </Grid>
          )}
          <SocialProof />
        </>
      )}
    </>
  );
};

export default TaxStep;
