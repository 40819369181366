import { SelectField, TextField } from '../../componentsv2/Field';
import Grid from '@mui/material/Grid';
import { useTranslation } from '../../hooks/i18n';
import { bankAccountCountries } from '../../constants/data';

export const MXAccountForm = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation(['myAccount', 'user']);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={disabled}
          name="companyLegalName"
          label={t('bankAccounts.companyLegalName.label')}
          placeholder={t('bankAccounts.companyLegalName.placeholder')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={disabled}
          name="bankName"
          label={t('bankAccounts.bankName.label')}
          placeholder={t('bankAccounts.bankName.placeholder')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={disabled}
          name="clabe"
          label={t('bankAccounts.clabe.label')}
          placeholder={t('bankAccounts.clabe.placeholder')}
          moreInfoTooltip={t('bankAccounts.clabe.tooltip')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={disabled}
          name="swiftCode"
          label={t('bankAccounts.swiftCode.label')}
          placeholder={t('bankAccounts.swiftCode.placeholder')}
          moreInfoTooltip={t('bankAccounts.swiftCode.tooltip')}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <SelectField
          disabled={disabled}
          name="accountType"
          label={t('bankAccounts.accountType.label')}
          placeholder={t('bankAccounts.accountType.placeholder')}
          options={[
            { label: t('bankAccounts.accountType.options.SAVINGS'), value: 'SAVINGS' },
            { label: t('bankAccounts.accountType.options.CHECKING'), value: 'CHECKING' },
          ]}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <SelectField
          disabled={disabled}
          name="currency"
          label={t('bankAccounts.currency.label')}
          placeholder={t('bankAccounts.currency.placeholder')}
          options={[
            { label: t('bankAccounts.currency.options.USD'), value: 'USD' },
            { label: t('bankAccounts.currency.options.MXN'), value: 'MXN' },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={disabled}
          optional
          name="treasuryFinanceEmail"
          label={t('bankAccounts.treasuryFinanceEmail.label')}
          placeholder={t('bankAccounts.treasuryFinanceEmail.placeholder')}
          moreInfoTooltip={t('bankAccounts.treasuryFinanceEmail.tooltip')}
        />
      </Grid>
    </Grid>
  );
};

export const USAccountForm = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation(['myAccount', 'user']);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={disabled}
          name="companyLegalName"
          label={t('bankAccounts.companyLegalName.label')}
          placeholder={t('bankAccounts.companyLegalName.placeholder')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={disabled}
          name="bankName"
          label={t('bankAccounts.bankName.label')}
          placeholder={t('bankAccounts.bankName.placeholder')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={disabled}
          name="bankAccountNumber"
          label={t('bankAccounts.bankAccountNumber.label')}
          placeholder={t('bankAccounts.bankAccountNumber.placeholder')}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectField
          disabled={disabled}
          name="accountType"
          label={t('bankAccounts.accountType.label')}
          placeholder={t('bankAccounts.accountType.placeholder')}
          options={[
            { label: t('bankAccounts.accountType.options.SAVINGS'), value: 'SAVINGS' },
            { label: t('bankAccounts.accountType.options.CHECKING'), value: 'CHECKING' },
          ]}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <SelectField
          disabled={disabled}
          name="currency"
          label={t('bankAccounts.currency.label')}
          placeholder={t('bankAccounts.currency.placeholder')}
          options={[{ label: t('bankAccounts.currency.options.USD'), value: 'USD' }]}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <SelectField
          disabled={disabled}
          name="bankingDataType"
          label={t('bankAccounts.bankingDataType.label')}
          placeholder={t('bankAccounts.bankingDataType.placeholder')}
          options={[
            { label: t('bankAccounts.bankingDataType.options.WIRE'), value: 'WIRE' },
            { label: t('bankAccounts.bankingDataType.options.ACH'), value: 'ACH' },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          disabled={disabled}
          name="abaRoutingNumber"
          label={t('bankAccounts.abaRoutingNumber.label')}
          placeholder={t('bankAccounts.abaRoutingNumber.placeholder')}
          moreInfoTooltip={t('bankAccounts.abaRoutingNumber.tooltip')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={disabled}
          optional
          name="treasuryFinanceEmail"
          label={t('bankAccounts.treasuryFinanceEmail.label')}
          placeholder={t('bankAccounts.treasuryFinanceEmail.placeholder')}
        />
      </Grid>
    </Grid>
  );
};

export const ROWAccountForm = ({ disabled, country }: { disabled: boolean; country: string }) => {
  const { t } = useTranslation(['myAccount', 'user']);

  const countryToCurrency: Record<string, string> = {
    argentina: 'ARS',
    australia: 'AUD',
    austria: 'EUR',
    belize: 'BZD',
    belgium: 'EUR',
    bolivia: 'BOB',
    brazil: 'BRL',
    canada: 'CAD',
    croatia: 'HRK',
    chile: 'CLP',
    colombia: 'COP',
    costaRica: 'CRC',
    cyprus: 'EUR',
    czechRepublic: 'CZK',
    denmark: 'DKK',
    dominicanRepublic: 'DOP',
    elSalvador: 'SVC',
    finland: 'EUR',
    france: 'EUR',
    germany: 'EUR',
    greece: 'EUR',
    guatemala: 'GTQ',
    honduras: 'HNL',
    hongKong: 'HKD',
    india: 'INR',
    ireland: 'EUR',
    italy: 'EUR',
    japan: 'JPY',
    lithuania: 'LTL',
    luxembourg: 'EUR',
    malta: 'EUR',
    mexico: 'MXN',
    netherlands: 'EUR',
    newZealand: 'NZD',
    nicaragua: 'NIO',
    norway: 'NOK',
    panama: 'PAB',
    paraguay: 'PYG',
    peru: 'PEN',
    poland: 'PLN',
    portugal: 'EUR',
    puertoRico: 'USD',
    singapore: 'SGD',
    spain: 'EUR',
    sweden: 'SEK',
    switzerland: 'CHF',
    unitedArabEmirates: 'AED',
    uk: 'GBP',
    usa: 'USD',
    uruguay: 'UYU',
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={disabled}
          name="companyLegalName"
          label={t('bankAccounts.companyLegalName.label')}
          placeholder={t('bankAccounts.companyLegalName.placeholder')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={disabled}
          name="bankName"
          label={t('bankAccounts.bankName.label')}
          placeholder={t('bankAccounts.bankName.placeholder')}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          disabled={disabled}
          name="bankAccountNumber"
          label={t('bankAccounts.bankAccountNumber.label')}
          placeholder={t('bankAccounts.bankAccountNumber.placeholder')}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          disabled={disabled}
          name="swiftCode"
          label={t('bankAccounts.swiftCode.label')}
          placeholder={t('bankAccounts.swiftCode.placeholder')}
          moreInfoTooltip={t('bankAccounts.swiftCode.tooltip')}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectField
          disabled={disabled}
          name="accountType"
          label={t('bankAccounts.accountType.label')}
          placeholder={t('bankAccounts.accountType.placeholder')}
          options={[
            { label: t('bankAccounts.accountType.options.SAVINGS'), value: 'SAVINGS' },
            { label: t('bankAccounts.accountType.options.CHECKING'), value: 'CHECKING' },
          ]}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <SelectField
          disabled={disabled}
          name="currency"
          label={t('bankAccounts.currency.label')}
          placeholder={t('bankAccounts.currency.placeholder')}
          options={[
            { label: t('bankAccounts.currency.options.USD'), value: 'USD' },
            // if country has local currency, add as option
            ...(countryToCurrency[country]
              ? [{ label: countryToCurrency[country], value: countryToCurrency[country] }]
              : []),
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={disabled}
          name="ownerAddress"
          label={t('bankAccounts.ownerAddress.label')}
          placeholder={t('bankAccounts.ownerAddress.placeholder')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={disabled}
          name="state"
          label={t('bankAccounts.state.label')}
          placeholder={t('bankAccounts.state.placeholder')}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          disabled={disabled}
          name="city"
          label={t('bankAccounts.city.label')}
          placeholder={t('bankAccounts.city.placeholder')}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          disabled={disabled}
          name="postalCode"
          label={t('bankAccounts.postalCode.label')}
          placeholder={t('bankAccounts.postalCode.placeholder')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={disabled}
          optional
          name="treasuryFinanceEmail"
          label={t('bankAccounts.treasuryFinanceEmail.label')}
          placeholder={t('bankAccounts.treasuryFinanceEmail.placeholder')}
          moreInfoTooltip={t('bankAccounts.treasuryFinanceEmail.tooltip')}
        />
      </Grid>
    </Grid>
  );
};

type AccountInformationFormProps = { country: string; disabled?: boolean };

const BankAccountRequestForm = ({ country, disabled = false }: AccountInformationFormProps) => {
  if (country === 'mexico') {
    return <MXAccountForm disabled={disabled} />;
  } else if (country === 'usa') {
    return <USAccountForm disabled={disabled} />;
  } else return <ROWAccountForm disabled={disabled} country={country} />;
};

export default BankAccountRequestForm;
