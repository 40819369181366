import { useState } from 'react';
import { logEvent } from '@amplitude/analytics-browser';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Chip from '../../componentsv2/Chip';
import Button from '../../componentsv2/Button';
import Table from '../../componentsv2/Table';
import { TextField } from '../../componentsv2/Field';
import ModalV2 from '../../components/ModalV2';
import { AddIcon, TrashIcon, EditIcon, EyeIcon } from '../../components/Icons';
import ErrorMessage from '../../components/ErrorMessage';
import {
  addCompanyLegalRepresentative,
  deleteCompanyLegalRepresentative,
  editCompanyLegalRepresentative,
} from '../../services/documents';
import { useTranslation } from '../../hooks/i18n';
import { Nullable } from '../../types';
import { APILegalRepresentative } from '../../types/documents';
import { statusColorsMap } from './utils';
import { docsCollectionEvents } from '../../constants/events';
import AddEditLegalRepresentativeDrawer from './AddEditLegalRepresentativeDrawer';

function getStatus(legalRepresentatives: APILegalRepresentative[]): string {
  if (!legalRepresentatives.length) return 'PENDING';
  const status = legalRepresentatives[0].status;
  if (!!legalRepresentatives[0].rejectComment && status === 'PENDING') return 'DENIED';
  return status;
}

const LegalRepresentatives = ({
  legalRepresentatives,
  companyId,
  loadLegalRepresentatives,
  isAdmin = false,
  language = 'en',
  onApprove = () => Promise.resolve(),
  onReject = () => Promise.resolve(),
}: {
  legalRepresentatives: APILegalRepresentative[];
  companyId: number;
  loadLegalRepresentatives: (companyId: number) => Promise<void>;
  isAdmin?: boolean;
  language?: string;
  onApprove?: (companyId: number, categoryId: number) => Promise<void>;
  onReject?: (companyId: number, categoryId: number, rejectionComment: string) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const [showLegalRepresentativeModal, setShowLegalRepresentativeModal] = useState(false);
  const [currentLegalRepresentative, setCurrentLegalRepresentative] =
    useState<Nullable<APILegalRepresentative>>(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showRejectionConfirmationModal, setShowRejectionConfirmationModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const categoryId = legalRepresentatives.length ? legalRepresentatives[0].categoryId : null;
  const status = getStatus(legalRepresentatives);

  const openLegalRepresentativesModal = (legalRepresentative?: APILegalRepresentative) => {
    legalRepresentative && setCurrentLegalRepresentative(legalRepresentative);
    setShowLegalRepresentativeModal(true);
  };

  const closeLegalRepresentativeModal = () => {
    setCurrentLegalRepresentative(null);
    setShowLegalRepresentativeModal(false);
  };

  const openConfirmationModal = (legalRepresentative?: APILegalRepresentative) => {
    legalRepresentative && setCurrentLegalRepresentative(legalRepresentative);
    setShowDeleteConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setCurrentLegalRepresentative(null);
    setShowDeleteConfirmationModal(false);
  };

  return (
    <>
      <ModalV2
        isOpen={showRejectionConfirmationModal}
        handleClose={() => {
          setShowRejectionConfirmationModal(false);
          setRejectionReason('');
        }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography>
              Please add a rejection reason (in {language === 'es' ? 'spanish' : 'english'})
            </Typography>
            <TextField
              multiline
              minRows={4}
              name="reason"
              onChange={(value) => {
                setRejectionReason(value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ gap: '4px', display: 'flex', justifyContent: 'center' }}>
              <Button
                color="danger"
                onClick={() => {
                  if (rejectionReason && !!categoryId) {
                    setIsLoading(true);
                    onReject(companyId, categoryId, rejectionReason).finally(() => {
                      setIsLoading(false);
                    });
                    setRejectionReason('');
                    setShowRejectionConfirmationModal(false);
                  } else {
                    alert('a reason');
                  }
                }}>
                Confirm
              </Button>
              <Button
                type="outline"
                color="danger"
                onClick={() => {
                  setRejectionReason('');
                  setShowRejectionConfirmationModal(false);
                }}>
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </ModalV2>
      {showLegalRepresentativeModal && (
        <AddEditLegalRepresentativeDrawer
          isAdmin={isAdmin}
          legalRepresentative={currentLegalRepresentative}
          companyId={companyId}
          onAddLegalRepresentative={async (companyId, values, files) => {
            await addCompanyLegalRepresentative(companyId, values, files, isAdmin).then(() => {
              loadLegalRepresentatives(companyId);
              closeLegalRepresentativeModal();
            });
          }}
          onEditLegalRepresentative={async (companyId, legalRepId, values) => {
            await editCompanyLegalRepresentative(companyId, legalRepId, values, isAdmin).then(() => {
              loadLegalRepresentatives(companyId);
              closeLegalRepresentativeModal();
            });
          }}
          handleClose={() => closeLegalRepresentativeModal()}
          showDrawer={showLegalRepresentativeModal}
          isAccepted={status === 'ACCEPTED'}
        />
      )}
      <ModalV2
        isOpen={showDeleteConfirmationModal}
        handleClose={() => {
          setShowDeleteConfirmationModal(false);
        }}
        size="small">
        <Grid container>
          <Grid item xs={12}>
            {currentLegalRepresentative && (
              <Typography>
                {t('client:documents.legalRepresentatives.confirmDelete', {
                  name: currentLegalRepresentative!.fullName,
                })}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: '4px' }}>
              <Button
                color="danger"
                isLoading={isDeleting}
                onClick={() => {
                  deleteCompanyLegalRepresentative(companyId, currentLegalRepresentative!.id, isAdmin)
                    .then(() => {
                      closeConfirmationModal();
                      logEvent(docsCollectionEvents.DELETE_LEGAL_REP);
                      loadLegalRepresentatives(companyId);
                    })
                    .finally(() => {
                      setIsDeleting(false);
                    });
                }}>
                {t('client:documents.legalRepresentatives.confirmDeleteBtn')}
              </Button>
              <Button
                type="outline"
                color="danger"
                disabled={isDeleting}
                onClick={() => {
                  closeConfirmationModal();
                }}>
                {t('client:documents.legalRepresentatives.confirmDeleteCancel')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </ModalV2>
      <Box
        sx={{
          boxShadow: '0px 10px 24px rgba(201, 210, 224, 0.3)',
          background: '#fbfcfe',
          borderRadius: '12px',
          padding: '2em',
          marginBottom: '1em',
        }}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ display: 'flex', gap: '2em', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '1.2em', fontWeight: 'bold' }}>
              {t('client:documents.legalRepresentatives.title')}
            </Typography>
            <Chip
              sx={{ ...statusColorsMap[status] }}
              label={t(`client:documents.status.${status}`)}
              size="small"
            />
            {status === 'DENIED' && (
              <ErrorMessage errorMessage={legalRepresentatives[0].rejectComment as string} />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: 'flex', justifyContent: { xs: 'initial', md: 'flex-end' } }}>
            {isAdmin && (
              <>
                <Button
                  type="link"
                  disabled={!categoryId || isLoading}
                  color="danger"
                  onClick={() => {
                    setShowRejectionConfirmationModal(true);
                    logEvent(docsCollectionEvents.REJECT_DOC, { Name: 'LEGAL_REPRESENTATIVES' });
                  }}>
                  Reject
                </Button>
                <Button
                  type="link"
                  disabled={!categoryId || isLoading}
                  onClick={() => {
                    if (categoryId) {
                      if (
                        !window.confirm(
                          'Are you sure you want to approve Legal Representatives? This operation is not reversible.'
                        )
                      )
                        return;
                      setIsLoading(true);
                      onApprove(companyId, categoryId).finally(() => {
                        setIsLoading(false);
                      });
                      logEvent(docsCollectionEvents.APPROVE_DOC, { Name: 'LEGAL_REPRESENTATIVES' });
                    }
                  }}>
                  Approve
                </Button>
              </>
            )}
            <Box
              sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingLeft: '2em' }}>
              <Button
                isFullHeight={false}
                type="outline"
                color="secondary"
                startIcon={<AddIcon />}
                disabled={status === 'ACCEPTED'}
                onClick={() => {
                  logEvent(docsCollectionEvents.OPEN_ADD_LEGAL_REP_MODAL);
                  openLegalRepresentativesModal();
                }}>
                {t('client:documents.buttons.add')}
              </Button>
            </Box>
          </Grid>
          {legalRepresentatives.length > 0 && (
            <Grid item xs={12}>
              <Table
                columns={[
                  {
                    field: 'fullName',
                    headerName: t('client:documents.shareholders.fullName.label'),
                  },
                  {
                    field: 'personType',
                    headerName: t('client:documents.shareholders.personType.label'),
                    renderCell: ({ personType }: APILegalRepresentative) => {
                      return <Typography>{t(`client:documents.shareholders.${personType}`)}</Typography>;
                    },
                  },
                  {
                    field: 'personIdNumber',
                    headerName: t('client:documents.shareholders.personIdNumber.label'),
                  },
                  {
                    field: 'email',
                    headerName: t('client:documents.shareholders.email.label'),
                  },
                  {
                    headerName: t('client:documents.shareholders.actions'),
                    renderCell: (legalRepresentative) => {
                      return (
                        <>
                          <IconButton
                            onClick={() => {
                              logEvent(docsCollectionEvents.OPEN_EDIT_LEGAL_REP_MODAL, {
                                Name: legalRepresentative.fullName,
                              });
                              openLegalRepresentativesModal(legalRepresentative);
                            }}>
                            {status === 'ACCEPTED' ? <EyeIcon /> : <EditIcon />}
                          </IconButton>
                          <IconButton
                            disabled={status === 'ACCEPTED'}
                            onClick={() => {
                              openConfirmationModal(legalRepresentative);
                            }}>
                            <TrashIcon />
                          </IconButton>
                        </>
                      );
                    },
                  },
                ]}
                data={legalRepresentatives}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default LegalRepresentatives;
