import axios from './axios';

export type InsuranceType = 'cargo' | 'payment';

const INSURANCE_TYPES_MAP: Record<InsuranceType, InsuranceTypeAPI> = {
  cargo: 'cargo_insurance',
  payment: 'payment_insurance',
};

type InsuranceTypeAPI = 'cargo_insurance' | 'payment_insurance';

type InsuranceAPI = {
  client: number;
  createdAt: string;
  deletedAt: null;
  id: number;
  insuranceType: InsuranceTypeAPI;
  updatedAt: string;
};

type Insurance = Omit<InsuranceAPI, 'insuranceType'> & { insuranceType: InsuranceType };

function formatResponse(data: InsuranceAPI): Insurance {
  return { ...data, insuranceType: data.insuranceType === 'cargo_insurance' ? 'cargo' : 'payment' };
}

export function submitInsurance(
  insuranceType: InsuranceType,
  language: string,
  companyId: number
): Promise<Insurance> {
  return axios
    .post<InsuranceAPI>(`companies/${companyId}/products/insurances/request/`, {
      insuranceType: INSURANCE_TYPES_MAP[insuranceType],
      language,
    })
    .then(({ data }) => formatResponse(data));
}

export function getInsurance(companyId: number): Promise<Insurance[]> {
  return axios
    .get<InsuranceAPI[]>(`companies/${companyId}/products/insurances/request/`)
    .then(({ data }) => data.map((elem) => formatResponse(elem)));
}
