export default function StatusTickIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5332 2.16504C7.0232 2.16504 2.5332 6.65504 2.5332 12.165C2.5332 17.675 7.0232 22.165 12.5332 22.165C18.0432 22.165 22.5332 17.675 22.5332 12.165C22.5332 6.65504 18.0432 2.16504 12.5332 2.16504ZM17.3132 9.86504L11.6432 15.535C11.5026 15.6755 11.312 15.7544 11.1132 15.7544C10.9145 15.7544 10.7238 15.6755 10.5832 15.535L7.7532 12.705C7.61372 12.5639 7.5355 12.3735 7.5355 12.175C7.5355 11.9766 7.61372 11.7862 7.7532 11.645C8.0432 11.355 8.5232 11.355 8.8132 11.645L11.1132 13.945L16.2532 8.80504C16.5432 8.51504 17.0232 8.51504 17.3132 8.80504C17.6032 9.09504 17.6032 9.56504 17.3132 9.86504Z"
        fill="currentColor"
      />
    </svg>
  );
}
