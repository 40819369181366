import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '../../componentsv2/Typography';
import CopyToClipboardButton from '../CopyToClipboardButton';
import { COLORS } from '../../themev2';

export default function Detail({
  value,
  isCopyable = false,
  title,
}: {
  value: string;
  isCopyable?: boolean;
  title: string;
}) {
  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '21px',
            color: COLORS.gray[1100],
          }}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            color: COLORS.gray[1200],
          }}>
          {value}
        </Typography>
        <Box sx={{ width: '40px' }}>
          {isCopyable && <CopyToClipboardButton textToCopy={value} customSX={{ paddingY: 0 }} />}
        </Box>
      </Grid>
    </Grid>
  );
}
