export default function CopyToClipboardIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8501 12.9126H9.6001C9.31364 12.9126 9.07926 12.6782 9.07926 12.3918V4.05843C9.07926 3.77197 9.31364 3.5376 9.6001 3.5376H14.1606L16.3709 5.74788V12.3918C16.3709 12.6782 16.1366 12.9126 15.8501 12.9126ZM9.6001 14.4751H15.8501C16.9992 14.4751 17.9334 13.5409 17.9334 12.3918V5.74788C17.9334 5.33447 17.7674 4.93734 17.4744 4.64437L15.2674 2.43408C14.9744 2.14111 14.5773 1.9751 14.1639 1.9751H9.6001C8.45101 1.9751 7.51676 2.90934 7.51676 4.05843V12.3918C7.51676 13.5409 8.45101 14.4751 9.6001 14.4751ZM5.43343 6.14176C4.28434 6.14176 3.3501 7.07601 3.3501 8.2251V16.5584C3.3501 17.7075 4.28434 18.6418 5.43343 18.6418H11.6834C12.8325 18.6418 13.7668 17.7075 13.7668 16.5584V15.5168H12.2043V16.5584C12.2043 16.8449 11.9699 17.0793 11.6834 17.0793H5.43343C5.14697 17.0793 4.9126 16.8449 4.9126 16.5584V8.2251C4.9126 7.93864 5.14697 7.70426 5.43343 7.70426H6.4751V6.14176H5.43343Z"
        fill="currentColor"
      />
    </svg>
  );
}
