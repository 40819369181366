import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFlag } from '@unleash/proxy-client-react';
import { identify, Identify, logEvent } from '@amplitude/analytics-browser';
import ApplicationStepper from '../../containers/ApplicationStepperv2';
import { useLazyTranslation } from '../../hooks/i18n';
import { getApplications, submitApplication } from '../../services/application';
import { loadClient as loadClientThunk, useAppDispatch, useAppSelector } from '../../store';
import IntroAppScreen from './IntroAppScreen';
import ApplicationCES from '../../componentsv2/ApplicationCES';
import SimpleBackdrop from '../../components/Backdrop';
import { Nullable } from '../../types';
import { APIApplication } from '../../types/application';
import { mapCountryFromBackend } from '../../utils/helpers';
import { invoiceEvents } from '../../constants/events';
import { APPLICATION_STARTED } from '../../constants/variables';
import { CURRENT_APP_STEP_STORAGE_KEY, INVOICE_FINANCING } from '../../constants/auth';

// TODO: create utility type to revert Nullable in all fields and change this any to APIApplication
const identifyUserApplication = (values: APIApplication) => {
  const identifyObj = new Identify();
  identifyObj.set('Business City', values.addressCity || '');
  identifyObj.set('Sector', values.sector || '');
  // TODO: maybe it'll be better to log money min and max separately for querying purposes
  identifyObj.set('Financial Needs', `${values.moneyMin} - ${values.moneyMax}`);
  values.addressCountry &&
    mapCountryFromBackend(values.addressCountry) === 'mx' &&
    identifyObj.set('SATWS', values.flowType === 'satws' ? 'ON' : 'OFF');
  identify(identifyObj);
};

const Application = () => {
  const { isLoading: isLoadingTranslations } = useLazyTranslation(['application']);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const client = useAppSelector((state) => state.client.client);
  const newCompany = useAppSelector((state) => state.client.newCompany);
  const storedCurrentStep = Number(localStorage.getItem(CURRENT_APP_STEP_STORAGE_KEY));
  const [displayCompletedModal, setDisplayCompletedModal] = useState(false);
  const [application, setApplication] = useState<Nullable<APIApplication>>(null);
  const [isLoadingApp, setIsLoadingApp] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const isCustomerListEnabled = useFlag('customers-list') && application?.flowType === 'satws';

  const isLoading = isLoadingTranslations || isLoadingApp;

  // TODO: improve values type
  const onApplicationSubmit = (values: any) => {
    if (!application) return;
    setIsLoadingApp(true);
    submitApplication(values, application.id, newCompany!)
      .then((submittedApplication) => {
        setDisplayCompletedModal(true);
        localStorage.removeItem(CURRENT_APP_STEP_STORAGE_KEY);
        identifyUserApplication(submittedApplication);
        logEvent(invoiceEvents.SUBMITTED);
      })
      .catch((error) => setErrorMessage(error.response.data))
      .finally(() => {
        setIsLoadingApp(false);
      });
  };

  const onCloseConfirmationModal = () => {
    setDisplayCompletedModal(false);
    dispatch(loadClientThunk());
  };

  useEffect(() => {
    if (client) {
      const company = client.companies[0];
      getApplications(company.id)
        .then((applications) => {
          if (applications.length > 0) {
            setApplication(applications[0]);
          }
        })
        .finally(() => {
          setIsLoadingApp(false);
        });
    }
  }, [client]);

  if (isLoading) {
    return <SimpleBackdrop isLoading hasOpaqueBackground />;
  }

  // We want to show the IntroAppScreen for two cases. 1) If he/she didn't applied yet or 2) If he/she is reapplying for a *second* time.
  // After the second time, isReapply will be marked as true and they won't be able to reapply.
  if (!application || (application.status === 'application_rejected' && !application.isReapply))
    return <IntroAppScreen setApplication={setApplication} application={application} />;
  if (application.status !== APPLICATION_STARTED) navigate(INVOICE_FINANCING);

  return (
    <>
      <ApplicationStepper
        // @ts-ignore WHY
        storedCurrentStep={storedCurrentStep}
        application={application}
        setDisplayCompletedModal={() => {}}
        onApplicationSubmit={onApplicationSubmit}
        errorMessage={errorMessage}
        isLoading={isLoading}
        isCustomerListEnabled={isCustomerListEnabled}
      />
      <ApplicationCES
        handleClose={() => {
          onCloseConfirmationModal();
          navigate(INVOICE_FINANCING);
        }}
        isOpen={displayCompletedModal}
        applicationId={application.id}
        companyId={newCompany!.id}
      />
    </>
  );
};

export default Application;
