import { FormikContext } from 'formik';
import { useContext } from 'react';
import { PatternFormat } from 'react-number-format';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { CommonProps } from './types';
import { logFieldChange } from '../../utils/tracking';
import { FormTrackingContext } from '../Form';
import Typography from '../Typography';
import classes from './PhoneNumberField.module.scss';

type PhoneNumberProps = CommonProps & {
  format: string;
  value: number;
};

const PhoneNumberField = ({
  format,
  name,
  error,
  fullWidth,
  placeholder,
  label,
  value,
  onChange,
  hideLabel = false,
  eventLogger = logFieldChange,
}: PhoneNumberProps) => {
  const formik = useContext(FormikContext);
  const { trackingEvent } = useContext(FormTrackingContext);
  const _error = error || (formik?.touched[name] && formik?.errors[name]);

  return (
    <FormControl fullWidth={fullWidth} className={classes.phoneWrapper} error={!!_error}>
      <FormLabel
        sx={{
          padding: '8px 0',
          fontWeight: 600,
          display: 'flex',
          alignItems: 'center',
        }}>
        <Typography sx={{ visibility: `${hideLabel ? 'hidden' : 'initial'}` }}>{label}</Typography>
      </FormLabel>
      <PatternFormat
        name="phoneNumber"
        color="9CABC2"
        format={format}
        style={{
          width: '100%',
          borderRadius: '4px',
          border: `1px solid ${_error ? '#d32f2f' : '#c9c9c9'}`,
          padding: '12px',
          fontSize: '16px',
          color: '#252525',
        }}
        value={value}
        placeholder={placeholder}
        onValueChange={onChange}
        onBlur={(e: any) => {
          const value = e.target.value;
          trackingEvent && e.target.hasChanged && eventLogger(trackingEvent, name, value);
          e.target.hasChanged = false;
        }}
      />
      {!!_error && (
        <FormHelperText>
          <Typography sx={{ color: 'inherit' }}>{_error}</Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default PhoneNumberField;
