export default function LargeBankingIcon() {
  return (
    <svg width="300" height="301" viewBox="0 0 300 301" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M154.625 27.0806L267.125 72.0803C271.5 73.8303 275 79.0802 275 83.7052V125.205C275 132.08 269.375 137.705 262.5 137.705H37.5C30.625 137.705 25 132.08 25 125.205V83.7052C25 79.0802 28.5001 73.8303 32.8751 72.0803L145.375 27.0806C147.875 26.0806 152.125 26.0806 154.625 27.0806Z"
        fill="#3B4567"
      />
      <path
        opacity="0.4"
        d="M275 275.205H25V237.705C25 230.83 30.625 225.205 37.5 225.205H262.5C269.375 225.205 275 230.83 275 237.705V275.205Z"
        fill="#3B4567"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.2532 137.705V225.205H47.0032V137.705H78.2532ZM136.505 137.705V225.205H105.255V137.705H136.505ZM194.745 137.705V225.205H163.495V137.705H194.745ZM252.997 137.705V225.205H221.747V137.705H252.997Z"
        fill="#3B4567"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.125 275.205C3.125 270.027 7.32233 265.83 12.5 265.83H287.5C292.678 265.83 296.875 270.027 296.875 275.205C296.875 280.383 292.678 284.58 287.5 284.58H12.5C7.32233 284.58 3.125 280.383 3.125 275.205Z"
        fill="#3B4567"
      />
      <path
        d="M150 106.455C160.355 106.455 168.75 98.0604 168.75 87.7051C168.75 77.3497 160.355 68.9551 150 68.9551C139.645 68.9551 131.25 77.3497 131.25 87.7051C131.25 98.0604 139.645 106.455 150 106.455Z"
        fill="#FDFDFD"
      />
    </svg>
  );
}
