import { Button as MUIButton, ButtonProps, CircularProgress, SxProps } from '@mui/material';
import { COLORS } from '../../themev2';
import Typography from '../Typography';

type ButtonType = 'fill' | 'outline' | 'link';
export type ButtonColor = 'primary' | 'secondary' | 'tertiary' | 'danger' | 'white';
type ButtonColorsPalette = {
  _default: string;
  hover: string;
  active: string;
};

type IButtonProps = Omit<ButtonProps, 'type' | 'color'> & {
  type?: ButtonType;
  color?: ButtonColor;
  isSubmit?: boolean;
  isLoading?: boolean;
  isFullHeight?: boolean;
  endIcon?: string | React.ReactNode;
};

const bgColorsMap: Record<ButtonColor, ButtonColorsPalette> = {
  primary: { _default: COLORS.green[600], hover: '#14B2A9', active: COLORS.green[700] },
  secondary: { _default: COLORS.indigo[600], hover: COLORS.indigo[400], active: COLORS.indigo[700] },
  tertiary: { _default: COLORS.gray[1300], hover: '#4F5A84', active: COLORS.gray[1400] },
  danger: {
    _default: COLORS.feedback.danger,
    hover: COLORS.feedback.lightDanger,
    active: COLORS.feedback.danger,
  },
  white: {
    _default: COLORS.brand.white,
    hover: COLORS.brand.white,
    active: COLORS.brand.white,
  },
};

const endIconMap = {
  arrowRight: (
    <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.0999 1.69995L5.8999 6.49995L1.0999 11.3"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  arrowDown: (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.7998 10.389L11.9998 15.189L7.19981 10.389"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  arrowUp: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.2002 14.4001L12.0002 9.6001L16.8002 14.4001"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

function getTextColor(type: ButtonType, color: ButtonColor): string | null {
  if (type === 'fill') {
    if (color === 'white') return COLORS.brand.night;
    return COLORS.brand.paper;
  }
  if (color === 'white') return COLORS.brand.night;
  return null;
}

const variantsMap: Record<ButtonType, ButtonProps['variant']> = {
  fill: 'contained',
  outline: 'outlined',
  link: 'text',
};

function getSxPropsForType(type: ButtonType, color: ButtonColor) {
  const { _default, hover, active } = bgColorsMap[color];
  const textColor = getTextColor(type, color) || _default;

  switch (type) {
    case 'fill':
      return {
        backgroundColor: _default,
        color: textColor,
        '&:hover': {
          backgroundColor: hover,
        },
        '&:active': {
          backgroundColor: active,
        },
        '&:disabled': {
          backgroundColor: '#EBF2FC',
          color: '#91A2BB',
        },
      };
    case 'outline':
      return {
        border: `1px solid ${textColor}`,
        color: textColor,
        '&:hover': {
          backgroundColor: 'initial',
          borderColor: hover,
          color: hover,
        },
        '&:active': {
          backgroundColor: 'initial',
          borderColor: active,
          color: active,
        },
        '&:disabled': {
          backgroundColor: '#EBF2FC',
          color: '#91A2BB',
        },
      };
    case 'link':
      return {
        color: textColor,
        '&:hover': {
          backgroundColor: 'initial',
          color: hover,
        },
        '&:active': {
          backgroundColor: 'initial',
          color: active,
        },
        textDecoration: 'underline',
        p: '8px 0px',
      };
  }
}

function getSxPropsForSize(size: ButtonProps['size']) {
  switch (size) {
    case 'small':
      return {
        padding: '4px 12px',
        fontSize: 14,
      };
    case 'medium':
      return {
        padding: '12px 24px',
        fontSize: 16,
      };
    case 'large':
      return {
        padding: '16px 32px',
        fontSize: 18,
      };
  }
}

export default function Button({
  type = 'fill',
  color = 'primary',
  disabled = false,
  size = 'medium',
  children,
  isSubmit = false,
  fullWidth = false,
  isLoading = false,
  endIcon = null,
  isFullHeight = true,
  ...rest
}: IButtonProps) {
  const sx: SxProps = {
    textTransform: 'none',
    borderRadius: '10px',
    fontSize: '16px',
    padding: '12px 24px',
    height: isFullHeight ? '100%' : 'auto',
    ...getSxPropsForSize(size),
    ...getSxPropsForType(type, color),
  };
  const _endIcon = typeof endIcon === 'string' ? endIconMap[endIcon as keyof typeof endIconMap] : endIcon;

  return (
    <MUIButton
      variant={variantsMap[type]}
      disabled={disabled || isLoading}
      sx={{ ...sx, position: 'relative' }}
      disableElevation
      fullWidth={fullWidth}
      {...rest}
      endIcon={!isLoading && _endIcon}
      type={isSubmit ? 'submit' : 'button'}>
      <Typography sx={{ color: 'inherit', fontWeight: 600, visibility: isLoading ? 'hidden' : 'initial' }}>
        {children}
      </Typography>
      <CircularProgress
        size={25}
        sx={{ color: 'inherit', position: 'absolute', visibility: isLoading ? 'initial' : 'hidden' }}
      />
    </MUIButton>
  );
}
