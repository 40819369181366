import { Trans } from 'react-i18next';
import { camelize } from 'humps';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '../../componentsv2/Typography';
import ModalV2 from '../../components/ModalV2';
import { ProgressBar } from '../Dashboard/ClientDashboard';
import { useTranslation } from '../../hooks/i18n';
import { CreditCommittee } from '../../types/application';
import { COLORS } from '../../themev2';
import { formatCreditLineCurrency } from '../../utils/helpers';
import { useUserLocation } from '../../hooks/useUserLocation';
import { formatNumericValue } from '../../utils/common';

const InfoBox: React.FC = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #E4EEFC',
        padding: '1.5em',
        borderRadius: '12px',
        boxShadow: '0px 2px 10px 0px #9CABC21A',
      }}>
      {children}
    </Box>
  );
};

type CreditLineModalProps = {
  handleClose: () => void;
  isOpen: boolean;
  creditCommittee: CreditCommittee;
};

export default function CreditLineModal({ handleClose, isOpen, creditCommittee }: CreditLineModalProps) {
  const { t } = useTranslation();
  const { language } = useUserLocation();

  const { creditLimit, debtors, discountFee, serviceFee, advanceRate, discountFeeStructure } =
    creditCommittee || {};

  const amountAsCurrency = creditLimit ? `${formatCreditLineCurrency(language, creditLimit, 'USD', 2)}` : '';

  const advanceRateToShow = typeof advanceRate === 'number' ? `${advanceRate}%` : 'N/A';
  const discountFeeToShow =
    typeof discountFee === 'number' ? `${formatNumericValue(discountFee, undefined, language)}%` : 'N/A';
  const serviceFeeToShow = typeof serviceFee === 'number' ? `${serviceFee}%` : 'N/A';

  return (
    <ModalV2 handleClose={handleClose} isOpen={isOpen} size="xl">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={700}>
            {t('application:creditLineModal.title')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ color: COLORS.gray[1200] }}>
            {t('application:creditLineModal.subtitle')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              border: '1px solid #E4EEFC',
              padding: '2em',
              borderRadius: '12px',
              boxShadow: '0px 2px 10px 0px #9CABC21A',
            }}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <Typography sx={{ color: COLORS.gray[1100] }}>
                  {t('application:creditLineModal.availableCredit')}
                </Typography>
                <Typography
                  sx={{ color: COLORS.brand.night, lineHeight: '27px', fontSize: '18px', fontWeight: 600 }}>
                  {`${amountAsCurrency}*`}
                </Typography>
                <Typography sx={{ color: '#66758F', lineHeight: '15px', fontSize: '10px', fontWeight: 400 }}>
                  {t('application:creditLineModal.creditDisclaimer')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ProgressBar progress={100} />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        color: COLORS.gray[1100],
                        fontWeight: 500,
                      }}>
                      {t('application:creditLineModal.utilized')}
                    </Typography>
                    <Typography
                      sx={{
                        color: COLORS.indigo[500],
                        fontWeight: 600,
                      }}>{`${formatCreditLineCurrency(language, 0, 'USD', 2)}`}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      textAlign="right"
                      sx={{
                        color: COLORS.gray[1100],
                        fontWeight: 500,
                      }}>
                      {t('application:creditLineModal.limit')}
                    </Typography>
                    <Typography
                      textAlign="right"
                      sx={{
                        color: COLORS.indigo[500],
                        fontWeight: 600,
                      }}>
                      {amountAsCurrency}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <InfoBox>
                <Typography sx={{ color: COLORS.gray[1100], fontWeight: 600 }}>
                  {t('application:creditLineModal.advanceRate')}
                </Typography>
                <Typography
                  sx={{
                    color: COLORS.indigo[500],
                    fontWeight: 600,
                  }}>
                  {advanceRateToShow}
                </Typography>
              </InfoBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <InfoBox>
                <Typography sx={{ color: COLORS.gray[1100], fontWeight: 600 }}>
                  {t('application:creditLineModal.discountFee')}
                </Typography>
                <Typography
                  sx={{
                    color: COLORS.indigo[500],
                    fontWeight: 600,
                  }}>
                  {discountFeeToShow}
                </Typography>
              </InfoBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <InfoBox>
                <Typography sx={{ color: COLORS.gray[1100], fontWeight: 600 }}>
                  {t('application:creditLineModal.serviceFee')}
                </Typography>
                <Typography
                  sx={{
                    color: COLORS.indigo[500],
                    fontWeight: 600,
                  }}>
                  {serviceFeeToShow}
                </Typography>
              </InfoBox>
            </Grid>
          </Grid>
        </Grid>
        {!!discountFeeStructure && (
          <Grid item xs={12}>
            <Typography sx={{ color: COLORS.gray[1100], fontSize: '12px' }}>
              {t(`application:creditLineModal.${camelize(discountFeeStructure)}Disclosure`)}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '16px', color: COLORS.gray[1200], fontWeight: 600 }}>
                {t('application:creditLineModal.approvedClients')}
              </Typography>
            </Grid>
            {debtors.length > 0 &&
              debtors.map(({ id, name, individualCreditLimit }) => (
                <Grid item xs={12} key={id}>
                  <Typography sx={{ fontSize: '16px', lineHeight: '24px', color: COLORS.gray[1100] }}>
                    <Trans t={t} i18nKey={'application:creditLineModal.client'}>
                      <span style={{ color: COLORS.gray[1200], fontWeight: 600 }}>{{ client: name }}</span>
                      <span style={{ color: '#1A97C0', fontWeight: 600 }}>
                        {{
                          amount: `${formatCreditLineCurrency(language, individualCreditLimit, 'USD', 0)}`,
                        }}
                      </span>
                    </Trans>
                  </Typography>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              border: '1px solid #E4EEFC',
              padding: '2em',
              borderRadius: '12px',
              boxShadow: '0px 2px 10px 0px #9CABC21A',
            }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '16px', color: COLORS.gray[1200], fontWeight: 600 }}>
                  {t('application:creditLineModal.additionalInfo')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ color: COLORS.gray[1100] }}>
                  {t('application:creditLineModal.additionalInfoDescription')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ModalV2>
  );
}
