import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { logEvent } from '@amplitude/analytics-browser';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Layout from '../../componentsv2/Layout';
import Typography from '../../componentsv2/Typography';
import Section from '../../componentsv2/Section';
import { Tabs, Tab } from '../../componentsv2/Tabs';
import Button from '../../componentsv2/Button';
import SimpleBackdrop from '../../components/Backdrop';
import ModalV2 from '../../components/ModalV2';
import { TextField } from '../../componentsv2/Field';
import Form from '../../componentsv2/Form';
import ErrorMessage from '../../components/ErrorMessage';
import { changePassword } from '../../services/clients';
import { getLLC } from '../../services/llc';
import { updateClient, updateCompany, useAppDispatch, useAppSelector } from '../../store';
import { useSnackbar } from '../../context';
import { useTranslation } from '../../hooks/i18n';
import { myAccountEvents } from '../../constants/events';
import { Nullable } from '../../types';
import { APILLC } from '../../types/llc';
import { COLORS } from '../../themev2';
import ClientDocuments from '../ClientDocuments';
import ClientBankAccounts from '../ClientBankAccounts';
import NotificationsSettings from '../NotificationsSettings';
import UserInvites from '../UserInvites';
import CompanyTab from './CompanyTab';

enum TabValues {
  Account = 'account',
  Company = 'company',
  Users = 'users',
  Notifications = 'notifications',
  Documents = 'documents',
  BankAccounts = 'bankAccounts',
}

const MyAccount = () => {
  const { t } = useTranslation(['myAccount', 'user', 'client']);
  const {
    client: { client, status, newCompanyUser, newCompany },
  } = useAppSelector((state) => state);
  const companyId = client?.companies[0].id;
  const dispatch = useAppDispatch();
  const [showPersonalInfoModal, setShowPersonalInfoModal] = useState(false);
  const [showCompanyInfoModal, setShowCompanyInfoModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);
  const [passwordError, setPasswordError] = useState<Nullable<any>>(null);
  const [currentTab, setCurrentTab] = useState(TabValues.Account);
  const [isLoading, setIsLoading] = useState(false);
  const [llc, setLlc] = useState<Nullable<APILLC>>(null);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (!companyId) return;
    setIsLoading(true);
    getLLC(companyId)
      .then((result) => {
        if (result.length > 0) {
          setLlc(result[0]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [companyId]);

  useEffect(() => {
    logEvent(myAccountEvents.VIEW_PAGE);
  }, []);

  if (!client) {
    return <SimpleBackdrop isLoading hasOpaqueBackground />;
  }

  const company = client.companies[0];
  const isCompanyAdministrator =
    newCompanyUser?.companyUserRoles[0].company.id === newCompany?.id &&
    (newCompanyUser?.companyUserRoles[0].role === 'administrator' ||
      newCompanyUser?.companyUserRoles[0].role === 'owner');

  return (
    <Layout>
      <Typography variant="h5" sx={{ marginBottom: '1em' }}>
        {t('title')}
      </Typography>

      <Grid container>
        <Grid item xs={12}>
          <Tabs
            value={currentTab}
            onChange={(e, newValue) => {
              setCurrentTab(newValue);
            }}>
            <Tab label={t('accountTab')} value={TabValues.Account} />
            <Tab label={t('companyTab')} value={TabValues.Company} />
            <Tab label={t('usersTab')} value={TabValues.Users} />
            <Tab label={t('notificationsTab')} value={TabValues.Notifications} />
            <Tab label={t('documentsTab')} value={TabValues.Documents} />
            <Tab label={t('bankAccountsTab')} value={TabValues.BankAccounts} />
          </Tabs>
        </Grid>
        {currentTab === TabValues.Account && (
          <>
            <Grid item xs={12}>
              <Section>
                <Grid container alignItems="center">
                  <Grid item xs={8} md={11}>
                    <Typography fontWeight="600" sx={{ fontSize: '18px' }}>
                      {t('personalInfo.title')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={1}>
                    <Button
                      color="secondary"
                      size="small"
                      onClick={() => {
                        setShowPersonalInfoModal(true);
                      }}>
                      {t('edit')}
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography sx={{ color: COLORS.gray[1100] }}>{t('personalInfo.fullName')}</Typography>
                    <Typography fontWeight="600">{client.name} </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography sx={{ color: COLORS.gray[1100] }}>{t('personalInfo.email')}</Typography>
                    <Typography fontWeight="600" sx={{ wordBreak: 'break-word' }}>
                      {client.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography sx={{ color: COLORS.gray[1100] }}>{t('personalInfo.phoneNumber')}</Typography>
                    <Typography fontWeight="600">{client.contactPhone} </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ color: COLORS.gray[1100] }}>{t('personalInfo.password')}</Typography>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPasswordModal(true);
                      }}>
                      {t('personalInfo.changePassword')}
                    </Link>
                  </Grid>
                </Grid>
              </Section>
            </Grid>
            <ModalV2
              isOpen={showPersonalInfoModal}
              handleClose={() => {
                setShowPersonalInfoModal(false);
              }}
              isAutoHeight>
              <Typography
                variant="h5"
                sx={{
                  background: COLORS.secondary.greenGradient,
                  backgroundClip: 'text',
                  '-webkit-background-clip': 'text',
                  color: 'transparent',
                }}>
                {t('personalInfo.title')}
              </Typography>
              <Divider sx={{ marginY: '1em' }} />
              <Formik
                initialValues={{
                  name: client.name || '',
                  email: client.email || '',
                  contactPhone: client.contactPhone || '',
                }}
                onSubmit={(values) => {
                  dispatch(updateClient(values)).then(() => {
                    logEvent(myAccountEvents.EDIT_PERSONAL_INFO, values);
                    showSnackbar(t('personalInfo.success'), 'success');
                    setShowPersonalInfoModal(false);
                  });
                }}>
                {({ dirty }) => (
                  <Form>
                    <TextField name="name" label={t('personalInfo.fullName')} />
                    <TextField disabled name="email" label={t('personalInfo.email')} />
                    <TextField name="contactPhone" label={t('personalInfo.phoneNumber')} />
                    <Box sx={{ marginY: '2em' }}>
                      <Button
                        color="secondary"
                        fullWidth
                        isLoading={status === 'loading'}
                        isSubmit
                        disabled={!dirty}>
                        {t('saveChanges')}
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </ModalV2>
            <ModalV2
              isOpen={showCompanyInfoModal}
              handleClose={() => {
                setShowCompanyInfoModal(false);
              }}
              isAutoHeight>
              <Typography
                variant="h5"
                sx={{
                  background: COLORS.secondary.greenGradient,
                  backgroundClip: 'text',
                  '-webkit-background-clip': 'text',
                  color: 'transparent',
                }}>
                {t('companyInfo.title')}
              </Typography>
              <Divider sx={{ marginY: '1em' }} />
              <Formik
                initialValues={{
                  fullName: company.name || '',
                  taxId: company.taxId || '',
                  addressStreetOne: company.addressStreetOne || '',
                  addressZipCode: company.addressZipCode || '',
                  addressCity: company.addressCity || '',
                  addressState: company.addressState || '',
                }}
                onSubmit={(values) => {
                  dispatch(
                    updateCompany({
                      companyId: company.id,
                      payload: values,
                    })
                  ).then(() => {
                    logEvent(myAccountEvents.EDIT_COMPANY_INFO, values);
                    showSnackbar(t('companyInfo.success'), 'success');
                    setShowCompanyInfoModal(false);
                  });
                }}>
                {({ dirty }) => (
                  <Form>
                    <TextField disabled name="fullName" label={t('companyInfo.fullName')} />
                    <Box sx={{ display: 'flex', gap: '1em' }}>
                      <TextField name="addressStreetOne" label={t('companyInfo.addressStreetOne')} />
                      <TextField name="addressCity" label={t('companyInfo.addressCity')} />
                    </Box>
                    <Box sx={{ display: 'flex', gap: '1em' }}>
                      <TextField name="addressState" label={t('companyInfo.addressState')} />
                      <TextField name="addressZipCode" label={t('companyInfo.addressZipCode')} />
                    </Box>
                    <TextField disabled name="taxId" label={t('companyInfo.taxId')} />
                    <Box sx={{ marginY: '2em' }}>
                      <Button
                        color="secondary"
                        fullWidth
                        isLoading={status === 'loading'}
                        isSubmit
                        disabled={!dirty}>
                        {t('saveChanges')}
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </ModalV2>
            <ModalV2
              isAutoHeight
              isOpen={showPasswordModal}
              handleClose={() => {
                setPasswordError(null);
                setShowPasswordModal(false);
              }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    sx={{
                      background: COLORS.secondary.greenGradient,
                      backgroundClip: 'text',
                      '-webkit-background-clip': 'text',
                      color: 'transparent',
                    }}>
                    {t('changePassword.title')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Formik
                    initialValues={{ oldPassword: '', newPassword: '' }}
                    validationSchema={Yup.object().shape({
                      oldPassword: Yup.string().required(t('common:requiredField')),
                      newPassword: Yup.string().required(t('common:requiredField')),
                    })}
                    onSubmit={(values) => {
                      setIsLoadingPassword(true);
                      setPasswordError(null);
                      changePassword(values)
                        .then(() => {
                          logEvent(myAccountEvents.CHANGE_PASSWORD);
                          showSnackbar(t('changePassword.passwordSuccess'), 'success');
                          setShowPasswordModal(false);
                        })
                        .catch((error) => {
                          setPasswordError(error);
                        })
                        .finally(() => {
                          setIsLoadingPassword(false);
                        });
                    }}>
                    <Form>
                      <TextField type="password" name="oldPassword" label={t('changePassword.oldPassword')} />
                      <TextField type="password" name="newPassword" label={t('changePassword.newPassword')} />
                      {passwordError && <ErrorMessage errorMessage={t('changePassword.passwordError')} />}
                      <Box sx={{ marginY: '2em' }}>
                        <Button color="secondary" fullWidth isLoading={isLoadingPassword} isSubmit>
                          {t('saveChanges')}
                        </Button>
                      </Box>
                    </Form>
                  </Formik>
                </Grid>
              </Grid>
            </ModalV2>
          </>
        )}
        {currentTab === TabValues.Company && (
          <Grid item xs={12}>
            <CompanyTab company={company} t={t} />
          </Grid>
        )}
        {currentTab === TabValues.Users && (
          <Grid item xs={12}>
            <UserInvites
              company={newCompany!}
              currentCompanyUser={newCompanyUser!}
              isCompanyAdministrator={isCompanyAdministrator}
            />
          </Grid>
        )}
        {currentTab === TabValues.Notifications && (
          <Grid item xs={12}>
            <NotificationsSettings />
          </Grid>
        )}
        {currentTab === TabValues.Documents && (
          <ClientDocuments showTopBar={false} showStakeholders={false} />
        )}
        {currentTab === TabValues.BankAccounts && (
          <Grid item xs={12}>
            <ClientBankAccounts companyId={company.id} />
          </Grid>
        )}
      </Grid>
    </Layout>
  );
};

export default MyAccount;
