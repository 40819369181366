import { ReactNode, useCallback, useContext, useState } from 'react';
import { FormikContext } from 'formik';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete, { AutocompleteProps, AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import MUITextfield from '@mui/material/TextField';
import Typography from '../Typography';
import { FormTrackingContext } from '../Form';
import { useTranslation } from '../../hooks/i18n';
import { CommonProps } from './types';
import { logFieldChange } from '../../utils/tracking';
import { COLORS } from '../../themev2';
import InfoButton from '../InfoButton';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from '@mui/material/utils/debounce';

type Option = { value: any; label: string };

type AutocompleteFieldProps = CommonProps & {
  value?: string;
  options: Option[];
  renderOption?: (option: Option) => ReactNode;
  inputStartAdornment?: ReactNode;
  showShortLabel?: boolean;
};

export default function AutocompleteField({
  fullWidth = true,
  error,
  name,
  label,
  onChange,
  value,
  options,
  placeholder,
  helperText,
  disabled = false,
  eventLogger = logFieldChange,
  optional = false,
  renderOption,
  inputStartAdornment,
  moreInfoTooltip = null,
  showShortLabel = false,
}: AutocompleteFieldProps) {
  const { t } = useTranslation();
  const formik = useContext(FormikContext);
  const _error = error || (formik?.touched[name] && formik?.errors[name]);
  const { trackingEvent } = useContext(FormTrackingContext);
  const _value = (formik && formik.values[name]) || value;

  return (
    <FormControl fullWidth={fullWidth} error={!!_error}>
      <FormLabel
        sx={{
          padding: '8px 0',
          fontWeight: 600,
          display: 'flex',
          alignItems: 'center',
        }}>
        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <Typography>{label}</Typography>
          {!!moreInfoTooltip && <InfoButton title={moreInfoTooltip} />}
        </div>
        {optional && <Typography sx={{ color: COLORS.gray[1000] }}>{t('optional')}</Typography>}
      </FormLabel>
      <Autocomplete
        autoHighlight
        disabled={disabled}
        disableClearable
        onChange={(_, { value }) => {
          formik && formik.setFieldValue(name, value);
          onChange && onChange(value);
          trackingEvent && eventLogger(trackingEvent, name, value);
        }}
        value={
          _value
            ? {
                value: _value,
                label: (options.find((option) => option.value.toString() === _value.toString()) as Option)
                  .label,
              }
            : undefined
        }
        renderOption={(props, { value, label }) => {
          return (
            // TODO: style options
            <MenuItem value={value} key={value} {...props}>
              {renderOption ? renderOption({ value, label }) : <div>{label}</div>}
            </MenuItem>
          );
        }}
        options={options}
        renderInput={(params) => (
          <MUITextfield
            {...params}
            InputProps={{
              ...params.InputProps,
              sx: {
                // TODO: color: COLORS.gray[1000] and font family for placeholder
                borderRadius: '4px',
                borderColor: COLORS.gray[1000],
                '& .MuiInputBase-input': showShortLabel // show shorter label for small autocomplete fields with ellipsis
                  ? {
                      maxWidth: '60%',
                    }
                  : undefined,
              },
              style: {
                padding: '5px',
              },
              startAdornment: inputStartAdornment || null,
            }}
            placeholder={placeholder}
          />
        )}
        sx={{
          background: disabled ? COLORS.gray[300] : COLORS.brand.white,
          borderColor: COLORS.gray[1000],
        }}
      />
      <FormHelperText>
        <Typography sx={{ color: 'inherit' }}>{_error || helperText}</Typography>
      </FormHelperText>
    </FormControl>
  );
}

interface OptionType {
  value: any;
  label: string;
}

interface SearchAutocompleteProps
  extends Omit<AutocompleteProps<OptionType, boolean, boolean, boolean>, 'renderInput' | 'options'> {
  fetchOptions: (searchText: string) => Promise<OptionType[]>;
  debounceInterval?: number;
  label?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  optional?: boolean;
  moreInfoTooltip?: string;
  inputStartAdornment?: React.ReactNode;
  renderOption?: (props: any, option: OptionType) => React.ReactNode;
  name: string;
  onChange?: (value: any) => void;
}

export const SearchAutocomplete: React.FC<SearchAutocompleteProps> = ({
  name,
  fetchOptions,
  debounceInterval = 300,
  label,
  placeholder,
  error,
  helperText,
  optional,
  moreInfoTooltip,
  inputStartAdornment,
  renderOption,
  onChange,
  ...autocompleteProps
}) => {
  const formik = useContext(FormikContext);
  const fieldValue = formik?.values[name];
  const fieldError = formik?.touched[name] && formik?.errors[name];
  const [options, setOptions] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState(false);

  const debouncedFetchOptions = useCallback(
    debounce(async (searchText: string) => {
      if (searchText === '') return;
      setLoading(true);
      try {
        const fetchedOptions = await fetchOptions(searchText);
        setOptions(fetchedOptions);
      } catch (error) {
        console.error('Error fetching options:', error);
      } finally {
        setLoading(false);
      }
    }, debounceInterval),
    [fetchOptions, debounceInterval]
  );

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string, reason: any) => {
    if (reason === 'reset') return;
    debouncedFetchOptions(value);
  };

  const handleChange = (_: React.SyntheticEvent<Element, Event>, value: any) => {
    formik?.setFieldValue(name, value?.value);
    onChange && onChange(value?.value);
  };

  return (
    <FormControl error={!!fieldError} fullWidth>
      {label && (
        <FormLabel
          sx={{
            padding: '8px 0',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
          }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography>{label}</Typography>
            {moreInfoTooltip && <InfoButton title={moreInfoTooltip} />}
          </div>
          {optional && <Typography sx={{ color: COLORS.gray[1000] }}>{'Optional'}</Typography>}
        </FormLabel>
      )}
      <Autocomplete
        {...autocompleteProps}
        options={options}
        onInputChange={handleInputChange}
        loading={loading}
        onChange={handleChange}
        renderOption={
          renderOption ||
          ((props, option) => (
            <MenuItem {...props} key={option.value}>
              {option.label}
            </MenuItem>
          ))
        }
        value={options.find((option) => option.value === fieldValue) || null}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <MUITextfield
            {...params}
            placeholder={placeholder}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              style: {
                padding: '5px',
              },
              startAdornment: inputStartAdornment,
              endAdornment: <>{loading ? <CircularProgress color="inherit" size={20} /> : null}</>,
            }}
            sx={{ borderColor: COLORS.gray[1000] }}
          />
        )}
      />
      {fieldError && (
        <FormHelperText>
          <Typography>{fieldError}</Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
};
