export default function LargeInsurancesIcon() {
  return (
    <svg width="280" height="280" viewBox="0 0 300 301" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M261.375 139.881C261.375 201.006 217 258.256 156.375 275.006C152.25 276.131 147.75 276.131 143.625 275.006C82.9999 258.256 38.625 201.006 38.625 139.881V85.0063C38.625 74.7563 46.3752 63.1313 56.0002 59.2563L125.625 30.7563C141.25 24.3813 158.875 24.3813 174.5 30.7563L244.125 59.2563C253.625 63.1313 261.5 74.7563 261.5 85.0063L261.375 139.881Z"
        fill="url(#paint0_linear_1597_34366)"
      />
      <path
        d="M181.25 132.131C181.25 114.881 167.25 100.881 150 100.881C132.75 100.881 118.75 114.881 118.75 132.131C118.75 146.131 128 157.756 140.625 161.756V194.631C140.625 199.756 144.875 204.006 150 204.006C155.125 204.006 159.375 199.756 159.375 194.631V161.756C172 157.756 181.25 146.131 181.25 132.131Z"
        fill="url(#paint1_linear_1597_34366)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1597_34366"
          x1="242.374"
          y1="134.908"
          x2="79.8982"
          y2="57.517"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0E3354" />
          <stop offset="1" stopColor="#134178" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1597_34366"
          x1="175.886"
          y1="145.839"
          x2="125.287"
          y2="129.462"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0E3354" />
          <stop offset="1" stopColor="#134178" />
        </linearGradient>
      </defs>
    </svg>
  );
}
