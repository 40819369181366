import { COLORS } from '../../themev2';

export default function CrossIcon({ size = 'md' }: { size?: string }) {
  const width = size === 'sm' ? '14' : '24';
  const height = size === 'sm' ? '14' : '24';
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3613 6L6.36133 18M18.3613 18L6.36133 6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
