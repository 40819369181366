import { APIFXRequestPayload, APIFXExchangeRateResponse, APICurrencyOptions } from '../types/fx';
import axios from './axios';

export function submitFXRequest(companyId: number, payload: APIFXRequestPayload): Promise<any> {
  return axios.post(`companies/${companyId}/products/fx/request/`, payload).then(({ data }) => data);
}

export function getFXRates(
  companyId: number,
  {
    fromCurrency,
    toCurrency,
  }: {
    fromCurrency: APICurrencyOptions;
    toCurrency: APICurrencyOptions;
  }
): Promise<APIFXExchangeRateResponse> {
  return axios
    .get(
      `/companies/${companyId}/products/fx/exchange-rate/?from_currency=${fromCurrency}&to_currency=${toCurrency}`
    )
    .then(({ data }) => data);
}
