import { useEffect } from 'react';
import { logEvent } from '@amplitude/analytics-browser';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '../../componentsv2/Chip';
import FXForm from './FxForm';
import Typography from '../../componentsv2/Typography';
import Layout from '../../componentsv2/Layout';
import PageHeader from '../../componentsv2/PageHeader';
import { TickCircleIcon } from '../../components/Icons';
import { useTranslation } from '../../hooks/i18n';
import { COLORS } from '../../themev2';
import { FXEvents } from '../../constants/events';
import classes from './FX.module.scss';

const steps = [
  {
    key: 'quote',
    step: 1,
  },
  {
    key: 'confirm',
    step: 2,
  },
  {
    key: 'exchange',
    step: 3,
  },
];

const reasonsToApply = [
  { key: 0, title: 'userFriendly' },
  { key: 1, title: 'betterExchangeRate' },
  { key: 2, title: 'noHiddenCosts' },
];

const FX = () => {
  const { t } = useTranslation(['fx']);

  useEffect(() => {
    logEvent(FXEvents.VIEW_PAGE);
  }, []);

  return (
    <Layout>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <PageHeader title={t('title')} subtitle={t('subtitle')} />
        </Grid>
        <Grid item xs={12} lg={7}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Box className={classes.whyUseCurrencyExchange}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ color: COLORS.brand.white, fontWeight: '700' }}>
                      {t('infoBox.title')}
                    </Typography>
                  </Grid>
                  {reasonsToApply.map(({ key, title }) => (
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: '19px' }} key={key}>
                      <TickCircleIcon />
                      <Typography variant="body1" sx={{ color: COLORS.brand.paper }}>
                        {t(`infoBox.reasons.${title}`)}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.howDoesItWork}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6" fontWeight={700}>
                      {t('howDoesItWork.title')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      {steps.map(({ step, key }) => {
                        return (
                          <Grid item xs={12} key={step}>
                            <Grid container alignItems="center" rowSpacing={1}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: { xs: '18px' },
                                }}>
                                <Chip
                                  label={step}
                                  sx={{ backgroundColor: '#18A19A', color: COLORS.brand.white }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    color: COLORS.brand.night,
                                    fontWeight: 600,
                                    lineHeight: '24px',
                                  }}>
                                  {t(`howDoesItWork.${key}.heading`)}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sx={{ marginLeft: { xs: '50px' } }}>
                                <Typography
                                  sx={{
                                    color: COLORS.gray[1200],
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    lineHeight: '21px',
                                  }}>
                                  {t(`howDoesItWork.${key}.text`)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Box className={classes.formWrapper}>
            <Grid container>
              <Grid item xs={12}>
                <Typography textAlign="center" variant="h6" fontWeight="700">
                  {t('form.title')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FXForm />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default FX;
