export default function LargeBuyerCheckIcon() {
  return (
    <svg width="399" height="300" viewBox="0 0 432 335" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1597_31764)">
        <path
          d="M272.146 297.574C267.021 297.574 262.771 293.324 262.771 288.199V56.9487C262.771 51.8237 267.021 47.5737 272.146 47.5737C277.271 47.5737 281.521 51.8237 281.521 56.9487V288.199C281.521 293.324 277.271 297.574 272.146 297.574Z"
          fill="url(#paint0_linear_1597_31764)"
        />
        <path
          opacity="0.4"
          d="M139.646 272.574H239.62V72.5737H139.646C110.686 72.5737 87.229 94.9487 87.229 122.574V222.574C87.229 250.199 110.686 272.574 139.646 272.574ZM290.346 272.574H277.242V72.5737H290.346C319.307 72.5737 342.763 94.9487 342.763 122.574V222.574C342.763 250.199 319.307 272.574 290.346 272.574Z"
          fill="url(#paint1_linear_1597_31764)"
        />
        <path
          d="M146.983 205.919C144.878 205.919 142.773 205.144 141.111 203.482L124.494 186.864C122.949 185.301 122.082 183.191 122.082 180.993C122.082 178.794 122.949 176.685 124.494 175.121C127.707 171.908 133.024 171.908 136.237 175.121L146.983 185.867L190.8 141.638C194.013 138.425 199.33 138.425 202.543 141.638C205.756 144.851 205.756 150.168 202.543 153.381L152.855 203.482C151.193 205.144 149.088 205.919 146.983 205.919Z"
          fill="url(#paint2_linear_1597_31764)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1597_31764"
          x1="279.912"
          y1="156.561"
          x2="263.169"
          y2="155.891"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0E3354" />
          <stop offset="1" stopColor="#134178" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1597_31764"
          x1="320.834"
          y1="159.764"
          x2="164.886"
          y2="53.3589"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0E3354" />
          <stop offset="1" stopColor="#134178" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1597_31764"
          x1="197.841"
          y1="168.302"
          x2="146.382"
          y2="134.154"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0E3354" />
          <stop offset="1" stopColor="#134178" />
        </linearGradient>
        <clipPath id="clip0_1597_31764">
          <rect width="303" height="303" fill="white" transform="translate(64.5 16.3813)" />
        </clipPath>
      </defs>
    </svg>
  );
}
