import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '../../components/Container';
import classes from './NotFound.module.scss';

const NotFound = () => (
  <Container className={classes.NotFound} preset={{ withHeader: false }} maxWidth="sm">
    <Typography variant="h4" align="center">
      PAGE NOT FOUND
    </Typography>
  </Container>
);

export default NotFound;
