import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { logEvent } from '@amplitude/analytics-browser';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '../../componentsv2/Typography';
import TopBar from '../../componentsv2/TopBar';
import MarcoLogo from '../../componentsv2/MarcoLogo';
import { SelectField, TextField } from '../../componentsv2/Field';
import Button from '../../componentsv2/Button';
import {
  CheckCircleOutlineOutlined as CheckCircleOutlineIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material';
import Form from '../../componentsv2/Form';
import ErrorMessage from '../../components/ErrorMessage';
import { ButtonSizes } from '../../components/Button';
import { InvoicesTableIcon } from '../../components/Icons';
import { SignUpResponse } from '../../services/types';
import { useTranslation } from '../../hooks/i18n';
import { useUserLocation } from '../../hooks/useUserLocation';
import { useAnonymousUser } from '../../hooks/anonymous';
import { loadUser as loadUserThunk, transformCompanyUser, useAppDispatch, useAppSelector } from '../../store';
import { CountryKeys } from '../../types';
import { hasDigit, hasLowerCase, hasUpperCase, phoneCountryCodes } from '../../utils/helpers';
import { signupEvents } from '../../constants/events';
import { REFRESH_TOKEN_STORAGE_KEY, ACCESS_TOKEN_STORAGE_KEY, filesURL } from '../../constants/auth';
import { COLORS } from '../../themev2';
import PhoneNumberField from '../../componentsv2/Field/PhoneNumberField';
import { signupWithInvitation } from '../../services/auth';
import { currentUserDetail } from '../../services/clients';
import { identifyUser } from '../../utils/tracking';
import { useSnackbar } from '../../context';
import { changeLanguage } from 'i18next';

export type InvitationSignupValues = {
  phoneNumber: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumberCountry: string;
};

const InvitationSignup = () => {
  const { t } = useTranslation();
  const { language } = useUserLocation();
  const { location } = useAnonymousUser();
  const { error, status } = useAppSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('token');
  const companyName = queryParams.get('company_name');
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    logEvent(signupEvents.VIEW_INVITATION_SIGNUP, { Company: companyName });
  }, []);

  const submit = (values: any) => {
    if (!token) return;
    setIsLoading(true);
    signupWithInvitation({ ...values, token, contactPhone: values.phoneNumber })
      .then(({ refresh, access }) => {
        localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, access);
        localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refresh);
        setIsLoading(false);
        dispatch(loadUserThunk())
          .unwrap()
          .then(() => {
            currentUserDetail().then((companyUser) => {
              transformCompanyUser(companyUser).then(({ client: { country, companies, name, email } }) => {
                identifyUser({ country, companyName: companies[0].name, name, email, language }).promise.then(
                  () => {
                    logEvent(signupEvents.SUBMIT_INVITATION_SIGNUP, { Company: companyName });
                  }
                );
              });
            });
          });
      })
      .catch((error) => {
        showSnackbar(t(`signup.invitationErrors.${error.response.data.error_code}`), 'error');
        setIsLoading(false);
      });
  };

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <TopBar>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <IconButton href="/">
            <MarcoLogo />
          </IconButton>
        </Box>
      </TopBar>
      <Box component="main" sx={{ flexGrow: 1, paddingTop: '1em', display: 'flex' }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ display: { xs: 'none', lg: 'flex' }, alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '1em',
                textAlign: 'center',
              }}>
              <InvoicesTableIcon />
              <Box sx={{ position: 'relative', top: '-5em', maxWidth: '60%' }}>
                <Typography sx={{ fontSize: '24px', marginBottom: '0.5em' }}>
                  {t('signup.whyMarco')}
                </Typography>
                <Typography sx={{ color: COLORS.gray[1100], fontSize: '18px' }}>
                  {t('signup.description')}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              backgroundColor: { xs: 'inherit', md: '#FBFCFE' },
              paddingBottom: '1em',
              display: 'flex',
              alignItems: 'center',
            }}>
            <Grid container rowSpacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ fontWeight: 600, textAlign: 'center' }}>
                  {t('signup.title')}
                </Typography>
              </Grid>
              <Grid item xs={10} md={8}>
                <Formik
                  validateOnChange
                  initialValues={{
                    phoneNumber: '',
                    email: queryParams.get('user_email')?.replace(' ', '+'),
                    name: queryParams.get('user_name')?.replace('+', ' '),
                    password: '',
                    confirmPassword: '',
                    phoneNumberCountry: location,
                  }}
                  validationSchema={Yup.object().shape({
                    phoneNumber: Yup.string().required(t('common:requiredField')),
                    email: Yup.string().required(t('common:requiredField')),
                    name: Yup.string().required(t('common:requiredField')),
                    password: Yup.string().required(t('common:requiredField')),
                    confirmPassword: Yup.string().required(t('common:requiredField')),
                    phoneNumberCountry: Yup.string().required(t('common:requiredField')),
                  })}
                  validate={(values) => {
                    const phoneLength = phoneCountryCodes[values.phoneNumberCountry].placeholder.replace(
                      /[() -]/g,
                      ''
                    ).length;
                    if (values.phoneNumber?.toString().length < phoneLength)
                      return { phoneNumber: t('signup.form.phoneNumber.errors.invalid') };
                  }}
                  onSubmit={(values) => {
                    const { phoneNumberCountry: phone, ...newValues } = values;
                    const mapValuesForBackend = {
                      ...newValues,
                      phoneNumber: `+${phoneCountryCodes[values.phoneNumberCountry].code}${
                        values.phoneNumber
                      }`,
                    };
                    submit(mapValuesForBackend);
                  }}>
                  {({ isValid, dirty, values, setFieldValue, errors }: FormikProps<any>) => {
                    return (
                      <Form name="form-signup" id="form-signup" trackingEvent={signupEvents.EDIT_FIELD}>
                        <Grid container rowSpacing={1}>
                          <Grid item xs={12}>
                            <TextField
                              name="email"
                              fullWidth
                              disabled
                              placeholder={t('signup.form.email.placeholder')}
                              label={t('signup.form.email.label')}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="name"
                              fullWidth
                              placeholder={t('signup.form.fullName.placeholder')}
                              label={t('signup.form.fullName.label')}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={0}>
                              <Grid item xs={4} md={3}>
                                <SelectField
                                  label={t('signup.form.phoneNumber.placeholder')}
                                  name="phoneNumberCountry"
                                  options={Object.entries(phoneCountryCodes)
                                    .map(([key, value]) => ({
                                      value: key,
                                      label: value.code.toString(),
                                    }))
                                    .sort((a, b) => a.value.localeCompare(b.value))}
                                  renderOption={({ label, value }) => (
                                    <MenuItem key={value} value={value}>
                                      <img
                                        src={`/images/countryFlags/${value}.svg`}
                                        alt={value.toUpperCase()}
                                        style={{ width: '24px', height: '24px', marginRight: '8px' }}
                                      />
                                      {`(+${label})`}
                                    </MenuItem>
                                  )}
                                  renderValue={(value) => {
                                    return (
                                      <Typography textAlign="center">{`(+${
                                        phoneCountryCodes[value as CountryKeys].code
                                      })`}</Typography>
                                    );
                                  }}
                                />
                              </Grid>
                              <Grid item xs={8} md={9}>
                                <PhoneNumberField
                                  name="phoneNumber"
                                  label="Phone number"
                                  hideLabel
                                  fullWidth
                                  value={values.phoneNumber}
                                  format={phoneCountryCodes[
                                    values.phoneNumberCountry as CountryKeys
                                  ].placeholder.replace(/[0-9]/g, '#')}
                                  placeholder={
                                    phoneCountryCodes[values.phoneNumberCountry as CountryKeys].placeholder
                                  }
                                  onChange={(value) => setFieldValue('phoneNumber', value.floatValue)}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              type="password"
                              fullWidth
                              name="password"
                              label={t('createPassword.passwordLabel')}
                              placeholder={t('createPassword.passwordPlaceholder')}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              type="password"
                              fullWidth
                              name="confirmPassword"
                              label={t('createPassword.confirmPasswordLabel')}
                              placeholder={t('createPassword.confirmPasswordPlaceholder')}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container sx={{ marginTop: '1em', textAlign: 'left' }} rowSpacing={1}>
                              <Grid item xs={12} sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                {values?.password &&
                                values.password.length >= 8 &&
                                values.password.length <= 20 ? (
                                  <CheckCircleOutlineIcon />
                                ) : (
                                  <RadioButtonUncheckedIcon />
                                )}
                                <Typography>{t('createPassword.passwordLengthCriteria')}</Typography>
                              </Grid>
                              <Grid item xs={12} sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                {values?.password &&
                                hasUpperCase(values.password) &&
                                hasLowerCase(values.password) ? (
                                  <CheckCircleOutlineIcon />
                                ) : (
                                  <RadioButtonUncheckedIcon />
                                )}
                                <Typography>
                                  {t('createPassword.passwordUpperAndLowercaseCriteria')}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                {values?.password && hasDigit(values.password) ? (
                                  <CheckCircleOutlineIcon />
                                ) : (
                                  <RadioButtonUncheckedIcon />
                                )}
                                <Typography>{t('createPassword.passwordDigitCriteria')}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          {!!error && (
                            <Grid item xs={12}>
                              <ErrorMessage errorMessage={t(`signup.form.errors.${error}`)} />
                            </Grid>
                          )}
                          <Grid item xs={12} sx={{ paddingTop: '2em !important' }}>
                            <Button
                              fullWidth
                              color="secondary"
                              size={ButtonSizes.Large}
                              isLoading={isLoading}
                              disabled={!dirty || !isValid}
                              isSubmit>
                              {t('signup.form.submit')}
                            </Button>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '8px',
                              paddingTop: '1em !important',
                            }}>
                            <Typography>{t('signup.alreadyHaveAnAccount')}</Typography>
                            <Link to={'/login'} style={{ color: COLORS.indigo[500] }}>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  lineHeight: '24px',
                                  textAlign: 'left',
                                  color: COLORS.indigo[500],
                                }}>
                                {t('signup.login')}
                              </Typography>
                            </Link>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
              <Grid item xs={10} md={8}>
                <Typography textAlign="center">
                  {t('signup.termsAndConditionsFirstPart')}{' '}
                  <a
                    href={`${filesURL}/${t('signup.termsAndConditionsDocument')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}>
                    {`${t('signup.termsAndConditions')}`}
                  </a>
                  {` ${t('signup.termsAndConditionsSecondPart')} `}
                  <a
                    href={`${filesURL}/${t('signup.privacyPolicyDocument')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}>
                    {t('signup.privacyPolicy')}
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default InvitationSignup;
