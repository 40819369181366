export default function TickCircleIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
        fill="url(#paint0_linear_399_25248)"
      />
      <path
        d="M10.5797 15.5821C10.3808 15.5819 10.1902 15.5027 10.0497 15.3621L7.21965 12.5321C7.08017 12.3909 7.00195 12.2005 7.00195 12.0021C7.00195 11.8036 7.08017 11.6132 7.21965 11.4721C7.50965 11.1821 7.98965 11.1821 8.27965 11.4721L10.5797 13.7721L15.7197 8.63205C16.0097 8.34205 16.4897 8.34205 16.7797 8.63205C17.0697 8.92205 17.0697 9.40205 16.7797 9.69205L11.1097 15.3621C10.9691 15.5027 10.7785 15.5819 10.5797 15.5821Z"
        fill="url(#paint1_linear_399_25248)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_399_25248"
          x1="22"
          y1="2.00001"
          x2="-0.458642"
          y2="7.6881"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#4572B8" />
          <stop offset="1" stopColor="#2FC0B8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_399_25248"
          x1="11.9996"
          y1="10.1487"
          x2="11.9786"
          y2="14.6639"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EAF5FF" />
          <stop offset="1" stopColor="#CDDFF0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
