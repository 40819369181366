import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Typography from '../Typography';
import {
  TickCircleIcon,
  MiniNotepadIcon,
  MiniContractIcon,
  MiniMoneyReceiveIcon,
} from '../../components/Icons';
import { useTranslation } from '../../hooks/i18n';
import { InternalPipelineStatus } from '../../types/client';
import { getPipelineStatus } from '../../utils/helpers';
import classes from './StatusTracker.module.scss';

function mapIndexToStatusState(index: number, currentIndex: number) {
  let state = 'pending';
  if (index === currentIndex) state = 'inProgress';
  else if (index < currentIndex) state = 'completed';
  return state;
}

type AppStatusIconMapType = {
  [key in AppStatusOptions]: JSX.Element;
};

const AppStatusIconMap: AppStatusIconMapType = {
  application: <TickCircleIcon />,
  proposal: <MiniNotepadIcon />,
  contract: <MiniContractIcon />,
  financing: <MiniMoneyReceiveIcon />,
};

interface StatusTrackerProps {
  pipelineStatus: InternalPipelineStatus;
  isLoading?: boolean;
  isRejected?: boolean;
}

const statuses = ['application', 'proposal', 'contract', 'financing'] as const;
export type AppStatusOptions = typeof statuses[number];

const StatusTracker = ({ pipelineStatus, isLoading = false, isRejected = false }: StatusTrackerProps) => {
  const { t } = useTranslation('application');
  const currentStatus = getPipelineStatus(pipelineStatus);
  const currentIndex = currentStatus === 'closed' ? statuses.length : statuses.indexOf(currentStatus);

  if (isLoading) return <Skeleton variant="rectangular" height={80} animation="wave" />;

  return (
    <div className={classes.statusTrackerContainer}>
      {statuses.map((status, index) => {
        return (
          <div
            key={status}
            style={{ position: 'relative', flex: 1 }}
            className={clsx({
              [classes.applicationStatus]: index > currentIndex,
              [classes.applicationStatusPending]: index === currentIndex,
              [classes.applicationStatusCompleted]: index < currentIndex,
              [classes.elementRejected]: isRejected && index === currentIndex,
              [classes.firstElement]: index === 0,
              [classes.lastElement]: index === statuses.length - 1,
              [classes.lastElementBackground]: index < currentIndex && index === statuses.length - 1,
              [classes.showElement]: index !== currentIndex,
            })}>
            <div
              className={clsx({
                [classes.statusElement]: true,
                [classes.firstElement]: index === 0,
                [classes.lastElement]: index === statuses.length - 1,
              })}>
              <Grid container alignItems="center" justifyContent="flex-end">
                <Grid item xs={2}>
                  {index < currentIndex && !isRejected && <TickCircleIcon />}
                  {index === currentIndex && isRejected && <CloseIcon />}
                  {index >= currentIndex &&
                    !isRejected &&
                    currentStatus !== 'closed' &&
                    AppStatusIconMap[status]}
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px', color: 'inherit' }}>
                    {t(`proposal.statusTracker.options.${status}`)}
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 400, fontSize: '12px', lineHeight: '18px', color: 'inherit' }}>
                    {t(`proposal.statusTracker.statuses.${mapIndexToStatusState(index, currentIndex)}`)}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            {statuses.length - 1 !== index && <div className={classes.triangle}></div>}
          </div>
        );
      })}
    </div>
  );
};

export default StatusTracker;
