import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { emailRegExp } from '../constants/forms';

export const CompanyStepSchema = (t: TFunction) =>
  Yup.object().shape({
    companyName: Yup.string().required(t('common:requiredField')),
    companyTaxId: Yup.string().required(t('common:requiredField')),
    addressCountry: Yup.string().required(t('common:requiredField')),
    addressStreetOne: Yup.string().required(t('common:requiredField')),
    addressCity: Yup.string().required(t('common:requiredField')),
    addressState: Yup.string().required(t('common:requiredField')),
    addressZipCode: Yup.string().required(t('common:requiredField')),
    companyWebsite: Yup.string(),
    yearCompanyWasFounded: Yup.number()
      .typeError(t('common:requiredField'))
      .min(1700, t('application:sections.generalInformation.fields.yearCompanyWasFoundedMinError'))
      .max(
        new Date().getFullYear(),
        t('application:sections.generalInformation.fields.yearCompanyWasFoundedMaxError')
      ),
    companyContactEmail: Yup.string().required(t('common:requiredField')),
    companyContactName: Yup.string().required(t('common:requiredField')),
    companyContactPhone: Yup.string().required(t('common:requiredField')),
    esg: Yup.array().min(1, t('common:requiredField')),
  });

export const CompanyLiteStepSchema = (t: TFunction) =>
  Yup.object().shape({
    companyName: Yup.string().required(t('common:requiredField')),
    companyTaxId: Yup.string().required(t('common:requiredField')),
    companyWebsite: Yup.string().nullable(),
    yearCompanyWasFounded: Yup.number()
      .typeError(t('common:requiredField'))
      .min(1700, t('application:sections.generalInformation.fields.yearCompanyWasFoundedMinError'))
      .max(
        new Date().getFullYear(),
        t('application:sections.generalInformation.fields.yearCompanyWasFoundedMaxError')
      ),
    companyContactEmail: Yup.string().required(t('common:requiredField')),
    companyContactName: Yup.string().required(t('common:requiredField')),
    companyContactPhone: Yup.string().required(t('common:requiredField')),
  });

export const AddCustomerSchema = (t: TFunction) =>
  Yup.object().shape(
    {
      legalBusinessName: Yup.string().required(t('common:requiredField')),
      tradeName: Yup.string().notRequired().nullable(),
      addressCountry: Yup.string().required(t('common:requiredField')),
      addressBusinessStreet: Yup.string().required(t('common:requiredField')),
      addressCity: Yup.string().required(t('common:requiredField')),
      addressState: Yup.string().required(t('common:requiredField')),
      addressZipCode: Yup.string().required(t('common:requiredField')),
      taxId: Yup.string().notRequired().nullable(),
      paymentTerms: Yup.array().min(1, t('common:requiredField')),
      contactNameForPayables: Yup.string().notRequired().nullable(),
      contactEmailForPayables: Yup.string()
        .notRequired()
        .matches(emailRegExp, t('common:emailInvalidError'))
        .nullable(),
      knowsBuyersEIN: Yup.boolean().notRequired(),
      ein: Yup.string()
        .nullable()
        .when(['knowsBuyersEIN', 'duns'], {
          is: (knowsBuyersEIN: boolean, duns: string) => knowsBuyersEIN && !duns,
          then: Yup.string().required(t('common:requiredField')).nullable(),
        }),
      duns: Yup.string()
        .nullable()
        .when(['knowsBuyersEIN', 'ein'], {
          is: (knowsBuyersEIN: boolean, ein: string) => knowsBuyersEIN && !ein,
          then: Yup.string().required(t('common:requiredField')).nullable(),
        }),
      hasAdditionalAddress: Yup.boolean().notRequired(),
      addressZipCodeTwo: Yup.string()
        .nullable()
        .when('hasAdditionalAddress', {
          is: true,
          then: Yup.string().required(t('common:requiredField')).nullable(),
        }),
      addressCityTwo: Yup.string()
        .nullable()
        .when('hasAdditionalAddress', {
          is: true,
          then: Yup.string().required(t('common:requiredField')).nullable(),
        }),
      addressBusinessStreetTwo: Yup.string()
        .nullable()
        .when('hasAdditionalAddress', {
          is: true,
          then: Yup.string().required(t('common:requiredField')).nullable(),
        }),
      addressStateTwo: Yup.string()
        .nullable()
        .when('hasAdditionalAddress', {
          is: true,
          then: Yup.string().required(t('common:requiredField')).nullable(),
        }),
    },
    [['ein', 'duns']]
  );

export const CompanyAndBusinessLiteSchema = (t: TFunction) =>
  Yup.object().shape({
    companyName: Yup.string().required(t('common:requiredField')),
    companyTaxId: Yup.string().required(t('common:requiredField')),
    sector: Yup.string().required(t('common:requiredField')),
    businessRegions: Yup.array().min(1, t('common:requiredField')),
    esg: Yup.array().min(1, t('common:requiredField')),
    incoTerms: Yup.array().min(1, t('common:requiredField')),
    yearCompanyWasFounded: Yup.number()
      .typeError(t('common:requiredField'))
      .required(t('common:requiredField'))
      .min(1700, t('application:sections.generalInformation.fields.yearCompanyWasFoundedMinError'))
      .max(
        new Date().getFullYear(),
        t('application:sections.generalInformation.fields.yearCompanyWasFoundedMaxError')
      )
      .nullable(),
    companyContactEmail: Yup.string().required(t('common:requiredField')),
    companyContactName: Yup.string().required(t('common:requiredField')),
    companyContactPhone: Yup.string().required(t('common:requiredField')),
    qualityChecks: Yup.string().required(t('common:requiredField')),
    practiceBillingsOrConsignments: Yup.string().required(t('common:requiredField')).nullable(),
    operatesWithCreditInsurance: Yup.string().required(t('common:requiredField')).nullable(),
    insuranceProvider: Yup.string().when('operatesWithCreditInsurance', {
      is: 'yes',
      then: Yup.string().required(t('common:requiredField')),
    }),
    usingFinancialInstitutions: Yup.string().required(t('common:requiredField')).nullable(),
    financialInstitution: Yup.string()
      .nullable()
      .when('usingFinancialInstitutions', {
        is: 'yes',
        then: Yup.string().required(t('common:requiredField')),
      }),
    financialInstitutionType: Yup.string()
      .nullable()
      .when('usingFinancialInstitutions', {
        is: 'yes',
        then: Yup.string().required(t('common:requiredField')),
      }),
  });

export const BusinessStepSchema = (t: TFunction) =>
  Yup.object().shape({
    sector: Yup.string().required(t('common:requiredField')),
    incoTerms: Yup.array().min(1, t('common:requiredField')),
    currencies: Yup.array().min(1, t('common:requiredField')),
    businessRegions: Yup.array().min(1, t('common:requiredField')),
    saleLast12Month: Yup.string().required(t('common:requiredField')),
    practiceBillingsOrConsignments: Yup.string().required(t('common:requiredField')).nullable(),
    qualityChecks: Yup.string().required(t('common:requiredField')),
    operatesWithCreditInsurance: Yup.string().required(t('common:requiredField')).nullable(),
    insuranceProvider: Yup.string().when('operatesWithCreditInsurance', {
      is: 'yes',
      then: Yup.string().required(t('common:requiredField')),
    }),
    usingFinancialInstitutions: Yup.string().required(t('common:requiredField')).nullable(),
    financialInstitution: Yup.string()
      .nullable()
      .when('usingFinancialInstitutions', {
        is: 'yes',
        then: Yup.string().required(t('common:requiredField')),
      }),
    financialInstitutionType: Yup.string()
      .nullable()
      .when('usingFinancialInstitutions', {
        is: 'yes',
        then: Yup.string().required(t('common:requiredField')),
      }),
  });
