import { useRef, useState } from 'react';
import { useTranslation } from '../../hooks/i18n';
import { IDocumentCategory } from '../../types/documents';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '../../componentsv2/Chip';
import Typography from '../../componentsv2/Typography';
import { CrossIcon } from '../../components/Icons';
import ErrorMessage from '../../components/ErrorMessage';
import Button from '../../componentsv2/Button';
import { UploadFile } from '@mui/icons-material';
import { statusColorsMap } from './utils';
import SwitchToggle from '../../components/Switch';
import ModalV2 from '../../components/ModalV2';
import { TextField } from '../../componentsv2/Field';
import { logEvent } from '@amplitude/analytics-browser';
import { docsCollectionEvents } from '../../constants/events';

const DocumentCategory = ({
  id: categoryId,
  name,
  description,
  language,
  status,
  assets,
  isLoading,
  isAdmin = false,
  documentId,
  enabled,
  onApprove = () => {},
  onReject = () => {},
  uploadAssets,
  deleteAsset,
  rejectReason,
  onToggle = () => {},
  groupName,
  keyName,
  template,
}: IDocumentCategory & {
  language: string;
  loadClientCategories: (companyId: number) => void;
  isAdmin?: boolean;
  onApprove?: (categoryId: number) => void;
  onReject?: (categoryId: number, rejectionComment: string) => void;
  uploadAssets: (
    categoryId: number,
    documentId: number,
    files: File[],
    categoryName: string,
    groupName: string
  ) => void;
  deleteAsset: (categoryId: number, documentId: number, assetId: number) => void;
  onToggle?: (categoryId: number) => void;
}) => {
  const { t } = useTranslation();
  const uploaderRef = useRef<any>(null);
  const _status = status;
  const [showRejectionConfirmationModal, setShowRejectionConfirmationModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');

  return (
    <>
      <Grid container sx={{ paddingY: '2em' }} justifyContent="center" alignItems="center" gap="1em">
        <Grid item xs={12} md={1.2}>
          {enabled && (
            <Chip
              sx={{ ...statusColorsMap[_status] }}
              label={t(`client:documents.status.${_status}`)}
              size="small"
            />
          )}
        </Grid>
        <Grid item xs={12} md>
          <Typography sx={{ fontSize: '1.2em', fontWeight: 'bold' }}>{name}</Typography>
          <Typography>{description}</Typography>
          {!!template && (
            <Typography>
              <a href={template.url} download={template.fileName} target="_blank" rel="noreferrer">
                {t('client:documents.downloadTemplate')}
              </a>
            </Typography>
          )}
          {assets.length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'start', gap: '4px', marginTop: '1em' }}>
              <Grid container gap={1}>
                <Grid item xs={12} md={1}>
                  <Typography sx={{ paddingTop: '0.5em', fontWeight: '600' }}>
                    {t('client:documents.uploaded')}:
                  </Typography>
                </Grid>
                <Grid item xs={12} md>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      maxWidth: '100%',
                      flex: 1,
                      gap: '8px',
                    }}>
                    {assets.map((asset) => (
                      <Box
                        key={asset.id}
                        sx={{
                          background: '#E6F6FB',
                          padding: '4px 10px',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          position: 'relative',
                        }}>
                        {asset.isLoading && (
                          <Box sx={{ position: 'absolute', left: '45%', top: '25%' }}>
                            <CircularProgress size={14} />
                          </Box>
                        )}
                        <a
                          href={asset.url}
                          download={asset.fileName}
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => {
                            logEvent(docsCollectionEvents.PREVIEW_DOC, { Name: asset.fileName });
                          }}>
                          <Typography sx={{ visibility: asset.isLoading ? 'hidden' : 'initial' }}>
                            {asset.fileName}
                          </Typography>
                        </a>
                        {status !== 'ACCEPTED' && !isAdmin && (
                          <IconButton
                            disableRipple
                            sx={{ marginLeft: '6px' }}
                            disabled={isLoading}
                            onClick={() => {
                              deleteAsset(categoryId, documentId, asset.id);
                            }}>
                            <CrossIcon size="sm" />
                          </IconButton>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          {_status === 'DENIED' && rejectReason && <ErrorMessage errorMessage={rejectReason} />}
        </Grid>
        <Grid item>
          {isAdmin ? (
            <Box sx={{ display: 'flex', gap: '4px', flexDirection: 'column', alignItems: 'center' }}>
              <SwitchToggle
                label={
                  <Typography variant="body1" style={{ fontWeight: '600' }}>
                    Enabled
                  </Typography>
                }
                checked={enabled}
                onChange={() => {
                  onToggle(categoryId);
                }}
                name="enabled"
                disabled={isLoading}
              />
              <Box sx={{ gap: '4px', display: 'flex' }}>
                <ModalV2
                  isOpen={showRejectionConfirmationModal}
                  handleClose={() => {
                    setShowRejectionConfirmationModal(false);
                    setRejectionReason('');
                  }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>
                        Please add a rejection reason (in {language === 'es' ? 'spanish' : 'english'})
                      </Typography>
                      <TextField
                        multiline
                        minRows={4}
                        name="reason"
                        onChange={(value) => {
                          setRejectionReason(value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ gap: '4px', display: 'flex', justifyContent: 'center' }}>
                        <Button
                          color="danger"
                          onClick={() => {
                            if (rejectionReason) {
                              onReject(categoryId, rejectionReason);
                              setRejectionReason('');
                              setShowRejectionConfirmationModal(false);
                            } else {
                              alert('Please add a rejection reason');
                            }
                          }}>
                          Confirm
                        </Button>
                        <Button
                          type="outline"
                          color="danger"
                          onClick={() => {
                            setRejectionReason('');
                            setShowRejectionConfirmationModal(false);
                          }}>
                          Cancel
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </ModalV2>
                <Button
                  disabled={isLoading}
                  onClick={() => {
                    if (
                      !window.confirm(
                        `Are you sure you want to approve ${name}? This operation is not reversible.`
                      )
                    )
                      return;

                    onApprove(categoryId);
                    logEvent(docsCollectionEvents.APPROVE_DOC, { Name: name });
                  }}>
                  Approve
                </Button>
                <Button
                  disabled={isLoading}
                  color="danger"
                  onClick={() => {
                    setShowRejectionConfirmationModal(true);
                    logEvent(docsCollectionEvents.REJECT_DOC, { Name: name });
                  }}>
                  Reject
                </Button>
              </Box>
            </Box>
          ) : (
            <Button
              type="link"
              color="secondary"
              startIcon={!isLoading ? <UploadFile /> : null}
              isLoading={isLoading}
              disabled={status === 'ACCEPTED'}
              onClick={() => {
                uploaderRef.current && uploaderRef.current.click();
                logEvent(docsCollectionEvents.CLICK_UPLOAD, {
                  'Document Group': groupName,
                  'Document Name': keyName,
                });
              }}>
              {t('client:documents.buttons.upload')}
            </Button>
          )}
        </Grid>
      </Grid>
      <input
        type="file"
        multiple
        style={{ display: 'none' }}
        ref={uploaderRef}
        onChange={(e: any) => {
          const files: File[] = Array.from(e.target.files);
          uploadAssets(categoryId, documentId, files, keyName, groupName);
        }}
      />
    </>
  );
};

export default DocumentCategory;
