import * as React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { ArrowLeftWithLine, ArrowRightWithLine } from '../../components/Icons';
import { COLORS } from '../../themev2';
import AppMenu from './AppMenu';
import { AppMenuItemType } from './AppMenuItem';

export const drawerWidth = 220;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DefaultDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
    },
  })
);

const MobileDrawer = styled(MuiDrawer)({
  '& .MuiDrawer-paper': {
    width: '100%',
    boxSizing: 'border-box',
  },
});

export type SidebarElement = AppMenuItemType & {
  items?: AppMenuItemType[];
};

type DrawerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  isAdmin: boolean;
};

const Drawer = ({ open, setOpen, isAdmin }: DrawerProps) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOnClickItemCallback = () => {
    if (!isMdUp) {
      setOpen(false);
    }
  };

  return (
    <>
      {isMdUp ? (
        <DefaultDrawer
          variant="permanent"
          open={open}
          PaperProps={{ sx: { backgroundColor: COLORS.gray[1400], borderRadius: '0 12px 12px 0' } }}>
          <DrawerHeader>
            <IconButton
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              sx={{ color: COLORS.brand.paper, marginLeft: '8px' }}>
              {open ? <ArrowLeftWithLine /> : <ArrowRightWithLine />}
            </IconButton>
          </DrawerHeader>
          <AppMenu
            isAdmin={isAdmin}
            handleOnClickItemCallback={handleOnClickItemCallback}
            isDrawerOpened={open}
          />
        </DefaultDrawer>
      ) : (
        <MobileDrawer
          anchor="left"
          variant="temporary"
          open={open}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { backgroundColor: COLORS.gray[1400], borderRadius: '0' },
          }}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose} sx={{ color: COLORS.brand.paper, marginLeft: '8px' }}>
              <ArrowLeftWithLine />
            </IconButton>
          </DrawerHeader>
          <AppMenu
            isAdmin={isAdmin}
            handleOnClickItemCallback={handleOnClickItemCallback}
            isDrawerOpened={open}
          />
        </MobileDrawer>
      )}
    </>
  );
};

export default Drawer;
