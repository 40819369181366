import { TabsProps, TabProps, Badge } from '@mui/material';
import Box from '@mui/material/Box';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import { COLORS } from '../../themev2';

export const Tabs = ({ children, ...rest }: TabsProps) => {
  return (
    <MuiTabs
      TabIndicatorProps={{
        style: { background: COLORS.indigo[500], color: COLORS.indigo[500] },
      }}
      {...rest}>
      {children}
    </MuiTabs>
  );
};
export const Tab = ({ children, badge, label, ...rest }: TabProps & { badge?: string | number }) => {
  return (
    <MuiTab
      sx={{
        fontFamily: 'Open Sauce One',
        textTransform: 'none',
        fontSize: '16px',
        borderBottom: 1,
        borderColor: 'divider',
        display: 'flex',
        '&.Mui-selected': { color: COLORS.indigo[500], fontWeight: 600 },
      }}
      label={
        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          {label}
          {badge !== undefined && (
            <Badge
              sx={{
                '.Mui-selected &': {
                  color: '#FBFCFE',
                  background: COLORS.indigo[500],
                },
                background: COLORS.gray[500],
                color: COLORS.gray[1200],
                borderRadius: '20px',
                fontSize: '14px',
                padding: '4px 10px 4px 10px',
              }}>
              {badge.toString()}
            </Badge>
          )}
        </Box>
      }
      {...rest}
    />
  );
};
