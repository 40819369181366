import { useContext } from 'react';
import { FormikContext } from 'formik';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { COLORS } from '../../themev2';
import Typography from '../Typography';
import { CommonProps } from './types';
import { FormTrackingContext } from '../Form';
import { logFieldChange } from '../../utils/tracking';

type CheckboxFieldProps = Omit<CommonProps, 'name'> & { value?: boolean; name?: string; checked?: boolean };

export default function CheckboxField({
  error,
  name,
  label,
  value,
  onChange,
  checked = false,
  eventLogger = logFieldChange,
  disabled,
}: CheckboxFieldProps) {
  const formik = useContext(FormikContext);
  const _error = error || (name && formik?.touched[name] && formik?.errors[name]);
  const { trackingEvent } = useContext(FormTrackingContext);

  return (
    <FormControl error={!!_error} fullWidth>
      <FormControlLabel
        control={
          <Checkbox
            checkedIcon={
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2" y="2" width="20" height="20" rx="2" fill="#4F55FD" />
                <path
                  d="M16 9L10.0337 15L8 12.9548"
                  stroke="#FDFDFD"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            color="secondary"
            disabled={disabled}
            checked={checked || value || (name && formik?.values[name])}
            onChange={(_, checked) => {
              !!name && formik && formik.setFieldValue(name, checked);
              onChange && onChange(checked);
              trackingEvent && name && eventLogger(trackingEvent, name, checked.toString());
            }}
            sx={{
              '&': {
                border: COLORS.gray[1000],
              },
              '&:hover': {
                background: 'none',
              },
            }}
          />
        }
        label={<Typography sx={{ color: disabled ? '#9CABC2' : 'initial' }}>{label}</Typography>}
      />
      {!!_error && (
        <FormHelperText error>
          <Typography sx={{ color: 'inherit' }}>{_error}</Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
}
