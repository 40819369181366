import { LazyTranslations } from '../types/i18n';
import { useTranslation as _useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export const useLazyTranslation = (namespaces: string[] = ['user']): LazyTranslations => {
  const { i18n, t } = _useTranslation();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    i18n
      .loadNamespaces(namespaces, (error) => {
        setError(error);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [i18n, namespaces]);

  return { isLoading, t, i18n, error };
};

export const useTranslation = (...params: Parameters<typeof _useTranslation>) => {
  return _useTranslation(...params);
};
