export default function MiniContractIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5201 11.2L17.7201 12.97L12.0601 7.31004L13.8201 4.51004C14.6401 3.22004 16.2801 3.16004 17.4901 4.36004L20.6701 7.54004C21.8001 8.67004 21.7301 10.43 20.5201 11.2Z"
        fill="#91A2BB"
      />
      <path
        opacity="0.4"
        d="M15.2101 20.45L6.50006 21.48C5.90006 21.55 5.36006 21.46 4.91006 21.24C4.42563 21.0055 4.03453 20.6144 3.80006 20.13C3.58006 19.68 3.50006 19.14 3.56006 18.55L4.60006 9.82998C4.85006 7.64998 5.66006 6.93999 7.96006 7.06999L12.0801 7.31999L17.7401 12.98L17.9801 17.1C18.1901 19.4 17.4001 20.2 15.2101 20.45Z"
        fill="#91A2BB"
      />
      <path
        d="M8.48004 17.6501L4.90004 21.2301C4.41562 20.9956 4.02451 20.6045 3.79004 20.1201L7.38004 16.5301C7.69004 16.2301 8.19004 16.2301 8.49004 16.5301C8.80004 16.8501 8.80004 17.3401 8.48004 17.6501Z"
        fill="#91A2BB"
      />
    </svg>
  );
}
