import { useState, useEffect, Suspense } from 'react';
import { Identify, identify, init } from '@amplitude/analytics-browser';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useFlag } from '@unleash/proxy-client-react';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/open-sauce-one';
import RootPage from './views/RootPage';
import ErrorFallback from './components/ErrorFallback';
import SimpleBackdrop from './components/Backdrop';
import AuthGuard from './components/AuthGuard';
import SuspenseBackdrop from './components/Backdrop/SuspenseBackdrop';
import { useAuth } from './hooks';
import { adminRoutesV2, allAccessibleRoutes, clientRoutesV2, publicRoutesV2 } from './router';
import { useWindowSize } from './hooks/useWindowSize';
import { useAppSelector, useAppDispatch, setShowSidebar, loadClient as loadClientThunk } from './store';
import { HTTPInterceptor } from './services/axios/http-interceptors';
import { APIClient } from './types/client';
import { COLORS, lightTheme } from './themev2';
import i18n from './i18n';

export default function App() {
  const enabled = useFlag('tracking_poc');
  const { isAuth, user, isLoadingUser, isAdmin } = useAuth();
  const dispatch = useAppDispatch();
  const { isDesktop } = useWindowSize();
  const client = useAppSelector((state) => state.client.client) || ({} as APIClient);
  const userPreferences = useAppSelector((state) => state.client.newUserPreferences);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    setIsFirstRender(false);
    if (isDesktop) {
      dispatch(setShowSidebar(true));
    }
  }, [dispatch, isDesktop]);

  useEffect(() => {
    // init amplitude
    const amplitudeKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
    if (amplitudeKey) {
      init(amplitudeKey);
    }
  }, [user]);

  useEffect(() => {
    // init user language
    if (userPreferences) i18n.changeLanguage(userPreferences.language);
  }, [userPreferences]);

  useEffect(() => {
    if (user?.isAdmin) {
      // force english language for admin users
      i18n.changeLanguage('en-US');
    }
  }, [user]);

  useEffect(() => {
    if (user && !user.isAdmin && !client?.id) {
      dispatch(loadClientThunk())
        .unwrap()
        .then(({ newCompany }) => {
          const { uuid, country } = newCompany;
          const identifyObj = new Identify();
          identifyObj.set('Company ID', uuid);
          identifyObj.set('Country of Incorporation', country);
          identify(identifyObj);
        });
    }
  }, [dispatch, user, client?.id]);

  return (
    <Suspense fallback={<SuspenseBackdrop />}>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <Router>
          <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
            <HTTPInterceptor />
            {isFirstRender || isLoadingUser ? (
              <SimpleBackdrop isLoading={isFirstRender || isLoadingUser} hasOpaqueBackground />
            ) : (
              <div
                style={{
                  backgroundColor: COLORS.gray[200],
                  minHeight: '100vh',
                }}>
                <Routes>
                  {publicRoutesV2.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={
                        <AuthGuard isAdmin={isAdmin} isAuth={isAuth} isPublicRoute>
                          {route.component}
                        </AuthGuard>
                      }
                    />
                  ))}
                  {adminRoutesV2.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={
                        <AuthGuard isAdmin={isAdmin} isAuth={isAuth} isAdminRoute>
                          {route.component}
                        </AuthGuard>
                      }
                    />
                  ))}
                  {clientRoutesV2.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={
                        <AuthGuard isAdmin={isAdmin} isAuth={isAuth} isClientRoute>
                          {route.component}
                        </AuthGuard>
                      }
                    />
                  ))}
                  {enabled &&
                    allAccessibleRoutes.map((route) => (
                      <Route key={route.path} path={route.path} element={route.component} />
                    ))}
                  <Route path="/" element={<RootPage />} />
                </Routes>
              </div>
            )}
          </Sentry.ErrorBoundary>
        </Router>
      </ThemeProvider>
    </Suspense>
  );
}
