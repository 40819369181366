import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { emailRegExp } from '../constants/forms';

export function getLoginSchema(t: TFunction) {
  return Yup.object().shape({
    email: Yup.string().required(t('login.emailRequiredError')).matches(emailRegExp, t('emailInvalidError')),
    password: Yup.string().required(t('login.passwordRequiredError')),
  });
}

export const NewPasswordSchema = (t: TFunction) =>
  Yup.object().shape({
    password: Yup.string()
      .required(t('requiredField'))
      .min(8, '')
      .max(20, '')
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, t('createPassword.invalidPassword')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required(t('requiredField')),
  });

export const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().required('').matches(emailRegExp, 'Email address is not valid'),
});
export const EmailVerificationSchema = Yup.object().shape({
  email: Yup.string().required('').matches(emailRegExp, 'Email address is not valid'),
});
