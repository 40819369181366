import { APIAsset } from './application';
import { Nullable } from './utils';

export type BankAccountRequestStatus = 'IN_REVIEW' | 'APPROVED' | 'REJECTED';
export type BankAccountType = 'SAVINGS' | 'CHECKING';
export type BankAccountDataType = 'WIRE' | 'ACH';
export type BankAccountCurrency = 'USD' | 'MXN';

export type AccountInfoUSA = {
  companyLegalName: string;
  bankName: string;
  bankAccountNumber: string;
  accountType: BankAccountType;
  currency: BankAccountCurrency;
  bankingDataType: BankAccountDataType;
  abaRoutingNumber: string;
  treasuryFinanceEmail: string;
};

export type AccountInfoMexico = {
  companyLegalName: string;
  bankName: string;
  bankAccountNumber: string;
  accountType: BankAccountType;
  currency: BankAccountCurrency;
  clabe: string;
  swiftCode: string;
  treasuryFinanceEmail: string;
};

export type AccountInfoRestOfWorld = {
  companyLegalName: string;
  bankName: string;
  bankAccountNumber: string;
  accountType: BankAccountType;
  currency: BankAccountCurrency;
  swiftCode: string;
  treasuryFinanceEmail: string;
  ownerAddress: string;
  state: string;
  city: string;
  postalCode: string;
};

export type BankAccountRequest = {
  id: number;
  assets: APIAsset[];
  createdAt: string;
  updatedAt: string;
  deletedAt: Nullable<string>;
  country: string;
  status: BankAccountRequestStatus;
  rejectedComment: Nullable<string>;
  accountInformation: AccountInfoUSA | AccountInfoMexico | AccountInfoRestOfWorld;
  company: number;
};

export type ApprovedBankAccount = {
  clientCompanyUuid: string;
  clientBankKey: number;
  bankAccountNo: string;
  bankName: string;
  bankAbaRoutingNo: string;
  bankSwiftCode: string;
  bankCurrency: string;
  bank: {
    address: string;
    city: string;
    zipCode: string;
    countryCode: string;
    phone: string;
    contact: string;
  };
  interBank: {
    name: string;
    accountNo: string;
    abaRoutingNo: string;
    swiftCode: string;
  };
};

export function isMxAccount(accountInformation: any): accountInformation is AccountInfoMexico {
  return typeof accountInformation.clabe !== 'undefined';
}

export function isUsaAccount(accountInformation: any): accountInformation is AccountInfoUSA {
  return typeof accountInformation.abaRoutingNumber !== 'undefined';
}

export function isRowAccount(accountInformation: any): accountInformation is AccountInfoRestOfWorld {
  return typeof accountInformation.ownerAddress !== 'undefined';
}

export type BankAccountFormValues = {
  companyLegalName: string;
  bankName: string;
  bankAccountNumber: string;
  accountType: string;
  country: string;
  currency: string;
  bankingDataType: string;
  abaRoutingNumber: string;
  treasuryFinanceEmail: string;
  clabe: string;
  swiftCode: string;
  ownerAddress: string;
  state: string;
  city: string;
  postalCode: string;
};
