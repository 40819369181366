import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { emailRegExp } from '../constants/forms';

export const SignupSchema = (t: TFunction) =>
  Yup.object().shape({
    fullName: Yup.string().required(t('requiredField')),
    phoneNumber: Yup.string().required(t('requiredField')),
    email: Yup.string()
      .required(t('requiredField'))
      .matches(emailRegExp, t('signup.form.email.errors.invalid')),
    businessName: Yup.string().required(t('requiredField')),
  });
