import React, { createContext, useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { FlagProvider } from '@unleash/proxy-client-react';
import { store } from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import './assets/scss/main.scss';
import './i18n';
import '@fontsource/open-sauce-one';
import { GlobalSnackbarProvider } from './context';

Sentry.init({
  // only log to Sentry if the environment is set (i.e. it's not local)
  dsn:
    !!process.env.REACT_APP_ENVIRONMENT && process.env.NODE_ENV !== 'development'
      ? process.env.REACT_APP_SENTRY_DSN
      : '',
  environment: process.env.REACT_APP_ENVIRONMENT || '',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const flagsConfig = {
  url: process.env.REACT_APP_UNLEASH_URL as string,
  clientKey: process.env.REACT_APP_UNLEASH_API_TOKEN as string,
  refreshInterval: Number(process.env.REACT_APP_UNLEASH_REFRESH_INTERVAL),
  appName: process.env.REACT_APP_UNLEASH_APP_NAME as string,
};

ReactDOM.render(
  <React.StrictMode>
    <FlagProvider config={flagsConfig}>
      <GlobalSnackbarProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </GlobalSnackbarProvider>
    </FlagProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
