import { useState } from 'react';
import { APIDocumentCategory, APIDocumentSession, IDocumentCategory } from '../types/documents';
import {
  adminAcceptClientDocumentCategory,
  adminCreateClientDocumentCategory,
  adminGetCompanyDocumentCategories,
  adminRejectClientDocumentCategory,
  adminToggleClientDocumentCategory,
  deleteDocumentAsset,
  getCompanyDocumentCategories,
  uploadDocumentAssets,
} from '../services/documents';
import { logEvent } from '@amplitude/analytics-browser';
import { docsCollectionEvents } from '../constants/events';
import { formatAPIAsset, getStatusFromDocuments, getStatusFromSession, groupDocuments } from '../utils/docs';

export function formatAPICategory(
  {
    id,
    group,
    nameEn,
    nameEs,
    descriptionEn,
    descriptionEs,
    documents,
    status,
    enabled,
    isExtraDocument,
    templateEn,
    templateEs,
  }: APIDocumentCategory,
  language: string
): IDocumentCategory {
  /* Transforms an API Document Category into our internal representation */
  const template = language === 'es' ? templateEs : templateEn;

  return {
    id,
    group,
    name: language === 'es' ? nameEs : nameEn,
    description: language === 'es' ? descriptionEs : descriptionEn,
    assets: documents[0].lastSession.assets.map((apiAsset) => ({
      ...formatAPIAsset(apiAsset),
    })),
    isLoading: false,
    rejectReason: documents[0].prevSessionRejectedComment,
    status: getStatusFromDocuments(documents, status),
    documentId: documents[0].id,
    enabled,
    isExtraDocument,
    groupName: group,
    // use name in english to uniquely identify documents independently from user language (analytics purposes)
    keyName: nameEn,
    template: !!template ? formatAPIAsset(template) : null,
  };
}

export function useDocuments(companyId: number | undefined, language: string, isAdmin = false) {
  const [documentCategories, setDocumentCategories] = useState<IDocumentCategory[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  function setCategoryIsLoading(categoryId: number, value: boolean) {
    setDocumentCategories((prev) =>
      prev.map((category) => (category.id === categoryId ? { ...category, isLoading: value } : category))
    );
  }

  function setAssetIsLoading(categoryId: number, assetId: number, value: boolean) {
    setDocumentCategories((prev) =>
      prev.map((category) =>
        category.id === categoryId
          ? {
              ...category,
              assets: category.assets.map((asset) =>
                asset.id === assetId ? { ...asset, isLoading: value } : asset
              ),
            }
          : category
      )
    );
  }

  function loadClientCategories() {
    /* Loads all Document categories from the API */
    if (!companyId) return;

    const _getCompanyDocumentCategories = isAdmin
      ? adminGetCompanyDocumentCategories
      : getCompanyDocumentCategories;

    setIsLoading(true);
    _getCompanyDocumentCategories(companyId)
      .then((categories) => {
        setDocumentCategories(
          categories
            .filter(({ enabled, multiple }) => (isAdmin ? true : enabled && !multiple))
            .sort((a, b) => a.nameEn.localeCompare(b.nameEn))
            .map((apiCategory) => formatAPICategory(apiCategory, language))
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function updateCategory(categoryId: number, sessionResult: APIDocumentSession) {
    /* Updates an internal document category based on an API result */
    setDocumentCategories((prev) =>
      prev.map((category) =>
        category.id === categoryId
          ? {
              ...category,
              assets: sessionResult.assets.map((asset) => formatAPIAsset(asset)),
              status: getStatusFromSession(sessionResult),
              rejectReason: sessionResult.rejectedComment,
            }
          : category
      )
    );
  }

  function updateCategoryToggle(categoryId: number) {
    /* Updates category after toggle */
    setDocumentCategories((prev) =>
      prev.map((category) =>
        category.id === categoryId
          ? {
              ...category,
              enabled: !category.enabled,
            }
          : category
      )
    );
  }

  function toggleCategory(categoryId: number) {
    /* Enables/Disables a Category */
    if (!companyId || !isAdmin) return;

    setCategoryIsLoading(categoryId, true);
    adminToggleClientDocumentCategory(companyId, categoryId).then(() => {
      updateCategoryToggle(categoryId);
      setCategoryIsLoading(categoryId, false);
    });
  }

  function approveCategory(categoryId: number) {
    /* Accepts a Category */
    if (!companyId || !isAdmin) return;

    setCategoryIsLoading(categoryId, true);
    adminAcceptClientDocumentCategory(companyId, categoryId).then((result) => {
      updateCategory(categoryId, result.documents[0].lastSession);
      setCategoryIsLoading(categoryId, false);
    });
  }

  function rejectCategory(categoryId: number, rejectionComment: string) {
    /* Rejects a Category */
    if (!companyId || !isAdmin) return;

    setCategoryIsLoading(categoryId, true);
    adminRejectClientDocumentCategory(companyId, categoryId, rejectionComment).then((result) => {
      updateCategory(categoryId, {
        ...result.documents[0].lastSession,
        rejectedComment: result.documents[0].prevSessionRejectedComment,
      });
      setCategoryIsLoading(categoryId, false);
    });
  }

  function createCategory(values: {
    group: string;
    nameEn: string;
    nameEs: string;
    descriptionEn: string;
    descriptionEs: string;
  }) {
    if (!companyId || !isAdmin) return;
    return adminCreateClientDocumentCategory(companyId, values).then(() => {
      return loadClientCategories();
    });
  }

  function deleteAsset(categoryId: number, documentId: number, assetId: number) {
    /* Deletes an asset using the API */
    if (!companyId) return;

    setAssetIsLoading(categoryId, assetId, true);
    deleteDocumentAsset(companyId, documentId, assetId)
      .then((result) => {
        updateCategory(categoryId, result);
        logEvent(docsCollectionEvents.DELETE_DOC, { Name: '' });
      })
      .finally(() => {
        setAssetIsLoading(categoryId, assetId, false);
      });
  }

  function uploadAssets(
    categoryId: number,
    documentId: number,
    files: File[],
    categoryName: string,
    groupName: string
  ) {
    /* Uploads assets using the API */
    if (!companyId) return;

    const data = new FormData();
    files.forEach((file) => {
      data.append('asset', file, file.name);
      data.append('file_name', file.name);
      data.append('asset_type', 'document');
    });
    setCategoryIsLoading(categoryId, true);
    uploadDocumentAssets(companyId, documentId, data)
      .then((result) => {
        logEvent(docsCollectionEvents.UPLOAD_DOC, {
          Files: files.map((f) => f.name),
          'Document Group': groupName,
          'Document Name': categoryName,
        });
        updateCategory(categoryId, result);
      })
      .finally(() => {
        setCategoryIsLoading(categoryId, false);
      });
  }

  return {
    groupedCategories: groupDocuments(
      // separate extra documents for admin purposes
      documentCategories.filter((category) => (isAdmin ? !category.isExtraDocument : true))
    ),
    groupedExtraCategories: groupDocuments(documentCategories.filter((category) => category.isExtraDocument)),
    isLoading,
    loadClientCategories,
    uploadAssets,
    deleteAsset,
    toggleCategory,
    approveCategory,
    rejectCategory,
    createCategory,
  };
}
