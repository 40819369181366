import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../hooks/i18n';
import {
  DataGrid,
  DataGridProps,
  gridPageCountSelector,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TableFilters from '../../components/TableFilters';
import Button from '../Button';
import { FilterType, Nullable } from '../../types';
import { styleDataGrid } from '../../constants/style';
import classes from './DataGrid.module.scss';

function CustomPagination({ currentPage }: { currentPage: number }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      size="small"
      color="primary"
      count={pageCount}
      page={currentPage + 1}
      siblingCount={0}
      boundaryCount={2}
      renderItem={(item) => (
        <PaginationItem
          classes={{
            root: 'buttons',
            selected: 'selected',
            previousNext: 'previousNext',
          }}
          components={{
            previous: ArrowBackIcon,
            next: ArrowForwardIcon,
          }}
          {...item}
        />
      )}
      classes={{
        root: 'pagination',
      }}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

type PaginationSettings = {
  rowCount: number;
  onPageChange: (newPage: number) => void;
};

type TableAction = {
  label: string;
  onClick: (ids: string[]) => void;
  shouldRender?: boolean;
  Icon?: Nullable<ReactElement>;
};

type CustomDataGridProps = DataGridProps & {
  tableName?: string;
  linkTo?: string;
  hideToolbar?: boolean;
  isLoading?: boolean;
  filterOptions?: FilterType[];
  currentFilters?: any;
  onApplyFilters?: Nullable<Function>;
  onClearFilters?: Nullable<Function>;
  currentPage?: number;
  paginationSettings?: PaginationSettings;
  actions?: TableAction[];
};

export default function CustomDataGrid({
  actions,
  tableName,
  hideFooter = false,
  linkTo,
  hideToolbar,
  paginationSettings,
  isLoading = false,
  filterOptions = [],
  currentFilters = null,
  onApplyFilters = null,
  onClearFilters = null,
  currentPage = 0,
  onCellClick,
  ...rest
}: CustomDataGridProps) {
  const { t } = useTranslation('admin');
  const [showFilters, setShowFilters] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(null);

  const shouldRenderFilters = showFilters && filterOptions.length > 0 && onApplyFilters && onClearFilters;

  const customToolbar = () => {
    return (
      <GridToolbarContainer className={classes.toolbarContainer}>
        <Box className={classes.componentHeader}>
          <Typography variant="h5" component="p" className={classes.tableName}>
            {tableName}
          </Typography>
          <Box>
            <GridToolbarFilterButton className={classes.filterButton} />
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  };

  const customFooter = () => {
    return (
      <Box className={classes.footerContainer}>
        {linkTo && (
          <Link className={classes.linkTo} to={linkTo}>
            Go to {tableName} List <ArrowForwardIcon sx={{ fontSize: '16px' }} />
          </Link>
        )}
        {CustomPagination({ currentPage })}
      </Box>
    );
  };

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          {!!actions?.length && !!selectedIds.length && (
            <Grid item>
              <Button
                endIcon={<ExpandMoreIcon />}
                onClick={(e) => {
                  setActionsAnchorEl(e.currentTarget);
                }}>
                {t(`admin:clientsPage.actions.title`)}
              </Button>
              <Menu
                open={!!actionsAnchorEl}
                onClose={() => {
                  setActionsAnchorEl(null);
                }}
                anchorEl={actionsAnchorEl}>
                {actions.map(
                  ({ label, onClick, shouldRender = true, Icon }) =>
                    shouldRender && (
                      <MenuItem>
                        <IconButton
                          onClick={() => onClick(selectedIds)}
                          sx={{
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}>
                          <SvgIcon>{Icon}</SvgIcon>
                          <Typography>{label}</Typography>
                        </IconButton>
                      </MenuItem>
                    )
                )}
              </Menu>
            </Grid>
          )}
          {filterOptions.length > 0 && (
            <Grid item>
              <Button
                startIcon={<FilterListIcon />}
                onClick={() => setShowFilters((prevState) => !prevState)}
                type="outline">
                {t(`admin:clientsPage.gridFilters.title`)}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      {shouldRenderFilters && (
        <Grid item xs={12}>
          <TableFilters
            filterOptions={filterOptions}
            initialValues={currentFilters}
            applyFilters={onApplyFilters}
            clearFilters={onClearFilters}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <DataGrid
          loading={isLoading}
          getRowId={(row) => row.id || row.invoice_key}
          sx={{
            ...styleDataGrid,
            border: 'none',
            '& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
          }}
          {...(!!paginationSettings
            ? {
                paginationMode: 'server',
                rowCount: paginationSettings.rowCount,
                onPageChange: paginationSettings.onPageChange,
              }
            : {})}
          classes={{
            columnHeaderDraggableContainer: 'columnHeaderContainer',
            columnHeaderTitleContainer: 'columnTitleContainer',
            columnHeaderTitle: 'columnHeaderTitle',
            columnHeader: 'columnHeader',
            columnHeaders: 'columnHeaders',
            cell: 'cell',
            row: 'row',
            footerContainer: 'footerContainer',
          }}
          components={{
            Pagination: CustomPagination,
            Toolbar: hideToolbar ? null : customToolbar,
            Footer: customFooter,
          }}
          componentsProps={{
            filterPanel: {
              filterFormProps: {
                operatorInputProps: {
                  sx: { display: 'none' },
                },
              },
            },
          }}
          hideFooterSelectedRowCount
          autoHeight
          hideFooter={hideFooter}
          disableColumnMenu
          onCellClick={(params, event, details) => {
            // small hack to prevent event propagation on checkbox click
            if (params.field === '__check__') return;

            !!onCellClick && onCellClick(params, event, details);
          }}
          onSelectionModelChange={(ids) => {
            setSelectedIds(ids.map((id) => id.toString()));
          }}
          {...rest}
        />
      </Grid>
    </Grid>
  );
}
