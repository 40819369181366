import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { SxProps } from '@mui/material';
import { CopyToClipboardIcon } from '../../components/Icons';
import { useTranslation } from '../../hooks/i18n';
import { COLORS } from '../../themev2';

interface CopyToClipboardButtonProps {
  textToCopy: string;
  onClick?: () => void;
  customSX?: SxProps;
}

const POPOVER_DURATION = 1500;

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  textToCopy,
  onClick = () => {},
  customSX,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
    setTimeout(() => setCopied(false), POPOVER_DURATION);
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    handleCopyClick();
    onClick && onClick();
  };

  const id = copied ? 'copied-popover' : undefined;

  return (
    <>
      <IconButton onClick={handleButtonClick} sx={{ color: '#91A2BB', ...customSX }}>
        <CopyToClipboardIcon />
      </IconButton>
      <Popover
        id={id}
        open={copied}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Typography sx={{ p: 1, background: COLORS.gray[1300], color: 'white' }}>
          {t('copiedToClipboard')}
        </Typography>
      </Popover>
    </>
  );
};

export default CopyToClipboardButton;
