import { ReactNode } from 'react';
import { default as MUIModal } from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import classes from './Modal.module.scss';

type ModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  children: ReactNode;
  displayCloseButton?: boolean;
  size?: 'small' | 'medium' | 'large' | 'xl';
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
};

const sizeToWidth = {
  small: '300px',
  medium: '600px',
  large: '900px',
  xl: '1200px',
};

const sizeToHeight = {
  small: '250px',
  medium: '300px',
  large: '600px',
  xl: '900px',
};

const Modal = ({
  isOpen,
  handleClose,
  children,
  displayCloseButton = true,
  size = 'medium',
  disableEscapeKeyDown = false,
  disableBackdropClick = false,
}: ModalProps) => {
  const onCloseModal = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' && disableBackdropClick) return;
    else if (reason === 'escapeKeyDown' && disableEscapeKeyDown) return;
    return handleClose();
  };

  return (
    <MUIModal
      open={isOpen}
      onClose={onCloseModal}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      className="modal">
      <Box
        className="paper"
        sx={{
          position: 'absolute',
          width: sizeToWidth[size],
          minHeight: sizeToHeight[size],
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: 24,
          padding: 6,
          outline: 'none',
        }}>
        {displayCloseButton && <CloseIcon onClick={handleClose} className={classes.closeIcon} />}
        {children}
      </Box>
    </MUIModal>
  );
};

export default Modal;
