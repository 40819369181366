import TextField, { TextFieldProps } from './TextField';
import CurrencyAdornment from './CurrencyAdornment';

type MoneyFieldProps = TextFieldProps & {
  currency?: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

const validateAndFormatValue = (inputValue: string): string => {
  const regex = /^(\d+([.,]\d{3})*([.,]\d{0,2})?)$/;

  if (regex.test(inputValue)) {
    return inputValue;
  }
  return '';
};

export default function MoneyField({ currency, setFieldValue, name, ...rest }: MoneyFieldProps) {
  return (
    <TextField
      {...rest}
      name={name}
      startAdornment={currency ? <CurrencyAdornment currency={currency} /> : null}
      onKeyUp={(e) => {
        const val = (e.target as HTMLInputElement).value;
        // Validate the current value, if valid update the field value, otherwise set it as empty or handle as needed
        const validatedValue = validateAndFormatValue(val);
        if (validatedValue) {
          setFieldValue(name, validatedValue, true);
        } else {
          setFieldValue(name, '', true);
        }
      }}
    />
  );
}
