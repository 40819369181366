import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import * as service from '../services/clients';
import * as adminService from '../services/admin';
import { ClientDetail } from '../services/types';
import { CountryKeys, EmptyString, Nullable, UserPreferences } from '../types';
import { APIApplication } from '../types/application';
import { APIBuyerCreditCheck } from '../types/buyerCreditCheck';
import { APIInsuranceRequest } from '../types/insurance';
import { APIClient, APICompany, ClientPipelineStatus, InternalPipelineStatus } from '../types/client';
import { APILLC } from '../types/llc';
import { APIFXRequest } from '../types/fxRequest';
import { backendCountryValues } from '../constants/data';
import { FactoringRequest, FactoringRequestStatus } from '../types/factoring';
import { Company, CompanyUser } from '../types/company';

type Status = 'loading' | 'idle' | 'failed';

export type ClientState = ClientDetail & {
  applications: APIApplication[];
  status: Status;
  error: Nullable<string>;
  client: Nullable<APIClient>;
  buyerCreditChecks: APIBuyerCreditCheck[];
  insuranceRequests: APIInsuranceRequest[];
  fxRequests: APIFXRequest[];
  llcRequest: Nullable<APILLC>;
  newCompany: Company;
  newUserPreferences: UserPreferences;
  newCompanyUser: CompanyUser;
  isFactoringActive: boolean;
};

const clientInitialState: Partial<ClientState> = {
  applications: [],
  error: null,
  buyerCreditChecks: [],
  insuranceRequests: [],
  fxRequests: [],
  llcRequest: null,
  client: null,
  isFactoringActive: false,
};

export const getCountryKey = (country: string): CountryKeys => {
  // @ts-ignore
  return Object.keys(backendCountryValues).find((key) => backendCountryValues[key] === country);
};

export const clientSlice = createSlice({
  name: 'client',
  initialState: clientInitialState,
  reducers: {
    resetClientState: () => clientInitialState,
    setNotificationPreferences: (state, action: PayloadAction<any>) => {
      if (state.newCompanyUser)
        state.newCompanyUser.notificationPreferences = {
          ...state.newCompanyUser.notificationPreferences,
          ...action.payload,
        };
    },
    setFactoringActivity: (state, action) => {
      state.isFactoringActive = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadClient.fulfilled, (state, action) => {
        state.client = action.payload.client;
        state.newCompany = action.payload.newCompany;
        state.newUserPreferences = action.payload.newUserPreferences;
        state.newCompanyUser = action.payload.newCompanyUser;
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        state.client = action.payload.client;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.client!.companies = [action.payload];
      })
      .addCase(adminGetClientBuyerCreditChecks.fulfilled, (state, action) => {
        state.buyerCreditChecks = action.payload;
      })
      .addCase(adminGetClientInsuranceRequests.fulfilled, (state, action) => {
        state.insuranceRequests = action.payload;
      })
      .addCase(adminGetClientFXRequests.fulfilled, (state, action) => {
        state.fxRequests = action.payload;
      })
      .addMatcher(
        isFulfilled(
          loadClient,
          updateClient,
          updateCompany,
          adminGetClientBuyerCreditChecks,
          adminGetClientInsuranceRequests,
          adminGetClientFXRequests
        ),
        (state) => {
          state.status = 'idle';
        }
      )
      .addMatcher(
        isPending(
          loadClient,
          updateClient,
          updateCompany,
          adminGetClientBuyerCreditChecks,
          adminGetClientInsuranceRequests,
          adminGetClientFXRequests
        ),
        (state) => {
          state.status = 'loading';
          state.error = null;
        }
      )
      .addMatcher(
        isRejected(
          loadClient,
          updateClient,
          updateCompany,
          adminGetClientBuyerCreditChecks,
          adminGetClientInsuranceRequests,
          adminGetClientFXRequests
        ),
        (state, action) => {
          state.status = 'failed';
          state.error = action.error.message || null;
        }
      );
  },
});

const factoringStatusToClientPipelineStatus: Partial<Record<FactoringRequestStatus, ClientPipelineStatus>> = {
  created: 'application_sent',
  submitted: 'application_received',
  pending_review: 'application_received',
  termsheet: 'termsheet_sent',
  document_validation: 'termsheet_received_and_document_collection',
  due_dilligence: 'due_dilligence',
  credit_committee: 'credit_committee',
  legal: 'legal',
  onboarding: 'onboarding',
};

// TODO: Should we assume it is closed? TBR(To Be Refactored)
export function getClientPipelineStatus(
  factoringRequest: EmptyString<FactoringRequest>
): EmptyString<ClientPipelineStatus> {
  if (factoringRequest === null || factoringRequest === '') return '';
  return factoringStatusToClientPipelineStatus[factoringRequest.status] || 'closed';
}

export function getInternalPipelineStatus(
  factoringRequest: EmptyString<FactoringRequest>
): InternalPipelineStatus {
  if (factoringRequest && factoringRequest.status === 'credit_committee' && factoringRequest.creditCommittee)
    return 'credit_committee_advanced';
  return getClientPipelineStatus(factoringRequest) as InternalPipelineStatus; // TODO: check empty string
}

export const statusTrackerMap: Record<EmptyString<ClientPipelineStatus>, string> = {
  '': 'signUp',
  application_sent: 'application',
  application_received: 'application',
  termsheet_sent: 'termSheet',
  termsheet_received_and_document_collection: 'termSheet',
  due_dilligence: 'creditDecision',
  credit_committee: 'creditDecision',
  legal: 'legalDocuments',
  onboarding: 'onboarding',
  closed: 'closed',
};

function transformCompanies(companies: Company[]): APICompany[] {
  // remove new keys, fill old ones
  return companies.map(
    ({
      productStatus,
      initialUser,
      taxIdManuallyVerified,
      taxIdManuallyVerifiedBy,
      institutionalEmail,
      institutionalPhone,
      ...rest
    }) => ({
      ...rest,
      businessRegions: [],
      currencies: [],
      sector: '', // TODO: is this used?
      institutionalEmail: institutionalEmail as string,
      institutionalPhone: institutionalPhone as string,
      institutionalWapp: '', // TODO: is this used?
    })
  );
}

export async function transformCompanyUser(client: CompanyUser): Promise<{
  client: APIClient;
  newCompany: Company;
  newUserPreferences: UserPreferences;
}> {
  const company = client.companies[0];
  const factoringRequest = await service.getClientActiveFactoringRequest(company.id);
  const clientPipelineStatus = getClientPipelineStatus(factoringRequest);

  return {
    client: {
      id: client.id,
      name: client.name,
      email: client.user.email,
      companyName: company.name,
      contactPhone: client.contactPhone,
      companyTaxId: company.taxId!,
      user: client.user.id,
      clientPipelineStatus: clientPipelineStatus,
      internalPipelineStatus: company.productStatus.readyForFactoring
        ? 'closed'
        : getInternalPipelineStatus(factoringRequest),
      statusTracker: statusTrackerMap[clientPipelineStatus],
      leadId: 'dummy',
      country: getCountryKey(company.country),
      referralId: 'dummy',
      referralName: 'dummy',
      companies: transformCompanies(client.companies),
      stage: 'dummy' as any, // TODO: map?
      status: 'dummy' as any, // TODO: map?
      uuid: client.uuid,
    },
    newCompany: company,
    newUserPreferences: client.user.preferences,
  };
}

export const loadClient = createAsyncThunk('client/loadClient', async () => {
  const companyUser = await service.currentUserDetail();
  const clientData = await transformCompanyUser(companyUser);
  return { ...clientData, newCompanyUser: companyUser };
});

export const updateClient = createAsyncThunk(
  'client/updateClient',
  async (payload: { name: string; contactPhone: string }) => {
    const client = await service.updateClient(payload);
    return client;
  }
);

export const updateCompany = createAsyncThunk(
  'client/updateCompany',
  async ({
    companyId,
    payload,
  }: {
    companyId: number;
    payload: Partial<{
      addressStreetOne: string;
      addressZipCode: string;
      addressCity: string;
      addressState: string;
    }>;
  }) => {
    const company = await service.updateCompany(companyId, payload);
    return company;
  }
);

// Admin data
export const adminGetClientBuyerCreditChecks = createAsyncThunk(
  'client/getClientBuyerCreditChecks',
  async (id: number) => {
    return await adminService.adminGetBuyerCreditCheckByClientId(id);
  }
);

export const adminGetClientInsuranceRequests = createAsyncThunk(
  'client/getClientInsuranceRequest',
  async (id: number) => {
    return await adminService.adminGetInsuranceRequestByClientId(id);
  }
);

export const adminGetClientFXRequests = createAsyncThunk('client/getClientFXRequest', async (id: number) => {
  return await adminService.adminGetFXRequestByClientId(id);
});

export const { resetClientState, setNotificationPreferences, setFactoringActivity } = clientSlice.actions;
