import { ApprovedBankAccount, BankAccountFormValues, BankAccountRequest } from '../types/bankAccount';
import axios from './axios';

function formatPayload(values: BankAccountFormValues, files: File[]): FormData {
  const data = new FormData();
  if (values.country === 'usa') {
    data.append('country', values.country);
    data.append('company_legal_name', values.companyLegalName);
    data.append('bank_name', values.bankName);
    data.append('account_type', values.accountType);
    data.append('currency', values.currency);
    data.append('aba_routing_number', values.abaRoutingNumber);
    data.append('banking_data_type', values.bankingDataType);
    data.append('bank_account_number', values.bankAccountNumber);
    data.append('treasury_finance_email', values.treasuryFinanceEmail);
  } else if (values.country === 'mexico') {
    data.append('country', values.country);
    data.append('company_legal_name', values.companyLegalName);
    data.append('bank_name', values.bankName);
    data.append('clabe', values.clabe);
    data.append('swift_code', values.swiftCode);
    data.append('account_type', values.accountType);
    data.append('currency', values.currency);
    data.append('treasury_finance_email', values.treasuryFinanceEmail);
  } else {
    data.append('country', values.country);
    data.append('company_legal_name', values.companyLegalName);
    data.append('bank_name', values.bankName);
    data.append('swift_code', values.swiftCode);
    data.append('account_type', values.accountType);
    data.append('currency', values.currency);
    data.append('treasury_finance_email', values.treasuryFinanceEmail);
    data.append('state', values.state);
    data.append('owner_address', values.ownerAddress);
    data.append('postal_code', values.postalCode);
    data.append('bank_account_number', values.bankAccountNumber);
    data.append('city', values.city);
  }

  files.forEach((file) => {
    data.append('asset', file, file.name);
    data.append('file_name', file.name);
    data.append('asset_type', 'document');
  });

  return data;
}

export function addBankAccountRequest(companyId: number, values: BankAccountFormValues, files: File[]) {
  const formData = formatPayload(values, files);
  return axios
    .post<BankAccountRequest>(
      `/document_collection/companies/${companyId}/bank_account_creation_requests/`,
      formData
    )
    .then(({ data }) => data);
}

export function editBankAccountRequest(companyId: number, requestId: number, values: BankAccountFormValues) {
  const formData = formatPayload(values, []);
  return axios
    .patch<BankAccountRequest>(
      `/document_collection/companies/${companyId}/bank_account_creation_requests/${requestId}/`,
      formData
    )
    .then(({ data }) => data);
}

export function getBankAccountRequests(companyId: number) {
  return axios
    .get<BankAccountRequest[]>(`document_collection/companies/${companyId}/bank_account_creation_requests/`)
    .then(({ data }) => data);
}

export function getApprovedBankAccounts(companyId: number) {
  return axios
    .get<ApprovedBankAccount[]>(`companies/${companyId}/products/factoring/factoring_data/bank_information/`)
    .then(({ data }) => data);
}

export function adminGetApprovedBankAccounts(companyId: number) {
  return axios
    .get<ApprovedBankAccount[]>(
      `/admins/companies/${companyId}/products/factoring/factoring_data/bank_information/`
    )
    .then(({ data }) => data);
}

export function adminGetBankAccountRequests(companyId: number) {
  return axios
    .get<BankAccountRequest[]>(
      `admins/document_collection/companies/${companyId}/bank_account_creation_requests/`
    )
    .then(({ data }) => data);
}

export function adminApproveBankAccountRequest(companyId: number, bankAccountRequest: number) {
  return axios
    .post<BankAccountRequest>(
      `admins/document_collection/companies/${companyId}/bank_account_creation_requests/${bankAccountRequest}/approve/`
    )
    .then(({ data }) => data);
}

export function adminRejectBankAccountRequest(
  companyId: number,
  bankAccountRequest: number,
  rejectionReason: string
) {
  return axios
    .post<BankAccountRequest>(
      `admins/document_collection/companies/${companyId}/bank_account_creation_requests/${bankAccountRequest}/reject/`,
      { rejectedComment: rejectionReason, rejectAssets: false }
    )
    .then(({ data }) => data);
}

export function uploadBankAccountAssets(companyId: number, requestId: number, files: File[]) {
  const data = new FormData();
  files.forEach((file) => {
    data.append('asset', file, file.name);
    data.append('file_name', file.name);
    data.append('asset_type', 'document');
  });
  return axios.post<BankAccountRequest>(
    `document_collection/companies/${companyId}/bank_account_creation_requests/${requestId}/assets/`,
    data
  );
}

export function deleteBankAccountAsset(companyId: number, requestId: number, assetId: number) {
  return axios.delete<BankAccountRequest>(
    `document_collection/companies/${companyId}/bank_account_creation_requests/${requestId}/assets/${assetId}`
  );
}
