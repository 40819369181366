import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Chip from '../../componentsv2/Chip';
import Typography from '../../componentsv2/Typography';
import Button from '../../componentsv2/Button';
import Table from '../../componentsv2/Table';
import { APIShareholder } from '../../types/documents';
import { AddIcon, TrashIcon, EditIcon, EyeIcon } from '../../components/Icons';
import { useTranslation } from '../../hooks/i18n';
import { useState } from 'react';
import { Nullable } from '../../types';
import ModalV2 from '../../components/ModalV2';
import {
  addCompanyShareholder,
  deleteCompanyShareholder,
  editCompanyShareholder,
} from '../../services/documents';
import { statusColorsMap } from './utils';
import ErrorMessage from '../../components/ErrorMessage';
import { logEvent } from '@amplitude/analytics-browser';
import { docsCollectionEvents } from '../../constants/events';
import { TextField } from '../../componentsv2/Field';
import AddEditShareholderDrawer from './AddEditShareholderDrawer';

function getStatus(shareholders: APIShareholder[]): string {
  if (!shareholders.length) return 'PENDING';
  const status = shareholders[0].status;
  if (!!shareholders[0].rejectComment && status === 'PENDING') return 'DENIED';
  return status;
}

const Shareholders = ({
  shareholders,
  companyId,
  loadShareholders,
  isAdmin = false,
  language = 'en',
  onApprove = () => Promise.resolve(),
  onReject = () => Promise.resolve(),
}: {
  shareholders: APIShareholder[];
  companyId: number;
  loadShareholders: (companyId: number) => Promise<void>;
  isAdmin?: boolean;
  language?: string;
  onApprove?: (companyId: number, categoryId: number) => Promise<void>;
  onReject?: (companyId: number, categoryId: number, rejectionComment: string) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const [showShareholdersModal, setShowShareholdersModal] = useState(false);
  const [currentShareholder, setCurrentShareholder] = useState<Nullable<APIShareholder>>(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showRejectionConfirmationModal, setShowRejectionConfirmationModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const categoryId = shareholders.length ? shareholders[0].categoryId : null;
  const status = getStatus(shareholders);

  const openShareholderModal = (shareholder?: APIShareholder) => {
    shareholder && setCurrentShareholder(shareholder);
    setShowShareholdersModal(true);
  };

  const closeShareholderModal = () => {
    setCurrentShareholder(null);
    setShowShareholdersModal(false);
  };

  const openConfirmationModal = (shareholder?: APIShareholder) => {
    shareholder && setCurrentShareholder(shareholder);
    setShowDeleteConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setCurrentShareholder(null);
    setShowDeleteConfirmationModal(false);
  };

  return (
    <>
      <ModalV2
        isOpen={showRejectionConfirmationModal}
        handleClose={() => {
          setShowRejectionConfirmationModal(false);
          setRejectionReason('');
        }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography>
              Please add a rejection reason (in {language === 'es' ? 'spanish' : 'english'})
            </Typography>
            <TextField
              multiline
              minRows={4}
              name="reason"
              onChange={(value) => {
                setRejectionReason(value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ gap: '4px', display: 'flex', justifyContent: 'center' }}>
              <Button
                color="danger"
                onClick={() => {
                  if (rejectionReason && !!categoryId) {
                    setIsLoading(true);
                    onReject(companyId, categoryId, rejectionReason).finally(() => {
                      setIsLoading(false);
                    });
                    setRejectionReason('');
                    setShowRejectionConfirmationModal(false);
                  } else {
                    alert('Please add a rejection reason');
                  }
                }}>
                Confirm
              </Button>
              <Button
                type="outline"
                color="danger"
                onClick={() => {
                  setRejectionReason('');
                  setShowRejectionConfirmationModal(false);
                }}>
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </ModalV2>
      {showShareholdersModal && (
        <AddEditShareholderDrawer
          isAdmin={isAdmin}
          shareholder={currentShareholder}
          companyId={companyId}
          onAddShareholder={async (companyId, values, files) => {
            await addCompanyShareholder(companyId, values, files, isAdmin).then(() => {
              loadShareholders(companyId);
              closeShareholderModal();
            });
          }}
          onEditShareholder={async (companyId, shareholderId, values) => {
            await editCompanyShareholder(companyId, shareholderId, values, isAdmin).then(() => {
              loadShareholders(companyId);
              closeShareholderModal();
            });
          }}
          handleClose={() => closeShareholderModal()}
          showDrawer={showShareholdersModal}
          isAccepted={status === 'ACCEPTED'}
        />
      )}
      <ModalV2
        isOpen={showDeleteConfirmationModal}
        handleClose={() => {
          setShowDeleteConfirmationModal(false);
        }}
        size="small">
        <Grid container>
          <Grid item xs={12}>
            {currentShareholder && (
              <Typography>
                {t('client:documents.shareholders.confirmDelete', { name: currentShareholder!.fullName })}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: '4px' }}>
              <Button
                color="danger"
                onClick={() => {
                  deleteCompanyShareholder(companyId, currentShareholder!.id, isAdmin).then(() => {
                    closeConfirmationModal();
                    logEvent(docsCollectionEvents.DELETE_SHAREHOLDER);
                    loadShareholders(companyId);
                  });
                }}>
                {t('client:documents.shareholders.confirmDeleteBtn')}
              </Button>
              <Button
                type="outline"
                color="danger"
                onClick={() => {
                  closeConfirmationModal();
                }}>
                {t('client:documents.shareholders.confirmDeleteCancel')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </ModalV2>
      <Box
        sx={{
          boxShadow: '0px 10px 24px rgba(201, 210, 224, 0.3)',
          background: '#fbfcfe',
          borderRadius: '12px',
          padding: '2em',
          marginBottom: '1em',
        }}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ display: 'flex', gap: '2em', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '1.2em', fontWeight: 'bold' }}>
              {t('client:documents.shareholders.title')}
            </Typography>
            <Chip
              sx={{ ...statusColorsMap[status] }}
              label={t(`client:documents.status.${status}`)}
              size="small"
            />
            {status === 'DENIED' && <ErrorMessage errorMessage={shareholders[0].rejectComment as string} />}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: 'flex', justifyContent: { xs: 'initial', md: 'flex-end' } }}>
            {isAdmin && (
              <>
                <Button
                  type="link"
                  disabled={!categoryId || isLoading}
                  color="danger"
                  onClick={() => {
                    setShowRejectionConfirmationModal(true);
                    logEvent(docsCollectionEvents.REJECT_DOC, { Name: 'SHAREHOLDERS' });
                  }}>
                  Reject
                </Button>
                <Button
                  type="link"
                  disabled={!categoryId || isLoading}
                  onClick={() => {
                    if (categoryId) {
                      if (
                        !window.confirm(
                          'Are you sure you want to approve Shareholders? This operation is not reversible.'
                        )
                      )
                        return;
                      setIsLoading(true);
                      onApprove(companyId, categoryId).finally(() => {
                        setIsLoading(false);
                      });
                      logEvent(docsCollectionEvents.APPROVE_DOC, { Name: 'SHAREHOLDERS' });
                    }
                  }}>
                  Approve
                </Button>
              </>
            )}
            <Box
              sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingLeft: '2em' }}>
              <Button
                isFullHeight={false}
                type="outline"
                color="secondary"
                startIcon={<AddIcon />}
                disabled={status === 'ACCEPTED'}
                onClick={() => {
                  logEvent(docsCollectionEvents.OPEN_ADD_SHAREHOLDER_MODAL);
                  openShareholderModal();
                }}>
                {t('client:documents.buttons.add')}
              </Button>
            </Box>
          </Grid>
          {shareholders.length > 0 && (
            <Grid item xs={12}>
              <Table
                columns={[
                  {
                    field: 'fullName',
                    headerName: t('client:documents.shareholders.fullName.label'),
                  },
                  {
                    field: 'personType',
                    headerName: t('client:documents.shareholders.personType.label'),
                    renderCell: ({ personType }: APIShareholder) => {
                      return <Typography>{t(`client:documents.shareholders.${personType}`)}</Typography>;
                    },
                  },
                  {
                    field: 'percentageShare',
                    headerName: t('client:documents.shareholders.percentageShare.label'),
                    renderCell: (shareholder) => <Typography>{shareholder.percentageShare}%</Typography>,
                  },
                  {
                    field: 'personIdNumber',
                    headerName: t('client:documents.shareholders.personIdNumber.label'),
                  },
                  {
                    field: 'email',
                    headerName: t('client:documents.shareholders.email.label'),
                  },
                  {
                    headerName: t('client:documents.shareholders.actions'),
                    renderCell: (shareholder) => {
                      return (
                        <>
                          <IconButton
                            onClick={() => {
                              logEvent(docsCollectionEvents.OPEN_EDIT_SHAREHOLDER_MODAL, {
                                Name: shareholder.fullName,
                              });
                              openShareholderModal(shareholder);
                            }}>
                            {status === 'ACCEPTED' ? <EyeIcon /> : <EditIcon />}
                          </IconButton>
                          <IconButton
                            disabled={status === 'ACCEPTED'}
                            onClick={() => {
                              openConfirmationModal(shareholder);
                            }}>
                            <TrashIcon />
                          </IconButton>
                        </>
                      );
                    },
                  },
                ]}
                data={shareholders}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default Shareholders;
