import { useEffect } from 'react';
import { logEvent } from '@amplitude/analytics-browser';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PageHeader from '../../componentsv2/PageHeader';
import Layout from '../../componentsv2/Layout';
import Typography from '../../componentsv2/Typography';
import Button from '../../componentsv2/Button';
import { useTranslation } from '../../hooks/i18n';
import { ArrowRightIcon, TickCircleIcon } from '../../components/Icons';
import { COLORS } from '../../themev2';
import BuyerCreditCheckForm from './BuyerCreditCheckForm';
import { buyerCheckEvents } from '../../constants/events';
import classes from './BuyerCreditCheck.module.scss';

const reasonsToApply = [
  { key: 0, title: 'creditIssues' },
  { key: 1, title: 'free' },
  { key: 2, title: 'time' },
];

const BuyerCreditCheck = () => {
  const { t } = useTranslation(['buyerCreditCheck']);

  useEffect(() => {
    logEvent(buyerCheckEvents.VIEW_PAGE);
  }, []);

  return (
    <Layout>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <PageHeader title={t('title')} subtitle={t('subtitle')} />
        </Grid>
        <Grid item xs={12} lg={7}>
          <Box className={classes.formWrapper}>
            <Grid container>
              <Grid item xs={12}>
                <Typography>{t('form.subheading')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <BuyerCreditCheckForm />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Box className={classes.boxWrapper}>
            <Grid container>
              <Grid item xs={12}>
                <svg
                  width="101"
                  height="101"
                  viewBox="0 0 101 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M69.5857 94.1792C67.8773 94.1792 66.4607 92.7625 66.4607 91.0542V13.9708C66.4607 12.2625 67.8773 10.8458 69.5857 10.8458C71.294 10.8458 72.7107 12.2625 72.7107 13.9708V91.0542C72.7107 92.7625 71.294 94.1792 69.5857 94.1792Z"
                    fill="#FDFDFD"
                  />
                  <path
                    opacity="0.4"
                    d="M25.419 85.8459H58.7436V19.1792H25.419C15.7655 19.1792 7.94659 26.6375 7.94659 35.8459V69.1792C7.94659 78.3875 15.7655 85.8459 25.419 85.8459ZM75.6523 85.8459H71.2842V19.1792H75.6523C85.3058 19.1792 93.1247 26.6375 93.1247 35.8459V69.1792C93.1247 78.3875 85.3058 85.8459 75.6523 85.8459Z"
                    fill="#FDFDFD"
                  />
                  <path
                    d="M27.8646 63.6277C27.163 63.6277 26.4614 63.3692 25.9075 62.8153L20.3682 57.2761C19.8532 56.7549 19.5643 56.0517 19.5643 55.3189C19.5643 54.5861 19.8532 53.8829 20.3682 53.3617C21.4391 52.2908 23.2117 52.2908 24.2826 53.3617L27.8646 56.9437L42.4703 42.2006C43.5412 41.1297 45.3137 41.1297 46.3846 42.2006C47.4556 43.2716 47.4556 45.0441 46.3846 46.115L29.8218 62.8153C29.2679 63.3692 28.5663 63.6277 27.8646 63.6277Z"
                    fill="#FDFDFD"
                  />
                </svg>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ color: COLORS.brand.white, fontWeight: '700' }}>
                  {t('infoBox.title')}
                </Typography>
              </Grid>
              {reasonsToApply.map(({ key, title }) => (
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: '19px' }} key={key}>
                  <TickCircleIcon />
                  <Typography variant="body1" sx={{ color: COLORS.brand.paper }}>
                    {t(`infoBox.reasons.${title}`)}
                  </Typography>
                </Grid>
              ))}
              <Grid item xs={12}>
                <a
                  href="https://www.holamarco.co/buyers-check"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none' }}>
                  <Button color="white" endIcon={<ArrowRightIcon />}>
                    {t('infoBox.buttonTitle')}
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default BuyerCreditCheck;
