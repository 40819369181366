import React from 'react';
import Loginv2 from '../viewsv2/Login';
import Signupv2 from '../viewsv2/Signup';
import NotFound from '../views/NotFound';
import RegisterConfirmationv2 from '../viewsv2/RegisterConfirmation';
import ResetPasswordv2 from '../viewsv2/ResetPassword';
import CreatePasswordv2 from '../viewsv2/CreatePassword';
import InvoiceFinancing from '../viewsv2/InvoiceFinancing';
import ApplicationV2 from '../viewsv2/Application';
import DashboardV2 from '../viewsv2/Dashboard';
import USLLCV2 from '../viewsv2/USLLC';
import BuyerCreditCheckV2 from '../viewsv2/BuyerCreditCheck';
import InsurancesV2 from '../viewsv2/Insurances';
import FXV2 from '../viewsv2/FX';
import EmailVerificationv2 from '../viewsv2/EmailVerification';
import ClientDocuments from '../viewsv2/ClientDocuments';
import MyAccount from '../viewsv2/MyAccount';
import InvitationSignup from '../viewsv2/InvitationSignup';
import MyAccountAdmin from '../viewsv2/MyAccountAdmin';
import ContactPage from '../viewsv2/ContactPage';
// TODO: Lazy load some more pages?

import {
  CLIENT,
  DASHBOARD,
  NOT_FOUND,
  APPLICATION,
  BUYER_CREDIT_CHECK,
  INSURANCES,
  LLC,
  CURRENCY_EXCHANGE,
  VERIFY_EMAIL_SENT,
  LOGIN,
  RESET_PASSWORD,
  EMAIL_VERIFICATION,
  CREATE_NEW_PASSWORD,
  SIGNUP,
  INVOICE_FINANCING,
  MY_DOCUMENTS,
  MY_ACCOUNT,
  BUYER_SUMMARY,
  ADMIN_DASHBOARD,
  INVITATION_SIGNUP,
  INVOICES,
  MY_ACCOUNT_ADMIN,
  CONTACT,
  BANKING_CONNECTED_ACCOUNTS,
  BANKING_CONNECTED_ACCOUNT_DETAIL,
  PAYMENTS,
  BANKING_REPORTS,
  REPORTING,
  TRACKING,
  TRACK_INVOICE_ROUTE,
  POST_CC,
  POST_CC_DETAIL,
  BANKING,
  BANKING_ACCOUNT_DETAIL,
  BANKING_TRANSACTIONS,
  BANKING_RECENT_TRANSACTIONS,
  PAYMENT_DETAILS,
  LOGS,
  BANKING_RECIPIENTS,
  BANKING_ADD_RECIPIENTS,
  BANKING_TRANSFERS,
  TRANSFERS,
  TRANSFER_DETAILS
} from '../constants/auth';

const BankingMetrics = React.lazy(() => import('../viewsv2/BankingMetrics'));
const BankingPage = React.lazy(() => import('../viewsv2/BankingPage'));
const BankingPageAccountDetail = React.lazy(() => import('../viewsv2/BankingPageAccountDetail'));
const BankingPageTransactions = React.lazy(() => import('../viewsv2/BankingPageTransactions'));
const BankingRecentTransactions = React.lazy(() => import('../viewsv2/BankingRecentTransactions'));
const AuditLogs = React.lazy(() => import('../viewsv2/AuditLogs'));
const BankingPageRecipients = React.lazy(() => import('../viewsv2/BankingPageRecipients'));
const BankingPageAddRecipients = React.lazy(() => import('../viewsv2/BankingPageAddRecipients'));
const BankingPageTransfers = React.lazy(() => import('../viewsv2/BankingPageTransfers'));
const ClientV2 = React.lazy(() => import('../viewsv2/Client'));
const BuyerSummary = React.lazy(() => import('../viewsv2/BuyerSummary'));
const Invoices = React.lazy(() => import('../viewsv2/Invoices'));
const Transfers = React.lazy(() => import('../viewsv2/Transfers'));
const Payments = React.lazy(() => import('../viewsv2/Payments'));
const PaymentDetails = React.lazy(() => import('../viewsv2/PaymentDetails'));
const Reporting = React.lazy(() => import('../viewsv2/Reporting'));
const BankingConnectedAccounts = React.lazy(() => import('../viewsv2/BankingConnectedAccounts'));
const BankingConnectedAccountDetail = React.lazy(() => import('../viewsv2/BankingConnectedAccountDetail'));
const Tracking = React.lazy(() => import('../viewsv2/Tracking'));
const TrackShipment = React.lazy(() => import('../viewsv2/TrackShipment'));
const PostCreditCommittee = React.lazy(() => import('../viewsv2/PostCreditCommittee'));
const PostCreditCommitteeFlowDetail = React.lazy(() => import('../viewsv2/PostCreditCommitteeFlowDetail'));
const TransferDetails = React.lazy(() => import('../viewsv2/TransferDetails'));

export const publicRoutesV2 = [
  { path: VERIFY_EMAIL_SENT, component: <RegisterConfirmationv2 /> },
  { path: LOGIN, component: <Loginv2 /> },
  { path: RESET_PASSWORD, component: <ResetPasswordv2 /> },
  { path: EMAIL_VERIFICATION, component: <EmailVerificationv2 /> },
  { path: CREATE_NEW_PASSWORD, component: <CreatePasswordv2 /> },
  { path: SIGNUP, component: <Signupv2 /> },
  { path: INVITATION_SIGNUP, component: <InvitationSignup /> },
  { path: NOT_FOUND, component: <NotFound /> },
];

export const allAccessibleRoutes = [{ path: TRACK_INVOICE_ROUTE, component: <TrackShipment /> }];

export const clientRoutesV2 = [
  { path: INVOICE_FINANCING, component: <InvoiceFinancing /> },
  { path: APPLICATION, component: <ApplicationV2 /> },
  { path: DASHBOARD, component: <DashboardV2 /> },
  { path: LLC, component: <USLLCV2 /> },
  { path: BUYER_CREDIT_CHECK, component: <BuyerCreditCheckV2 /> },
  { path: INSURANCES, component: <InsurancesV2 /> },
  { path: CURRENCY_EXCHANGE, component: <FXV2 /> },
  { path: MY_DOCUMENTS, component: <ClientDocuments /> },
  { path: BUYER_SUMMARY, component: <BuyerSummary /> },
  { path: INVOICES, component: <Invoices /> },
  { path: MY_ACCOUNT, component: <MyAccount /> },
  { path: CONTACT, component: <ContactPage /> },
  { path: TRANSFERS, component: <Transfers /> },
  { path: PAYMENTS, component: <Payments /> },
  { path: PAYMENT_DETAILS, component: <PaymentDetails /> },
  { path: REPORTING, component: <Reporting /> },
  { path: BANKING, component: <BankingPage /> },
  { path: BANKING_ACCOUNT_DETAIL, component: <BankingPageAccountDetail /> },
  { path: BANKING_TRANSACTIONS, component: <BankingPageTransactions /> },
  { path: BANKING_RECIPIENTS, component: <BankingPageRecipients /> },
  { path: BANKING_ADD_RECIPIENTS, component: <BankingPageAddRecipients /> },
  { path: BANKING_TRANSFERS, component: <BankingPageTransfers /> },
  { path: TRANSFER_DETAILS, component: <TransferDetails /> },
];

export const adminRoutesV2 = [
  { path: CLIENT, component: <ClientV2 /> },
  { path: ADMIN_DASHBOARD, component: <DashboardV2 /> },
  { path: MY_ACCOUNT_ADMIN, component: <MyAccountAdmin /> },
  { path: BANKING_CONNECTED_ACCOUNTS, component: <BankingConnectedAccounts /> },
  { path: BANKING_CONNECTED_ACCOUNT_DETAIL, component: <BankingConnectedAccountDetail /> },
  { path: BANKING_REPORTS, component: <BankingMetrics /> },
  { path: BANKING_RECENT_TRANSACTIONS, component: <BankingRecentTransactions /> },
  { path: TRACKING, component: <Tracking /> },
  { path: POST_CC, component: <PostCreditCommittee /> },
  { path: POST_CC_DETAIL, component: <PostCreditCommitteeFlowDetail /> },
  { path: LOGS, component: <AuditLogs /> },
];
