export const withdrawalCurrencyOptions = ['USD', 'CAD', 'MXN', 'PEN', 'COP', 'CLP', 'EUR', 'AED'] as const;

export type WithdrawalCurrency = typeof withdrawalCurrencyOptions[number];

export const latestActivityInvoiceAmount = 3;

export const latestActivityTransfersAmount = 3;

export const latestActivityPaymentsAmount = 3;

export const nullDate = '--/--/--';

export const invoiceFilterOptions = [
  'open',
  'closed',
  'overdue',
  'pending',
  'held',
  'defaulted',
  'error',
  'openAndOverdue',
];

export const buyerFilterOptions = ['true', 'false'];

export const noaStatusFilterOptions = ['signed', 'pending', 'waived', 'sent'];

export const addBuyerPaymentTermsOptions = ['0', '15', '21', '30', '45', '60', '75', '90', '120', '120+'];

export const howLongSellingToBuyerOptions = [
  'newBuyer',
  'lessThan6Months',
  'between6And12Months',
  'between1And3Years',
  'moreThan3Years',
];

export const fundingTypeFilterOptions = ['wire', 'zero_check', 'transfer'];

export const paymentsCategoryFilterOptions = ['collection', 'adjustment'];

export const paymentsStatusFilterOptions = ['pending', 'completed'];
