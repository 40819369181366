import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

function isBodyVariant(variant?: string) {
  return variant?.includes('body');
}

export default styled(Typography)((props) => ({
  whiteSpace: 'pre-line',
  color: isBodyVariant(props.variant) ? '#3B4567' : '#283952',
}));
