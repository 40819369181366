export default function StatusIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6695 23.1668C10.6195 23.1668 10.5695 23.1668 10.5195 23.1468C6.68948 22.3668 3.57949 19.5468 2.41949 15.8068C2.29949 15.4068 2.51949 14.9868 2.90949 14.8668C3.30949 14.7468 3.72949 14.9668 3.84949 15.3568C4.84949 18.5768 7.51949 20.9968 10.8195 21.6668C11.2295 21.7468 11.4895 22.1468 11.3995 22.5568C11.3295 22.9168 11.0095 23.1668 10.6695 23.1668Z"
        fill="currentColor"
      />
      <path
        d="M22.6299 12.3472C22.2499 12.3472 21.9199 12.0572 21.8799 11.6772C21.3999 6.93719 17.4399 3.36719 12.6799 3.36719C7.90991 3.36719 3.95991 6.93719 3.47991 11.6672C3.43991 12.0772 3.07991 12.3872 2.65991 12.3372C2.24991 12.2972 1.94991 11.9272 1.98991 11.5172C2.54991 6.01719 7.14991 1.86719 12.6799 1.86719C18.2199 1.86719 22.8199 6.01719 23.3699 11.5172C23.4099 11.9272 23.1099 12.2972 22.6999 12.3372C22.6799 12.3472 22.6499 12.3472 22.6299 12.3472Z"
        fill="currentColor"
      />
      <path
        d="M14.6898 23.168C14.3398 23.168 14.0298 22.928 13.9598 22.568C13.8798 22.158 14.1398 21.768 14.5398 21.688C17.8198 21.018 20.4898 18.618 21.4998 15.418C21.6198 15.018 22.0498 14.798 22.4398 14.928C22.8398 15.048 23.0498 15.478 22.9298 15.868C21.7498 19.588 18.6498 22.378 14.8398 23.158C14.7898 23.158 14.7398 23.168 14.6898 23.168Z"
        fill="currentColor"
      />
    </svg>
  );
}
