import { lazy, useEffect, useState } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { logEvent } from '@amplitude/analytics-browser';
import { camelize } from 'humps';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import Layout from '../../componentsv2/Layout';
import Typography from '../../componentsv2/Typography';
import Button from '../../componentsv2/Button';
import InfoButton from '../../componentsv2/InfoButton';
import Section from '../../componentsv2/Section';
import StatusTracker from '../../componentsv2/StatusTracker';
import PageHeader from '../../componentsv2/PageHeader';
import Chip from '../../componentsv2/Chip';
import {
  AddIcon,
  ArrowRightIcon,
  EyeIcon,
  StarIcon,
  TickCircleIcon,
  TrashIcon,
} from '../../components/Icons';
import Modal from '../../components/ModalV2';
import Snackbar from '../../components/Snackbar';
import SimpleBackdrop from '../../components/Backdrop';
import FeedbackSubmittedIcon from '../../components/Icons/MessageSentIcon';
import { setFactoringActivity, useAppDispatch, useAppSelector } from '../../store';
import { useLazyTranslation } from '../../hooks/i18n';
import { deleteApplication, getApplications, updateProductStatus } from '../../services/application';
import { getCompanyDocumentCategories } from '../../services/documents';
import { getCreditFacilities } from '../../services/servicing';
import { APIDocumentCategory } from '../../types/documents';
import { Nullable } from '../../types';
import { APIClient, InternalPipelineStatus } from '../../types/client';
import { APIApplication } from '../../types/application';
import { getPipelineStatus } from '../../utils/helpers';
import { APPLICATION, MY_DOCUMENTS } from '../../constants/auth';
import {
  APPLICATION_APPROVED,
  APPLICATION_PENDING_SUBMIT,
  APPLICATION_STARTED,
  APPLICATION_SUBMITTED,
} from '../../constants/variables';
import { creditLineEvents, servicingEvents, termSheetEvents } from '../../constants/events';
import { COLORS } from '../../themev2';
import CreditLineModal from './CreditLineModal';
import classes from './InvoiceFinancing.module.scss';
import { formatNumericValue } from '../../utils/common';
const Servicing = lazy(() => import('./Servicing'));

const FinancingStepHeader = ({
  title,
  header,
  description,
}: {
  title: string;
  header: string;
  description: string;
}) => {
  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <Typography sx={{ color: COLORS.secondary.galacticGradient, fontSize: '16px' }} fontWeight={600}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ color: COLORS.brand.night }} fontWeight={700}>
          {header}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography sx={{ color: COLORS.gray[1200], fontSize: '16px' }}>{description}</Typography>
      </Grid>
    </Grid>
  );
};

const CustomAlert = styled(Alert)({
  '& .MuiAlert-icon': {
    color: COLORS.brand.paper,
  },
  background: '#0EA480',
  color: COLORS.brand.paper,
});

const steps = [
  {
    title: 'sendYourApp.title',
    description: 'sendYourApp.description',
    step: 1,
  },
  {
    title: 'receiveProposal.title',
    description: 'receiveProposal.description',
    step: 2,
  },
  {
    title: 'signContract.title',
    description: 'signContract.description',
    step: 3,
  },
  {
    title: 'startFinancing.title',
    description: 'startFinancing.description',
    step: 4,
  },
];

const reasonsToApply = [
  { key: 0, title: 'expediteGrowth' },
  { key: 1, title: 'anticipateWithoutRisk' },
  { key: 2, title: 'agileAndFast' },
];

const statusColorsMap: Record<string, object> = {
  onTheWay: { backgroundColor: '#E8F0FC', color: '#3B4567' },
  signed: { backgroundColor: '#E1FAED', color: '#11705F' },
  approved: { backgroundColor: '#E1FAED', color: '#11705F' },
  inReview: { backgroundColor: '#E8F0FC', color: '#3B4567' },
  denied: { backgroundColor: '#FEE3E3', color: '#930909' },
  pending: { backgroundColor: '#FCF0DB', color: '#AC8318' },
};

const proposalStatusMap: Record<InternalPipelineStatus, string> = {
  application_sent: 'notApplicable',
  application_received: 'onTheWay',
  termsheet_sent: 'pending',
  termsheet_received_and_document_collection: 'signed',
  due_dilligence: 'signed',
  credit_committee: 'signed',
  credit_committee_advanced: 'signed',
  legal: 'signed',
  onboarding: 'signed',
  closed: 'signed',
};

type InvoiceFinancingLocationState = {
  state: { openFundingRequest?: boolean };
};

const InvoiceFinancing = () => {
  const location = useLocation() as Location & InvoiceFinancingLocationState;
  const { isLoading: isLoadingTranslations, t } = useLazyTranslation(['application']);
  const { internalPipelineStatus, companies = [] } =
    useAppSelector((state) => state.client.client) || ({} as APIClient);
  const { isFactoringActive } = useAppSelector((state) => state.client);
  const dispatch = useAppDispatch();
  const newCompany = useAppSelector((state) => state.client.newCompany);
  const [application, setApplication] = useState<Nullable<APIApplication>>(null);
  const [isLoadingApp, setIsLoadingApp] = useState(true);
  const [showDeleteAppModal, setShowDeleteAppModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayDeletedAppMessage, setDisplayDeletedAppMessage] = useState(false);
  const [displaySignedMessage, setDisplaySignedAppMessage] = useState(false);
  const [displayCreditLineApprovedMessage, setDisplayCreditLineApprovedMessage] = useState(false);
  const [showCreditLineDetails, setShowCreditLineDetails] = useState(false);
  const [documentCategories, setDocumentCategories] = useState<APIDocumentCategory[]>([]);
  const [openFundingRequest, setOpenFundingRequest] = useState(location.state?.openFundingRequest ?? false);
  const navigate = useNavigate();

  const { creditCommittee, contractMpsa } = application || {};

  const camelizedInternalPipelineStatus = camelize(internalPipelineStatus || '');
  const isLoading = isLoadingTranslations || isLoadingApp;

  const pipelineStatus = internalPipelineStatus ? getPipelineStatus(internalPipelineStatus) : '';

  const existsApplication = application?.status === APPLICATION_STARTED;
  const isServicingViewable = newCompany?.productStatus.readyForFactoring;
  const finishedApp =
    isServicingViewable ||
    application?.status === APPLICATION_SUBMITTED ||
    application?.status === APPLICATION_PENDING_SUBMIT ||
    application?.status === APPLICATION_APPROVED;
  const isTermsheetViewable = pipelineStatus === 'proposal';
  const isCreditContractViewable = pipelineStatus === 'contract';

  const docsSectionStatus =
    internalPipelineStatus && ['onboarding', 'credit_committee_advanced'].includes(internalPipelineStatus)
      ? 'approved'
      : 'pending';

  const amount = formatNumericValue(creditCommittee?.creditLimit || 0, 0);

  // This effect is to clear the link state used to receive the request to open the funding drawer
  useEffect(() => {
    navigate(location.pathname, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companies.length > 0) {
      getApplications(companies[0].id)
        .then((applications) => {
          if (applications.length > 0) {
            setApplication({
              ...applications[0],
            });
          }
        })
        .finally(() => {
          setIsLoadingApp(false);
        });
    }
  }, [companies]);

  useEffect(() => {
    companies?.length > 0 &&
      getCompanyDocumentCategories(companies[0].id).then((categories) => {
        setDocumentCategories(categories);
      });
  }, [companies]);

  useEffect(() => {
    if (
      application &&
      internalPipelineStatus === 'termsheet_received_and_document_collection' &&
      typeof application.flowMetadata?.shouldDisplaySignatureModal === 'undefined'
    ) {
      setDisplaySignedAppMessage(true);
    }
  }, [isLoadingApp, internalPipelineStatus, application]);

  useEffect(() => {
    if (
      application &&
      internalPipelineStatus === 'credit_committee_advanced' &&
      typeof application.flowMetadata?.shouldDisplayCreditLineModal === 'undefined'
    ) {
      setDisplayCreditLineApprovedMessage(true);
    }
  }, [isLoadingApp, internalPipelineStatus, application]);

  useEffect(() => {
    if (newCompany && typeof application?.flowMetadata?.shouldDisplayAllSetView !== 'undefined') {
      getCreditFacilities(newCompany.id).then((facilities) => {
        if (facilities.length > 0) {
          const isActive = facilities.some((facility) => facility.status === 'active');
          dispatch(setFactoringActivity(isActive));
        }
      });
    }
  }, [newCompany, dispatch, application?.flowMetadata?.shouldDisplayAllSetView]);

  const handleOnDeleteApplication = () => {
    if (application?.id) {
      setIsSubmitting(true);
      deleteApplication(application?.id)
        .then(() => {
          setApplication(null);
          setDisplayDeletedAppMessage(true);
          setShowDeleteAppModal(false);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const handleOnCloseSignedModal = () => {
    setDisplaySignedAppMessage(false);
    const payload = {
      flowMetadata: {
        shouldDisplaySignatureModal: false,
      },
    };
    if (application) {
      updateProductStatus(newCompany!.id, newCompany!.productStatus.id, payload).then((updatedApp) => {
        logEvent(termSheetEvents.CLOSE_CONFIRMATION_MODAL);
        setApplication({ ...application, ...payload });
      });
    }
  };

  const handleOnCloseCreditLineApprovedModal = () => {
    setDisplayCreditLineApprovedMessage(false);
    if (application) {
      const payload = {
        flowMetadata: {
          ...application.flowMetadata,
          shouldDisplayCreditLineModal: false,
        },
      };
      updateProductStatus(newCompany!.id, newCompany!.productStatus.id, payload).then((updatedApp) => {
        logEvent(creditLineEvents.CLOSE_CONFIRMATION_MODAL);
        setApplication({ ...application, ...payload });
      });
    }
  };

  if (isLoading) return <SimpleBackdrop hasOpaqueBackground isLoading />;

  return (
    <Layout>
      <Grid container rowSpacing={2}>
        <Grid item xs={9}>
          <PageHeader title={t('application:title')} subtitle={t('application:subtitle')} />
        </Grid>
        {typeof application?.flowMetadata?.shouldDisplayAllSetView !== 'undefined' &&
          !application?.flowMetadata?.shouldDisplayAllSetView &&
          isFactoringActive && (
            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
              <Button
                isFullHeight={false}
                startIcon={<AddIcon />}
                onClick={() => {
                  logEvent(servicingEvents.CLICK_FUNDING_REQUEST);
                  setOpenFundingRequest(true);
                }}>
                {t('application:fundingRequestBtn')}
              </Button>
            </Grid>
          )}
        {isLoading ? (
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={350} animation="wave" />
          </Grid>
        ) : (
          <>
            {/* TODO: This condition will be refactored afterwards */}
            {isServicingViewable && (
              <Grid item xs={12}>
                <Servicing
                  flowMetadata={newCompany?.productStatus.factoringFlowMetadata!}
                  isOpenFundingRequestDrawer={openFundingRequest}
                  handleCloseFundingDrawer={() => setOpenFundingRequest(false)}
                />
              </Grid>
            )}
            {finishedApp && !isServicingViewable && (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Section>
                      <Grid container>
                        {!!internalPipelineStatus && (
                          <Grid item xs={12}>
                            <StatusTracker pipelineStatus={internalPipelineStatus} />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Divider sx={{ borderColor: COLORS.gray[600] }} />
                        </Grid>
                        <Grid item xs={12}>
                          <FinancingStepHeader
                            title={t(`application:financingStep.${camelizedInternalPipelineStatus}.title`)}
                            header={t(
                              `application:financingStep.${camelizedInternalPipelineStatus}.header`,
                              pipelineStatus === 'contract' || pipelineStatus === 'financing'
                                ? {
                                  amount,
                                }
                                : {}
                            )}
                            description={t(
                              `application:financingStep.${camelizedInternalPipelineStatus}.description`
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Section>
                  </Grid>
                  {isTermsheetViewable && !!internalPipelineStatus && (
                    <Grid item xs={12}>
                      <Section>
                        <Grid container alignItems="center">
                          <Grid item xs={12} md={2}>
                            <Chip
                              label={
                                <Typography fontWeight={600} sx={{ color: 'inherit' }}>
                                  {t(`application:statusChip.${proposalStatusMap[internalPipelineStatus]}`)}
                                </Typography>
                              }
                              sx={{ ...statusColorsMap[proposalStatusMap[internalPipelineStatus]] }}
                            />
                          </Grid>
                          <Grid item xs={12} md>
                            <Typography sx={{ fontSize: '1.2em' }} fontWeight={600}>
                              {t('application:proposalSection.title')}
                            </Typography>
                            <Typography>
                              {t(
                                `application:proposalSection.description.${proposalStatusMap[internalPipelineStatus]}`
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            sx={{
                              display: 'flex',
                              justifyContent: { xs: 'initial', md: 'flex-end' },
                              alignItems: 'center',
                              gap: '8px',
                            }}>
                            {!isTermsheetViewable && (
                              <InfoButton
                                title={
                                  <Typography sx={{ color: '#FFF' }}>
                                    {t('application:proposalSection.tooltip')}
                                  </Typography>
                                }
                                placement="left"
                              />
                            )}
                            {isTermsheetViewable ? (
                              <Button
                                disabled={!application?.proposalLink}
                                startIcon={<EyeIcon />}
                                onClick={() => {
                                  internalPipelineStatus === 'termsheet_sent'
                                    ? logEvent(termSheetEvents.VIEW_PROPOSAL)
                                    : logEvent(termSheetEvents.VIEW_SIGNED_PROPOSAL);
                                  application?.proposalLink &&
                                    window.open(application?.proposalLink, '_blank');
                                }}>
                                {t('application:proposalSection.button')}
                              </Button>
                            ) : (
                              <Typography sx={{ color: COLORS.gray[1000], fontWeight: 600 }}>
                                {t('application:comingSoon')}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Section>
                    </Grid>
                  )}
                  {documentCategories.length > 0 && isTermsheetViewable && (
                    <Grid item xs={12}>
                      <Section>
                        <Grid container alignItems="center">
                          <Grid item xs={12} md={2}>
                            <Chip
                              label={
                                <Typography fontWeight={600} sx={{ color: 'inherit' }}>
                                  {t(`application:statusChip.${docsSectionStatus}`)}
                                </Typography>
                              }
                              sx={{
                                ...statusColorsMap[docsSectionStatus],
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md>
                            <Typography sx={{ fontSize: '1.2em' }} fontWeight={600}>
                              {t('application:documentsSection.title')}
                            </Typography>
                            <Typography>{t(`application:documentsSection.description.pending`)}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            sx={{
                              display: 'flex',
                              justifyContent: { xs: 'initial', md: 'flex-end' },
                              alignItems: 'center',
                              gap: '8px',
                            }}>
                            <Button
                              onClick={() => {
                                navigate(MY_DOCUMENTS);
                              }}>
                              {t('application:documentsSection.button')}
                            </Button>
                          </Grid>
                        </Grid>
                      </Section>
                    </Grid>
                  )}
                  {isCreditContractViewable && (
                    <>
                      <Grid item xs={12}>
                        <Section>
                          <Grid container alignItems="center">
                            <Grid item xs={12} md>
                              <Typography sx={{ fontSize: '1.2em' }} fontWeight={600}>
                                {t('application:creditSection.creditLine.title')}
                              </Typography>
                              <Typography>{t(`application:creditSection.creditLine.description`)}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              sx={{
                                display: 'flex',
                                justifyContent: { xs: 'initial', md: 'flex-end' },
                                alignItems: 'center',
                                gap: '8px',
                              }}>
                              <Button
                                startIcon={<EyeIcon />}
                                disabled={!application?.creditCommittee}
                                onClick={() => {
                                  logEvent(creditLineEvents.VIEW_CREDIT_LINE_DETAILS);
                                  setShowCreditLineDetails(true);
                                }}>
                                {t('application:creditSection.creditLine.button')}
                              </Button>
                            </Grid>
                          </Grid>
                        </Section>
                      </Grid>
                      <Grid item xs={12}>
                        <Section>
                          <Grid container alignItems="center">
                            <Grid item xs={12} md={7}>
                              <Typography sx={{ fontSize: '1.2em' }} fontWeight={600}>
                                {t('application:creditSection.contract.title')}
                              </Typography>
                              <Typography>
                                {t(
                                  `application:creditSection.contract.${contractMpsa?.recipients
                                    ? 'contractSentDescription'
                                    : 'awaitingContractDescription'
                                  }`
                                )}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={5}
                              sx={{
                                display: 'flex',
                                justifyContent: { xs: 'initial', md: 'flex-end' },
                                alignItems: 'center',
                                gap: '8px',
                              }}>
                              {contractMpsa?.recipients ? (
                                <Grid container justifyContent="flex-end">
                                  {contractMpsa?.clientLink && (
                                    <Grid
                                      item
                                      xs={12}
                                      sx={{
                                        display: 'flex',
                                        justifyContent: { xs: 'initial', md: 'flex-end' },
                                      }}>
                                      <Button
                                        startIcon={<EyeIcon />}
                                        onClick={() => {
                                          logEvent(creditLineEvents.VIEW_CONTRACT);
                                          contractMpsa?.clientLink &&
                                            window.open(contractMpsa?.clientLink, '_blank');
                                        }}>
                                        {t('application:creditSection.contract.button')}
                                      </Button>
                                    </Grid>
                                  )}
                                </Grid>
                              ) : (
                                <Typography sx={{ color: COLORS.gray[1000], fontWeight: 600 }}>
                                  {t('application:comingSoon')}
                                </Typography>
                              )}
                            </Grid>
                            {internalPipelineStatus === 'legal' && contractMpsa?.recipients && (
                              <>
                                <Grid item xs={12}>
                                  <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container>
                                    {contractMpsa?.recipients?.length > 0 &&
                                      contractMpsa?.recipients.map(
                                        ({ firstName, id, email, lastName, hasCompleted }) => {
                                          const status = hasCompleted ? 'signed' : 'pending';
                                          return (
                                            <Grid item xs={12} md key={id}>
                                              <Box
                                                sx={{
                                                  padding: '1.5em',
                                                  border: '1px solid #D7E1F5',
                                                  borderRadius: '8px',
                                                  height: '100%',
                                                }}>
                                                <Chip
                                                  label={
                                                    <Typography fontWeight={600} sx={{ color: 'inherit' }}>
                                                      {t(`application:statusChip.${status}`)}
                                                    </Typography>
                                                  }
                                                  sx={{
                                                    ...statusColorsMap[status],
                                                  }}
                                                />
                                                <Typography
                                                  sx={{
                                                    fontWeight: 600,
                                                    color: '#0A1334',
                                                    fontSize: '16px',
                                                    lineHeight: '24px',
                                                  }}>
                                                  {`${firstName} ${lastName}`}
                                                </Typography>
                                                <Typography sx={{ color: '#4F5A84', wordBreak: 'break-all' }}>
                                                  {email}
                                                </Typography>
                                              </Box>
                                            </Grid>
                                          );
                                        }
                                      )}
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Section>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            )}
            {!finishedApp && (
              <>
                <Grid item xs={12} lg={7} md={8}>
                  <Box className={classes.howDoesItWorkBoxWrapper}>
                    <Grid container rowSpacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h6" fontWeight={700}>
                          {t('application:howItWorks.title')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          {steps.map(({ step, title, description }) => {
                            return (
                              <Grid item xs={12} key={step}>
                                <Grid container alignItems="center" rowSpacing={1}>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: { xs: '18px' },
                                    }}>
                                    <Chip
                                      label={step}
                                      sx={{ backgroundColor: '#18A19A', color: COLORS.brand.white }}
                                    />
                                    <Typography
                                      sx={{
                                        fontSize: '16px',
                                        color: COLORS.brand.night,
                                        fontWeight: 600,
                                        lineHeight: '24px',
                                      }}>
                                      {t(`application:howItWorks.steps.${title}`)}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sx={{ marginLeft: { xs: '50px' } }}>
                                    <Typography
                                      sx={{
                                        color: COLORS.gray[1200],
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        lineHeight: '21px',
                                      }}>
                                      {t(`application:howItWorks.steps.${description}`)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                      {existsApplication ? (
                        <Grid item xs={12} md={5}>
                          <Button
                            fullWidth
                            startIcon={<TrashIcon />}
                            type="link"
                            color="tertiary"
                            onClick={() => setShowDeleteAppModal(true)}>
                            {t(`application:howItWorks.deleteButtonTitle`)}
                          </Button>
                        </Grid>
                      ) : null}
                      <Grid item xs={12} md>
                        <Button
                          fullWidth
                          endIcon={<ArrowRightIcon />}
                          disabled={application?.isReapply && application?.status === 'application_rejected'}
                          onClick={() => {
                            navigate(APPLICATION);
                          }}>
                          {t(
                            `application:howItWorks.${existsApplication
                              ? 'continueAppButtonTitle'
                              : application?.status === 'application_rejected'
                                ? 'reapplyButtonTitle'
                                : 'submitAppButtonTitle'
                            }`
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={5} md={4}>
                  <Box className={classes.whyApplyForFinancing}>
                    <Grid container>
                      <Grid item xs={12}>
                        <svg
                          width="100"
                          height="100"
                          viewBox="0 0 100 100"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            opacity="0.4"
                            d="M67.6663 15.2H32.333C22.0413 15.2 13.708 23.575 13.708 33.825V73.0333C13.708 83.2833 22.083 91.6583 32.333 91.6583H67.6247C77.9163 91.6583 86.2497 83.2833 86.2497 73.0333V33.825C86.2913 23.5333 77.9163 15.2 67.6663 15.2Z"
                            fill="#FDFDFD"
                          />
                          <path
                            d="M59.7913 8.33325H40.208C35.8747 8.33325 32.333 11.8333 32.333 16.1666V20.0833C32.333 24.4166 35.833 27.9166 40.1663 27.9166H59.7913C64.1247 27.9166 67.6247 24.4166 67.6247 20.0833V16.1666C67.6663 11.8333 64.1247 8.33325 59.7913 8.33325ZM45.0413 70.6249C44.2497 70.6249 43.458 70.3333 42.833 69.7083L36.583 63.4583C36.0018 62.8702 35.6759 62.0767 35.6759 61.2499C35.6759 60.4231 36.0018 59.6297 36.583 59.0416C37.7913 57.8333 39.7913 57.8333 40.9997 59.0416L45.0413 63.0833L59.4997 48.6249C60.708 47.4166 62.708 47.4166 63.9163 48.6249C65.1247 49.8333 65.1247 51.8333 63.9163 53.0416L47.2497 69.7083C46.6663 70.3333 45.833 70.6249 45.0413 70.6249Z"
                            fill="#FDFDFD"
                          />
                        </svg>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" sx={{ color: COLORS.brand.white, fontWeight: '700' }}>
                          {t('application:whyApply.title')}
                        </Typography>
                      </Grid>
                      {reasonsToApply.map(({ key, title }) => (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: 'flex', alignItems: 'center', gap: '19px' }}
                          key={key}>
                          <TickCircleIcon />
                          <Typography variant="body1" sx={{ color: COLORS.brand.paper }}>
                            {t(`application:whyApply.${title}`)}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
      <Snackbar open={displayDeletedAppMessage} handleOnClose={() => setDisplayDeletedAppMessage(false)}>
        <CustomAlert severity="success" className={classes.alertMessage}>
          {t('application:deleteAppModal.successDeletionMessage')}
        </CustomAlert>
      </Snackbar>
      <Modal
        handleClose={() => setShowDeleteAppModal(false)}
        isOpen={showDeleteAppModal}
        displayCloseButton={false}>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" fontWeight={600}>
              {t('application:deleteAppModal.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ color: '#66758F' }} variant="body1">
              {t('application:deleteAppModal.description')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ borderColor: '#DDE6F6' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              type="outline"
              onClick={() => {
                setShowDeleteAppModal(false);
              }}>
              {t('application:deleteAppModal.cancelButtonTitle')}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button fullWidth color="danger" onClick={handleOnDeleteApplication} isLoading={isSubmitting}>
              {t('application:deleteAppModal.submitButtonTitle')}
            </Button>
          </Grid>
        </Grid>
      </Modal>
      {application?.creditCommittee && (
        <CreditLineModal
          creditCommittee={application?.creditCommittee}
          isOpen={showCreditLineDetails}
          handleClose={() => {
            setShowCreditLineDetails(false);
          }}
        />
      )}
      <Modal handleClose={handleOnCloseSignedModal} isOpen={displaySignedMessage}>
        <Grid container spacing={2}>
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <FeedbackSubmittedIcon />
          </Grid>
          <Grid item xs={12}>
            <Typography
              textAlign="center"
              variant="h5"
              sx={{ color: '#283952', fontWeight: '700', fontSize: '24px' }}>
              {t('application:signedModal.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="center" sx={{ color: '#71819C' }}>
              {t('application:signedModal.message')}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '24px' }}>
            <Button fullWidth color="secondary" onClick={handleOnCloseSignedModal}>
              {t('application:signedModal.button')}
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        handleClose={handleOnCloseCreditLineApprovedModal}
        isOpen={displayCreditLineApprovedMessage}
        isAutoHeight>
        <Grid container spacing={2}>
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <StarIcon />
          </Grid>
          <Grid item xs={12}>
            <Typography
              textAlign="center"
              variant="h5"
              sx={{ color: '#283952', fontWeight: '700', fontSize: '24px' }}>
              {t('application:creditLineConfirmationModal.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              textAlign="center"
              variant="h2"
              fontWeight={700}
              sx={{
                background:
                  'linear-gradient(74.44deg, #4E57D3 -10.53%, #2D95CD 46.22%, #2D96CD 46.23%, #2D96CC 46.24%, #2EC4B6 98.43%)',
                backgroundClip: 'text',
                '-webkit-background-clip': 'text',
                color: 'transparent',
              }}>
              {t('application:creditLineConfirmationModal.amount', { amount })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="center" sx={{ color: '#71819C' }}>
              {t('application:creditLineConfirmationModal.message')}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '24px' }}>
            <Button fullWidth color="secondary" onClick={handleOnCloseCreditLineApprovedModal}>
              {t('application:creditLineConfirmationModal.button')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="center" sx={{ color: '#71819C', fontSize: '12px' }}>
              {t('application:creditLineConfirmationModal.creditDisclaimer')}
            </Typography>
          </Grid>
        </Grid>
      </Modal>
    </Layout>
  );
};

export default InvoiceFinancing;
