import { useEffect } from 'react';
import { logEvent } from '@amplitude/analytics-browser';
import { Formik, FormikProps } from 'formik';
import { Trans } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '../../componentsv2/Typography';
import FormSection from '../../componentsv2/FormSection';
import { RadioField, SelectField, TextField } from '../../componentsv2/Field';
import MultiSelectField from '../../componentsv2/Field/MultiSelectField';
import Button from '../../componentsv2/Button';
import Form from '../../componentsv2/Form';
import { useTranslation } from '../../hooks/i18n';
import { useAppSelector } from '../../store';
import { EmptyString, Nullable, SignupCountryKeys } from '../../types';
import { ApplicationStepProps, GenericRadioOptions } from '../../types/application';
import { CompanyAndBusinessLiteSchema } from '../../schemas';
import { mapCountryFromBackend } from '../../utils/helpers';
import { backendCountryValues, getCountries, regions, sectors } from '../../constants/data';
import { filesURL } from '../../constants/auth';
import { esgOptions, qualityChecksOptions, typicalIncoTermsOptions } from '../../constants/application';
import { invoiceEvents } from '../../constants/events';

export type CompanyAndBusinessLiteState = {
  companyName?: string;
  companyTaxId?: string;
  addressCountry?: EmptyString<SignupCountryKeys>;
  yearCompanyWasFounded?: Nullable<number>;
  esg?: string[];
  companyWebsite?: string;
  companyContactEmail?: string;
  companyContactName?: string;
  companyContactPhone?: string;
  sector: string;
  incoTerms: string[];
  businessRegions: string[];
  practiceBillingsOrConsignments: Nullable<GenericRadioOptions>;
  qualityChecks: string;
  operatesWithCreditInsurance: Nullable<GenericRadioOptions>;
  insuranceProvider?: Nullable<string>;
  usingFinancialInstitutions: Nullable<GenericRadioOptions>;
  financialInstitution?: Nullable<string>;
  financialInstitutionType?: Nullable<string>;
};

const CompanyAndBusinessStepLite = ({
  onStepSubmit,
  title,
  subtitle,
  applicationDraft,
}: ApplicationStepProps) => {
  const { t } = useTranslation('application');
  const client = useAppSelector((state) => state.client.client);

  useEffect(() => {
    logEvent(invoiceEvents.STEP_ONE);
  }, []);

  const mappedCountry = applicationDraft?.addressCountry
    ? mapCountryFromBackend(applicationDraft.addressCountry)
    : '';

  const initialValues: CompanyAndBusinessLiteState = {
    companyName: applicationDraft?.companyName ?? '',
    companyTaxId: applicationDraft?.companyTaxId ?? '',
    addressCountry: mappedCountry,
    yearCompanyWasFounded: applicationDraft.yearCompanyWasFounded ?? null,
    esg: applicationDraft?.esg ?? [],
    companyWebsite: applicationDraft?.companyWebsite ?? '',
    companyContactEmail: applicationDraft?.companyContactEmail ?? '',
    companyContactName: applicationDraft?.companyContactName ?? '',
    companyContactPhone: applicationDraft?.companyContactPhone ?? '',
    sector: applicationDraft?.sector ?? '',
    incoTerms: applicationDraft?.incoTerms ?? [],
    businessRegions: applicationDraft?.businessRegions ?? [],
    practiceBillingsOrConsignments: applicationDraft?.practiceBillingsOrConsignments ?? null,
    qualityChecks: applicationDraft?.qualityChecks ?? '',
    operatesWithCreditInsurance: applicationDraft.operatesWithCreditInsurance ?? null,
    insuranceProvider: applicationDraft.insuranceProvider ?? '',
    usingFinancialInstitutions: applicationDraft.operatesWithCreditInsurance ?? null,
    financialInstitution: applicationDraft.financialInstitution ?? '',
    financialInstitutionType: applicationDraft.financialInstitutionType ?? '',
  };

  const handleOnSubmit = (values: CompanyAndBusinessLiteState) => {
    const mapValuesForBackend = {
      ...values,
      addressCountry: values['addressCountry'] ? backendCountryValues[values['addressCountry']] : '',
    };
    if (values.usingFinancialInstitutions === 'no') {
      delete mapValuesForBackend.financialInstitution;
      delete mapValuesForBackend.financialInstitutionType;
    }
    if (values.operatesWithCreditInsurance === 'no') {
      delete mapValuesForBackend.insuranceProvider;
    }

    return onStepSubmit(mapValuesForBackend);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={CompanyAndBusinessLiteSchema(t)}
        onSubmit={handleOnSubmit}
        enableReinitialize>
        {({ isValid, errors, values, setFieldValue }: FormikProps<CompanyAndBusinessLiteState>) => {
          return (
            <Form trackingEvent={invoiceEvents.EDIT_FIELD} name="companyData" id="companyData">
              <FormSection title={t('sections.generalInformation.title')}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled
                      name="companyName"
                      placeholder={t('sections.generalInformation.fields.companyLegalNamePlaceholder')}
                      label={t('sections.generalInformation.fields.companyLegalNameLabel')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="companyTaxId"
                      placeholder={t('sections.generalInformation.fields.rfcPlaceholder')}
                      label={t('sections.generalInformation.fields.rfcLabel')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectField
                      disabled
                      name="addressCountry"
                      label={t('sections.generalInformation.fields.countryLabel')}
                      options={getCountries(t).map(({ countryKey, country }) => ({
                        value: countryKey,
                        label: country,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      optional
                      name="companyWebsite"
                      placeholder={t('sections.generalInformation.fields.websitePlaceholder')}
                      label={t('sections.generalInformation.fields.websiteLabel')}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: '3px' }}>
                    <TextField
                      type="number"
                      name="yearCompanyWasFounded"
                      placeholder={t('sections.generalInformation.fields.yearCompanyWasFoundedPlaceholder')}
                      label={t('sections.generalInformation.fields.yearCompanyWasFoundedLabel')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultiSelectField
                      name="esg"
                      label={t('sections.generalInformation.fields.esgLabel')}
                      placeholder={t('sections.generalInformation.fields.esgPlaceholder')}
                      options={esgOptions.map((value: string) => ({
                        value,
                        label: t(`application:sections.generalInformation.fields.esgOptions.${value}`),
                      }))}
                      moreInfoTooltip={
                        <Trans t={t} i18nKey={'sections.generalInformation.fields.esgTooltip'}>
                          <Typography sx={{ color: '#FFF' }}>
                            ESG criteria are a set of rules to follow when making business decisions. They
                            refer to activities that impact the environment, the company's relationship with
                            society, and the way in which the company is managed together with compliance with
                            regulations.
                          </Typography>
                          <Typography sx={{ color: '#FFF' }}>
                            Compliance with these criteria has no impact on the approval of the financing
                            line.
                          </Typography>
                        </Trans>
                      }
                    />
                  </Grid>
                </Grid>
              </FormSection>
              <FormSection title={t('sections.contactInformation.title')}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="companyContactName"
                      placeholder={t('sections.contactInformation.fields.fullNamePlaceholder')}
                      label={t('sections.contactInformation.fields.fullNameLabel')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled
                      name="companyContactEmail"
                      placeholder={t('sections.contactInformation.fields.emailPlaceholder')}
                      label={t('sections.contactInformation.fields.emailLabel')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="companyContactPhone"
                      placeholder={t('sections.contactInformation.fields.phoneNumberPlaceholder')}
                      label={t('sections.contactInformation.fields.phoneNumberLabel')}
                    />
                  </Grid>
                </Grid>
              </FormSection>
              <FormSection title={t('sections.businessInformation.title')}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <SelectField
                      name="sector"
                      label={t('sections.businessInformation.fields.sectorLabel')}
                      placeholder={t('sections.businessInformation.fields.sectorPlaceholder')}
                      options={sectors.map((value) => ({
                        value,
                        label: t(`sections.businessInformation.fields.sectorOptions.${value}`),
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultiSelectField
                      name="incoTerms"
                      label={
                        <>
                          {t('sections.businessInformation.fields.incoTermsLabelFirst')}
                          <a
                            href={`${filesURL}/Incoterms.png`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}>
                            {t('sections.businessInformation.fields.incoTermsLabelSecond')}
                          </a>
                          {t('sections.businessInformation.fields.incoTermsLabelThird')}
                        </>
                      }
                      placeholder={t('sections.businessInformation.fields.incoTermsPlaceholder')}
                      options={typicalIncoTermsOptions.map((value: string) => ({
                        value,
                        label: t(`application:sections.businessInformation.fields.incoTermsOptions.${value}`),
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultiSelectField
                      name="businessRegions"
                      label={t('sections.businessInformation.fields.regionsLabel')}
                      placeholder={t('sections.businessInformation.fields.regionsPlaceholder')}
                      options={regions.map((value) => ({
                        value,
                        label: t(`sections.businessInformation.fields.regionsOptions.${value}`),
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectField
                      name="qualityChecks"
                      label={t('sections.businessInformation.fields.qualityChecksLabel')}
                      placeholder={t('sections.businessInformation.fields.qualityChecksPlaceholder')}
                      options={qualityChecksOptions.map((value: string) => ({
                        value,
                        label: t(
                          `application:sections.businessInformation.fields.qualityChecksOptions.${value}`
                        ),
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RadioField
                      name="practiceBillingsOrConsignments"
                      label={t('sections.businessInformation.fields.sellOnConsignmentLabel')}
                      options={[
                        { label: t('common:yes'), value: 'yes' },
                        { label: t('common:no'), value: 'no' },
                      ]}
                      moreInfoTooltip={
                        <Typography sx={{ color: '#FFF' }}>
                          {t('sections.businessInformation.fields.sellOnConsignmentTooltip')}
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: values.operatesWithCreditInsurance === 'yes' ? '-1em' : '0' }}>
                    <RadioField
                      name="operatesWithCreditInsurance"
                      label={t('sections.businessInformation.fields.companyOperatesWithCreditInsuranceLabel')}
                      options={[
                        { label: t('common:yes'), value: 'yes' },
                        { label: t('common:no'), value: 'no' },
                      ]}
                    />
                  </Grid>
                  {values.operatesWithCreditInsurance === 'yes' && (
                    <Grid item xs={12}>
                      <TextField
                        name="insuranceProvider"
                        placeholder={t('sections.businessInformation.fields.insuranceProviderPlaceholder')}
                        label={t('sections.businessInformation.fields.insuranceProviderLabel')}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: values.usingFinancialInstitutions === 'yes' ? '-1em' : '0' }}>
                    <RadioField
                      name="usingFinancialInstitutions"
                      label={t('sections.businessInformation.fields.usingFinancialInstitutionsLabel', {
                        business: client?.companyName,
                      })}
                      options={[
                        { label: t('common:yes'), value: 'yes' },
                        { label: t('common:no'), value: 'no' },
                      ]}
                    />
                  </Grid>
                  {values.usingFinancialInstitutions === 'yes' && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          name="financialInstitution"
                          placeholder={t(
                            'sections.businessInformation.fields.financialInstitutionPlaceholder'
                          )}
                          label={t('sections.businessInformation.fields.financialInstitutionLabel')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="financialInstitutionType"
                          placeholder={t(
                            'sections.businessInformation.fields.financialInstitutionTypePlaceholder'
                          )}
                          label={t('sections.businessInformation.fields.financialInstitutionTypeLabel')}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </FormSection>
              <Grid container justifyContent="right" sx={{ marginTop: '2em' }}>
                <Grid item xs={12} md={12} sx={{ display: 'flex', gap: '1em' }}>
                  <Button isSubmit disabled={!isValid}>
                    {t('steps.company.submitBtn')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CompanyAndBusinessStepLite;
