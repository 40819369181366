import Grid from '@mui/material/Grid';
import Button, { ButtonProps } from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';

type ScoreButtonProps = ButtonProps & {
  isSelected?: boolean;
  buttonColor?: string;
};

const ScoreButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'buttonColor',
})<ScoreButtonProps>(({ isSelected, buttonColor }) => ({
  borderColor: buttonColor,
  color: buttonColor,
  width: '100%',
  ':hover': {
    backgroundColor: buttonColor,
    color: 'white',
    borderColor: buttonColor,
  },
  ...(isSelected && {
    backgroundColor: buttonColor,
    color: 'white',
  }),
}));

type Score = {
  value: number;
  color: string;
};

type EaseScoreProps = {
  handleOnChange: (newValue: number | null) => void;
  value: number | null;
  difficultLabel: string;
  easyLabel: string;
  scores?: Score[];
};

export const defaultScores = [
  { value: 1, color: '#930909' },
  { value: 2, color: '#C54646' },
  { value: 3, color: '#AC8318' },
  { value: 4, color: '#0EA480' },
  { value: 5, color: '#11705F' },
];

const EaseScore = ({
  scores = defaultScores,
  handleOnChange,
  value,
  difficultLabel,
  easyLabel,
}: EaseScoreProps) => {
  const handleChange = (newValue: number | null) => {
    if (newValue !== value) handleOnChange(newValue);
    else handleOnChange(null);
  };
  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12} sx={{ display: 'flex', gap: '10px' }}>
        {scores.map(({ value: scoreValue, color }) => (
          <ScoreButton
            key={scoreValue}
            isSelected={value === scoreValue}
            buttonColor={color}
            variant="outlined"
            onClick={() => handleChange(scoreValue)}>
            {scoreValue}
          </ScoreButton>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#B4C4DC' }} />
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="subtitle1" sx={{ color: '#930909' }}>
          {difficultLabel}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: '#11705F' }}>
          {easyLabel}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EaseScore;
