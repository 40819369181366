import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import { login as loginThunk, logout as logoutThunk, loadUser as loadUserThunk } from '../store';
import { Credentials, Nullable, User } from '../types';
import { ACCESS_TOKEN_STORAGE_KEY } from '../constants/auth';

type AuthHookResponse = {
  user: Nullable<User>;
  isLoadingUser: boolean;
  isLoading: boolean;
  error: Nullable<string>;
  isAdmin: boolean;
  isAuth: boolean;
  login: (credentials: Credentials) => Promise<User>;
  logout: () => void;
};

export function useAuth(): AuthHookResponse {
  const { user, error, loadingUserData, status } = useAppSelector((store) => store.auth);
  const dispatch = useAppDispatch();
  const hasTokens = !!localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

  useEffect(() => {
    if (!user && hasTokens) {
      dispatch(loadUserThunk());
    }
  }, [dispatch, user, hasTokens]);

  function login(credentials: Credentials) {
    return dispatch(loginThunk(credentials)).unwrap();
  }

  function logout() {
    return dispatch(logoutThunk()).unwrap();
  }

  return {
    user,
    error,
    isLoadingUser: loadingUserData === 'loading',
    isLoading: status === 'loading',
    isAdmin: !!user?.isAdmin,
    isAuth: hasTokens,
    login,
    logout,
  };
}
