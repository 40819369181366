import { ReactElement, createContext, useContext, useState } from 'react';
import { Nullable } from '../types';
import { Alert, Snackbar, SxProps } from '@mui/material';
import Typography from '../componentsv2/Typography';

type SnackbarType = 'error' | 'success';

const SnackbarContext = createContext<{ showSnackbar: (message: string, type: SnackbarType) => void }>({
  showSnackbar: () => {},
});

export function useSnackbar() {
  return useContext(SnackbarContext);
}

const typeIconMap: Record<SnackbarType, ReactElement> = {
  error: (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 12C0 5.39062 5.34375 0 12 0C18.6094 0 24 5.39062 24 12C24 18.6562 18.6094 24 12 24C5.34375 24 0 18.6562 0 12ZM8.20312 9.79688L10.4062 12L8.20312 14.2031C7.73438 14.6719 7.73438 15.375 8.20312 15.7969C8.625 16.2656 9.32812 16.2656 9.75 15.7969L11.9531 13.5938L14.2031 15.7969C14.625 16.2656 15.3281 16.2656 15.75 15.7969C16.2188 15.375 16.2188 14.6719 15.75 14.2031L13.5469 12L15.75 9.79688C16.2188 9.375 16.2188 8.67188 15.75 8.20312C15.3281 7.78125 14.625 7.78125 14.2031 8.20312L11.9531 10.4531L9.75 8.20312C9.32812 7.78125 8.625 7.78125 8.20312 8.20312C7.73438 8.67188 7.73438 9.375 8.20312 9.79688Z"
        fill="#FFFAFA"
      />
    </svg>
  ),
  success: (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 12C0 5.39062 5.34375 0 12 0C18.6094 0 24 5.39062 24 12C24 18.6562 18.6094 24 12 24C5.34375 24 0 18.6562 0 12ZM17.3906 9.9375C17.9062 9.42188 17.9062 8.625 17.3906 8.10938C16.875 7.59375 16.0781 7.59375 15.5625 8.10938L10.5 13.1719L8.39062 11.1094C7.875 10.5938 7.07812 10.5938 6.5625 11.1094C6.04688 11.625 6.04688 12.4219 6.5625 12.9375L9.5625 15.9375C10.0781 16.4531 10.875 16.4531 11.3906 15.9375L17.3906 9.9375Z"
        fill="#FDFDFD"
      />
    </svg>
  ),
};

const typeColorMap: Record<SnackbarType, SxProps> = {
  error: { color: '#FFFAFA', background: '#C54646' },
  success: { color: '#F7FDF8', background: '#0EA480' },
};

const SNACKBAR_DURATION = 4000;

export function GlobalSnackbarProvider({ children }: any) {
  const [snackbarData, setSnackbarData] = useState<Nullable<{ message: string; type: SnackbarType }>>(null);

  const showSnackbar = (message: string, type: SnackbarType) => {
    setSnackbarData({ message, type });
  };

  const hideSnackbar = () => {
    setSnackbarData(null);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      {!!snackbarData && (
        <Snackbar
          open={true}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={SNACKBAR_DURATION}
          ClickAwayListenerProps={{ onClickAway: () => null }} // prevent clsoing by clicking outside the snackbar
          onClose={() => {
            hideSnackbar();
          }}>
          <Alert
            onClose={() => {
              hideSnackbar();
            }}
            icon={typeIconMap[snackbarData.type]}
            sx={{
              width: '100%',
              ...typeColorMap[snackbarData?.type],
              display: 'flex',
              alignItems: 'center',
            }}>
            <Typography sx={{ color: 'inherit' }}>{snackbarData.message}</Typography>
          </Alert>
        </Snackbar>
      )}
    </SnackbarContext.Provider>
  );
}
