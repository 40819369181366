import { useLocation, Location, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TopBar from '../../componentsv2/TopBar';
import MarcoLogo from '../../componentsv2/MarcoLogo';
import Typography from '../../componentsv2/Typography';
import Button from '../../componentsv2/Button';
import { InboxIcon } from '../../components/Icons';
import { useLazyTranslation } from '../../hooks/i18n';
import { useWindowSize } from '../../hooks/useWindowSize';
import { COLORS } from '../../themev2';

interface LocationState {
  state: { email: string };
}

const RegisterConfirmation = () => {
  const { t } = useLazyTranslation();
  const navigate = useNavigate();
  const location = useLocation() as Location & LocationState;
  const { isMobile } = useWindowSize();

  if (!location?.state?.email) {
    navigate('/');
  }

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <TopBar>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <MarcoLogo />
          </Box>
        </TopBar>
      </Box>
      <Grid xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1.5em',
            width: isMobile ? '100%' : '40%',
            padding: '2em',
            textAlign: 'center',
          }}>
          <InboxIcon />
          <Typography variant="h4" sx={{ fontSize: isMobile ? '1.8em' : '32px', fontWeight: 700 }}>
            {t('register.title')}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column' } }}>
            <Trans t={t} i18nKey={'register.userEmailVerification'}>
              <Typography sx={{ fontWeight: 600 }}>We sent you an email to:</Typography>
              <Typography sx={{ marginLeft: '5px', fontWeight: 600 }}>
                <p>{{ email: location?.state?.email }}</p>
              </Typography>
            </Trans>
          </Box>
          <Typography sx={{ color: COLORS.gray[1100] }}>{t('register.description')}</Typography>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ marginLeft: '2px', color: COLORS.gray[1100] }}>
              {`${t('register.contactSupport')} `}
              <a href={'mailto:' + t('register.supportEmail')}>{t('register.supportEmail')}</a>
              {` ${t('register.fillForm')} `}
            </Typography>
          </Box>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://holamarco.co/contacto"
            style={{ textDecoration: 'none' }}>
            <Button color="secondary">{t('register.contactUs')}</Button>
          </a>
        </Box>
      </Grid>
    </>
  );
};

export default RegisterConfirmation;
