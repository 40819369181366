import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { identify, Identify, logEvent, setUserId } from '@amplitude/analytics-browser';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '../../componentsv2/Typography';
import TopBar from '../../componentsv2/TopBar';
import MarcoLogo from '../../componentsv2/MarcoLogo';
import { SelectField, TextField } from '../../componentsv2/Field';
import Button from '../../componentsv2/Button';
import Form from '../../componentsv2/Form';
import AutocompleteField from '../../componentsv2/Field/AutocompleteField';
import PhoneNumberField from '../../componentsv2/Field/PhoneNumberField';
import ErrorMessage from '../../components/ErrorMessage';
import { ButtonSizes } from '../../components/Button';
import { InvoicesTableIcon } from '../../components/Icons';
import { SignUpResponse } from '../../services/types';
import { useTranslation } from '../../hooks/i18n';
import { useUserLocation } from '../../hooks/useUserLocation';
import { useAnonymousUser } from '../../hooks/anonymous';
import { signUp as signUpThunk, useAppDispatch, useAppSelector } from '../../store';
import { SignupSchema } from '../../schemas';
import { CountryOption, Nullable, SignupCountryKeys } from '../../types';
import { SignupFields } from '../../types/signup';
import { Referral } from '../../types/signup';
import { phoneCountryCodes } from '../../utils/helpers';
import { getCountries } from '../../constants/data';
import { signupEvents } from '../../constants/events';
import { APP_FLOW, CURRENT_APP_STEP_STORAGE_KEY, filesURL } from '../../constants/auth';
import { COLORS } from '../../themev2';
import classes from './Signup.module.scss';

const logSignupSubmit = (
  { country, businessName, fullName }: SignupFields,
  language: string,
  page: Nullable<string>
) => {
  logEvent(signupEvents.FORM_SUBMITTED, {
    'Country Of Incorporation': country,
    'Company Name': businessName,
    Name: fullName,
    Language: language,
    Page: page,
  });
};

const Signup = () => {
  const { t } = useTranslation();
  const { language } = useUserLocation();
  const { location } = useAnonymousUser();
  const { error, status } = useAppSelector((state) => state.auth);
  const isLoading = status === 'loading';
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [referral, setReferral] = useState<Referral>({ id: null, source: null });
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const utmRef = queryParams.get('utm_ref');
  const utmSource = queryParams.get('utm_source');
  const referralClient = queryParams.get('referral_client');
  const page = queryParams.get('page');
  const [captcha, setCaptcha] = useState<string>();

  useEffect(() => {
    setReferral({ id: utmRef, source: utmSource });
    logEvent(signupEvents.VIEW_PAGE, {
      URL_utm_ref: utmRef,
      URL_utm_source: utmSource,
      URL_referral_client: referralClient,
    });
  }, [utmRef, utmSource, referralClient]);

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <TopBar>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <IconButton href="/">
            <MarcoLogo />
          </IconButton>
        </Box>
      </TopBar>
      <Box component="main" sx={{ flexGrow: 1, paddingTop: '1em', display: 'flex' }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ display: { xs: 'none', lg: 'flex' }, alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '1em',
                textAlign: 'center',
              }}>
              <InvoicesTableIcon />
              <Box sx={{ position: 'relative', top: '-5em', maxWidth: '60%' }}>
                <Typography sx={{ fontSize: '24px', marginBottom: '0.5em' }}>
                  {t('signup.whyMarco')}
                </Typography>
                <Typography sx={{ color: COLORS.gray[1100], fontSize: '18px' }}>
                  {t('signup.description')}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              backgroundColor: { xs: 'inherit', md: '#FBFCFE' },
              paddingBottom: '1em',
              display: 'flex',
              alignItems: 'center',
            }}>
            <Grid container rowSpacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ fontWeight: 600, textAlign: 'center' }}>
                  {t('signup.title')}
                </Typography>
              </Grid>
              <Grid item xs={10} md={8}>
                <Formik
                  validateOnBlur={false}
                  validateOnChange
                  initialValues={{
                    country: location,
                    fullName: '',
                    phoneNumber: '',
                    email: '',
                    businessName: '',
                    phoneNumberCountry: location,
                  }}
                  validationSchema={SignupSchema(t)}
                  validate={(values) => {
                    if (!captcha) return { captcha: t('signup.form.errors.captcha') };
                    const phoneLength = phoneCountryCodes[values.phoneNumberCountry].placeholder.replace(
                      /[() -]/g,
                      ''
                    ).length;
                    if (values.phoneNumber?.toString().length < phoneLength)
                      return { phoneNumber: t('signup.form.phoneNumber.errors.invalid') };
                  }}
                  onSubmit={(values) => {
                    const { phoneNumberCountry: phone, ...newValues } = values;
                    const mapValuesForBackend = {
                      ...newValues,
                      phoneNumber: `+${phoneCountryCodes[values.phoneNumberCountry].code}${
                        values.phoneNumber
                      }`,
                    };
                    dispatch(
                      signUpThunk({
                        values: { ...mapValuesForBackend, captcha: captcha! },
                        referral,
                        page,
                        language,
                        resetCaptcha: () => {
                          (window as any).grecaptcha.reset();
                        },
                        referralClient,
                      })
                    )
                      .unwrap()
                      .then((data: any) => {
                        logSignupSubmit(mapValuesForBackend, language, page);
                        navigate('/verifyemailsent', { state: { email: values.email } });
                        localStorage.removeItem(APP_FLOW);
                        localStorage.removeItem(CURRENT_APP_STEP_STORAGE_KEY);
                      });
                  }}>
                  {({ isValid, dirty, values, setFieldValue, submitCount }: FormikProps<any>) => {
                    return (
                      <Form name="form-signup" id="form-signup" trackingEvent={signupEvents.EDIT_FIELD}>
                        <Grid container rowSpacing={1}>
                          <Grid item xs={5}>
                            <AutocompleteField
                              placeholder={t('signup.form.country.placeholder')}
                              label={t('signup.form.country.placeholder')}
                              name="country"
                              options={getCountries(t)
                                .sort((a: CountryOption, b: CountryOption) =>
                                  a.country.localeCompare(b.country)
                                )
                                .map(({ country, countryKey }) => ({
                                  value: countryKey,
                                  label: country,
                                }))}
                              renderOption={({ label, value }) => (
                                <div style={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
                                  <div
                                    style={{
                                      height: '24px',
                                      width: '24px',
                                      borderRadius: '20%',
                                      marginRight: '1em',
                                      overflow: 'hidden',
                                      flexShrink: 0, // Prevent this container from shrinking
                                      flexGrow: 0, // Prevent this container from growing
                                    }}>
                                    <img
                                      alt={label}
                                      src={`/images/countryFlags/${value}.svg`}
                                      style={{ width: '100%', height: '100%' }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      minWidth: 0,
                                      flexGrow: 1, // Allow this container to take up remaining space
                                    }}>
                                    {label}
                                  </div>
                                </div>
                              )}
                              onChange={(value) => {
                                setFieldValue('phoneNumberCountry', value);
                                setFieldValue('phoneNumber', '');
                              }}
                              inputStartAdornment={
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginRight: '0.2em',
                                  }}>
                                  <img
                                    alt={values.country}
                                    src={`/images/countryFlags/${values.country}.svg`}
                                  />
                                </div>
                              }
                              moreInfoTooltip={
                                <div className={classes.contactUsTooltipWrapper}>
                                  <Typography sx={{ color: '#FFF' }}>
                                    {t('signup.form.country.selectCountryOrRegion')}
                                  </Typography>
                                  <div className={classes.contactUsTooltip}>
                                    <Typography sx={{ color: '#FFF' }}>
                                      {t('signup.form.country.dontSeeCountry')}{' '}
                                      <a href="https://marcofi.com/contact" className={classes.contactUsLink}>
                                        {t('signup.form.country.contactUs')}
                                      </a>
                                    </Typography>
                                  </div>
                                </div>
                              }
                              showShortLabel
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <TextField
                              name="businessName"
                              fullWidth
                              placeholder={t('signup.form.businessName.placeholder')}
                              label={t('signup.form.businessName.label')}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label={t('signup.form.fullName.label')}
                              name="fullName"
                              placeholder={t('signup.form.fullName.placeholder')}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={0}>
                              <Grid item xs={4} md={3}>
                                <SelectField
                                  label={t('signup.form.phoneNumber.placeholder')}
                                  name="phoneNumberCountry"
                                  options={Object.entries(phoneCountryCodes)
                                    .map(([key, value]) => ({
                                      value: key,
                                      label: value.code.toString(),
                                    }))
                                    .sort((a, b) => a.value.localeCompare(b.value))}
                                  renderOption={({ label, value }) => (
                                    <MenuItem key={value} value={value}>
                                      <img
                                        src={`/images/countryFlags/${value}.svg`}
                                        alt={value.toUpperCase()}
                                        style={{ width: '24px', height: '24px', marginRight: '8px' }}
                                      />
                                      {`(+${label})`}
                                    </MenuItem>
                                  )}
                                  renderValue={(value) => {
                                    return (
                                      <Typography textAlign="center">{`(+${
                                        phoneCountryCodes[value as SignupCountryKeys].code
                                      })`}</Typography>
                                    );
                                  }}
                                />
                              </Grid>
                              <Grid item xs={8} md={9}>
                                <PhoneNumberField
                                  name="phoneNumber"
                                  label="Phone number"
                                  hideLabel
                                  fullWidth
                                  value={values.phoneNumber}
                                  format={phoneCountryCodes[
                                    values.phoneNumberCountry as SignupCountryKeys
                                  ].placeholder.replace(/[0-9]/g, '#')}
                                  placeholder={
                                    phoneCountryCodes[values.phoneNumberCountry as SignupCountryKeys]
                                      .placeholder
                                  }
                                  onChange={(value) => setFieldValue('phoneNumber', value.floatValue)}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="email"
                              fullWidth
                              placeholder={t('signup.form.email.placeholder')}
                              label={t('signup.form.email.label')}
                            />
                          </Grid>
                          {!!error && (
                            <Grid item xs={12}>
                              <ErrorMessage errorMessage={t(`signup.form.errors.${error}`)} />
                            </Grid>
                          )}
                          {!captcha && submitCount > 0 && (
                            <Grid item xs={12}>
                              <ErrorMessage errorMessage={t('signup.form.errors.captcha')} />
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginY: '8px !important',
                            }}>
                            <ReCAPTCHA
                              sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
                              onChange={(value) => {
                                setCaptcha(value as string);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{}}>
                            <Button
                              fullWidth
                              color="secondary"
                              size={ButtonSizes.Large}
                              isLoading={isLoading}
                              isSubmit>
                              {t('signup.form.submit')}
                            </Button>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '8px',
                              paddingTop: '1em !important',
                            }}>
                            <Typography>{t('signup.alreadyHaveAnAccount')}</Typography>
                            <Link to={'/login'} style={{ color: COLORS.indigo[500] }}>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  lineHeight: '24px',
                                  textAlign: 'left',
                                  color: COLORS.indigo[500],
                                }}>
                                {t('signup.login')}
                              </Typography>
                            </Link>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Signup;
