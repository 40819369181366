import { Box } from '@mui/material';
import { COLORS } from '../../themev2';

const TopBar: React.FC = ({ children }) => {
  return (
    <Box
      sx={{
        width: '100%',
        background: COLORS.brand.white,
        boxShadow: '0px 10px 30px rgba(196, 208, 225, 0.2)',
        padding: '1em',
      }}>
      {children}
    </Box>
  );
};

export default TopBar;
