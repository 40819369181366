import { HTMLInputTypeAttribute, useContext, useState } from 'react';
import { FormikContext } from 'formik';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { default as MUITextField } from '@mui/material/TextField';
import { useTranslation } from '../../hooks/i18n';
import { COLORS } from '../../themev2';
import { FormTrackingContext } from '../Form';
import Typography from '../Typography';
import { CommonProps } from './types';
import { logFieldChange } from '../../utils/tracking';
import InfoButton from '../InfoButton';
import EyeSlashIcon from '../../components/Icons/EyeSlashIcon';
import EyeIcon from '../../components/Icons/EyeIcon';
import { SxProps } from '@mui/material';
import ClearIcon from '../../components/Icons/ClearIcon';

export type TextFieldProps = CommonProps & {
  value?: string;
  formatValue?: (value: string) => string;
  type?: HTMLInputTypeAttribute | undefined;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  multiline?: boolean;
  minRows?: number;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  isFloat?: boolean;
  sx?: SxProps;
  onBlur?: (value: string) => void;
};

export default function TextField({
  fullWidth = true,
  label,
  error,
  helperText,
  placeholder,
  name,
  onChange,
  onBlur,
  value,
  disabled = false,
  formatValue = (value) => value,
  eventLogger = logFieldChange,
  optional = false,
  moreInfoTooltip = null,
  onKeyUp,
  type,
  multiline,
  minRows,
  endAdornment,
  startAdornment,
  isFloat = false,
  sx = {},
  clearable = false,
}: TextFieldProps) {
  const { t } = useTranslation();
  const formik = useContext(FormikContext);
  const { trackingEvent } = useContext(FormTrackingContext);
  const _error = error || (formik?.touched[name] && formik?.errors[name]);
  const [showPassword, setShowPassword] = useState(false);

  const handleClear = () => {
    formik && formik.setFieldValue(name, '');
    onChange && onChange('');
  };

  return (
    <FormControl fullWidth={fullWidth}>
      <FormLabel
        sx={{
          padding: '8px 0',
          fontWeight: 600,
          display: 'flex',
          alignItems: 'center',
        }}>
        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <Typography>{label}</Typography>
          {moreInfoTooltip && <InfoButton title={moreInfoTooltip} />}
        </div>
        {optional && <Typography sx={{ color: COLORS.gray[1000] }}>{t('optional')}</Typography>}
      </FormLabel>
      <MUITextField
        color="secondary"
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        disabled={disabled}
        error={!!_error}
        name={name}
        variant="outlined"
        fullWidth={fullWidth}
        value={formatValue(value || formik?.values[name])}
        onChange={(e: any) => {
          let value = e.target.value;
          if (type === 'number') value = isFloat ? parseFloat(value) : parseInt(value);
          formik && formik.setFieldValue(name, value);
          onChange && onChange(value);
          e.target.hasChanged = true;
        }}
        onBlur={(e: any) => {
          formik && formik.handleBlur(e);
          const value = e.target.value;
          onBlur && onBlur(value);
          trackingEvent &&
            type !== 'password' &&
            e.target.hasChanged &&
            eventLogger(trackingEvent, name, value);
          e.target.hasChanged = false;
        }}
        onWheel={() => {
          if (document.activeElement instanceof HTMLElement && type === 'number') {
            document.activeElement.blur();
          }
        }}
        helperText={!!_error ? <Typography sx={{ color: 'inherit' }}>{_error}</Typography> : helperText}
        inputProps={{
          sx: {
            background: disabled ? COLORS.gray[300] : COLORS.brand.white,
            marginLeft: !!startAdornment ? '-8px' : undefined,
            '::placeholder': {
              color: COLORS.gray[1000],
              opacity: 1,
            },
          },
        }}
        InputProps={{
          sx: {},
          style: startAdornment ? { gap: '0', paddingLeft: '10px' } : {},
          endAdornment:
            type === 'password' ? (
              <InputAdornment position="end" style={{ padding: 0 }}>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowPassword((prev) => !prev);
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end">
                  {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                </IconButton>
              </InputAdornment>
            ) : clearable && formik?.values[name] ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear input"
                  onClick={handleClear}
                  edge="end"
                  style={{ padding: 0, paddingRight: '8px', color: '#4F5A84' }}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : (
              endAdornment
            ),
          startAdornment: startAdornment ? (
            <InputAdornment position="start" sx={{ color: '#3B4567' }}>
              {startAdornment}
            </InputAdornment>
          ) : undefined,
        }}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        sx={{
          '.MuiInputBase-input': {
            padding: '12px',
          },
          '& .MuiFormHelperText-root': {
            marginLeft: 0,
          },
          '& fieldset': {
            borderColor: COLORS.gray[900],
            borderRadius: '8px',
          },
          ...sx,
        }}
        multiline={multiline}
        minRows={minRows}
      />
    </FormControl>
  );
}
