import { COLORS } from '../../themev2';

export default function MessageTextIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M2 13.7151V7.73512C2 4.97512 4.24 2.74512 7 2.74512H17C19.76 2.74512 22 4.97512 22 7.73512V14.7151C22 17.4651 19.76 19.6951 17 19.6951H15.5C15.19 19.6951 14.89 19.8451 14.7 20.0951L13.2 22.0851C12.54 22.9651 11.46 22.9651 10.8 22.0851L9.3 20.0951C9.14 19.8751 8.78 19.6951 8.5 19.6951H7C4.24 19.6951 2 17.4651 2 14.7151V13.7151Z"
      />
      <path d="M17 9.49512H7C6.59 9.49512 6.25 9.15512 6.25 8.74512C6.25 8.33512 6.59 7.99512 7 7.99512H17C17.41 7.99512 17.75 8.33512 17.75 8.74512C17.75 9.15512 17.41 9.49512 17 9.49512ZM13 14.4951H7C6.59 14.4951 6.25 14.1551 6.25 13.7451C6.25 13.3351 6.59 12.9951 7 12.9951H13C13.41 12.9951 13.75 13.3351 13.75 13.7451C13.75 14.1551 13.41 14.4951 13 14.4951Z" />
    </svg>
  );
}
