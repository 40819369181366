import React, { useState } from 'react';
import { Divider, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { drawerWidth } from '../Drawer';
import Button from '../Button';
import MarcoLogo from '../MarcoLogo';
import { LogoutIcon, NotificationsIcon } from '../../components/Icons';
import IconExpandLess from '@mui/icons-material/ExpandLess';
import IconExpandMore from '@mui/icons-material/ExpandMore';
import { COLORS } from '../../themev2';
import { useLazyTranslation } from '../../hooks/i18n';
import { CountryKeys, Nullable, User } from '../../types';
import { APINotification } from '../../types/client';
import { getLanguageOptions } from '../../constants/data';
import { ADMIN_TAKEOVER_TOKEN_STORAGE_KEY, MY_ACCOUNT, MY_ACCOUNT_ADMIN } from '../../constants/auth';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks';

interface AppBarProps extends MuiAppBarProps {
  open: boolean;
  fullWidth?: boolean;
}

const languageLabelMap: Record<string, string> = {
  en: 'English',
  es: 'Español',
};

const MarcoAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'fullWidth',
})<AppBarProps>(({ theme, open, fullWidth }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: fullWidth ? '100%' : `calc(100% - ${theme.spacing(9)})`,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    ...(open && {
      marginLeft: 0,
    }),
  },
  ...(open && {
    marginLeft: drawerWidth,
    width: fullWidth ? '100%' : `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

type MarcoAppBarProps = {
  open: boolean;
  fullWidth?: boolean;
  logoutUser: Function;
  alerts?: APINotification[];
  useUserLocation: Function;
  user: Nullable<User>;
  setOpen: (open: boolean) => void;
};

const AppBar = ({
  open,
  fullWidth = false,
  logoutUser,
  alerts = [],
  useUserLocation,
  user,
  setOpen,
}: MarcoAppBarProps) => {
  const { t, i18n } = useLazyTranslation();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElLanguage, setAnchorElLanguage] = useState<null | HTMLElement>(null);
  const { isAdmin } = useAuth();

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const { location, handleChangeLocation, language } = useUserLocation();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleCloseLanguageMenu = () => {
    setAnchorElLanguage(null);
  };

  return (
    <MarcoAppBar
      position="fixed"
      open={open}
      style={{ backgroundColor: COLORS.brand.white }}
      elevation={0}
      fullWidth={fullWidth}>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {isMdDown && (
          <IconButton onClick={() => setOpen(!open)}>
            <MenuIcon />
          </IconButton>
        )}
        <IconButton href="/">
          <MarcoLogo />
        </IconButton>
        <Box sx={{ marginRight: '16px' }}>
          {!!localStorage.getItem(ADMIN_TAKEOVER_TOKEN_STORAGE_KEY) && (
            <Button startIcon={<KeyboardArrowLeftIcon />} onClick={() => logoutUser()}>
              {t('backToAdminAppBtn')}
            </Button>
          )}
          {location && (
            <>
              <Tooltip title={t('language')}>
                <>
                  <Button
                    variant="text"
                    onClick={handleOpenLanguageMenu}
                    endIcon={anchorElLanguage ? <IconExpandLess /> : <IconExpandMore />}
                    sx={{
                      marginRight: '8px',
                      textTransform: 'capitalize',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}>
                    {languageLabelMap[language]}
                  </Button>
                </>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-language-selector"
                anchorEl={anchorElLanguage}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElLanguage)}
                onClose={handleCloseLanguageMenu}>
                {getLanguageOptions(location).map(({ language, key, title }) => {
                  return (
                    <MenuItem key={key} onClick={() => handleChangeLocation(language as CountryKeys)}>
                      <Radio
                        checked={language.substring(0, 2) === i18n.language.substring(0, 2)}
                        value={language}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': title }}
                      />
                      <Typography textAlign="center">{title}</Typography>
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          )}
          <Tooltip title={t('notifications')}>
            <IconButton
              sx={{
                marginRight: '8px',
              }}>
              <Badge
                color="primary"
                variant="dot"
                overlap="circular"
                invisible={alerts.length === 0}
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#4F55FD',
                    transform: 'translate(0px, 0px)',
                  },
                }}>
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            {user && (
              <Avatar sx={{ backgroundColor: COLORS.gray[1100] }}>
                {user?.firstName.charAt(0).toUpperCase()}
              </Avatar>
            )}
          </IconButton>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-user-options"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}>
            <MenuItem sx={{ minWidth: '200px', '&:hover': { backgroundColor: 'transparent' } }}>
              <Typography textAlign="center" sx={{ fontWeight: 600 }}>
                {user?.firstName}
              </Typography>
            </MenuItem>
            <Divider />
            <div>
              <MenuItem
                onClick={() => {
                  navigate(isAdmin ? MY_ACCOUNT_ADMIN : MY_ACCOUNT);
                }}>
                <Typography textAlign="center">{t('myAccount')}</Typography>
              </MenuItem>
              <Divider />
            </div>
            <MenuItem onClick={() => logoutUser()} sx={{ color: '#930909', gap: '8px' }}>
              <LogoutIcon />
              <Typography textAlign="center">{t('logout')}</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </MarcoAppBar>
  );
};

export default AppBar;
