import axios from './axios';
import { Holiday } from '../types/holidays';

export function getHolidays(companyId: number): Promise<Holiday[]> {
    return axios
        .get(`companies/${companyId}/products/holidays/`)
        .then(({ data }) => data);
}


export function submitProductCES(companyId: number, payload: { score?: number; comment?: string, product?: string }): Promise<any> {
    return axios
        .post(`companies/${companyId}/products/product_ces/`, payload).then(({ data }) => data || {});

}

export function updateProductCES(companyId: number, productCESId: number, payload: { score?: number; comment?: string, product?: string }): Promise<any> {
    return axios
        .patch(`companies/${companyId}/products/product_ces/${productCESId}/`, payload).then(({ data }) => data || {});

}

export function checkProductCESisFinished(companyId: number, product: string): Promise<any> {
    return axios
        .get(`companies/${companyId}/products/product_ces/check_finished/`, { params: { product } }).then(({ data }) => data || {});
}