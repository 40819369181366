export default function MessageSentIcon() {
  return (
    <svg width="88" height="80" viewBox="0 0 88 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M87.6668 20.9583V45.9583C87.6668 52.2083 85.5835 57.4167 81.9168 61.0834C78.2918 64.7084 73.0835 66.7917 66.8335 66.7917V75.6667C66.8335 79 63.1252 81.0001 60.3752 79.1668L41.8335 66.7917H33.0002C33.3335 65.5417 33.5002 64.25 33.5002 62.9167C33.5002 58.6667 31.8752 54.7501 29.2085 51.7918C26.2085 48.3751 21.7502 46.25 16.8335 46.25C12.1668 46.25 7.91685 48.1668 4.87518 51.2918C4.50018 49.6251 4.3335 47.8333 4.3335 45.9583V20.9583C4.3335 8.45833 12.6668 0.125 25.1668 0.125H66.8335C79.3335 0.125 87.6668 8.45833 87.6668 20.9583Z"
        fill="url(#paint0_linear_290_20791)"
      />
      <path
        d="M60.5832 36.875H31.4165C29.7082 36.875 28.2915 35.4583 28.2915 33.75C28.2915 32.0417 29.7082 30.625 31.4165 30.625H60.5832C62.2915 30.625 63.7082 32.0417 63.7082 33.75C63.7082 35.4583 62.2915 36.875 60.5832 36.875Z"
        fill="url(#paint1_linear_290_20791)"
      />
      <path
        d="M16.8332 46.25C7.62484 46.25 0.166504 53.7083 0.166504 62.9167C0.166504 66.0417 1.04145 69 2.58311 71.5C5.45811 76.3333 10.7498 79.5833 16.8332 79.5833C22.9165 79.5833 28.2082 76.3333 31.0832 71.5C32.6249 69 33.4998 66.0417 33.4998 62.9167C33.4998 53.7083 26.0415 46.25 16.8332 46.25ZM25.0415 61.5416L16.1665 69.7501C15.5832 70.2917 14.7914 70.5834 14.0414 70.5834C13.2498 70.5834 12.4581 70.2916 11.8331 69.6666L7.70816 65.5417C6.49983 64.3334 6.49983 62.3334 7.70816 61.125C8.91649 59.9167 10.9165 59.9167 12.1248 61.125L14.1249 63.125L20.7916 56.9584C22.0416 55.7917 24.0415 55.8749 25.2082 57.1249C26.3749 58.3749 26.2915 60.3749 25.0415 61.5416Z"
        fill="url(#paint2_linear_290_20791)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_290_20791"
          x1="79.3448"
          y1="39.9937"
          x2="13.8359"
          y2="37.1205"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BAAA1" />
          <stop offset="1" stopColor="#0B8DAA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_290_20791"
          x1="60.1713"
          y1="33.75"
          x2="33.7697"
          y2="27.4711"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BAAA1" />
          <stop offset="1" stopColor="#0B8DAA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_290_20791"
          x1="30.171"
          y1="62.9165"
          x2="3.96321"
          y2="61.8166"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BAAA1" />
          <stop offset="1" stopColor="#0B8DAA" />
        </linearGradient>
      </defs>
    </svg>
  );
}
