import axios from './axios';

export type ContactFormValues = {
  name: string;
  email: string;
  subject: string;
  description: string;
};

export function sendContactForm(companyId: number, values: ContactFormValues, files: File[]) {
  const formData = new FormData();
  formData.set('name', values.name);
  formData.set('email', values.email);
  formData.set('subject', values.subject);
  formData.set('description', values.description);
  files.forEach((file) => {
    formData.append('asset', file, file.name);
    formData.append('file_name', file.name);
    formData.append('asset_type', 'contact_form_asset');
  });

  return axios
    .post(`/companies/${companyId}/contact_form/`, formData, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=BoUnDaRyStRiNg`,
      },
    })
    .then(({ data }) => data);
}
