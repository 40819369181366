export default function LargeCurrencyExchangeIcon() {
  return (
    <svg width="340" height="280" viewBox="0 0 300 301" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M202.375 25.8813H97.625C52.125 25.8813 25 53.0063 25 98.5063V203.131C25 248.756 52.125 275.881 97.625 275.881H202.25C247.75 275.881 274.875 248.756 274.875 203.256V98.5063C275 53.0063 247.875 25.8813 202.375 25.8813Z"
        fill="url(#paint0_linear_1597_34262)"
      />
      <path
        d="M223.125 170.006C222.136 167.704 220.302 165.87 218 164.881C216.857 164.382 215.622 164.126 214.375 164.131H85.625C80.5 164.131 76.25 168.381 76.25 173.506C76.25 178.631 80.5 182.881 85.625 182.881H191.875L169.875 204.881C166.25 208.506 166.25 214.506 169.875 218.131C171.75 220.006 174.125 220.881 176.5 220.881C178.875 220.881 181.25 220.006 183.125 218.131L221.125 180.131C222 179.256 222.625 178.256 223.125 177.131C224 174.837 224 172.301 223.125 170.006ZM76.875 131.756C77.875 134.006 79.625 135.881 82 136.881C83.125 137.381 84.375 137.631 85.625 137.631H214.5C219.625 137.631 223.875 133.381 223.875 128.256C223.875 123.131 219.625 118.881 214.5 118.881H108.25L130.25 96.8812C133.875 93.2562 133.875 87.2562 130.25 83.6312C128.484 81.8907 126.104 80.915 123.625 80.915C121.146 80.915 118.766 81.8907 117 83.6312L79 121.506C78.125 122.381 77.375 123.506 76.875 124.631C76.0002 126.926 76.0002 129.462 76.875 131.756Z"
        fill="url(#paint1_linear_1597_34262)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1597_34262"
          x1="253.432"
          y1="134.869"
          x2="79.498"
          y2="42.0298"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0E3354" />
          <stop offset="1" stopColor="#134178" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1597_34262"
          x1="211.203"
          y1="141.933"
          x2="110.957"
          y2="85.4577"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0E3354" />
          <stop offset="1" stopColor="#134178" />
        </linearGradient>
      </defs>
    </svg>
  );
}
