import { useEffect } from 'react';
import { logEvent } from '@amplitude/analytics-browser';
import Grid from '@mui/material/Grid';
import { Formik, FormikProps } from 'formik';
import FormSection from '../../componentsv2/FormSection';
import Form from '../../componentsv2/Form';
import { RadioField, SelectField, TextField } from '../../componentsv2/Field';
import MultiSelectField from '../../componentsv2/Field/MultiSelectField';
import Button from '../../componentsv2/Button';
import Typography from '../../componentsv2/Typography';
import { useTranslation } from '../../hooks/i18n';
import { useAppSelector } from '../../store';
import { ApplicationStepProps, GenericRadioOptions } from '../../types/application';
import { Nullable } from '../../types';
import { BusinessStepSchema } from '../../schemas';
import { stringCurrencyFormatter } from '../../utils/helpers';
import { currencies, sectors, regions } from '../../constants/data';
import { invoiceEvents } from '../../constants/events';
import { filesURL } from '../../constants/auth';
import { qualityChecksOptions, typicalIncoTermsOptions } from '../../constants/application';

export type BusinessState = {
  sector: string;
  incoTerms: string[];
  currencies: string[];
  businessRegions: string[];
  saleLast12Month?: string;
  practiceBillingsOrConsignments: Nullable<GenericRadioOptions>;
  qualityChecks: string;
  operatesWithCreditInsurance: Nullable<GenericRadioOptions>;
  insuranceProvider?: Nullable<string>;
  usingFinancialInstitutions: Nullable<GenericRadioOptions>;
  financialInstitution?: Nullable<string>;
  financialInstitutionType?: Nullable<string>;
};

const BusinessStep = ({
  onStepReturn,
  onStepSubmit,
  title,
  subtitle,
  applicationDraft,
  isLoading,
}: ApplicationStepProps) => {
  const { t } = useTranslation(['application', 'common']);
  const client = useAppSelector((state) => state.client.client);

  useEffect(() => {
    logEvent(invoiceEvents.STEP_TWO);
  }, []);

  const initialValues: BusinessState = {
    sector: applicationDraft?.sector ?? '',
    incoTerms: applicationDraft?.incoTerms ?? [],
    currencies: applicationDraft?.currencies ?? [],
    businessRegions: applicationDraft?.businessRegions ?? [],
    saleLast12Month: applicationDraft?.saleLast12Month
      ? `$${stringCurrencyFormatter(String(applicationDraft.saleLast12Month))}`
      : '0',
    practiceBillingsOrConsignments: applicationDraft?.practiceBillingsOrConsignments ?? null,
    qualityChecks: applicationDraft?.qualityChecks ?? '',
    operatesWithCreditInsurance: applicationDraft.operatesWithCreditInsurance ?? null,
    insuranceProvider: applicationDraft.insuranceProvider ?? '',
    usingFinancialInstitutions: applicationDraft?.usingFinancialInstitutions ?? null,
    financialInstitution: applicationDraft?.financialInstitution ?? '',
    financialInstitutionType: applicationDraft?.financialInstitutionType ?? '',
  };

  const handleOnSubmit = (values: BusinessState) => {
    const rawSales = values?.saleLast12Month
      ? Number(values.saleLast12Month.toString().replace(/\D/g, ''))
      : null;
    //@ts-ignore
    values['sale_last_12_month'] = rawSales;
    values.saleLast12Month = undefined;
    if (values.usingFinancialInstitutions === 'no') {
      delete values.financialInstitution;
      delete values.financialInstitutionType;
    }
    if (values.operatesWithCreditInsurance === 'no') {
      delete values.insuranceProvider;
    }

    return onStepSubmit(values);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleOnSubmit}
        validationSchema={BusinessStepSchema(t)}>
        {({ values, isValid }: FormikProps<BusinessState>) => {
          return (
            <Form trackingEvent={invoiceEvents.EDIT_FIELD}>
              <FormSection title={t('sections.businessInformation.title')}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <SelectField
                      name="sector"
                      label={t('sections.businessInformation.fields.sectorLabel')}
                      placeholder={t('sections.businessInformation.fields.sectorPlaceholder')}
                      options={sectors.map((value) => ({
                        value,
                        label: t(`sections.businessInformation.fields.sectorOptions.${value}`),
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultiSelectField
                      name="incoTerms"
                      label={
                        <>
                          {t('sections.businessInformation.fields.incoTermsLabelFirst')}
                          <a
                            href={`${filesURL}/Incoterms.png`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}>
                            {t('sections.businessInformation.fields.incoTermsLabelSecond')}
                          </a>
                          {t('sections.businessInformation.fields.incoTermsLabelThird')}
                        </>
                      }
                      placeholder={t('sections.businessInformation.fields.incoTermsPlaceholder')}
                      options={typicalIncoTermsOptions.map((value: string) => ({
                        value,
                        label: t(`application:sections.businessInformation.fields.incoTermsOptions.${value}`),
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultiSelectField
                      name="currencies"
                      label={t('sections.businessInformation.fields.currenciesLabel')}
                      placeholder={t('sections.businessInformation.fields.currenciesPlaceholder')}
                      options={currencies.map((value) => ({
                        value,
                        label: t(`sections.businessInformation.fields.currenciesOptions.${value}`),
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultiSelectField
                      name="businessRegions"
                      label={t('sections.businessInformation.fields.regionsLabel')}
                      placeholder={t('sections.businessInformation.fields.regionsPlaceholder')}
                      options={regions.map((value) => ({
                        value,
                        label: t(`sections.businessInformation.fields.regionsOptions.${value}`),
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="saleLast12Month"
                      placeholder={t('sections.businessInformation.fields.annualSalesPlaceholder')}
                      label={t('sections.businessInformation.fields.annualSalesLabel')}
                      formatValue={(value) => (value ? `$ ${stringCurrencyFormatter(value)}` : '')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectField
                      name="qualityChecks"
                      label={t('sections.businessInformation.fields.qualityChecksLabel')}
                      placeholder={t('sections.businessInformation.fields.qualityChecksPlaceholder')}
                      options={qualityChecksOptions.map((value: string) => ({
                        value,
                        label: t(
                          `application:sections.businessInformation.fields.qualityChecksOptions.${value}`
                        ),
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RadioField
                      name="practiceBillingsOrConsignments"
                      label={t('sections.businessInformation.fields.sellOnConsignmentLabel')}
                      options={[
                        { label: t('common:yes'), value: 'yes' },
                        { label: t('common:no'), value: 'no' },
                      ]}
                      moreInfoTooltip={
                        <Typography sx={{ color: '#FFF' }}>
                          {t('sections.businessInformation.fields.sellOnConsignmentTooltip')}
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: values.operatesWithCreditInsurance === 'yes' ? '-1em' : '0' }}>
                    <RadioField
                      name="operatesWithCreditInsurance"
                      label={t('sections.businessInformation.fields.companyOperatesWithCreditInsuranceLabel')}
                      options={[
                        { label: t('common:yes'), value: 'yes' },
                        { label: t('common:no'), value: 'no' },
                      ]}
                    />
                  </Grid>
                  {values.operatesWithCreditInsurance === 'yes' && (
                    <Grid item xs={12}>
                      <TextField
                        name="insuranceProvider"
                        placeholder={t('sections.businessInformation.fields.insuranceProviderPlaceholder')}
                        label={t('sections.businessInformation.fields.insuranceProviderPlaceholder')}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: values.usingFinancialInstitutions === 'yes' ? '-1em' : '0' }}>
                    <RadioField
                      name="usingFinancialInstitutions"
                      label={t('sections.businessInformation.fields.usingFinancialInstitutionsLabel', {
                        business: client?.companyName,
                      })}
                      options={[
                        { label: t('common:yes'), value: 'yes' },
                        { label: t('common:no'), value: 'no' },
                      ]}
                    />
                  </Grid>
                  {values.usingFinancialInstitutions === 'yes' && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          name="financialInstitution"
                          placeholder={t(
                            'sections.businessInformation.fields.financialInstitutionPlaceholder'
                          )}
                          label={t('sections.businessInformation.fields.financialInstitutionLabel')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="financialInstitutionType"
                          placeholder={t(
                            'sections.businessInformation.fields.financialInstitutionTypePlaceholder'
                          )}
                          label={t('sections.businessInformation.fields.financialInstitutionTypeLabel')}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </FormSection>
              <Grid container justifyContent="right" sx={{ marginTop: '2em' }}>
                <Grid item xs={12} md={12} sx={{ display: 'flex', gap: '1em' }}>
                  <Button type="outline" onClick={onStepReturn} disabled={isLoading}>
                    {t('steps.business.returnBtn')}
                  </Button>
                  <Button isSubmit disabled={!isValid}>
                    {t('steps.company.submitBtn')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default BusinessStep;
