export default function InboxIcon() {
  return (
    <svg width="141" height="141" viewBox="0 0 141 141" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M95.2609 23.7299H46.3776C25.1442 23.7299 12.4859 36.3882 12.4859 57.6215V94.7799C12.4859 116.072 25.1442 128.73 46.3776 128.73H95.2026C116.436 128.73 129.094 116.072 129.094 94.8382V57.6215C129.153 36.3882 116.494 23.7299 95.2609 23.7299Z"
        fill="url(#paint0_linear_745_14900)"
      />
      <path
        d="M125.069 71.7381H104.769C99.0526 71.7381 93.9776 74.8881 91.4109 80.0214L86.5109 89.7048C85.3442 92.0381 83.0109 93.4964 80.4442 93.4964H61.3109C59.5026 93.4964 56.9359 93.0881 55.2442 89.7048L50.3442 80.0798C49.104 77.5967 47.1981 75.5072 44.8393 74.0445C42.4804 72.5818 39.7614 71.8035 36.9859 71.7964H16.5692C14.2942 71.7964 12.4859 73.6048 12.4859 75.8798V94.8964C12.4859 116.071 25.2026 128.73 46.4359 128.73H95.3192C115.328 128.73 127.636 117.763 129.153 98.2798V75.8214C129.153 73.6048 127.344 71.7381 125.069 71.7381ZM85.5834 38.1381C84.7601 37.3245 83.6492 36.8682 82.4917 36.8682C81.3342 36.8682 80.2234 37.3245 79.4001 38.1381L75.2001 42.3381V12.0631C75.2001 9.67144 73.2167 7.68811 70.8251 7.68811C68.4334 7.68811 66.4501 9.67144 66.4501 12.0631V42.3381L62.2501 38.1381C61.4268 37.3245 60.3159 36.8682 59.1584 36.8682C58.0009 36.8682 56.89 37.3245 56.0667 38.1381C54.3751 39.8298 54.3751 42.6298 56.0667 44.3214L67.7334 55.9881C67.7917 56.0464 67.8501 56.0464 67.8501 56.1048C68.2001 56.4548 68.6667 56.7464 69.1334 56.9798C69.7167 57.1548 70.2417 57.2714 70.8251 57.2714C71.4084 57.2714 71.9334 57.1548 72.4584 56.9214C72.9834 56.6881 73.4501 56.3964 73.9167 55.9881L85.5834 44.3214C87.2751 42.6298 87.2751 39.8298 85.5834 38.1381Z"
        fill="url(#paint1_linear_745_14900)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_745_14900"
          x1="117.449"
          y1="76.2293"
          x2="25.8053"
          y2="71.9579"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BAAA1" />
          <stop offset="1" stopColor="#0B8DAA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_745_14900"
          x1="117.502"
          y1="68.2083"
          x2="25.7629"
          y2="64.4973"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BAAA1" />
          <stop offset="1" stopColor="#0B8DAA" />
        </linearGradient>
      </defs>
    </svg>
  );
}
