import clsx from 'clsx';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Typography from '../Typography';
import { useTranslation } from '../../hooks/i18n';
import classes from './StatusTracker.module.scss';
import { Close as CloseIcon } from '@mui/icons-material';
import { StatusTickIcon } from '../../components/Icons';

function mapIndexToStatusState(index: number, currentIndex: number, isComplete: boolean) {
  if (isComplete) return 'completed';
  let state = 'pending';
  if (index === currentIndex) state = 'inProgress';
  else if (index < currentIndex) state = 'completed';
  return state;
}

type Status = {
  value: string;
  label: string;
  icon?: JSX.Element;
};

interface StatusTrackerProps {
  currentStatus: string;
  isLoading?: boolean;
  isRejected?: boolean;
  statuses: Status[];
  isComplete?: boolean;
}

const StatusTracker = ({
  currentStatus,
  isLoading = false,
  isRejected = false,
  statuses,
  isComplete = false,
}: StatusTrackerProps) => {
  const { t } = useTranslation('application');
  const currentIndex = statuses.map((s) => s.value).indexOf(currentStatus);

  if (isLoading) return <Skeleton variant="rectangular" height={80} animation="wave" />;

  return (
    <div className={classes.statusTrackerContainer}>
      {statuses.map((status, index) => {
        return (
          <div
            key={status.value}
            style={{ position: 'relative', flex: 1 }}
            className={clsx({
              [classes.status]: index > currentIndex,
              [classes.statusPending]: index === currentIndex,
              [classes.statusCompleted]: isComplete || index < currentIndex,
              [classes.elementRejected]: isRejected && index === currentIndex,
              [classes.firstElement]: index === 0,
              [classes.lastElement]: index === statuses.length - 1,
              [classes.lastElementBackground]: index < currentIndex && index === statuses.length - 1,
              [classes.showElement]: index !== currentIndex,
              [classes.rounded]: true,
            })}>
            <div
              className={clsx({
                [classes.statusElement]: true,
                [classes.firstElement]: index === 0,
                [classes.lastElement]: index === statuses.length - 1,
                [classes.rounded]: true,
              })}>
              <Grid container alignItems="center" justifyContent="flex-end">
                {isComplete ? (
                  <Grid item xs={2}>
                    <StatusTickIcon />
                  </Grid>
                ) : (
                  <Grid item xs={2}>
                    {index < currentIndex && !isRejected && <StatusTickIcon />}
                    {index === currentIndex && isRejected && <CloseIcon />}
                    {index >= currentIndex && !isRejected && status.icon}
                  </Grid>
                )}
                <Grid item xs={8}>
                  <Typography
                    sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px', color: 'inherit' }}>
                    {status.label}
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 400, fontSize: '12px', lineHeight: '18px', color: 'inherit' }}>
                    {t(
                      `proposal.statusTracker.statuses.${mapIndexToStatusState(
                        index,
                        currentIndex,
                        isComplete
                      )}`
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            {statuses.length - 1 !== index && <div className={classes.triangle}></div>}
          </div>
        );
      })}
    </div>
  );
};

export default StatusTracker;
