export default function LargeCheckMarkIcon() {
  return (
    <svg width="399" height="300" viewBox="0 0 432 335" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M269.53 62.4373H162.47C131.286 62.4373 106.036 87.8135 106.036 118.871V237.672C106.036 268.73 131.412 294.106 162.47 294.106H269.404C300.587 294.106 325.837 268.73 325.837 237.672V118.871C325.964 87.6873 300.587 62.4373 269.53 62.4373Z"
        fill="url(#paint0_linear_1597_30052)"
      />
      <path
        d="M245.669 41.6313H186.331C173.201 41.6313 162.47 52.2363 162.47 65.3663V77.2338C162.47 90.3638 173.075 100.969 186.205 100.969H245.669C258.799 100.969 269.404 90.3638 269.404 77.2338V65.3663C269.53 52.2363 258.799 41.6313 245.669 41.6313ZM200.976 230.375C198.577 230.375 196.179 229.491 194.285 227.598L175.347 208.66C173.587 206.878 172.599 204.474 172.599 201.969C172.599 199.464 173.587 197.059 175.347 195.278C179.009 191.616 185.069 191.616 188.73 195.278L200.976 207.524L244.785 163.715C248.446 160.054 254.506 160.054 258.167 163.715C261.829 167.376 261.829 173.436 258.167 177.098L207.667 227.598C205.9 229.491 203.375 230.375 200.976 230.375Z"
        fill="url(#paint1_linear_1597_30052)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1597_30052"
          x1="306.975"
          y1="163.433"
          x2="150.544"
          y2="84.1739"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0E3354" />
          <stop offset="1" stopColor="#134178" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1597_30052"
          x1="260.228"
          y1="123.914"
          x2="172.607"
          y2="97.4036"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0E3354" />
          <stop offset="1" stopColor="#134178" />
        </linearGradient>
      </defs>
    </svg>
  );
}
