import { APILLC, LLCDocument } from '../types/llc';
import axios from './axios';

export function submitLLC(language: string, companyId: number) {
  return axios
    .post<APILLC>(`/companies/${companyId}/products/llc/company_llc/`, { language })
    .then(({ data }) => data);
}

export function getLLC(companyId: number) {
  return axios.get<APILLC[]>(`/companies/${companyId}/products/llc/company_llc/`).then(({ data }) => data);
}

export function setSignableDocumentInProcess(companyId: number, llcId: number, documentId: number) {
  return axios
    .post<LLCDocument>(
      `/companies/${companyId}/products/llc/company_llc/${llcId}/documents/${documentId}/set_in_process/`
    )
    .then(({ data }) => data);
}
