import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logEvent } from '@amplitude/analytics-browser';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import TopBar from '../../componentsv2/TopBar';
import MarcoLogo from '../../componentsv2/MarcoLogo';
import Typography from '../../componentsv2/Typography';
import InfoButton from '../../componentsv2/InfoButton';
import { CrossIcon } from '../../components/Icons';
import SimpleBackdrop from '../../components/Backdrop';
import { getCompanyLegalRepresentatives, getCompanyShareholders } from '../../services/documents';
import { useAppSelector } from '../../store';
import { useDocuments } from '../../hooks/documents';
import { useLazyTranslation } from '../../hooks/i18n';
import { useUserLocation } from '../../hooks/useUserLocation';
import { INVOICE_FINANCING, filesURL } from '../../constants/auth';
import { docsCollectionEvents } from '../../constants/events';
import { APILegalRepresentative, APIShareholder } from '../../types/documents';
import DocumentCategories from './DocumentCategories';
import LegalRepresentatives from './LegalRepresentatives';
import Shareholders from './Shareholders';

function getGuideUrl(country: string, language: string) {
  if (country === 'mx') return `${filesURL}/Documents_Guide_MX.pdf`;
  return `${filesURL}/Documents_Guide_${language.toUpperCase()}.pdf`;
}

const ClientDocuments = ({
  showTopBar = true,
  showStakeholders = true,
}: {
  showTopBar?: boolean;
  showStakeholders?: boolean;
}) => {
  const { t, isLoading: isLoadingTranslations } = useLazyTranslation(['common', 'client']);
  const { client } = useAppSelector((state) => state.client);
  const navigate = useNavigate();
  const { language } = useUserLocation();
  const [shareholders, setShareholders] = useState<APIShareholder[]>([]);
  const [legalRepresentatives, setLegalRepresentatives] = useState<APILegalRepresentative[]>([]);
  const [isLoadingShareholders, setIsLoadingShareholders] = useState(false);
  const [isLoadingLegalRepresentatives, setIsLoadingLegalRepresentatives] = useState(false);

  const companyId = client?.companies[0].id;

  const { loadClientCategories, groupedCategories, uploadAssets, isLoading, deleteAsset } = useDocuments(
    companyId,
    language
  );

  const loadShareholders = (companyId: number) => {
    setIsLoadingShareholders(true);
    return getCompanyShareholders(companyId)
      .then((shareholders) => {
        setShareholders(shareholders);
      })
      .finally(() => {
        setIsLoadingShareholders(false);
      });
  };

  const loadLegalRepresentatives = (companyId: number) => {
    setIsLoadingLegalRepresentatives(true);
    return getCompanyLegalRepresentatives(companyId)
      .then((legalRepresentatives) => {
        setLegalRepresentatives(legalRepresentatives);
      })
      .finally(() => {
        setIsLoadingLegalRepresentatives(false);
      });
  };

  useEffect(() => {
    logEvent(docsCollectionEvents.VIEW_DOCUMENTS_PAGE);
  }, []);

  useEffect(() => {
    if (companyId) {
      loadClientCategories();
      showStakeholders && loadShareholders(companyId);
      showStakeholders && loadLegalRepresentatives(companyId);
    }
  }, [companyId, showStakeholders]);

  if (isLoadingTranslations || isLoading || !companyId || !client)
    return showTopBar ? (
      <SimpleBackdrop hasOpaqueBackground isLoading />
    ) : (
      <Box sx={{ display: 'grid', minHeight: '100vh', placeItems: 'center', width: '100%' }}>
        <CircularProgress size={40} />
      </Box>
    );

  const guideUrl = getGuideUrl(client.country, language);

  return (
    <>
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        {showTopBar && (
          <TopBar>
            <Box sx={{ margin: 'auto', width: '80%', display: 'flex' }}>
              <IconButton href="/" sx={{ flex: 1 }} disableRipple>
                <MarcoLogo />
              </IconButton>
              <IconButton
                onClick={() => {
                  navigate(INVOICE_FINANCING);
                }}>
                <CrossIcon />
              </IconButton>
            </Box>
          </TopBar>
        )}
        <Box component="main" sx={{ flexGrow: 1, backgroundColor: '#F1F6FD', paddingTop: '2em' }}>
          <Container>
            <Grid container alignItems="center">
              <Grid item xs={12} md={8}>
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontWeight: 600,
                    background: 'linear-gradient(255.79deg, #4572B8 0%, #2FC0B8 95.35%)',
                    '-webkit-background-clip': 'text',
                    '-webkit-text-fill-color': 'transparent',
                  }}>
                  {t('client:documents.title')}
                </Typography>
                <Typography>{t('client:documents.subtitle')}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: { xs: 'initial', md: 'flex-end' },
                  }}>
                  <InfoButton title={t('client:documents.guideInfoButton')} />
                  <a
                    href={guideUrl}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      logEvent(docsCollectionEvents.CLICK_VIEW_GUIDE, {
                        Version: guideUrl.split('/').slice(-1),
                      });
                    }}
                    style={{ color: '#4F55FD' }}>
                    <Typography sx={{ color: '#4F55FD', fontWeight: '600' }}>
                      {t('client:documents.buttons.viewGuide')}
                    </Typography>
                  </a>
                </Box>
              </Grid>
              {showStakeholders && (
                <>
                  <Grid item xs={12}>
                    {isLoadingShareholders ? (
                      <Skeleton variant="rectangular" height={80} animation="wave" />
                    ) : (
                      <Shareholders
                        shareholders={shareholders}
                        companyId={companyId}
                        loadShareholders={loadShareholders}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {isLoadingLegalRepresentatives ? (
                      <Skeleton variant="rectangular" height={80} animation="wave" />
                    ) : (
                      <LegalRepresentatives
                        legalRepresentatives={legalRepresentatives}
                        companyId={companyId}
                        loadLegalRepresentatives={loadLegalRepresentatives}
                      />
                    )}
                  </Grid>
                </>
              )}
              <DocumentCategories
                groupedCategories={groupedCategories}
                companyId={companyId}
                language={language}
                loadClientCategories={loadClientCategories}
                uploadAssets={uploadAssets}
                deleteAsset={deleteAsset}
              />
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default ClientDocuments;
