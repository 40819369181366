import { styled } from '@mui/material';
import { COLORS } from '../../themev2';
import { default as MUITooltip, TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.gray[1200],
    boxShadow: theme.shadows[1],
    fontSize: 12,
    color: '#FFF !important',
    padding: '1em',
    borderRadius: '8px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLORS.gray[1200],
  },
}));

export default Tooltip;
