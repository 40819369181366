import Drawer, { DrawerProps } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../Button';
import { useTranslation } from '../../hooks/i18n';
import ConfirmDialog, { ConfirmDialogProps } from '../ConfirmDialog';
import { useState } from 'react';
import { FormikConfig } from 'formik';

type InfoDrawerProps = DrawerProps & {
  children: React.ReactNode;
  showDrawer: boolean;
  handleClose: () => void;
  buttonLabel?: string;
  isSubmitting?: boolean;
  submit?: () => void;
  back?: () => void;
  disclaimer?: string;
  disableSubmit?: boolean;
  transitionDuration?: number;
  // confirm dialog props
  formikProps?: any;
  confirmDialog?: boolean;
  validateForm?: () => Promise<Object>;
  confirmDialogProps?: Partial<ConfirmDialogProps> & {
    content: (formValues: any) => string | React.ReactNode;
  };
  setFieldTouched?: (field: string, touched: boolean, shouldValidate?: boolean) => void;
};

export default function InfoDrawer({
  children,
  showDrawer,
  handleClose,
  anchor = 'right',
  buttonLabel,
  submit,
  formikProps,
  back,
  isSubmitting = false,
  disclaimer,
  disableSubmit = false,
  transitionDuration,
  confirmDialog = false,
  confirmDialogProps,
}: InfoDrawerProps) {
  const { t } = useTranslation(['common']);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  return (
    <>
      <ConfirmDialog
        open={showConfirmDialog}
        title={confirmDialogProps?.title || 'Confirm'}
        onCancel={() => {
          setShowConfirmDialog(false);
        }}
        isLoading={isSubmitting}
        onConfirm={async () => {
          submit && submit();
          setShowConfirmDialog(false);
        }}
        content={
          confirmDialogProps?.content
            ? confirmDialogProps?.content(formikProps.values)
            : 'Are you sure you want to submit?'
        }
      />
      <Drawer
        transitionDuration={transitionDuration}
        anchor={anchor}
        open={showDrawer}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: { xs: '100%', md: '60%', lg: '50%' },
            padding: '2rem',
            paddingBottom: !!submit ? 0 : undefined,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: { xs: 'initial', md: '8px 0 0 8px' },
          },
        }}>
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', top: '5px', right: '5px', cursor: 'pointer' }}>
          <CloseIcon />
        </IconButton>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box sx={{ overflowY: 'auto', flex: 1 }}>{children}</Box>
          {submit && (
            <Box
              sx={{
                position: 'sticky',
                bottom: 0,
                py: '1rem',
                px: '2rem',
                backgroundColor: 'background.paper',
                marginX: '-2rem',
                zIndex: 10,
                boxShadow: '0px -10px 30px 0px #7F7F7F29',
              }}>
              {disclaimer && (
                <Typography
                  sx={{ fontSize: '12px', lineHeight: '16px', color: 'text.secondary', mb: '1rem' }}>
                  {disclaimer}
                </Typography>
              )}
              <Box sx={{ display: 'flex', gap: '8px' }}>
                {back && (
                  <Button
                    isFullHeight={false}
                    fullWidth
                    color="secondary"
                    type="outline"
                    onClick={() => {
                      back();
                    }}>
                    {t('common:back')}
                  </Button>
                )}
                <Button
                  disabled={disableSubmit}
                  fullWidth
                  color="secondary"
                  isLoading={isSubmitting}
                  onClick={async () => {
                    if (confirmDialog) {
                      const errors = await formikProps!.validateForm!();
                      Object.keys(formikProps.values).forEach((field) =>
                        formikProps.setFieldTouched!(field, true, false)
                      ); // Set all fields as touched
                      if (Object.keys(errors).length > 0) return;
                    }
                    confirmDialog ? setShowConfirmDialog(true) : submit();
                  }}>
                  {buttonLabel || t('common:submit')}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  );
}
