export default function BuyerCheckIcon() {
  return (
    <svg width="100" height="101" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M69.3554 94.2817C67.647 94.2817 66.2304 92.865 66.2304 91.1567V14.0734C66.2304 12.365 67.647 10.9484 69.3554 10.9484C71.0637 10.9484 72.4804 12.365 72.4804 14.0734V91.1567C72.4804 92.865 71.0637 94.2817 69.3554 94.2817Z"
        fill="url(#paint0_linear_1115_6717)"
      />
      <path
        opacity="0.4"
        d="M25.1887 85.9484H58.5133V19.2817H25.1887C15.5352 19.2817 7.71631 26.7401 7.71631 35.9484V69.2817C7.71631 78.4901 15.5352 85.9484 25.1887 85.9484ZM75.422 85.9484H71.0539V19.2817H75.422C85.0755 19.2817 92.8944 26.7401 92.8944 35.9484V69.2817C92.8944 78.4901 85.0755 85.9484 75.422 85.9484Z"
        fill="url(#paint1_linear_1115_6717)"
      />
      <path
        d="M27.6343 63.7303C26.9327 63.7303 26.2311 63.4718 25.6771 62.9178L20.1379 57.3786C19.6228 56.8574 19.334 56.1542 19.334 55.4214C19.334 54.6887 19.6228 53.9854 20.1379 53.4642C21.2088 52.3933 22.9814 52.3933 24.0523 53.4642L27.6343 57.0463L42.2399 42.3032C43.3109 41.2323 45.0834 41.2323 46.1543 42.3032C47.2252 43.3741 47.2252 45.1467 46.1543 46.2176L29.5915 62.9178C29.0376 63.4718 28.336 63.7303 27.6343 63.7303Z"
        fill="url(#paint2_linear_1115_6717)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1115_6717"
          x1="65.7096"
          y1="109.615"
          x2="74.4119"
          y2="109.433"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E57D3" />
          <stop offset="0.520833" stopColor="#2D95CD" />
          <stop offset="0.520933" stopColor="#2D96CD" />
          <stop offset="0.521033" stopColor="#2D96CC" />
          <stop offset="1" stopColor="#2EC4B6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1115_6717"
          x1="0.618133"
          y1="98.2151"
          x2="105.938"
          y2="60.7462"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E57D3" />
          <stop offset="0.520833" stopColor="#2D95CD" />
          <stop offset="0.520933" stopColor="#2D96CD" />
          <stop offset="0.521033" stopColor="#2D96CC" />
          <stop offset="1" stopColor="#2EC4B6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1115_6717"
          x1="17.032"
          y1="67.8206"
          x2="51.3967"
          y2="55.9305"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E57D3" />
          <stop offset="0.520833" stopColor="#2D95CD" />
          <stop offset="0.520933" stopColor="#2D96CD" />
          <stop offset="0.521033" stopColor="#2D96CC" />
          <stop offset="1" stopColor="#2EC4B6" />
        </linearGradient>
      </defs>
    </svg>
  );
}
