import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks';

const RootPage = () => {
  const { isAuth, isAdmin } = useAuth();

  if (!isAuth) return <Navigate to="/login" />;
  if (isAdmin) {
    return <Navigate to="/admin-dashboard" />;
  }
  return <Navigate to="/dashboard" />;
};

export default RootPage;
