import { createSlice } from '@reduxjs/toolkit';
import { countryCodes } from '../constants/data';
import { CountryKeys } from '../types';

const getUserCountryFromBrowser = (): CountryKeys => {
  // inferes country from browser

  if (navigator.language) {
    const [language, country] = navigator.language.split('-');
    const lowerCasedCountry = country?.toLowerCase() as CountryKeys;
    if (countryCodes.includes(lowerCasedCountry)) {
      return lowerCasedCountry;
    } else if (language === 'es') {
      return 'mx';
    }
  }

  return 'us';
};

type AnonymousState = {
  userLocation: CountryKeys;
};

const anonymousInitialState: AnonymousState = {
  userLocation: getUserCountryFromBrowser(),
};

export const anonymousSlice = createSlice({
  name: 'anonymous',
  initialState: anonymousInitialState,
  reducers: {
    setAnonymousUserLocation: (state, action) => {
      state.userLocation = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setAnonymousUserLocation } = anonymousSlice.actions;
