import { Form as FormikForm, FormikFormProps } from 'formik';
import { createContext } from 'react';

export const FormTrackingContext = createContext<{ trackingEvent?: string }>({});

type FormProps = FormikFormProps & {
  trackingEvent?: string;
};

export default function Form({ children, trackingEvent, ...rest }: FormProps) {
  return (
    <FormTrackingContext.Provider value={{ trackingEvent }}>
      <FormikForm {...rest}>{children}</FormikForm>
    </FormTrackingContext.Provider>
  );
}
