import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import * as service from '../services/auth';
import { CountryKeys, Nullable } from '../types';
import { Credentials, User } from '../types/auth';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  ADMIN_TAKEOVER_TOKEN_STORAGE_KEY,
  CURRENT_IMPERSONATED_COMPANY_UUID,
  REFRESH_TOKEN_STORAGE_KEY,
} from '../constants/auth';
import { SignupFields, Referral } from '../types/signup';

type Status = 'loading' | 'idle' | 'failed';

export type AuthState = {
  user: Nullable<User>;
  status: Status;
  error: Nullable<string>;
  maxRetriesError: boolean;
  userLocation: Nullable<CountryKeys>;
  loadingUserData: Status;
  shouldNavigateAfterLoadingUser: boolean;
  adminTakeoverClient: Nullable<string>;
  showSidebar: boolean;
};

const authInitialState: AuthState = {
  user: null,
  error: null,
  status: 'idle',
  maxRetriesError: false,
  userLocation: null,
  loadingUserData: 'idle',
  shouldNavigateAfterLoadingUser: false,
  adminTakeoverClient: localStorage.getItem(CURRENT_IMPERSONATED_COMPANY_UUID)
    ? localStorage.getItem(CURRENT_IMPERSONATED_COMPANY_UUID)
    : null,
  showSidebar: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setMaxRetriesError: (state, action) => {
      state.maxRetriesError = action.payload;
    },
    setShouldNavigateAfterLoadingUser: (state, action) => {
      state.shouldNavigateAfterLoadingUser = action.payload;
    },
    setAdminTakeoverClient: (state, action) => {
      state.adminTakeoverClient = action.payload;
    },
    setShowSidebar: (state, action) => {
      state.showSidebar = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload;
        state.maxRetriesError = false;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.error = null;
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loadingUserData = 'idle';
      })
      .addCase(loadUser.pending, (state, action) => {
        state.loadingUserData = 'loading';
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.loadingUserData = 'failed';
      })
      .addMatcher(isFulfilled(login, logout, signUp), (state) => {
        state.status = 'idle';
      })
      .addMatcher(isPending(login, logout, signUp), (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addMatcher(isRejected(login, logout, signUp), (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const login = createAsyncThunk('auth/login', async (credentials: Credentials) => {
  const { access, refresh } = await service.tokenApi(credentials);
  localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, access);
  localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refresh);
  const user = await service.userDetailApi();
  return user;
});

export const logout = createAsyncThunk('auth/logout', async () => {
  // using a thunk to prevent side effects inside the reducer
  localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
  localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
  localStorage.removeItem(ADMIN_TAKEOVER_TOKEN_STORAGE_KEY);
  localStorage.removeItem(CURRENT_IMPERSONATED_COMPANY_UUID);
});

export const loadUser = createAsyncThunk('auth/loadUser', async () => {
  const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  if (token) {
    const user = await service.userDetailApi();
    return user;
  }
  return null;
});

export const setUserPassword = createAsyncThunk(
  'auth/setUserPassword',
  async ({ token, password }: { token: string; password: string }) => {
    const { access, refresh } = await service.setUserPassword(token, password);
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, access);
    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refresh);
  }
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (
    {
      values,
      referral,
      page,
      language,
      resetCaptcha,
      referralClient,
    }: {
      values: SignupFields & { captcha: string };
      referral: Referral;
      page: Nullable<string>;
      language: string;
      resetCaptcha: any;
      referralClient: Nullable<string>;
    },
    { rejectWithValue }
  ) => {
    try {
      const user = await service.signUp(values, referral, page, language, referralClient);
      return user;
    } catch (error: any) {
      resetCaptcha();
      return rejectWithValue(error.response.data.error_code);
    }
  }
);

export const {
  setMaxRetriesError,
  setShouldNavigateAfterLoadingUser,
  setAdminTakeoverClient,
  setShowSidebar,
} = authSlice.actions;
