import React from 'react';
import { any, bool, oneOf, shape } from 'prop-types';
import clsx from 'clsx';
import { Breakpoint } from '@mui/system';
import ContainerWrapper from '@mui/material/Container';
import classes from './Container.module.scss';

interface ContainerProps {
  maxWidth: Breakpoint | false;
  children?: any;
  noPadding?: boolean;
  className?: string;
  innerClassName?: string;
  preset?: {
    withHeader?: boolean;
  };
}

function Container({
  preset,
  className,
  innerClassName,
  maxWidth,
  noPadding,
  children,
  ...rest
}: ContainerProps) {
  return (
    <div
      className={clsx(
        preset?.withHeader && classes.withHeader,
        className,
        classes.container,
        noPadding && classes.noPadding
      )}>
      <ContainerWrapper
        maxWidth={maxWidth}
        {...rest}
        className={clsx(classes.innerContainer, innerClassName, noPadding && classes.noPadding)}>
        {children}
      </ContainerWrapper>
    </div>
  );
}

Container.propTypes = {
  maxWidth: oneOf(['sm', 'md', 'lg', 'xl', false]),
  children: any,
  preset: shape({
    withBar: bool,
    withHeader: bool,
    withHeaderAndBar: bool,
    withHeaderAndBarAndPagination: bool,
    withHeaderBarPaginationAndFilters: bool,
  }),
};

export default Container;
