import { Navigate } from 'react-router-dom';
import { getRedirectPage, getUrlParams } from '../../utils/helpers';
import { logUrlParamsEvents } from '../../utils/tracking';

type AuthGuardProps = {
  isPublicRoute?: boolean;
  children: React.ReactNode;
  isAuth?: boolean;
  isAdmin?: boolean;
  isClientRoute?: boolean;
  isAdminRoute?: boolean;
};

const AuthGuard = (props: AuthGuardProps) => {
  const {
    children,
    isPublicRoute = false,
    isAuth = false,
    isClientRoute = false,
    isAdminRoute = false,
    isAdmin,
  } = props;

  const params = getUrlParams();
  logUrlParamsEvents(params);
  
  // If it's a public route and the user is already authenticated, redirect to dashboard or specific route based on url params
  if (isAuth && isPublicRoute) {
    const redirectPage = getRedirectPage(params);
    return <Navigate to={redirectPage || '/dashboard'} replace />;
  }

  // If it's a private route and the user is not authenticated, redirect to the login page with a next parameter
  if (!isAuth && !isPublicRoute) {
    // add `next` url Param (keeping preexisting ones if needed)
    const newParams = new URLSearchParams();
    const urlParams = getUrlParams();
    Object.entries(urlParams).forEach(([key, value]) => {
      newParams.set(key, value);
    });
    newParams.set('next', window.location.pathname);

    return <Navigate to={`/login?${newParams.toString()}`} replace />;
  }

  // If it's a client route and the user is an admin or viceversa, redirect home
  if ((isAuth && isClientRoute && isAdmin) || (isAdminRoute && !isAdmin)) return <Navigate to="/" replace />;

  // If it's a private route and the user is not authenticated, redirect to login with no params
  if (!isPublicRoute && !isAuth) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};

export default AuthGuard;
