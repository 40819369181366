// TODO Align with design
export const COLORS = Object.freeze({
  secondary: '#efefef',
  primary: '#101010',
  default: '#ffffff',
  white: '#ffffff',
  lightgray: '#fafafa',
  gray: '#e0e0e0',
  darkGray: '#333',
  midGray: '#bdbdbd',
  hardGray: '#646464',
  liteGreen: '#36FD00',
  medium: '#8D8D8D',
  black: '#000000',
  lightBlack: '#999999',
  blackMedium: '#333',
  blackOpacity: 'rgba(0,0,0,0.54)',
  blackOpacityHex: 'rgba(0,0,0,0.3)',
  blackOpacityLite: 'rgba(0,0,0,0.04)',
  lightGrayLow: 'rgb(255 255 255 / 8%)',
  whiteOpacityHex: 'rgba(255,255,255,0.3)',
  whiteGray: 'rgba(255, 255, 255, 0.04)',
  whiteOpacityHalf: 'rgba(255, 255, 255, 50%)',
  whiteOpacity: 'rgb(255 255 255 / 80%)',
  transparent: 'transparent',
  red: '#D32948',
  lightRed: '#FF5757',
  darkRed: '#CF2424',
  turquoise: '#29D3B1',
  turquoiseMin: 'rgba(41, 211, 177, 0.08)',
  mineShaft: '#323232',
  violet: '#694AB4',
});
