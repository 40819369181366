import { ReactNode } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import classes from './ModalV2.module.scss';
import { SxProps } from '@mui/material';
import { COLORS } from '../../themev2';

type ModalV2Props = {
  isOpen: boolean;
  handleClose: () => void;
  children: ReactNode;
  displayCloseButton?: boolean;
  size?: 'small' | 'medium' | 'large' | 'xl';
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  isAutoHeight?: boolean;
  sx?: SxProps;
};

const sizeToWidth = {
  small: '300px',
  medium: '600px',
  large: '900px',
  xl: '1200px',
};

const sizeToHeight = {
  small: '250px',
  medium: '350px',
  large: '600px',
  xl: '700px',
};

const ModalV2 = ({
  isOpen,
  handleClose,
  children,
  displayCloseButton = true,
  size = 'medium',
  disableEscapeKeyDown = false,
  disableBackdropClick = false,
  isAutoHeight = false,
  sx = {},
}: ModalV2Props) => {
  const onCloseModal = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' && disableBackdropClick) return;
    else if (reason === 'escapeKeyDown' && disableEscapeKeyDown) return;
    return handleClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      componentsProps={{
        backdrop: {
          sx: {
            background: `${COLORS.gray[1300]}cc`,
          },
        },
      }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      className="modal">
      <Box
        className="paper"
        sx={{
          position: 'absolute',
          width: sizeToWidth[size],
          height: isAutoHeight ? 'auto' : sizeToHeight[size],
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: 24,
          padding: 6,
          outline: 'none',
          overflowY: 'auto',
          '@media (max-width: 600px)': {
            width: '100%',
            height: '100%',
            borderRadius: 0,
          },
          ...sx,
        }}>
        {displayCloseButton && <CloseIcon onClick={handleClose} className={classes.closeIcon} />}
        {children}
      </Box>
    </Modal>
  );
};

export default ModalV2;
