export default function LogsIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 5.9502C7.59 5.9502 7.25 5.6102 7.25 5.2002V2.2002C7.25 1.7902 7.59 1.4502 8 1.4502C8.41 1.4502 8.75 1.7902 8.75 2.2002V5.2002C8.75 5.6102 8.41 5.9502 8 5.9502Z"
        fill="currentColor"
      />
      <path
        d="M16 5.9502C15.59 5.9502 15.25 5.6102 15.25 5.2002V2.2002C15.25 1.7902 15.59 1.4502 16 1.4502C16.41 1.4502 16.75 1.7902 16.75 2.2002V5.2002C16.75 5.6102 16.41 5.9502 16 5.9502Z"
        fill="currentColor"
      />
      <path
        d="M18.2 22.3502C16.02 22.3502 14.25 20.5802 14.25 18.4002C14.25 16.2202 16.02 14.4502 18.2 14.4502C20.38 14.4502 22.15 16.2202 22.15 18.4002C22.15 20.5802 20.38 22.3502 18.2 22.3502ZM18.2 15.9502C16.85 15.9502 15.75 17.0502 15.75 18.4002C15.75 19.7502 16.85 20.8502 18.2 20.8502C19.55 20.8502 20.65 19.7502 20.65 18.4002C20.65 17.0502 19.55 15.9502 18.2 15.9502Z"
        fill="currentColor"
      />
      <path
        d="M21.9999 22.9502C21.8099 22.9502 21.6199 22.8802 21.4699 22.7302L20.4699 21.7302C20.1799 21.4402 20.1799 20.9602 20.4699 20.6702C20.7599 20.3802 21.2399 20.3802 21.5299 20.6702L22.5299 21.6702C22.8199 21.9602 22.8199 22.4402 22.5299 22.7302C22.3799 22.8802 22.1899 22.9502 21.9999 22.9502Z"
        fill="currentColor"
      />
      <path
        d="M8.5 14.7002C8.37 14.7002 8.24 14.6702 8.12 14.6202C7.99 14.5702 7.87999 14.5002 7.78999 14.4102C7.74999 14.3602 7.7 14.3102 7.67 14.2602C7.63 14.2002 7.6 14.1402 7.58 14.0802C7.55 14.0202 7.53 13.9602 7.52 13.9002C7.51 13.8302 7.5 13.7602 7.5 13.7002C7.5 13.4402 7.60999 13.1802 7.78999 12.9902C7.87999 12.9002 7.99 12.8302 8.12 12.7802C8.48 12.6202 8.93001 12.7102 9.21001 12.9902C9.39001 13.1802 9.5 13.4402 9.5 13.7002C9.5 13.7602 9.49 13.8302 9.48 13.9002C9.47 13.9602 9.45 14.0202 9.42 14.0802C9.4 14.1402 9.37 14.2002 9.33 14.2602C9.29 14.3102 9.25001 14.3602 9.21001 14.4102C9.02001 14.5902 8.76 14.7002 8.5 14.7002Z"
        fill="currentColor"
      />
      <path
        d="M12 14.7002C11.74 14.7002 11.48 14.5902 11.29 14.4102C11.11 14.2202 11 13.9702 11 13.7002C11 13.5702 11.03 13.4402 11.08 13.3202C11.13 13.2002 11.2 13.0902 11.29 12.9902C11.66 12.6202 12.33 12.6202 12.71 12.9902C12.89 13.1802 13 13.4402 13 13.7002C13 13.7602 12.99 13.8302 12.98 13.9002C12.97 13.9602 12.95 14.0202 12.92 14.0802C12.9 14.1402 12.87 14.2002 12.83 14.2602C12.79 14.3102 12.75 14.3602 12.71 14.4102C12.52 14.5902 12.26 14.7002 12 14.7002Z"
        fill="currentColor"
      />
      <path
        d="M8.5 18.2002C8.24 18.2002 7.97999 18.0902 7.78999 17.9102C7.60999 17.7202 7.5 17.4602 7.5 17.2002C7.5 17.0702 7.53 16.9402 7.58 16.8202C7.63 16.6902 7.69999 16.5802 7.78999 16.4902C8.15999 16.1202 8.84001 16.1202 9.21001 16.4902C9.39001 16.6802 9.5 16.9402 9.5 17.2002C9.5 17.4602 9.39001 17.7202 9.21001 17.9102C9.02001 18.0902 8.76 18.2002 8.5 18.2002Z"
        fill="currentColor"
      />
      <path
        d="M20.5 10.0402H3.5C3.09 10.0402 2.75 9.70016 2.75 9.29016C2.75 8.88016 3.09 8.54016 3.5 8.54016H20.5C20.91 8.54016 21.25 8.88016 21.25 9.29016C21.25 9.70016 20.91 10.0402 20.5 10.0402Z"
        fill="currentColor"
      />
      <path
        d="M13.37 22.9502H8C4.35 22.9502 2.25 20.8502 2.25 17.2002V8.7002C2.25 5.0502 4.35 2.9502 8 2.9502H16C19.65 2.9502 21.75 5.0502 21.75 8.7002V13.2002C21.75 13.6102 21.41 13.9502 21 13.9502C20.59 13.9502 20.25 13.6102 20.25 13.2002V8.7002C20.25 5.8402 18.86 4.4502 16 4.4502H8C5.14 4.4502 3.75 5.8402 3.75 8.7002V17.2002C3.75 20.0602 5.14 21.4502 8 21.4502H13.37C13.78 21.4502 14.12 21.7902 14.12 22.2002C14.12 22.6102 13.78 22.9502 13.37 22.9502Z"
        fill="currentColor"
      />
    </svg>
  );
}
