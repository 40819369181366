export default function StarIcon() {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M64.1251 21.7084L70 33.4584C70.7917 35.0834 72.9167 36.625 74.7084 36.9584L85.3334 38.7084C92.1251 39.8334 93.7084 44.75 88.8334 49.6667L80.5417 57.9584C79.1667 59.3334 78.3751 62.0417 78.8334 64L81.2084 74.25C83.0834 82.3334 78.7501 85.5 71.6251 81.25L61.6667 75.3334C59.875 74.25 56.8751 74.25 55.0834 75.3334L45.125 81.25C38 85.4584 33.6667 82.3334 35.5417 74.25L37.9167 64C38.3751 62.0834 37.5834 59.375 36.2084 57.9584L27.9168 49.6667C23.0418 44.7917 24.6251 39.875 31.4168 38.7084L42.0417 36.9584C43.8334 36.6667 45.9584 35.0834 46.7501 33.4584L52.6251 21.7084C55.7501 15.3334 60.9168 15.3334 64.1251 21.7084Z"
        fill="url(#paint0_linear_3021_120)"
      />
      <path
        opacity="0.4"
        d="M33.3333 23.9584H8.33333C6.62499 23.9584 5.20833 22.5417 5.20833 20.8334C5.20833 19.125 6.62499 17.7084 8.33333 17.7084H33.3333C35.0417 17.7084 36.4583 19.125 36.4583 20.8334C36.4583 22.5417 35.0417 23.9584 33.3333 23.9584Z"
        fill="url(#paint1_linear_3021_120)"
      />
      <path
        opacity="0.4"
        d="M20.8333 82.2916H8.33333C6.62499 82.2916 5.20833 80.875 5.20833 79.1666C5.20833 77.4583 6.62499 76.0416 8.33333 76.0416H20.8333C22.5417 76.0416 23.9583 77.4583 23.9583 79.1666C23.9583 80.875 22.5417 82.2916 20.8333 82.2916Z"
        fill="url(#paint2_linear_3021_120)"
      />
      <path
        opacity="0.4"
        d="M12.5 53.125H8.33333C6.62499 53.125 5.20833 51.7083 5.20833 50C5.20833 48.2917 6.62499 46.875 8.33333 46.875H12.5C14.2083 46.875 15.625 48.2917 15.625 50C15.625 51.7083 14.2083 53.125 12.5 53.125Z"
        fill="#18A19A"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3021_120"
          x1="85.0156"
          y1="50.0333"
          x2="32.6701"
          y2="47.8243"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#0BAAA1" />
          <stop offset="1" stop-color="#0B8DAA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3021_120"
          x1="33.3376"
          y1="20.8333"
          x2="9.76255"
          y2="15.8863"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#0BAAA1" />
          <stop offset="1" stop-color="#0B8DAA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3021_120"
          x1="22.0859"
          y1="79.1666"
          x2="7.54846"
          y2="77.3363"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#0BAAA1" />
          <stop offset="1" stop-color="#0B8DAA" />
        </linearGradient>
      </defs>
    </svg>
  );
}
