export default function FolderIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M15.72 2H8.27999C7.89999 2 7.57999 2.32 7.57999 2.7C7.57999 3.08 7.89999 3.4 8.27999 3.4H11.54L12.94 5.26C13.25 5.67 13.29 5.73 13.87 5.73H17.59C17.97 5.73 18.34 5.78 18.7 5.88C18.74 6.06 18.76 6.24 18.76 6.43V6.78C18.76 7.16 19.08 7.48 19.46 7.48C19.84 7.48 20.16 7.16 20.16 6.78V6.42C20.14 3.98 18.16 2 15.72 2Z"
        fill="currentColor"
      />
      <path
        d="M20.14 6.54C19.71 6.23 19.22 6 18.69 5.87C18.34 5.77 17.96 5.72 17.58 5.72H13.86C13.28 5.72 13.24 5.66 12.93 5.25L11.53 3.39C10.88 2.53 10.37 2 8.74 2H6.42C3.98 2 2 3.98 2 6.42V17.58C2 20.02 3.98 22 6.42 22H17.58C20.02 22 22 20.02 22 17.58V10.14C22 8.65 21.27 7.34 20.14 6.54ZM14.33 16H9.67C9.28 16 8.97 15.69 8.97 15.3C8.97 14.92 9.28 14.6 9.67 14.6H14.32C14.7 14.6 15.02 14.92 15.02 15.3C15.02 15.69 14.71 16 14.33 16Z"
        fill="currentColor"
      />
    </svg>
  );
}
