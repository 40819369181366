import { useEffect, useState } from 'react';
import { logEvent } from '@amplitude/analytics-browser';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Formik, FormikProps } from 'formik';
import FormSection from '../../componentsv2/FormSection';
import Form from '../../componentsv2/Form';
import { SelectField } from '../../componentsv2/Field';
import Button from '../../componentsv2/Button';
import Uploader from '../../componentsv2/Uploader';
import { useTranslation } from '../../hooks/i18n';
import { useUserLocation } from '../../hooks/useUserLocation';
import { deleteApplicationAsset, uploadApplicationAssets } from '../../services/application';
import { ApplicationStepProps } from '../../types/application';
import { hasEmptyValues } from '../../utils/helpers';
import { filesURL } from '../../constants/auth';
import { moneyOptions } from '../../constants/data';
import { invoiceEvents } from '../../constants/events';
import { useAppSelector } from '../../store';

export type FinancingState = {
  creditFacility: number[];
};

const getTemplateUrl = (language: string) => {
  return `${filesURL}/${language === 'en' ? 'Template_Marco_EN' : 'Plantilla_Marco_ES'}.xlsx`;
};

const FinancingStepAging = ({
  onStepReturn,
  onStepSubmit,
  title,
  subtitle,
  applicationDraft,
  onFilesUpload,
  onFileDelete,
  isLoading,
  isLoadingStep,
}: ApplicationStepProps) => {
  const { t } = useTranslation('application');
  const { assets } = applicationDraft;
  const { location, language } = useUserLocation();
  const [isUploading, setIsUploading] = useState(false);
  const newCompany = useAppSelector((state) => state.client.newCompany);

  useEffect(() => {
    logEvent(invoiceEvents.STEP_THREE);
  }, []);

  const uploadFiles = (files: File[]) => {
    const data = new FormData();
    files.forEach((file) => {
      data.append('asset', file, file.name);
      data.append('file_name', file.name);
      data.append('asset_type', 'AR');
      data.append('asset_source', 'source_1');
    });
    setIsUploading(true);
    // @ts-ignore
    uploadApplicationAssets(data, applicationDraft.id, newCompany.id)
      .then((uploadedFiles) => {
        onFilesUpload(uploadedFiles);
      })
      .then(() => logEvent(invoiceEvents.UPLOAD_FILE, { 'Upload File Result': 'SUCCESS' }))
      .catch(() => logEvent(invoiceEvents.UPLOAD_FILE, { 'Upload File Result': 'ERROR' }))
      .finally(() => setIsUploading(false));
  };

  const initialValues: FinancingState = {
    creditFacility:
      applicationDraft?.moneyMin && applicationDraft?.moneyMax
        ? [applicationDraft.moneyMin, applicationDraft.moneyMax]
        : [],
  };

  const handleSubmit = (values: FinancingState) => {
    const {
      creditFacility: [moneyMin, moneyMax],
    } = values;
    const mappedValues = {
      moneyMin,
      moneyMax,
    };
    return onStepSubmit(mappedValues);
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values }: FormikProps<FinancingState>) => {
          return (
            <Form trackingEvent={invoiceEvents.EDIT_FIELD}>
              <FormSection title={t('sections.financialNeeds.title')}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <SelectField
                      name="creditFacility"
                      label={t('sections.financialNeeds.fields.creditFacilityLabel')}
                      placeholder={t('sections.financialNeeds.fields.creditFacilityPlaceholder')}
                      options={moneyOptions}
                    />
                  </Grid>
                </Grid>
              </FormSection>
              <FormSection title={t('sections.clientsInformation.title')}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12}>
                    <Typography>{t('sections.clientsInformation.subtitle')}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={7}
                    xl={6}
                    onClick={() => {
                      let template = '';
                      if (location === 'mx') {
                        template = location.toUpperCase();
                      } else {
                        template = language.toUpperCase();
                      }
                      logEvent(invoiceEvents.DOWNLOAD_TEMPLATE, {
                        Template: template,
                        'Download Template Result': 'SUCCESS',
                      });
                    }}>
                    {location && (
                      <a
                        style={{ textDecoration: 'none' }}
                        href={getTemplateUrl(language)}
                        target="_blank"
                        rel="noopener noreferrer"
                        download>
                        <Button color="secondary">
                          {t('sections.clientsInformation.downloadMarcoTemplate')}
                        </Button>
                      </a>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Uploader
                      files={assets}
                      onDrop={uploadFiles}
                      fileTypes={['xlsx']}
                      deleteFile={(assetId: number) => {
                        deleteApplicationAsset(newCompany!.id, applicationDraft.id, assetId).then(
                          (deletedAsset) => {
                            onFileDelete(deletedAsset);
                          }
                        );
                      }}
                      isUploading={isUploading}
                    />
                  </Grid>
                </Grid>
              </FormSection>
              <Grid container justifyContent="left" sx={{ marginTop: '2em' }}>
                <Grid item xs={12} md={12} sx={{ display: 'flex', gap: '1em' }}>
                  <Button type="outline" onClick={onStepReturn} disabled={isLoading}>
                    {t('steps.financing.returnBtn')}
                  </Button>
                  <Button
                    isSubmit
                    isLoading={isLoading || isLoadingStep}
                    disabled={hasEmptyValues(values) || isLoading || assets?.length === 0}>
                    {t('steps.financing.submitBtn')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default FinancingStepAging;
