import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { COLORS } from '../../themev2';

type SuspenseBackdropProps = {
  isLoading?: boolean;
};

const SuspenseBackdrop = (props: SuspenseBackdropProps) => {
  const { isLoading = true } = props;

  return (
    <div>
      <Backdrop
        open={isLoading}
        sx={{
          color: '#fff',
          zIndex: 999999,
          backgroundColor: '#F6FAFE',
        }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={50} sx={{ color: COLORS.green[600] }} />
        </Box>
      </Backdrop>
    </div>
  );
};

export default SuspenseBackdrop;
