import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '../../componentsv2/Chip';
import Section from '../../componentsv2/Section';
import { COLORS } from '../../themev2';
import Button from '../../componentsv2/Button';
import { useEffect, useState } from 'react';
import { Company, CompanyUser, CompanyUserInvitation, CompanyUserRole, UserRole } from '../../types/company';
import {
  deleteInvitation,
  deleteMember,
  editMember,
  getCompanyUserInvitations,
  inviteUser,
  resendInvitation,
} from '../../services/clients';
import { useTranslation } from '../../hooks/i18n';
import ModalV2 from '../../components/ModalV2';
import { RadioField, TextField } from '../../componentsv2/Field';
import { Formik } from 'formik';
import Form from '../../componentsv2/Form';
import { useSnackbar } from '../../context';
import { AxiosError } from 'axios';
import { CircularProgress } from '@mui/material';
import MoreMenu from '../../componentsv2/MoreMenu';
import { logEvent } from '@amplitude/analytics-browser';
import { userInviteEvents } from '../../constants/events';
import InfoDrawer from '../../componentsv2/InfoDrawer';
import {
  adminDeleteCompanyUser,
  adminDeleteInvitation,
  adminEditCompanyUserRole,
  adminGetUserInvitations,
  adminInviteUser,
  adminResendInvitation,
} from '../../services/admin';

type Member = {
  name: string;
  email: string;
  role: UserRole;
  isPending: boolean;
  invitationId?: number;
  userRoleId?: number;
};

function orderMembers(members: Member[], currentCompanyUser?: CompanyUser) {
  const currentCompanyUserIndex = members.findIndex(({ name }) => name === currentCompanyUser?.name);
  if (currentCompanyUserIndex !== -1) {
    const [currentCompanyUser] = members.splice(currentCompanyUserIndex, 1);
    members.unshift(currentCompanyUser);
  }
  return members;
}

function transformInvitation({ userName, userEmail, role, id }: CompanyUserInvitation): Member {
  return { name: userName, email: userEmail, role, isPending: true, invitationId: id };
}

type UserInvitesProps = {
  company: Company;
  currentCompanyUser?: CompanyUser;
  isCompanyAdministrator: boolean;
};

const UserInvites = ({ company, currentCompanyUser, isCompanyAdministrator }: UserInvitesProps) => {
  const { t } = useTranslation(['myAccount', 'user']);
  const [members, setMembers] = useState<Member[]>([]);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [currentlyEditing, setCurrentlyEditing] = useState<Member | null>(null);
  const [currentlyDeleting, setCurrentlyDeleting] = useState<Member | null>(null);
  const [isLoadingInvite, setIsLoadingInvite] = useState(false);
  const [isLoadingMembers, setIsLoadingMembers] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [isDeleting, setIsDeleting] = useState(false);
  const companyId = company.id;

  function loadMembers(companyId: number): Promise<void> {
    setIsLoadingMembers(true);
    const _getInvitations = !!currentCompanyUser ? getCompanyUserInvitations : adminGetUserInvitations;
    return _getInvitations(companyId)
      .then((invitations) => {
        setMembers(() => [
          ...company.companyUserRoles.map(
            ({
              user: {
                name,
                user: { email },
              },
              role,
              id,
            }) => ({
              name,
              email,
              role,
              isPending: false,
              userRoleId: id,
            })
          ),
          ...invitations.map((invitation) => transformInvitation(invitation)),
        ]);
      })
      .finally(() => {
        setIsLoadingMembers(false);
      });
  }

  function getMenuActions(member: Member): { label: string; onClick: () => void }[] {
    const { invitationId, isPending } = member;

    // if it's not an admin, return no actions
    if (!isCompanyAdministrator) return [];

    // if the user is pending, show resend and delete invitation
    if (isPending)
      return [
        {
          label: t(`users.actions.resend`),
          onClick: () => {
            const _resendInvitation = !!currentCompanyUser ? resendInvitation : adminResendInvitation;
            _resendInvitation(companyId, invitationId!).then(() => {
              logEvent(userInviteEvents.RESEND_INVITE);
              showSnackbar(t('users.createSuccess'), 'success');
            });
          },
        },
        {
          label: t(`users.actions.deleteInvite`),
          onClick: () => {
            const _deleteInvitation = !!currentCompanyUser ? deleteInvitation : adminDeleteInvitation;
            _deleteInvitation(companyId, invitationId!).then(() => {
              logEvent(userInviteEvents.DELETE_INVITE);
              showSnackbar(t('users.deleteSuccess'), 'success');
              loadMembers(companyId);
            });
          },
        },
      ];

    // if the user is not pending, show edit and delete
    return [
      {
        label: t(`users.actions.edit`),
        onClick: () => {
          setCurrentlyEditing(member);
          setIsFormModalOpen(true);
        },
      },
      {
        label: t(`users.actions.delete`),
        onClick: () => {
          setCurrentlyDeleting(member);
        },
      },
    ];
  }

  useEffect(() => {
    logEvent(userInviteEvents.VIEW_PAGE);
    loadMembers(companyId);
  }, [company, companyId]);

  return (
    <>
      <Section>
        <Grid container alignItems="center">
          <Grid item xs={12} md>
            <Typography sx={{ fontWeight: 600, fontSize: '18px' }}>{t('users.title')}</Typography>
          </Grid>
          {isCompanyAdministrator && (
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  onClick={() => {
                    setIsFormModalOpen(true);
                  }}>
                  {t('users.addBtn')}
                </Button>
              </Box>
            </Grid>
          )}
          {isLoadingMembers && (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Grid>
          )}
          {orderMembers(members, currentCompanyUser).map((member, index) => {
            const { name, email, role, isPending, invitationId, userRoleId } = member;
            const menuActions = getMenuActions(member);

            return (
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <Avatar sx={{ backgroundColor: COLORS.gray[1100] }}>
                        {name.charAt(0).toUpperCase()}
                      </Avatar>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          paddingLeft: '1em',
                        }}>
                        <Typography sx={{ fontWeight: 600 }}>
                          {name} {name === currentCompanyUser?.name && `(${t('users.you')})`}
                        </Typography>
                        <Typography sx={{ color: COLORS.gray[1100] }}>{email}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  {isPending && (
                    <Grid item xs={1}>
                      <Typography>{t(`users.invited`)}</Typography>
                    </Grid>
                  )}
                  <Grid item>
                    <Chip label={t(`users.roles.${role}`)} />
                  </Grid>
                  <Grid item xs={1}>
                    {name !== currentCompanyUser?.name && menuActions.length > 0 && (
                      <MoreMenu items={menuActions} />
                    )}
                  </Grid>
                  {index !== members.length - 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Section>
      <Formik
        key={currentlyEditing?.email}
        initialValues={{
          userName: currentlyEditing?.name || '',
          userEmail: currentlyEditing?.email || '',
          role: (currentlyEditing?.role || '') as UserRole,
        }}
        validationSchema={Yup.object().shape({
          userName: currentlyEditing ? Yup.string() : Yup.string().required(t('common:requiredField')),
          userEmail: currentlyEditing ? Yup.string() : Yup.string().required(t('common:requiredField')),
          role: Yup.string().required(t('common:requiredField')),
        })}
        onSubmit={(values) => {
          setIsLoadingInvite(true);
          const _editMember = !!currentCompanyUser ? editMember : adminEditCompanyUserRole;
          const _inviteUser = !!currentCompanyUser ? inviteUser : adminInviteUser;
          const result = currentlyEditing
            ? _editMember(companyId, currentlyEditing.userRoleId!, values)
            : _inviteUser(companyId, values);
          result
            .then((result) => {
              const event = currentlyEditing ? userInviteEvents.EDIT_INVITE : userInviteEvents.FORM_SUBMITTED;

              setCurrentlyEditing(null);
              logEvent(event, values);
              const successMessage = currentlyEditing ? 'users.editSuccess' : 'users.createSuccess';
              showSnackbar(t(successMessage), 'success');

              loadMembers(companyId).then(async () => {
                // update members list setting the result role
                setMembers((members) => {
                  const memberIndex = members.findIndex(({ email }) => email === currentlyEditing?.email);
                  if (memberIndex !== -1) {
                    members[memberIndex].role = values.role;
                  }
                  return members;
                });

                const successMessage = currentlyEditing ? 'users.editSuccess' : 'users.createSuccess';
                showSnackbar(t(successMessage), 'success');
                setIsFormModalOpen(false);
                setCurrentlyEditing(null);
              });
            })
            .catch((error: AxiosError) => {
              const errorCode = error?.response?.data.error_code;
              showSnackbar(t(`users.errors.${errorCode}`, { userEmail: values.userEmail }), 'error');
            })
            .finally(() => {
              setIsLoadingInvite(false);
            });
        }}>
        {({ submitForm }) => (
          <InfoDrawer
            showDrawer={isFormModalOpen}
            submit={submitForm}
            isSubmitting={isLoadingInvite}
            handleClose={() => {
              setIsFormModalOpen(false);
              setCurrentlyEditing(null);
            }}>
            <Form trackingEvent={userInviteEvents.EDIT_FIELD}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: '24px',
                      fontWeight: 600,
                      background: 'linear-gradient(255.79deg, #4572B8 0%, #2FC0B8 95.35%)',
                      '-webkit-background-clip': 'text',
                      '-webkit-text-fill-color': 'transparent',
                    }}>
                    {t(currentlyEditing ? 'users.modal.editTitle' : 'users.modal.createTitle')}
                  </Typography>
                  <Typography>{t('users.modal.description')}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={!!currentlyEditing}
                    name="userName"
                    label={t('users.modal.userName.label')}
                    placeholder={t('users.modal.userName.placeholder')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={!!currentlyEditing}
                    name="userEmail"
                    label={t('users.modal.userEmail.label')}
                    placeholder={t('users.modal.userEmail.placeholder')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RadioField
                    name="role"
                    label={t('users.modal.roles.label')}
                    options={[
                      ...(!!currentlyEditing && !currentCompanyUser
                        ? [
                            {
                              value: 'owner',
                              label: t('users.modal.roles.options.owner.title'),
                              subLabel: t('users.modal.roles.options.owner.description'),
                              disabled: members.some(({ role }) => role === 'owner'),
                            },
                          ]
                        : []),
                      {
                        value: 'administrator',
                        label: t('users.modal.roles.options.administrator.title'),
                        subLabel: t('users.modal.roles.options.administrator.description'),
                      },
                      {
                        value: 'collaborator',
                        label: t('users.modal.roles.options.collaborator.title'),
                        subLabel: t('users.modal.roles.options.collaborator.description'),
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Form>
          </InfoDrawer>
        )}
      </Formik>
      <ModalV2
        isAutoHeight
        isOpen={!!currentlyDeleting}
        handleClose={() => {
          setCurrentlyDeleting(null);
        }}>
        {currentlyDeleting && (
          <Grid container>
            <Grid item>
              <Typography>
                {t('users.confirmationModal.description', {
                  name: currentlyDeleting.name,
                  email: currentlyDeleting.email,
                })}
              </Typography>
            </Grid>
            <Grid item sx={{ display: 'flex', gap: '4px', marginLeft: 'auto' }}>
              <Button
                type="outline"
                color="danger"
                onClick={() => {
                  setCurrentlyDeleting(null);
                }}>
                {t('users.confirmationModal.cancelBtn')}
              </Button>
              <Button
                color="danger"
                isLoading={isDeleting}
                onClick={() => {
                  const _deleteMember = !!currentCompanyUser ? deleteMember : adminDeleteCompanyUser;
                  setIsDeleting(true);
                  _deleteMember(companyId, currentlyDeleting.userRoleId!).then(() => {
                    logEvent(userInviteEvents.DELETE_USER);
                    showSnackbar(t('users.deleteMemberSuccess'), 'success');
                    setMembers((members) => {
                      const memberIndex = members.findIndex(
                        ({ email }) => email === currentlyDeleting!.email
                      );
                      if (memberIndex !== -1) {
                        members.splice(memberIndex, 1);
                      }
                      return members;
                    });
                    setCurrentlyDeleting(null);
                    setIsDeleting(false);
                  });
                }}>
                {t('users.confirmationModal.confirmBtn')}
              </Button>
            </Grid>
          </Grid>
        )}
      </ModalV2>
    </>
  );
};

export default UserInvites;
