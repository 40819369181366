import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { COLORS } from '../../themev2';
import { CrossIcon, DocumentUploadIcon, SmallDocumentIcon, TrashIcon } from '../../components/Icons';
import Button from '../Button';
import Typography from '../Typography';
import { useTranslation } from '../../hooks/i18n';
import { APIAsset } from '../../types/application';

type UploaderProps = {
  files: APIAsset[] | File[];
  onDrop?: (acceptedFiles: File[]) => void;
  fileTypes?: AcceptedFileType[];
  deleteFile?: Function;
  isUploading: boolean;
  isCompact?: boolean;
  maxSize?: string;
};

type AcceptedFileType = keyof typeof acceptedFilesMapper;

type _File = {
  id: string;
  name: string;
};

const acceptedFilesMapper = {
  xlsx: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pdf: 'application/pdf',
  image: 'image/jpeg, image/jpg, image/png',
};

const mapFileTypesToAcceptedFiles = (fileTypes: AcceptedFileType[]): string => {
  const mimeTypes = fileTypes.map((fileType) => {
    const mimeType = acceptedFilesMapper[fileType];
    return mimeType;
  });
  return mimeTypes.join(',');
};

const Uploader = ({
  onDrop,
  files,
  fileTypes,
  deleteFile,
  isUploading,
  isCompact = false,
  maxSize = '200MB',
}: UploaderProps) => {
  const _onDrop = useCallback(onDrop ? onDrop : () => {}, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: _onDrop,
    accept: fileTypes ? mapFileTypesToAcceptedFiles(fileTypes) : '',
  });
  const { t } = useTranslation();
  const _files: _File[] = (files as any[]).map((f: any) => {
    return {
      id: f.id || f.name,
      name: f.fileName || f.name,
    };
  });

  return (
    <Box
      {...getRootProps()}
      sx={{
        cursor: 'pointer',
        background: COLORS.gray[300],
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        border: `1px dashed ${COLORS.gray[1000]}`,
      }}>
      <input {...getInputProps()} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: isCompact ? '1em' : '2em',
          gap: isCompact ? '12px' : '24px',
        }}>
        {isUploading ? (
          <Grid container>
            <Grid item xs={12}>
              <Typography textAlign="center" sx={{ fontSize: '1.5em' }}>
                {t('uploader.uploading')}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size={48} />
            </Grid>
          </Grid>
        ) : (
          <>
            <DocumentUploadIcon />
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{t('uploader.title')}</Typography>
            {!isCompact && <Typography>{t('uploader.maxSize', { maxSize })}</Typography>}
            <Button>{t('uploader.button')}</Button>
            {!!_files.length && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '24px',
                  alignItems: 'center',
                  textOverflow: 'ellipsis',
                  flexWrap: 'wrap',
                }}>
                {_files.map((f) => (
                  <Box
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    sx={{
                      position: 'relative',
                      background: '#E4EEFC',
                      display: 'flex',
                      border: `1px solid ${COLORS.gray[1000]}`,
                      padding: '1em',
                      marginBottom: '1em',
                      borderRadius: '10px',
                      cursor: 'pointer',
                      alignItems: 'center',
                      gap: '12px',
                    }}
                    key={f.id}>
                    <SmallDocumentIcon />
                    <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{f.name}</Typography>
                    {deleteFile && (
                      <IconButton
                        onClick={() => deleteFile(f.id)}
                        sx={{
                          position: 'absolute',
                          top: -10,
                          right: -10,
                          padding: '4px',
                          color: '#FFFFFF',
                          background: '#91A2BB',
                          width: '24px',
                          height: '24px',
                          '&:hover': {
                            backgroundColor: '#91A2BB',
                          },
                        }}>
                        <CrossIcon />
                      </IconButton>
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Uploader;
