export default function BankIcon({ size = 'md' }: { size?: 'md' | 'lg' }) {
  const width = size === 'md' ? 24 : 100;
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 100 101"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M51.5417 9.30957L89.0417 24.3095C90.5 24.8928 91.6667 26.6428 91.6667 28.1844V42.0178C91.6667 44.3095 89.7917 46.1845 87.5 46.1845H12.5C10.2083 46.1845 8.33334 44.3095 8.33334 42.0178V28.1844C8.33334 26.6428 9.50003 24.8928 10.9584 24.3095L48.4584 9.30957C49.2917 8.97624 50.7083 8.97624 51.5417 9.30957Z"
      />
      <path
        opacity="0.4"
        d="M91.6667 92.0182H8.33334V79.5182C8.33334 77.2266 10.2083 75.3516 12.5 75.3516H87.5C89.7917 75.3516 91.6667 77.2266 91.6667 79.5182V92.0182Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0844 46.1846V75.3512H15.6677V46.1846H26.0844ZM45.5017 46.1846V75.3512H35.085V46.1846H45.5017ZM64.915 46.1846V75.3512H54.4983V46.1846H64.915ZM84.3323 46.1846V75.3512H73.9156V46.1846H84.3323Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.04166 92.0186C1.04166 90.2927 2.44077 88.8936 4.16666 88.8936H95.8333C97.5592 88.8936 98.9583 90.2927 98.9583 92.0186C98.9583 93.7444 97.5592 95.1436 95.8333 95.1436H4.16666C2.44077 95.1436 1.04166 93.7444 1.04166 92.0186Z"
      />
      <path d="M50 35.7686C53.4518 35.7686 56.25 32.9703 56.25 29.5186C56.25 26.0668 53.4518 23.2686 50 23.2686C46.5482 23.2686 43.75 26.0668 43.75 29.5186C43.75 32.9703 46.5482 35.7686 50 35.7686Z" />
    </svg>
  );
}
