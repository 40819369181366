import { COLORS } from '../../themev2';

export default function SecuritySafeIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M20.91 11.8651C20.91 16.7551 17.36 21.3351 12.51 22.6751C12.18 22.7651 11.82 22.7651 11.49 22.6751C6.64 21.3351 3.09 16.7551 3.09 11.8651V7.47506C3.09 6.65506 3.71 5.72506 4.48 5.41506L10.05 3.13506C11.3 2.62506 12.71 2.62506 13.96 3.13506L19.53 5.41506C20.29 5.72506 20.92 6.65506 20.92 7.47506L20.91 11.8651Z"
      />
      <path d="M14.5 11.2451C14.5 10.5821 14.2366 9.94619 13.7678 9.47735C13.2989 9.00851 12.663 8.74512 12 8.74512C11.337 8.74512 10.7011 9.00851 10.2322 9.47735C9.76339 9.94619 9.5 10.5821 9.5 11.2451C9.5 12.3651 10.24 13.2951 11.25 13.6151V16.2451C11.25 16.6551 11.59 16.9951 12 16.9951C12.41 16.9951 12.75 16.6551 12.75 16.2451V13.6151C13.2561 13.4579 13.6987 13.1433 14.0135 12.717C14.3283 12.2907 14.4987 11.775 14.5 11.2451Z" />
    </svg>
  );
}
