import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Layout from '../../componentsv2/Layout';
import Typography from '../../componentsv2/Typography';
import Section from '../../componentsv2/Section';
import { COLORS } from '../../themev2';
import Button from '../../componentsv2/Button';
import { Link } from 'react-router-dom';
import ModalV2 from '../../components/ModalV2';
import { TextField } from '../../componentsv2/Field';
import { Formik } from 'formik';
import Form from '../../componentsv2/Form';
import ErrorMessage from '../../components/ErrorMessage';
import { logEvent } from '@amplitude/analytics-browser';
import { myAccountEvents } from '../../constants/events';
import { useState } from 'react';
import { Nullable } from '../../types';
import { changePassword } from '../../services/clients';
import { useSnackbar } from '../../context';
import { useTranslation } from '../../hooks/i18n';

const MyAccountAdmin = () => {
  const { t } = useTranslation(['myAccount', 'user']);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);
  const [passwordError, setPasswordError] = useState<Nullable<any>>(null);
  const { showSnackbar } = useSnackbar();

  return (
    <Layout>
      <Typography variant="h5" sx={{ marginBottom: '1em' }}>
        {t('title')}
      </Typography>
      <Section>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Typography fontWeight="600" sx={{ fontSize: '18px' }}>
              {t('personalInfo.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ color: COLORS.gray[1100] }}>{t('personalInfo.password')}</Typography>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                setShowPasswordModal(true);
              }}>
              {t('personalInfo.changePassword')}
            </Link>
          </Grid>
        </Grid>
      </Section>
      <ModalV2
        isAutoHeight
        isOpen={showPasswordModal}
        handleClose={() => {
          setPasswordError(null);
          setShowPasswordModal(false);
        }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{
                background: COLORS.secondary.greenGradient,
                backgroundClip: 'text',
                '-webkit-background-clip': 'text',
                color: 'transparent',
              }}>
              {t('personalInfo.changePassword')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={{ oldPassword: '', newPassword: '' }}
              validationSchema={Yup.object().shape({
                oldPassword: Yup.string().required(t('common:requiredField')),
                newPassword: Yup.string().required(t('common:requiredField')),
              })}
              onSubmit={(values) => {
                setIsLoadingPassword(true);
                setPasswordError(null);
                changePassword(values)
                  .then(() => {
                    logEvent(myAccountEvents.CHANGE_PASSWORD);
                    showSnackbar(t('changePassword.passwordSuccess'), 'success');
                    setShowPasswordModal(false);
                  })
                  .catch((error) => {
                    setPasswordError(error);
                  })
                  .finally(() => {
                    setIsLoadingPassword(false);
                  });
              }}>
              <Form>
                <TextField type="password" name="oldPassword" label={t('changePassword.oldPassword')} />
                <TextField type="password" name="newPassword" label={t('changePassword.newPassword')} />
                {passwordError && <ErrorMessage errorMessage={t('changePassword.passwordError')} />}
                <Box sx={{ marginY: '2em' }}>
                  <Button color="secondary" fullWidth isLoading={isLoadingPassword} isSubmit>
                    {t('saveChanges')}
                  </Button>
                </Box>
              </Form>
            </Formik>
          </Grid>
        </Grid>
      </ModalV2>
    </Layout>
  );
};

export default MyAccountAdmin;
