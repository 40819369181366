import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import * as applicationService from '../services/application';
import * as clientService from '../services/clients';
import { DropzoneFile, Nullable } from '../types';
import { APIAsset } from '../types/application';

type Status = 'loading' | 'idle' | 'failed';

export type AssetsState = {
  assets: APIAsset[];
  status: Status;
  error: Nullable<string>;
};

const assetsInitialState: AssetsState = {
  assets: [],
  error: null,
  status: 'idle',
};

export const assetsSlice = createSlice({
  name: 'assets',
  initialState: assetsInitialState,
  reducers: {
    resetAssetsSlice: () => assetsInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssets.fulfilled, (state, action) => {
        state.assets = action.payload;
      })
      .addCase(getAssetsByUserId.fulfilled, (state, action) => {
        state.assets = action.payload;
      })
      .addMatcher(isFulfilled(getAssets, uploadAssets, getAssetsByUserId), (state) => {
        state.status = 'idle';
      })
      .addMatcher(isPending(getAssets, uploadAssets, getAssetsByUserId), (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addMatcher(isRejected(getAssets, uploadAssets, getAssetsByUserId), (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  },
});

export const getAssets = createAsyncThunk(
  'assets/getAssets',
  async (isDebtor: boolean = false, { getState }) => {
    // const state = getState() as RootState;
    let assets = await clientService.getAssets();
    // TODO: Check this logic when we add Debtors
    // if (isDebtor) {
    //   let invoicesKeys: any = [];
    //   const debtorInvoices = state.form.DebtorInvoices;
    //   debtorInvoices.forEach((invoice: any) => {
    //     invoicesKeys.push(invoice.invoice_key);
    //   });
    //   assets.filter((asset: APIAsset) => invoicesKeys.includes(asset.invoice));
    // }
    return assets.reverse();
  }
);

export const uploadAssets = createAsyncThunk(
  'assets/uploadAssets',
  async ({
    files,
    title = null,
    applicationId,
  }: {
    files: DropzoneFile[];
    title?: Nullable<string>;
    applicationId: number;
  }) => {
    const data = new FormData();
    files.forEach((file: DropzoneFile) => {
      data.append('asset', file, file.name);
      data.append('file_name', file.name);
      data.append('asset_type', title ?? 'OTHER');
      data.append('asset_source', 'source_1');
    });
    const assets = await applicationService.uploadAssets(data, applicationId);
    return assets;
  }
);

export const getAssetsByUserId = createAsyncThunk('assets/getAssetsByUserId', async (id: number) => {
  const assets = await clientService.getAssetsByUserId(id);
  return assets;
});

export const { resetAssetsSlice } = assetsSlice.actions;
