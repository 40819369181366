import { SignupCountryKeys } from '../types';

export const getRndInteger = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

export function formatDate(date: Date, locale = 'en-US', format = {}, showWeekday = true, onlyDate = false) {

  let currentDate = date;
  if (onlyDate) {
    // Use this to prevent timezone issues when working with ONLY dates, like "2022-12-25"
    const utcYear = date.getUTCFullYear();
    const utcMonth = date.getUTCMonth();
    const utcDay = date.getUTCDate();
    currentDate = new Date(utcYear, utcMonth, utcDay);
  }

  try {
    return currentDate.toLocaleDateString(locale, {
      weekday: showWeekday ? 'long' : undefined,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      ...format,
    });
  } catch (error) {
    // Fallback to a default locale
    return currentDate.toLocaleDateString('en-US', {
      weekday: showWeekday ? 'long' : undefined,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      ...format,
    });
  }
}



export function formatDateTime(date: Date, locale = 'en-US', format = {}, showWeekday = false) {
  let d = formatDate(date, locale, format);
  if (!showWeekday) {
    d = d.split(' ').slice(1).join('');
  }
  const t = date.toLocaleTimeString();

  return `${d} ${t}`;
}

export const zipCodesMap: Partial<Record<SignupCountryKeys, RegExp>> = {
  ca: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
  co: /^\d{6}$/,
  dk: /^\d{4}$/,
  do: /^\d{5}$/,
  ec: /^\d{6}$/,
  sv: /^\d{5}$/,
  gt: /^\d{5}$/,
  hn: /^\d{5}$/,
  mx: /^\d{5}$/,
  ni: /^\d{5}$/,
  pa: /^\d{4}$/,
  pe: /^\d{5}$/,
  pr: /^\d{5}$/,
  us: /^\b\d{5}\b(?:[- ]{1}\d{4})?$/,
  bo: /^\d{4}$/,
  br: /^\d{5}-\d{3}$/,
  cl: /^\d{7}$/,
  py: /^\d{4}$/,
  uy: /^\d{5}$/,
  cr: /^\d{4,5}$/,
  es: /^\d{5}$/,
  ch: /^\d{4}$/,
  in: /^\d{6}$/,
};

export function formatNumericValue(value: number, digits = 2, language = 'en-US', currency?: string) {
  const formattedValue = new Intl.NumberFormat(language, {
    useGrouping: true,
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(value);
  return `${formattedValue}${currency ? ` ${currency}` : ''}`;
}

export function formatBankingDateDetails(value: string, language = 'en-US') {
  const date = new Date(value);

  const datePart = date.toLocaleString(language, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert 24-hour time to 12-hour time
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedHours = hours.toString().padStart(2, '0');

  const formattedTime = `${formattedHours}:${minutes} ${ampm}`;

  if (language.startsWith('en')) {
    return `${datePart} at ${formattedTime}`;
  } else {
    return `${datePart} ${formattedTime}`;
  }
}

export function formatBankingDate(value: string, language = 'en-US') {
  const date = new Date(value);
  return date.toLocaleString(language, {
    timeZoneName: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

export const transactionTypeTranslations = {
  DISPUTE: 'Dispute',
  DISPUTE_REVERSAL: 'Dispute Reversal',
  DISPUTE_LOST: 'Dispute Lost',
  REFUND: 'Refund',
  REFUND_REVERSAL: 'Refund Reversal',
  REFUND_FAILURE: 'Refund Failure',
  PAYMENT_RESERVE_HOLD: 'Payment Reserve Hold',
  PAYMENT_RESERVE_RELEASE: 'Payment Reserve Release',
  PAYOUT: 'Payout',
  PAYOUT_FAILURE: 'Payout Failure',
  PAYOUT_REVERSAL: 'Payout Reversal',
  CONVERSION_BUY: 'Conversion Buy',
  CONVERSION_SELL: 'Conversion Sell',
  CONVERSION_REVERSAL: 'Conversion Reversal',
  DEPOSIT: 'Deposit',
  ADJUSTMENT: 'Adjustment',
  FEE: 'Fee',
  DD_CREDIT: 'Direct Debit Credit',
  DD_DEBIT: 'Direct Debit Debit',
  DC_CREDIT: 'Debit Card Credit',
  DC_DEBIT: 'Debit Card Debit',
  TRANSFER: 'Transfer',
  PAYMENT: 'Payment',
  ISSUING_AUTHORISATION_HOLD: 'Issuing Authorisation Hold',
  ISSUING_AUTHORISATION_RELEASE: 'Issuing Authorisation Release',
  ISSUING_CAPTURE: 'Issuing Capture',
  ISSUING_REFUND: 'Issuing Refund',
  CARD_PURCHASE: 'Card Purchase',
  PURCHASE: 'Purchase',
};
