import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TermSheetState {
  factoring_line?: number;
  factoring_line_increase?: number;
  advance_rate?: number;
  discount_fees?: number;
  discount_fees_past_due?: number;
  discount_fees_past_due_grace?: number;
  insurance_cost?: number;
  servicing_fee?: number;
  due_diligance?: string;
}

const TermSheetInitialState: TermSheetState = {};

// Slice
export const termSheet = createSlice({
  name: 'termSheet',
  initialState: {
    TermSheet: TermSheetInitialState,
  },
  reducers: {
    setTermSheet: (state, action: PayloadAction<TermSheetState>) => {
      state.TermSheet = { ...state.TermSheet, ...action.payload };
    },
  },
});

// Actions
export const { 
  setTermSheet,
} = termSheet.actions;
